.listen {
    &__audio {
        margin: 2em 0 2em;
        audio {
            width: 100%;
            margin-bottom: em(1.6, 1);
        }
        &-title {
            font-size: 1.2em;
            color: #384249;
            font-weight: $light;
            font-style: italic;
        }
    }
    &__download {
        position: relative;
        padding-left: 2.2em;
        font-size: 1.3em;
        font-weight: $light;
        color: #3c3c3b!important;
        @include default-icons-absolute-before('\E00D', 1.6rem, $color-black, 50%, inherit, inherit, 0, 'icons-project');
        &:before {
            padding: 0.3rem 0.3rem;
            border: 1px solid #3c3c3b;
            transform: translateY(-50%);
        }
    }
}
