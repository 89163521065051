.agenda {
    padding: 6.5rem 0 5.5rem;
    background-color: $color-3--1;
    .title-decoration {
        span,
        .btn-wrap {
            background-color: $color-3--1;
        }
    }
    &__wrap {
        margin: 2.5rem -0.1rem 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        > * {
            width: 25%;
            padding: 0 0.1rem 2.5rem;
            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0;
            }
        }
        img {
            width: 100%;
            height: auto;
            display: block;
            margin: 0 auto;
        }
    }
    &__content {
        padding: 1.7rem 2.5rem 0 7rem;
        .category {
            margin-bottom: 0.4rem;
            color: $color-2--3;
            font-size: 1.2rem;
        }
        a {
            display: block;
            position: relative;
            padding-bottom: 1rem;
            margin-bottom: 0.7rem;
            color: $color-black;
            font-size: 1.8rem;
            font-weight: $black;
            &:before {
                width: 3.2rem;
                height: 0.1rem;
                background-color: $color-2--2;
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
            }
        }
        &--noimg {
            margin-top: 4.3rem;
        }
    }
    .date-1 {
        position: absolute;
        top: -3rem;
        left: 0;
    }

    .hour-place {
        text-align: left;
    }

    .place {
        padding-left: 1.5rem;
        @include default-icons-absolute-before('\e050', 1.3rem, $color-2--2, 0, inherit, inherit, 0, 'icons-default');
        &:before {
            font-style: normal;
        }
    }

    &-proposer {
        width: 22.5rem;
        height: 4rem;
        display: block;
        margin: 3rem auto 0;
        padding: 1.5rem 1rem 1.5rem 3.8rem;
        color: $color-black;
        font-size: 1.3rem;
        font-weight: $bold;
        background-color: transparent;
        border: 1px solid $color-2--2;
        &:before {
            left: 1.5rem;
            font-size: 1.4rem;
            content: '\e05c';
            color: $color-black;
        }
        &:hover,
        &:focus {
            border-color: $color-3--4;
        }
    }
}

.agenda-callendar {
    background-color: $color-2--3;
    form {
        margin: 0;
        padding: 3rem 0 0.5rem;
    }
}
.agenda-form-theme {
    .form__field-wrapper {
        margin: 0 auto 1.75em;
        max-width: 242px;
    }
    .field-wrapper {
        display: flex;
        flex-wrap: wrap;
        color: $color-white;
        * {
            color: inherit;
        }
        label {
            display: block;
            font-size: 1.6em;
            width: 100%;
            text-transform: uppercase;
            color: $color-white;
        }
        select {
            border: none;
            width: auto;
            flex-grow: 1;
            padding: em(1.2, $input__font-size) em(1, $input__font-size);
        }
        button {
            margin: 1.5px 0 1.5px 10px;
            width: 37px;
            height: 37px;
            background-color: $color-2--1;
            border: none;
            border-radius: 50%;
            &:before {
                color: inherit;
                transition: none;
            }
            &:hover {
                background-color: $color-white;
                color: $color-2--1;
            }
        }
    }
}
.agenda__figure {
    display: table;
    margin: 0 auto;
}
