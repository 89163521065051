.site-infos {
    width: 50%;
    margin: 0 auto!important;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    &__img-wrap {
        margin-right: 0;
        margin-bottom: 2.5rem;
    }
    p {
        &.site-infos__contact {
            .button-1 {
                margin-top: 2rem;
            }
        }
    }
}
