.block-contact {
    .list-infos__wrapper {
        display: flex;
        .list-infos__listitems + .list-infos__listitems {
            padding-top: 0;
            margin-top: 0;
            border-top: 0;
        }
    }
}
