// # Global
$domain: "ploermel.bzh";

// ## Typography
$import-google-font-1: "https://fonts.googleapis.com/css?family=Raleway:100,200,300,400,500,600,700,800,900";
$import-google-font-2: "https://fonts.googleapis.com/css?family=Oswald:300,400,700";

$typo-1: 'Raleway', sans-serif;
$typo-2: $typo-1;
$typo-3: $typo-1;

$thin: 100;
$extralight: 200;
$light: 300;
$normal: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;
$black: 900;

// ## Path
$image-bg-url: "../Images/bg/";
$image-fancybox-url: "../Images/fancybox/";
$font-path: "../Fonts/";

// ## Media queries
$smartphone: 767;
$tablet: 1279;

// ## B&w
$color-black: #000;
$color-white: #fff;
$color-red: #fe0000;
$color-green: #278417;
$color-blue: #0091c4;

// ## Alert & required
$alert-background: #fcdcde;
$alert-title: #ff000d;
$alert-color: $color-black;

// ## color-1
$color-1--1: #c4e2a5;
$color-1--2: #88c54c;
$color-1--3: #66a12c;

// ## color-2
$color-2--1: #68c9f3;
$color-2--2: #00acec;
$color-2--3: #1075a1;
$color-2--4: #242c59;

// ## Color-3
$color-3--1: #ebf2f5;
$color-3--2: #c2d9e1;
$color-3--3: #808f95;
$color-3--4: #4c4c4c;
$color-3--5: #f6f6f6;
$color-3--6: #727272;
