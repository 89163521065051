.kiosque {
    &__wrap {
        align-items: center;
        flex-direction: column;
    }
    &__bottom {
        display: flex;
        align-items: center;
    }
    .list-document-1__listitems {
        margin-top: 2rem;
        padding-left: 0;
    }
    .bloc-publications {
        width: 85%;
        &__wrapper {
            flex-direction: column;
        }
    }
    &__newsletter {
        max-width: 100%;
        margin-left: 0;
        h3 {
            b {
                display: inline-block;
            }
        }
        form {
            display: inline-block;
        }
        .newsletter__links {
            margin-left: 2rem;
            margin-top: 1rem;
            li {
                display: block;
                margin-top: 0.5rem;
                float: none;
            }
        }
    }
    .newsletter__fields {
        max-width: 100%;
        display: flex;
        &-wrapper {
            width: 32rem;
            max-width: 32rem;
            vertical-align: middle;
        }
    }
}
