.telecharger {
    margin: 2rem 0;
    .list-document {
        &__listitems {
            padding: 3rem 1rem;
            > * {
                margin-left: 0;
                padding: 2.3rem 1rem 2.3rem 0;
                flex-basis: 100%;
                &:nth-child(odd) {
                    border-right: none;
                }
                &:nth-child(3) {
                    border-bottom: 1px solid $color-white;
                }
            }
        }
    }
}
