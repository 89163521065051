.telecharger {
    .list-document {
        &__listitems {
            flex-direction: row;
        }
        &__title-file {
            a {
                line-height: 1.1;
            }
        }
    }
}
