.block-contact {
    margin: 6rem 0;
    .list-type-3 {
        &__item {
            display: flex;
            padding: 0;
            &:before,
            &:after {
                content: none;
            }
            img {
                max-width: 100%;
                margin-right: 0;
            }
            .list-infos {
                flex: 1;
                padding: 3.6rem 4rem;
                &__item {
                    padding-left: 0;
                    &:before {
                        content: '';
                    }
                    &.list-infos__hours {
                        $list-infos__item__font-size: 1.5;
                        padding-left: em(2.4, $list-infos__item__font-size);
                        &:before {
                            content: "\e05b";
                        }
                    }
                    &.list-infos__website,
                    &.list-infos__email {
                        width: 4rem;
                        height: 4rem;
                        display: inline-block;
                        text-indent: -9999px;
                        overflow: hidden;
                        a {
                            width: 100%;
                            height: 100%;
                            display: block;
                            padding: 0;
                            margin: 0;
                            &:before {
                                font-size: 1.6rem;
                                text-indent: 0;
                                color: $color-white;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                            }
                        }
                    }
                    &.list-infos__website {
                        a {
                            &:before {
                                content: "\e089";
                            }
                        }
                    }
                    &.list-infos__email {
                        a {
                            &:before {
                                content: "\e028";
                            }
                        }
                    }
                    a {
                        text-decoration: none;
                        &:hover,
                        &:focus {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
        &__picture {
            min-width: 28rem;
        }
        &__category {
            font-size: 1.2rem;
            font-weight: $normal;
            text-transform: uppercase;
        }
        &__title {
            margin-bottom: 0;
            font-size: 2rem;
            font-weight: $black;
        }
        &__wrapper {
            & + .list-infos__item {
                margin-top: 0;
            }
        }
    }
    .list-infos__listitems {
        .list-infos__item:first-child {
            margin-top: 0;
        }
        &:first-child {
            padding-right: 1.5rem;
        }
        & + .list-infos__listitems {
            border-left: 0;
            padding-left: 0;
        }
    }
    h2 {
        margin-bottom: 1.5rem;
        font-size: 1.8rem;
        font-weight: $black;
        color: $color-3--4;
        text-transform: uppercase;
    }
}
