.agenda {
    &__wrap {
        flex-direction: column;
        > * {
            width: 100%;
            margin: 0 auto;
        }
    }
    &-home {
        .agenda {
            &__wrap {
                max-width: 100%;
            }
            &__item {
                width: 100%;
                max-width: 100%;
            }
        }
    }
}
