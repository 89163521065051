.banner {
    &__item {
        height: 30.5rem;
    }
    &__info {
        top: 3rem;
        right: 8rem;
        &-wrap {
            a {
                font-size: 3.5rem;
            }
        }
    }
    .pagerCarrousel1 {
        margin-top: 6rem;
    }
}
