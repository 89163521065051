// @name Header page
.header {
    //overflow: hidden;
    &--right,
    > .search-box {
        display: none;
    }
}

// @name Main section
.section-main {
    $this: &;
    &__wrapper {
        padding-top: 3rem;
    }
    &__wrapper-2 {
        display: flex;
        flex-wrap: wrap;
        > * {
            display: block;
        }
    }
    &__content {
        padding-top: 3.5rem;
        & + #{$this}__aside {
            padding-right: 0;
            padding-left: 0;
            .column-bloc {
                padding-right: 0;
                padding-left: 0;
            }
        }
    }
    &__aside {
        width: 100%;
        padding-right: 0;
        padding-left: 0;
    }
}

// @name Footer
.footer {
    .footer__wrapper-2 {
        flex-wrap: wrap;
    }
    .site-infos {
        flex-basis: 60%;
        flex-shrink: 0;
    }
    .menu-cross {
        flex-basis: 30%;
        flex-shrink: 0;
    }
    .newsletter {
        margin-top: 7.5rem;
        margin-left: 15rem;
        flex-basis: 43.5%;
    }
    .community {
        margin-top: 7.5rem;
        flex-basis: 29%;
    }
}
