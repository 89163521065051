.family-portal {
    a {
        display: block;
        padding: 1rem 2rem;
        border: 1px solid #cccccc;
        font-size: 1.6rem;
        color: $color-black;
        text-transform: uppercase;
        text-decoration: none;
        font-weight: $light;
        transition: all 0.3s ease;
        svg {
            display: inline-block;
            width: 3.3rem;
            height: 3.3rem;
            fill: none;
            stroke: $color-2--2;
            stroke-width: 10;
        }
        p {
            padding-left: 0.3rem;
            display: inline-block;
        }
        b {
            display: block;
            font-weight: $black;
        }
        &:hover,
        &:focus {
            background-color: #cfe3ec;
        }
    }
}
