// @name Header page
.header {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 3rem;
    position: relative;
    &--right {
        min-width: 22.1rem;
        display: flex;
        align-items: center;
    }
}

// @name Top of content
.top-of-content {
    background: $color-3--5;

}

.top-of-content__wrapper {
    @extend .wrapper-main;
    padding: 2em 0;
    border-bottom: 2px solid rgba(197, 197, 197, 0.5);
}

// @name Main wrapper
.main {
    width: 100%;
    clear: both;
}

// @name Main section
.section-main {
    $this: &;
    // @name Main section wrappers
    &__wrapper {
        @extend .wrapper-main;
        padding-top: 7em;
        padding-bottom: 3em;
    }
    &__wrapper-2 {
        display: table;
        width: 100%;
        table-layout: fixed;
        > * {
            display: table-cell;
            vertical-align: top;
        }
    }
    // @name Main section aside
    &__aside {
        width: 35rem;
        padding-right: 7rem;
        > .column-bloc {
            *:first-child {
                margin-top: 0;
            }
        }
    }
    // @name Main section content
    &__content {
        width: 100%;
    }
}

// @name Footer
.footer {
    background: $color-black url('../Images/temp/footer-bg.png') no-repeat center;
    .footer__wrapper {
        @extend .wrapper-main--home;
        padding-top: 3em;
        padding-bottom: 1em;
    }
    .footer__wrapper-2 {
        margin: 0 -1.5em;
        display: flex;
        width: 100%;
        > * {
            margin: 0 1.5em;
        }
    }
    .site-infos {
        flex-basis: 39%;
        flex-shrink: 0;
    }
    .menu-cross {
        flex-shrink: 0;
    }
    .newsletter {

    }
    .community {
        flex-basis: 17%;
    }
}
