// @name Videos
.videos {
    margin: 4em 0;
}

.video__title {
    $video__title__font-size: 1.7;
    font-family: $typo-2;
    font-weight: $black;
    font-style: italic;
    font-size: #{$video__title__font-size}em;
    color: $color-2--3;
    margin-bottom: em(1.5, $video__title__font-size);
}

.videos__item {
    position: relative;
    width: 100%;
    margin: 0 auto;

    video {
        width: 100%;
    }

    .videos__ratio + iframe,
    .videos__ratio + .iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
}

.videos__ratio {
    display: block;
    width: 100%;
    height: auto;
}

.ce-textpic {
    .videos,
    .videos__item {
        margin: 0;
    }
}

// @name Videos for popin
// @description if only one, no margin
.popin {
    #content {
        .setPos {
            &:only-of-type {
                .videos {
                    margin: 0;
                    .videos__item {
                        &:only-of-type {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}
