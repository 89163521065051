.actus {

    /* Local variable */
    $this: &;

    background-color: $color-white;
    .title-decoration {
        span,
        .btn-wrap {
            background-color: $color-white;
        }
    }
    .news__wrap {
        > * {
            width: 33.33%;
        }
        img {
            width: 100%;
            height: auto;
        }
    }
    &--lg {
        display: flex;
        position: relative;
        #{$this}__info {
            position: absolute;
            top: 50%;
            left: 45%;
            transform: translateY(-50%);
            background-color: $color-2--3;
            color: $color-white;
            a {
                color: $color-white;
                text-transform: uppercase;
                h3 {
                    position: relative;
                    font-weight: $thin;
                    font-size: 4rem;
                    &:before {
                        width: 0.1rem;
                        height: 9.3rem;
                        content: '';
                        position: absolute;
                        bottom: -3rem;
                        left: -3rem;
                        background-color: $color-white;
                        transform: rotate(-35deg);
                    }
                }
                b {
                    font-weight: $black;
                }
            }
            p {
                margin-top: 3rem;
                margin-left: 2.5rem;
                font-size: 1.5rem;
                font-weight: $light;
            }
            &-wrap {
                padding: 3.5rem 4rem 8.5rem;
                position: relative;
                &:before {
                    width: 0.1rem;
                    height: 7rem;
                    content: '';
                    position: absolute;
                    bottom: -0.7rem;
                    left: 40%;
                    background-color: $color-white;
                    transform: rotate(-35deg);
                }
            }
        }
    }
}
