// @name Cross menu
.menu-cross {
    text-transform: uppercase;
    overflow: hidden;
    li {
        display: block;
        vertical-align: top;
        margin-bottom: 0.7rem;
        padding: .2em 1.5em;
        @include default-icons-absolute-before('\e027', 1.1rem, $color-white, 0.3rem, inherit, inherit, 0);
        a {
            color: $color-white;
            text-decoration: none;
            font-size: 1.2rem;
            font-weight: $light;
            &:hover,
            &:focus {
                span {
                    text-decoration: underline;
                }
            }
        }
        &.active a {
            text-decoration: none;
            font-weight: $bold;
        }
        &:first-child {
            margin-top: 1rem;
        }
    }
}
