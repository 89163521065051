@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Raleway:100,200,300,400,500,600,700,800,900");
@import url("https://fonts.googleapis.com/css?family=Oswald:300,400,700");
.icons-default,
.icons-project {
  content: "\e001";
  font-family: 'icons-default';
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  speak: none;
}

.icons-project {
  font-family: 'icons-project';
}

.ghost, .nolabel > label,
.nolabel > legend, .invisible {
  position: absolute !important;
  top: auto !important;
  left: -9999px !important;
  width: 1px !important;
  height: 1px !important;
  overflow: hidden !important;
}

.hidden {
  display: none;
}

.clear-fix:after, .radio:after, .checkbox:after, .wrapper-main:after, .sg-menu ul:after, .top-of-content__wrapper:after, .section-main__wrapper:after, .heading .headding__wrapper-title:after, .heading .heading__wrapper:after, .menu-stratis .menu-stratis__level-1:after, .menu-main-1:after, .wrapper-main--home:after, .footer .footer__wrapper:after, .pager-single-xl ul:after, .list-type-1:after, .list-type-1__item:after, .list-type-2:after, .list-type-3:after, .answerChart:after, .social .social-list-head:after {
  clear: both;
  display: block;
  content: "";
  overflow: hidden;
  visibility: hidden;
  height: 0;
  speak: none;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

@font-face {
  font-family: "icons-default";
  src: url("../Fonts/default-icons-webfont.eot");
  src: url("../Fonts/default-icons-webfont.eot?#iefix") format("embedded-opentype"), url("../Fonts/default-icons-webfont.woff") format("woff"), url("../Fonts/default-icons-webfont.ttf") format("truetype"), url("../Fonts/default-icons-webfont.svg#icons-default") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "icons-project";
  src: url("../Fonts/icons.eot");
  src: url("../Fonts/icons.eot?#iefix") format("embedded-opentype"), url("../Fonts/icons.woff") format("woff"), url("../Fonts/icons.ttf") format("truetype"), url("../Fonts/icons.svg#icons-project") format("svg");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  overflow-x: hidden;
}

.svg-symbol {
  display: none;
}

/* Grillade : Simple Grid System */
/* Demo : http://codepen.io/raphaelgoetter/pen/EyvbqA */
/* Grids common rules (for mono- and multi-lines grid) */
/* 1. avoid min-width: auto */
/* 2. avoid min-height: auto */
@media (min-width: 768px) {
  [class*=" grid"] > *,
  [class^="grid"] > * {
    box-sizing: border-box;
    min-width: 0;
    /* 1. */
    min-height: 0;
    /* 2. */
  }
  [class*=" grid"].has-gutter,
  [class^="grid"].has-gutter {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
  [class*=" grid"].has-gutter > *,
  [class^="grid"].has-gutter > * {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
  [class*=" grid"].has-gutter-l,
  [class^="grid"].has-gutter-l {
    margin-right: -1rem;
    margin-left: -1rem;
  }
  [class*=" grid"].has-gutter-l > *,
  [class^="grid"].has-gutter-l > * {
    margin-right: 1rem;
    margin-left: 1rem;
  }
  [class*=" grid"].has-gutter-xl,
  [class^="grid"].has-gutter-xl {
    margin-right: -2rem;
    margin-left: -2rem;
  }
  [class*=" grid"].has-gutter-xl > *,
  [class^="grid"].has-gutter-xl > * {
    margin-right: 2rem;
    margin-left: 2rem;
  }
  /* Mono-line grid system (.grid) */
  .grid {
    display: flex;
  }
  .grid > * {
    flex: 1 1 0%;
  }
  /* Multi-line grid system (.grid-X) */
  /* 1. @bugfix IE https://github.com/alsacreations/KNACSS/issues/133; */
  [class*=" grid-"],
  [class^="grid-"] {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  [class*=" grid-"] > *,
  [class^="grid-"] > * {
    flex: 0 0 auto;
    width: calc(100% - .01px);
    /* 1. */
  }
  [class*=" grid-"].has-gutter > *,
  [class^="grid-"].has-gutter > * {
    width: calc(100% - 1rem - .01px);
  }
  [class*=" grid-"].has-gutter-l > *,
  [class^="grid-"].has-gutter-l > * {
    width: calc(100% - 2rem - .01px);
  }
  [class*=" grid-"].has-gutter-xl > *,
  [class^="grid-"].has-gutter-xl > * {
    width: calc(100% - 4rem - .01px);
  }
}

/* Grid offsets */
.push {
  margin-left: auto;
}

.pull {
  margin-right: auto;
}

/* Grid order */
.grid-item-first {
  order: -1;
}

.grid-item-last {
  order: 1;
}

[class*="--reverse"] {
  flex-direction: row-reverse;
}

@media (min-width: 768px) {
  [class*="grid-2"] > * {
    width: calc(100% * 1 / 2 - .01px);
  }
  [class*="grid-2"].has-gutter > * {
    width: calc(100% * 1 / 2 - 1rem - .01px);
  }
  [class*="grid-2"].has-gutter-l > * {
    width: calc(100% * 1 / 2 - 2rem - .01px);
  }
  [class*="grid-2"].has-gutter-xl > * {
    width: calc(100% * 1 / 2 - 4rem - .01px);
  }
  [class*="grid-3"] > * {
    width: calc(100% * 1 / 3 - .01px);
  }
  [class*="grid-3"].has-gutter > * {
    width: calc(100% * 1 / 3 - 1rem - .01px);
  }
  [class*="grid-3"].has-gutter-l > * {
    width: calc(100% * 1 / 3 - 2rem - .01px);
  }
  [class*="grid-3"].has-gutter-xl > * {
    width: calc(100% * 1 / 3 - 4rem - .01px);
  }
  [class*="grid-4"] > * {
    width: calc(100% * 1 / 4 - .01px);
  }
  [class*="grid-4"].has-gutter > * {
    width: calc(100% * 1 / 4 - 1rem - .01px);
  }
  [class*="grid-4"].has-gutter-l > * {
    width: calc(100% * 1 / 4 - 2rem - .01px);
  }
  [class*="grid-4"].has-gutter-xl > * {
    width: calc(100% * 1 / 4 - 4rem - .01px);
  }
  [class*="grid-5"] > * {
    width: calc(100% * 1 / 5 - .01px);
  }
  [class*="grid-5"].has-gutter > * {
    width: calc(100% * 1 / 5 - 1rem - .01px);
  }
  [class*="grid-5"].has-gutter-l > * {
    width: calc(100% * 1 / 5 - 2rem - .01px);
  }
  [class*="grid-5"].has-gutter-xl > * {
    width: calc(100% * 1 / 5 - 4rem - .01px);
  }
  [class*="grid-6"] > * {
    width: calc(100% * 1 / 6 - .01px);
  }
  [class*="grid-6"].has-gutter > * {
    width: calc(100% * 1 / 6 - 1rem - .01px);
  }
  [class*="grid-6"].has-gutter-l > * {
    width: calc(100% * 1 / 6 - 2rem - .01px);
  }
  [class*="grid-6"].has-gutter-xl > * {
    width: calc(100% * 1 / 6 - 4rem - .01px);
  }
  [class*="grid-7"] > * {
    width: calc(100% * 1 / 7 - .01px);
  }
  [class*="grid-7"].has-gutter > * {
    width: calc(100% * 1 / 7 - 1rem - .01px);
  }
  [class*="grid-7"].has-gutter-l > * {
    width: calc(100% * 1 / 7 - 2rem - .01px);
  }
  [class*="grid-7"].has-gutter-xl > * {
    width: calc(100% * 1 / 7 - 4rem - .01px);
  }
  [class*="grid-8"] > * {
    width: calc(100% * 1 / 8 - .01px);
  }
  [class*="grid-8"].has-gutter > * {
    width: calc(100% * 1 / 8 - 1rem - .01px);
  }
  [class*="grid-8"].has-gutter-l > * {
    width: calc(100% * 1 / 8 - 2rem - .01px);
  }
  [class*="grid-8"].has-gutter-xl > * {
    width: calc(100% * 1 / 8 - 4rem - .01px);
  }
  [class*="grid-9"] > * {
    width: calc(100% * 1 / 9 - .01px);
  }
  [class*="grid-9"].has-gutter > * {
    width: calc(100% * 1 / 9 - 1rem - .01px);
  }
  [class*="grid-9"].has-gutter-l > * {
    width: calc(100% * 1 / 9 - 2rem - .01px);
  }
  [class*="grid-9"].has-gutter-xl > * {
    width: calc(100% * 1 / 9 - 4rem - .01px);
  }
  [class*="grid-10"] > * {
    width: calc(100% * 1 / 10 - .01px);
  }
  [class*="grid-10"].has-gutter > * {
    width: calc(100% * 1 / 10 - 1rem - .01px);
  }
  [class*="grid-10"].has-gutter-l > * {
    width: calc(100% * 1 / 10 - 2rem - .01px);
  }
  [class*="grid-10"].has-gutter-xl > * {
    width: calc(100% * 1 / 10 - 4rem - .01px);
  }
  [class*="grid-11"] > * {
    width: calc(100% * 1 / 11 - .01px);
  }
  [class*="grid-11"].has-gutter > * {
    width: calc(100% * 1 / 11 - 1rem - .01px);
  }
  [class*="grid-11"].has-gutter-l > * {
    width: calc(100% * 1 / 11 - 2rem - .01px);
  }
  [class*="grid-11"].has-gutter-xl > * {
    width: calc(100% * 1 / 11 - 4rem - .01px);
  }
  [class*="grid-12"] > * {
    width: calc(100% * 1 / 12 - .01px);
  }
  [class*="grid-12"].has-gutter > * {
    width: calc(100% * 1 / 12 - 1rem - .01px);
  }
  [class*="grid-12"].has-gutter-l > * {
    width: calc(100% * 1 / 12 - 2rem - .01px);
  }
  [class*="grid-12"].has-gutter-xl > * {
    width: calc(100% * 1 / 12 - 4rem - .01px);
  }
}

/* Sizing individual children */
@media (min-width: 768px) {
  .full {
    flex: 0 0 auto;
    width: calc(100% - .01px);
  }
  .has-gutter .full {
    width: calc(100% - 1rem - .01px);
  }
  .has-gutter-l .full {
    width: calc(100% - 2rem - .01px);
  }
  .has-gutter-xl .full {
    width: calc(100% - 4rem - .01px);
  }
  .one-half {
    flex: 0 0 auto;
    width: calc(50% - .01px);
  }
  .has-gutter .one-half {
    width: calc(50% - 1rem - .01px);
  }
  .has-gutter-l .one-half {
    width: calc(50% - 2rem - .01px);
  }
  .has-gutter-xl .one-half {
    width: calc(50% - 4rem - .01px);
  }
  .one-third {
    flex: 0 0 auto;
    width: calc(100% / 3 - .01px);
  }
  .has-gutter .one-third {
    width: calc(100% / 3 - 1rem - .01px);
  }
  .has-gutter-l .one-third {
    width: calc(100% / 3 - 2rem - .01px);
  }
  .has-gutter-xl .one-third {
    width: calc(100% / 3 - 4rem - .01px);
  }
  .one-quarter {
    flex: 0 0 auto;
    width: calc(100% / 4 - .01px);
  }
  .has-gutter .one-quarter {
    width: calc(100% / 4 - 1rem - .01px);
  }
  .has-gutter-l .one-quarter {
    width: calc(100% / 4 - 2rem - .01px);
  }
  .has-gutter-xl .one-quarter {
    width: calc(100% / 4 - 4rem - .01px);
  }
  .one-fifth {
    flex: 0 0 auto;
    width: calc(100% / 5 - .01px);
  }
  .has-gutter .one-fifth {
    width: calc(100% / 5 - 1rem - .01px);
  }
  .has-gutter-l .one-fifth {
    width: calc(100% / 5 - 2rem - .01px);
  }
  .has-gutter-xl .one-fifth {
    width: calc(100% / 5 - 4rem - .01px);
  }
  .two-thirds {
    flex: 0 0 auto;
    width: calc(100% / 3 * 2 - .01px);
  }
  .has-gutter .two-thirds {
    width: calc(100% / 3 * 2 - 1rem - .01px);
  }
  .has-gutter-l .two-thirds {
    width: calc(100% / 3 * 2 - 2rem - .01px);
  }
  .has-gutter-xl .two-thirds {
    width: calc(100% / 3 * 2 - 4rem - .01px);
  }
  .three-quarters {
    flex: 0 0 auto;
    width: calc(100% / 4 * 3 - .01px);
  }
  .has-gutter .three-quarters {
    width: calc(100% / 4 * 3 - 1rem - .01px);
  }
  .has-gutter-l .three-quarters {
    width: calc(100% / 4 * 3 - 2rem - .01px);
  }
  .has-gutter-xl .three-quarters {
    width: calc(100% / 4 * 3 - 4rem - .01px);
  }
}

/* Responsive Small Breakpoint */
@media (min-width: 768px) and (max-width: 767px) {
  [class*="-small-4"] > * {
    width: calc(100% / 4 - .01px);
  }
  [class*="-small-4"].has-gutter > * {
    width: calc(100% / 4 - 1rem - .01px);
  }
  [class*="-small-4"].has-gutter-l > * {
    width: calc(100% / 4 - 2rem - .01px);
  }
  [class*="-small-4"].has-gutter-xl > * {
    width: calc(100% / 4 - 4rem - .01px);
  }
  [class*="-small-3"] > * {
    width: calc(100% / 3 - .01px);
  }
  [class*="-small-3"].has-gutter > * {
    width: calc(100% / 3 - 1rem - .01px);
  }
  [class*="-small-3"].has-gutter-l > * {
    width: calc(100% / 3 - 2rem - .01px);
  }
  [class*="-small-3"].has-gutter-xl > * {
    width: calc(100% / 3 - 4rem - .01px);
  }
  [class*="-small-2"] > * {
    width: calc(100% / 2 - .01px);
  }
  [class*="-small-2"].has-gutter > * {
    width: calc(100% / 2 - 1rem - .01px);
  }
  [class*="-small-2"].has-gutter-l > * {
    width: calc(100% / 2 - 2rem - .01px);
  }
  [class*="-small-2"].has-gutter-xl > * {
    width: calc(100% / 2 - 4rem - .01px);
  }
  [class*="-small-1"] > * {
    width: calc(100% - .01px);
  }
  [class*="-small-1"].has-gutter > * {
    width: calc(100% - 1rem - .01px);
  }
  [class*="-small-1"].has-gutter-l > * {
    width: calc(100% - 2rem - .01px);
  }
  [class*="-small-1"].has-gutter-xl > * {
    width: calc(100% - 4rem - .01px);
  }
}

* {
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  box-sizing: border-box;
}

*,
*:after,
*:before {
  box-sizing: inherit;
}

body {
  line-height: 1.3;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ul {
  list-style: none;
}

ol {
  list-style: decimal inside none;
}

blockquote,
q {
  quotes: none;
}

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: '';
  content: none;
}

.italic,
em,
i {
  font-style: italic;
}

.strong,
b,
strong {
  font-weight: 700;
}

b em,
b i,
em b,
em strong,
i b,
i strong,
strong em,
strong i {
  font-weight: 700;
  font-style: italic;
}

img {
  vertical-align: bottom;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
}

a:focus, a:hover {
  text-decoration: underline;
}

a img {
  border: 0;
}

abbr,
acronym,
dfn {
  cursor: help;
  border-bottom: 1px dotted #000;
}

abbr a,
acronym a,
dfn a {
  display: inline !important;
}

kbd {
  border-color: grey;
  border-style: solid;
  border-width: 1px 2px 2px 1px;
  padding: 0 0.4em;
}

button {
  background: none;
  border: none;
  cursor: pointer;
  display: inline-block;
  margin: 0;
  overflow: visible;
  padding: 0;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
}

input[type="button"] {
  vertical-align: middle;
  width: auto;
  border: none;
  cursor: pointer;
}

mark {
  background: yellow;
  color: #000;
}

iframe {
  border: none;
}

button::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner {
  padding: 0 !important;
  border: 0 none !important;
}

.sg-title-2 {
  font-size: 3.5em;
  color: #88c54c;
  margin: 1.14286em 0 0.57143em;
}

.sg-title-3 {
  font-size: 2.9em;
  color: #00acec;
  margin: 1.2069em 0 0.62069em;
}

.sg-title-4 {
  font-size: 2em;
  color: #000;
  margin: 1.6em 0 0.75em;
}

.sg-code {
  margin: 2.14286em 0 0.71429em;
  padding: 15px 10px;
  background: #3f3f3f;
  position: relative;
  display: block;
  white-space: pre;
  overflow: auto;
  max-width: 700px;
}

.sg-code:before {
  position: absolute;
  top: 0;
  right: 0;
  padding: 2px 5px;
  background: #808f95;
  display: block;
  content: "Code";
  font-size: 1.2em;
  font-family: "Raleway", sans-serif;
  margin-bottom: 2px;
  color: #fff;
  border-bottom-left-radius: 5px;
}

.sg-code code {
  font-family: monospace;
  font-size: 1.4em;
  border-radius: 2px;
  color: #fff;
  text-align: left;
}

.sg-description {
  margin: 0.71429em 0 0.35714em;
  background: #67757b;
  border-radius: 2px;
  padding: 10px;
  font-size: 1.4em;
  color: #fff;
}

.sg-description:before {
  display: block;
  content: "Description:";
  font-size: 1.2em;
  font-family: "Raleway", sans-serif;
  margin-bottom: 2px;
  color: #fff;
  font-weight: 700;
}

.sg-footer p {
  font-size: 1.4rem;
  text-align: center;
}

.sg-footer a {
  color: #88c54c;
  text-decoration: underline;
}

.sg-footer a:hover, .sg-footer a:focus {
  text-decoration: none;
}

.sg-menu {
  background: #88c54c;
}

.sg-menu li {
  display: inline-block;
  vertical-align: middle;
}

.sg-menu li.active a, .sg-menu a:hover, .sg-menu a:focus {
  background: #2d3336;
}

.sg-menu a {
  color: #fff;
  font-size: 1.2em;
  display: block;
  padding: 0.66667em 0.5em;
  transition: background ease .3s;
}

.ddm {
  position: relative;
}

.ddm .ddm__sub-level {
  position: absolute;
  top: 30px;
  right: 0;
  z-index: 100;
  display: none;
}

.ddm.ddm--active .ddm__sub-level {
  display: block;
}

.ddm.ddm--off .ddm__title {
  display: none;
}

.ddm.ddm--off .ddm__sub-level {
  position: static;
  top: inherit;
  right: inherit;
  z-index: inherit;
  display: block;
}

.ddm.ddm--on .ddm__title {
  display: block;
}

.access__title {
  border-bottom: 1px dotted #000;
}

.access-tooltip {
  position: absolute;
  background: #66a12c;
  border: 1px solid gray;
  border-radius: 3px;
  padding: 0.76923em;
  color: #fff;
  font-size: 1.3em;
  max-width: 30vw;
}

.access-tooltip:before {
  content: "";
  position: absolute;
  top: -12px;
  left: 12px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px 0 0 12px;
  border-color: transparent transparent transparent #66a12c;
}

.combobox-container {
  position: relative;
}

.combobox-suggestions {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  cursor: pointer;
}

.combobox-suggestion {
  font-size: 1.2em;
  color: #000;
  background: #ebf2f5;
  border-right: 0.1rem solid #c2d9e1;
  border-bottom: 0.1rem solid #c2d9e1;
  border-left: 0.1rem solid #c2d9e1;
  padding: .5rem;
}

.combobox-suggestion:first-child {
  border-top: 0.1rem solid #c2d9e1;
}

.combobox-suggestion:hover, .combobox-suggestion:focus {
  background: #66a12c;
  color: #fff;
}

.combobox-suggestion:focus {
  outline: 0.1rem dotted #fff;
  outline-offset: -.2rem;
}

.combobox-clear-button {
  position: absolute;
  right: .8rem;
  top: 50%;
  transform: translateY(-50%);
  width: 2.5rem;
  height: 2.5rem;
  font-family: inherit;
  font-size: 1.4em;
  color: #000;
  background: #ebf2f5;
  border-radius: 50%;
  transition: background ease .3s, color ease .3s;
}

.combobox-clear-button:hover, .combobox-clear-button:focus {
  background: #808f95;
  color: #fff;
}

.tooltip-css {
  position: relative;
}

.tooltip-css::before, .tooltip-css::after {
  position: absolute;
  display: block;
  z-index: -98;
  transition: all ease-out .1s;
  pointer-events: none;
}

.tooltip-css::before {
  transform: translateY(-100%);
  top: calc(100% + 10px);
  left: 0;
  content: attr(data-tooltip);
  speak: none;
  white-space: nowrap;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 300;
  background: #00acec;
  padding: 10px 15px;
  opacity: 0;
}

.tooltip-css::after {
  opacity: 0;
  transform: translateY(-100%);
  bottom: -1rem;
  left: 1.5rem;
  content: '';
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-right: 0 solid transparent;
  border-bottom: 0 solid transparent;
  border-left: 8px solid #00acec;
}

.tooltip-css:hover, .tooltip-css:focus {
  text-decoration: none;
}

.tooltip-css:hover::before, .tooltip-css:hover::after, .tooltip-css:focus::before, .tooltip-css:focus::after {
  z-index: 98;
  opacity: 1;
  transition: all ease-out .4s;
  transform: translateY(0);
}

.tooltip-css.tooltip-css--right::before, .tooltip-css.tooltip-css--right:after {
  left: inherit;
}

.tooltip-css.tooltip-css--right::before {
  right: -15px;
}

.tooltip-css.tooltip-css--right::after {
  right: 0px;
}

.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  vertical-align: top;
}

.fancybox-galerie button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  border-radius: 0;
  text-decoration: none;
}

.fancybox-galerie button:before {
  background: none;
  content: "";
  speak: none;
}

.fancybox-wrap p {
  font-size: 1.3em;
}

.fancybox-wrap a:focus {
  outline: 1px dotted #fff;
}

.fancybox-wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8020;
  background: #000;
  color: #000;
}

.fancybox-skin {
  overflow: visible;
  position: relative;
  text-shadow: none;
  background: #fff;
  z-index: 9000;
}

.fancybox-galerie .fancybox-skin {
  background: #000;
}

.fancybox-opened {
  z-index: 10011;
}

.fancybox-outer, .fancybox-inner {
  position: relative;
}

.fancybox-outer span {
  display: inline-block;
}

.fancybox-inner {
  overflow: hidden;
}

.fancybox-type-iframe .fancybox-inner {
  -webkit-overflow-scrolling: touch;
  height: calc(100vh - 75px) !important;
}

.fancybox-error {
  color: #444;
  margin: 0;
  white-space: nowrap;
}

.fancybox-image, .fancybox-iframe {
  display: block;
  width: 100%;
  height: 100%;
}

.fancybox-image {
  max-width: 100%;
  max-height: 100%;
}

#fancybox-loading {
  background: url("../Images/fancybox/loading_bg.png") no-repeat 0 0;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
  opacity: 0.8;
  cursor: pointer;
  z-index: 8060;
}

#fancybox-loading div {
  width: 44px;
  height: 44px;
  background: url("../Images/fancybox/fancybox_loading.gif") center center no-repeat;
}

.fancybox-close {
  position: absolute;
  bottom: -15px;
  right: 15px;
  padding-right: 15px;
  color: #fff;
  cursor: pointer;
  z-index: 8060;
  font-size: 1.3em;
  font-weight: 700;
  text-decoration: none;
  background: url("../Images/fancybox/close.png") no-repeat 100% 50%;
}

.fancybox-close i {
  font-style: normal;
}

.fancybox-close img {
  display: none;
}

.fancybox-close:hover, .fancybox-close:focus {
  color: #fff;
  font-weight: 700;
  font-size: 1.3em;
  text-decoration: none;
}

.fancybox-close:hover .hover, .fancybox-close:focus .hover {
  text-decoration: underline;
}

.fancybox-nav {
  position: absolute;
  bottom: -35px;
  width: 40%;
  height: 20px;
  cursor: pointer;
  text-decoration: none;
  z-index: 8070;
  color: #fff;
  font-size: 1.3em;
}

.fancybox-nav:hover .hover, .fancybox-nav:focus .hover {
  text-decoration: underline;
}

.fancybox-nav img {
  display: none;
}

.fancybox-prev {
  left: 10%;
  text-align: right;
}

.fancybox-prev span {
  padding: 0 10px 0 20px;
  background: url("../Images/fancybox/precedent.png") no-repeat 0 50%;
}

.fancybox-next {
  right: 10%;
  text-align: left;
}

.fancybox-next span {
  padding: 0 20px 0 10px;
  background: url("../Images/fancybox/suivant.png") no-repeat 100% 50%;
}

.fancybox-nav:hover span {
  visibility: visible;
}

.fancybox-tmp {
  position: absolute;
  top: -99999px;
  left: -99999px;
  visibility: hidden;
  max-width: 99999px;
  max-height: 99999px;
  overflow: visible !important;
}

.fancybox-lock {
  overflow: visible !important;
}

.fancybox-lock body {
  overflow: hidden !important;
}

.fancybox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;
  z-index: 8010;
  background: rgba(0, 0, 0, 0.5);
}

.fancybox-overlay-fixed {
  position: fixed;
  bottom: 0;
  right: 0;
}

.fancybox-lock .fancybox-overlay {
  overflow: auto;
  overflow-y: scroll;
}

.fancybox-title {
  visibility: hidden;
  position: relative;
  text-shadow: none;
  z-index: 8050;
  padding: 0 15px 15px 15px;
}

.fancybox-title .captionFancybox {
  padding-right: 100px;
}

.fancybox-galerie .fancybox-title .numberOfFancybox {
  margin: 0;
}

.fancybox-galerie .fancybox-title .captionFancybox {
  border-top: 1px solid #353535;
  margin-top: 10px;
  padding-top: 5px;
  padding-right: 0;
}

.fancybox-opened .fancybox-title {
  visibility: visible;
  background: transparent;
  width: 100%;
}

.fancybox-title-float-wrap {
  position: absolute;
  bottom: 0;
  left: -15px;
  margin-bottom: -55px;
  z-index: 8050;
}

.fancybox-title-float-wrap .child {
  display: none;
  padding: 2px 0;
  color: #FFF;
  font-weight: 700;
  line-height: 24px;
}

.fancybox-title-outside-wrap {
  position: relative;
  color: #fff;
}

.fancybox-title-inside-wrap {
  padding-top: 10px;
}

.fancybox-title-over-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  padding: 10px;
  background: #000;
  background: rgba(0, 0, 0, 0.8);
}

.fancybox-more-infos {
  display: none;
}

#fancybox-buttons {
  position: fixed;
  width: 150px;
  height: 30px;
  bottom: 0;
  left: 0;
  text-align: left;
  z-index: 8050;
}

#fancybox-buttons .btnPlay {
  color: #fff;
  text-decoration: none;
  font-size: 1.3em;
  position: relative;
  padding-left: 40px;
}

#fancybox-buttons .btnPlay:before {
  content: '';
  background: transparent url("../Images/fancybox//play.png") no-repeat 0 0;
  width: 30px;
  height: 30px;
  position: absolute;
  top: -4px;
  right: inherit;
  bottom: inherit;
  left: 0;
  z-index: 1;
}

#fancybox-buttons .btnPlay img {
  display: none;
}

#fancybox-buttons .btnPlay .hover {
  margin-top: 2px;
  display: block;
}

#fancybox-buttons .btnPlay:hover span, #fancybox-buttons .btnPlay:focus span {
  text-decoration: underline;
}

#fancybox-buttons .btnPlay.btnPlayOn:before {
  background-image: url("../Images/fancybox/pause.png");
}

/*
    Unminified development version of the datepicker stylesheet. Please use the
    minified version in production - remember to run a find/replace on the
    minified file to update the image locations to suit your installation - more
    info can be found in the header of the minified file.
    F.Y.I: Here's the HTML produced for each instance of the datePicker, in this
    case associated with a form element having an id of "demo-1"
<div id="fd-demo-1" class="date-picker drag-enabled" aria-describedby="fd-dp-aria-describedby" aria-labelledby="demo-1_label" aria-hidden="false" role="application" dir="ltr">
    <div>
        <table class="date-picker-table" role="grid">
            <thead class="date-picker-thead">
                <tr role="presentation">
                    <th class="date-picker-title drag-enabled" colspan="7"><span class="month-display drag-enabled">January&nbsp;</span><span class="year-display drag-enabled">2013</span></th>
                </tr>
                <tr role="presentation">
                    <th><span class="prev-but prev-year" id="demo-1-prev-year-but" title="Previous year">«</span></th>
                    <th><span class="prev-but prev-month" id="demo-1-prev-month-but" title="Previous month">‹</span></th>
                    <th colspan="3"><span class="today-but fd-disabled" id="demo-1-today-but" title="">Today</span></th>
                    <th><span class="next-but next-month" id="demo-1-next-month-but" title="Next month">›</span></th>
                    <th><span class="next-but next-year" id="demo-1-next-year-but" title="Next year">»</span></th>
                </tr>
                <tr>
                    <th class="date-picker-day-header" scope="col" role="columnheader" id="demo-1-col-0" title="Monday">Mon</th>
                    <th class="date-picker-day-header" scope="col" role="columnheader" id="demo-1-col-1" title="Tuesday"><span class="fd-day-header" title="Tuesday">Tue</span></th>
                    <th class="date-picker-day-header" scope="col" role="columnheader" id="demo-1-col-2" title="Wednesday"><span class="fd-day-header" title="Wednesday">Wed</span></th>
                    <th class="date-picker-day-header" scope="col" role="columnheader" id="demo-1-col-3" title="Thursday"><span class="fd-day-header" title="Thursday">Thu</span></th>
                    <th class="date-picker-day-header" scope="col" role="columnheader" id="demo-1-col-4" title="Friday"><span class="fd-day-header" title="Friday">Fri</span></th>
                    <th class="date-picker-day-header date-picker-highlight" scope="col" role="columnheader" id="demo-1-col-5" title="Saturday"><span class="fd-day-header" title="Saturday">Sat</span></th>
                    <th class="date-picker-day-header date-picker-highlight" scope="col" role="columnheader" id="demo-1-col-6" title="Sunday"><span class="fd-day-header" title="Sunday">Sun</span></th>
                </tr>
            </thead>
            <tbody>
                <tr role="row">
                    <td aria-describedby="demo-1-col-0" aria-selected="false" role="gridcell" class="date-picker-unused" title="">&nbsp;</td>
                    <td aria-describedby="demo-1-col-1" aria-selected="false" role="gridcell" title="January 01, 2013" class="day-1 cell-1 cd-20130101 yyyymm-201301 mmdd-0101">1</td>
                    <td aria-describedby="demo-1-col-2" aria-selected="false" role="gridcell" title="January 02, 2013" class="day-2 cell-2 cd-20130102 yyyymm-201301 mmdd-0102">2</td>
                    <td aria-describedby="demo-1-col-3" aria-selected="false" role="gridcell" title="January 03, 2013" class="day-3 cell-3 cd-20130103 yyyymm-201301 mmdd-0103">3</td>
                    <td aria-describedby="demo-1-col-4" aria-selected="false" role="gridcell" title="January 04, 2013" class="day-4 cell-4 cd-20130104 yyyymm-201301 mmdd-0104">4</td>
                    <td aria-describedby="demo-1-col-5" aria-selected="false" role="gridcell" title="January 05, 2013" class="day-5 cell-5 cd-20130105 yyyymm-201301 mmdd-0105 date-picker-highlight">5</td>
                    <td aria-describedby="demo-1-col-6" aria-selected="false" role="gridcell" title="January 06, 2013" class="day-6 cell-6 cd-20130106 yyyymm-201301 mmdd-0106 date-picker-highlight">6</td>
                </tr>
                <tr role="row">
                    <td aria-describedby="demo-1-col-0" aria-selected="false" role="gridcell" title="January 07, 2013" class="day-0 cell-7 cd-20130107 yyyymm-201301 mmdd-0107">7</td>
                    <td aria-describedby="demo-1-col-1" aria-selected="false" role="gridcell" title="January 08, 2013" class="day-1 cell-8 cd-20130108 yyyymm-201301 mmdd-0108">8</td>
                    <td aria-describedby="demo-1-col-2" aria-selected="false" role="gridcell" title="January 09, 2013" class="day-2 cell-9 cd-20130109 yyyymm-201301 mmdd-0109">9</td>
                    <td aria-describedby="demo-1-col-3" aria-selected="false" role="gridcell" title="January 10, 2013" class="day-3 cell-10 cd-20130110 yyyymm-201301 mmdd-0110">10</td>
                    <td aria-describedby="demo-1-col-4" aria-selected="false" role="gridcell" title="January 11, 2013" class="day-4 cell-11 cd-20130111 yyyymm-201301 mmdd-0111">11</td>
                    <td aria-describedby="demo-1-col-5" aria-selected="false" role="gridcell" title="January 12, 2013" id="demo-1-date-picker-hover" class="day-5 cell-12 cd-20130112 yyyymm-201301 mmdd-0112 date-picker-today date-picker-highlight date-picker-hover" tabindex="0">12</td>
                    <td aria-describedby="demo-1-col-6" aria-selected="false" role="gridcell" title="January 13, 2013" class="day-6 cell-13 cd-20130113 yyyymm-201301 mmdd-0113 date-picker-highlight">13</td>
                </tr>
                ... snip ...
            </tbody>
        </table>
    </div>
</div>
*/
/*
    Hide the datepickers associated input by using a className
*/
.fd-hidden-input {
  display: none;
}

/*
    Screen reader class - used to hide spans inside each cell from the visual
    display. See: http://webaim.org/techniques/css/invisiblecontent/
*/
.fd-screen-reader {
  position: absolute;
  left: -999em;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  -moz-outline: 0 none;
  outline: 0 none;
}

/*
    The wrapper div
*/
.date-picker {
  position: absolute;
  z-index: 9999;
  text-align: center;
  /*
    Change the font-size to suit your needs. The following line is for the demo
    that has a 14px font-size defined on the body tag.
    All other rules are "em" based and inherit from the font-size set here.
    */
  user-select: none;
  transition: opacity .75s ease-in-out;
}

/*
    The following rule is used whenever CSS Animations are supported by the
    browser
*/
.date-picker.fd-dp-fade {
  opacity: 0;
}

/*
    Styles for the static datePickers
*/
.static-datepicker {
  position: relative;
  top: 0;
  left: 0;
  z-index: 0;
  max-width: 242px;
  margin: 0 auto 1.5em;
}

/*
    The activation "button" created beside each input for popup datePickers
*/
.date-picker-control span {
  display: block;
  width: 18px;
  height: 18px;
  overflow: hidden;
  margin: auto 0;
  resize: none;
  outline: none;
}

/*
    The hover effect on the activation button
*/
/*
    A base64 encoded image-sprite is used for the activation button. IE6 and 7
    get fed "normal" png images within the rule that follows.
*/
/*
    Hover states etc for the activation button
*/
/*
    Default datepicker "button" styles
*/
.date-picker th span {
  line-height: 1em;
}

/*
    The "month, year" display
*/
.date-picker .month-display,
.date-picker .year-display {
  display: inline;
  font-size: 1.6rem;
  font-weight: 900;
  text-transform: uppercase;
}

/*
    Generic button class for Next & Previous (both month & year) buttons
*/
.date-picker .date-picker-thead {
  position: relative;
}

.date-picker .date-picker-thead .prev-but,
.date-picker .date-picker-thead .next-but {
  font-size: 2em;
  display: inline-block;
  width: 30px;
  text-align: center;
  font-family: "Raleway", sans-serif;
  font-weight: 200;
  cursor: pointer;
  color: #00acec;
}

.date-picker .date-picker-thead .prev-but:hover, .date-picker .date-picker-thead .prev-but:active,
.date-picker .date-picker-thead .next-but:hover,
.date-picker .date-picker-thead .next-but:active {
  color: #4c4c4c;
}

.date-picker .date-picker-thead tr:nth-child(2) {
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 2px;
  left: 0;
  width: 100%;
}

.date-picker .date-picker-thead tr:nth-child(2) th {
  height: 30px;
}

.date-picker .date-picker-thead tr:nth-child(2) th:first-child,
.date-picker .date-picker-thead tr:nth-child(2) th:last-child,
.date-picker .date-picker-thead tr:nth-child(2) th[colspan] {
  display: none;
}

.date-picker .date-picker-thead .prev-but,
.date-picker .date-picker-thead .next-but,
.date-picker .date-picker-thead .today-but {
  transition: all .2s ease-in-out;
}

/*
    Hover effect for Next & Previous (month, year) buttons
*/
/*
    Today button
*/
.date-picker .today-but {
  text-align: center;
  margin: 0 auto;
  font-weight: normal;
  font-size: 1em;
  width: 100%;
  text-decoration: none;
  padding-top: 0.3em;
  text-transform: uppercase;
  vertical-align: middle;
  cursor: pointer;
}

/*
    Disabled buttons
*/
.date-picker-disabled .prev-but,
.date-picker-disabled .next-but,
.date-picker-disabled .today-but,
.date-picker-disabled .prev-but:hover,
.date-picker-disabled .next-but:hover,
.date-picker-disabled .today-but:hover,
.date-picker .prev-but.fd-disabled:hover,
.date-picker .next-but.fd-disabled:hover,
.date-picker .fd-disabled,
.date-picker .fd-disabled:hover {
  color: #aaa;
  cursor: default !important;
  opacity: 1;
}

.date-picker table {
  table-layout: auto;
  empty-cells: show;
  border-spacing: 4px;
  border-collapse: separate;
  position: relative;
  margin: 0;
  padding: 0 0 1em;
  text-align: center;
  width: auto;
  height: auto;
  color: #fff;
  font-size: 1em;
}

/*
    Common TD & TH styling
*/
.date-picker td,
.date-picker th {
  padding: 0;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  font-weight: 200;
  font-family: "Raleway", sans-serif;
  width: 30px;
  overflow: hidden;
}

.date-picker th {
  font-size: 1.6rem;
  padding: 0;
  text-align: center;
  vertical-align: middle;
}

.date-picker td {
  height: 4rem;
  font-size: 1.8rem;
  background-color: transparent;
  transition: background-color 0.25s ease;
}

.date-picker .date-picker-thead {
  position: relative;
}

.date-picker .date-picker-thead:after {
  content: '';
  display: block;
  position: absolute;
  margin-top: -1em;
  left: 0;
  width: 100%;
  height: 7px;
  border-bottom: 1px solid rgba(0, 172, 236, 0.6);
  z-index: -1;
}

.date-picker .date-picker-day-header {
  cursor: help;
  padding: 0.2em 0 1em;
  text-transform: uppercase;
  height: auto;
}

/*
	The TH cell used to display the "month, year" title
*/
.date-picker .date-picker-title {
  width: auto;
  height: auto;
  padding: 0.4em 0;
}

/*
	The "highlight days" style
*/
.date-picker td[data-highlight],
.date-picker td.date-picker-highlight {
  border-bottom: 1px solid #fff;
}

.date-picker td[data-highlight]:hover, .date-picker td[data-highlight]:focus,
.date-picker td.date-picker-highlight:hover,
.date-picker td.date-picker-highlight:focus {
  border-bottom-color: transparent;
  color: #000;
  background-color: #00acec;
}

.date-picker .date-picker-today {
  cursor: pointer;
  background-color: #0e6991;
}

.date-picker td.date-picker-selected-date {
  color: #000;
  background-color: #00acec;
}

/*
	The "disabled days" style
*/
.date-picker .day-disabled {
  color: #68c9f3 !important;
  cursor: default;
  background-color: transparent !important;
}

/*
	The date "out of range" style
*/
.date-picker .out-of-range,
.date-picker .not-selectable {
  color: #ccc;
  font-style: oblique;
  background: #fcfcfc;
  cursor: default;
}

/*
	Week number "out of range" && "month-out" styles
*/
.date-picker th.month-out,
.date-picker th.out-of-range {
  color: #aaa;
  font-style: oblique;
  background: #fcfcfc;
}

/*
	Week numbers "out of range"
*/
.date-picker .out-of-range {
  opacity: 0.6;
}

/*
	Used when the entire grid is full but the next/prev months dates cannot be
	selected
*/
.date-picker .not-selectable {
  opacity: 0.8;
}

.oldie .date-picker .out-of-range {
  filter: alpha(opacity=60);
}

.oldie .date-picker .not-selectable {
  filter: alpha(opacity=80);
}

.date-picker tr {
  display: table-row;
}

.date-picker sup {
  font-size: 0.86em;
  letter-spacing: normal;
  text-transform: none;
  height: 0;
  line-height: 1;
  position: relative;
  top: -0.2em;
  vertical-align: baseline !important;
}

.date-picker .month-display,
.date-picker .year-display {
  cursor: default;
}

.date-picker td:focus,
.date-picker .date-picker-hover {
  overflow: hidden;
  outline: 0 none;
}

/*
	Add a box-shadow and enhance border for datepickers that have keyboard focus
*/
.date-picker-focus table {
  outline: 1px dashed #00acec;
}

/*
	Draggable datepickers
*/
.date-picker .drag-enabled,
.date-picker .drag-enabled span {
  cursor: move;
}

/*
	Disabled datePicker
*/
.date-picker-disabled table {
  opacity: .8 !important;
}

.date-picker-disabled table:before,
.date-picker-disabled table:after {
  display: none;
}

.oldie .date-picker-disabled table {
  filter: alpha(opacity=80);
}

.date-picker-disabled,
.date-picker-disabled td,
.date-picker-disabled th,
.date-picker-disabled th span {
  cursor: default !important;
}

.ui-timepicker-wrapper {
  overflow-y: auto;
  height: 150px;
  width: 6.5em;
  background: #fff;
  border: 1px solid #ddd;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  outline: none;
  z-index: 10001;
  margin: 0;
}

.ui-timepicker-wrapper.ui-timepicker-with-duration {
  width: 13em;
}

.ui-timepicker-wrapper.ui-timepicker-with-duration.ui-timepicker-step-30,
.ui-timepicker-wrapper.ui-timepicker-with-duration.ui-timepicker-step-60 {
  width: 11em;
}

.ui-timepicker-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ui-timepicker-duration {
  margin-left: 5px;
  color: #888;
}

.ui-timepicker-list:hover .ui-timepicker-duration {
  color: #888;
}

.ui-timepicker-list li {
  padding: 3px 0 3px 5px;
  cursor: pointer;
  white-space: nowrap;
  color: #000;
  list-style: none;
  margin: 0;
}

.ui-timepicker-list:hover .ui-timepicker-selected {
  background: #fff;
  color: #000;
}

li.ui-timepicker-selected,
.ui-timepicker-list li:hover,
.ui-timepicker-list .ui-timepicker-selected:hover {
  background: #1980EC;
  color: #fff;
}

li.ui-timepicker-selected .ui-timepicker-duration,
.ui-timepicker-list li:hover .ui-timepicker-duration {
  color: #ccc;
}

.ui-timepicker-list li.ui-timepicker-disabled,
.ui-timepicker-list li.ui-timepicker-disabled:hover,
.ui-timepicker-list li.ui-timepicker-selected.ui-timepicker-disabled {
  color: #888;
  cursor: default;
}

.ui-timepicker-list li.ui-timepicker-disabled:hover,
.ui-timepicker-list li.ui-timepicker-selected.ui-timepicker-disabled {
  background: #f2f2f2;
}

/*
 * CSS Styles that are needed by jScrollPane for it to operate correctly.
 *
 * Include this stylesheet in your site or copy and paste the styles below into your stylesheet - jScrollPane
 * may not operate correctly without them.
 */
.jspContainer {
  overflow: hidden;
  position: relative;
}

.jspPane {
  position: absolute;
}

.jspVerticalBar {
  position: absolute;
  top: 0;
  right: 0;
  width: 16px;
  height: 100%;
  background: red;
}

.jspHorizontalBar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 16px;
  background: red;
}

.jspCap {
  display: none;
}

.jspHorizontalBar .jspCap {
  float: left;
}

.jspTrack {
  background: #dde;
  position: relative;
}

.jspDrag {
  background: #bbd;
  position: relative;
  top: 0;
  left: 0;
  cursor: pointer;
}

.jspHorizontalBar .jspTrack,
.jspHorizontalBar .jspDrag {
  float: left;
  height: 100%;
}

.jspArrow {
  background: #50506d;
  text-indent: -20000px;
  display: block;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.jspArrow.jspDisabled {
  cursor: default;
  background: #80808d;
}

.jspVerticalBar .jspArrow {
  height: 16px;
}

.jspHorizontalBar .jspArrow {
  width: 16px;
  float: left;
  height: 100%;
}

.jspVerticalBar .jspArrow:focus {
  outline: none;
}

.jspCorner {
  background: #eeeef4;
  float: left;
  height: 100%;
}

/* Yuk! CSS Hack for IE6 3 pixel bug :( */
* html .jspCorner {
  margin: 0 -3px 0 0;
}

.rte .entiteImageFloatLeft {
  float: left;
  margin: .5rem 1rem 1rem 0;
}

.rte .entiteImageFloatRight {
  float: right;
  margin: .5rem 0 1rem 1rem;
}

.rte .breadcrumb.spFilDAriane {
  width: auto;
  display: block;
  vertical-align: inherit;
  margin-bottom: 4rem;
}

.rte .breadcrumb.spFilDAriane p a {
  text-decoration: none;
}

.rte .breadcrumb.spFilDAriane p a:hover, .rte .breadcrumb.spFilDAriane p a:focus {
  text-decoration: underline;
}

.rte .spIntroduction p, .rte .spDescription {
  font-size: 1.8em;
  margin: 0.61111em 0;
  color: #4c4c4c;
}

.rte .spAttention, .rte .spANoter, .rte .spASavoir {
  overflow: hidden;
  margin: 2rem 0;
  padding: 1.5rem 1.5rem 1.5rem 4.5rem;
  background: #ebf2f5;
  position: relative;
  position: relative;
}

.rte .spAttention *[aria-hidden="true"], .rte .spANoter *[aria-hidden="true"], .rte .spASavoir *[aria-hidden="true"] {
  display: none;
}

.rte .spAttention:before, .rte .spANoter:before, .rte .spASavoir:before {
  font-family: "icons-default";
  font-size: 1.8rem;
  color: #000;
  position: absolute;
  top: 1.5rem;
  right: inherit;
  bottom: inherit;
  left: 1.5rem;
  content: "";
  line-height: 1;
}

.rte .spAttention p:first-of-type, .rte .spANoter p:first-of-type, .rte .spASavoir p:first-of-type {
  margin-top: 0;
}

.rte .spAttention p:last-of-type, .rte .spANoter p:last-of-type, .rte .spASavoir p:last-of-type {
  margin-bottom: 0;
}

.rte .spAttention {
  background: #ffe5e5;
}

.rte .spAttention:before {
  content: "\e088";
}

.rte .spANoter {
  background: #def6ff;
}

.rte .spANoter:before {
  content: "\e0a5";
}

.rte .spASavoir {
  background: #edfceb;
}

.rte .co-home-menu {
  margin: 2rem 0;
  font-size: 1rem;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}

.rte .co-home-menu.pro > * {
  flex: 1 1 33.33334%;
}

.rte .co-home-menu > * {
  flex: 1 1 20%;
}

.rte .co-home-menu > li {
  padding: 1.5rem;
  border: 0.1rem solid #c2d9e1;
  font-size: 1rem;
  margin: 0 -1px -1px 0;
}

.rte .co-home-menu > li:before {
  content: "";
  display: none;
}

.rte .co-home-menu > li h3 {
  font-size: 1.8rem;
  color: #88c54c;
  text-transform: uppercase;
  font-weight: 300;
  margin: 0 0 1rem;
}

.rte .co-home-menu > li h3 a {
  color: #88c54c;
  text-decoration: none;
}

.rte .co-home-menu > li h3 a:hover, .rte .co-home-menu > li h3 a:focus {
  text-decoration: underline;
}

.rte .co-home-menu .co-home-sousmenu > li {
  display: inline;
  padding: 0;
}

.rte .co-home-menu .co-home-sousmenu > li:before {
  content: "";
  display: none;
}

.rte .co-home-menu .co-home-sousmenu > li a {
  color: #000;
  text-decoration: none;
}

.rte .co-home-menu .co-home-sousmenu > li a:hover, .rte .co-home-menu .co-home-sousmenu > li a:focus {
  text-decoration: underline;
}

.rte .co-home-img {
  display: none;
}

.tarteaucitron-modal-open {
  overflow: hidden;
  height: 100%;
}

#tarteaucitronContentWrapper {
  display: unset;
}

/***
* Reset CSS
*/
#tarteaucitronRoot div, #tarteaucitronRoot span, #tarteaucitronRoot applet, #tarteaucitronRoot object, #tarteaucitronRoot iframe, #tarteaucitronRoot h1, #tarteaucitronRoot h2, #tarteaucitronRoot h3, #tarteaucitronRoot h4, #tarteaucitronRoot h5, #tarteaucitronRoot h6, #tarteaucitronRoot p, #tarteaucitronRoot blockquote, #tarteaucitronRoot pre, #tarteaucitronRoot a, #tarteaucitronRoot abbr, #tarteaucitronRoot acronym, #tarteaucitronRoot address, #tarteaucitronRoot big, #tarteaucitronRoot cite, #tarteaucitronRoot code, #tarteaucitronRoot del, #tarteaucitronRoot dfn, #tarteaucitronRoot em, #tarteaucitronRoot img, #tarteaucitronRoot ins, #tarteaucitronRoot kbd, #tarteaucitronRoot q, #tarteaucitronRoot s, #tarteaucitronRoot samp, #tarteaucitronRoot small, #tarteaucitronRoot strike, #tarteaucitronRoot strong, #tarteaucitronRoot sub, #tarteaucitronRoot sup, #tarteaucitronRoot tt, #tarteaucitronRoot var, #tarteaucitronRoot b, #tarteaucitronRoot u, #tarteaucitronRoot i, #tarteaucitronRoot center, #tarteaucitronRoot dl, #tarteaucitronRoot dt, #tarteaucitronRoot dd, #tarteaucitronRoot ol, #tarteaucitronRoot ul, #tarteaucitronRoot li, #tarteaucitronRoot fieldset, #tarteaucitronRoot form, #tarteaucitronRoot label, #tarteaucitronRoot legend, #tarteaucitronRoot table, #tarteaucitronRoot caption, #tarteaucitronRoot tbody, #tarteaucitronRoot tfoot, #tarteaucitronRoot thead, #tarteaucitronRoot tr, #tarteaucitronRoot th, #tarteaucitronRoot td, #tarteaucitronRoot article, #tarteaucitronRoot aside, #tarteaucitronRoot canvas, #tarteaucitronRoot details, #tarteaucitronRoot embed, #tarteaucitronRoot figure, #tarteaucitronRoot figcaption, #tarteaucitronRoot footer, #tarteaucitronRoot header, #tarteaucitronRoot hgroup, #tarteaucitronRoot menu, #tarteaucitronRoot nav, #tarteaucitronRoot output, #tarteaucitronRoot ruby, #tarteaucitronRoot section, #tarteaucitronRoot summary, #tarteaucitronRoot time, #tarteaucitronRoot mark, #tarteaucitronRoot audio, #tarteaucitronRoot video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  /*background: initial;*/
  text-align: initial;
  text-shadow: initial;
}

/* Animation */
#tarteaucitronRoot * {
  transition: border 300ms, background 300ms, opacity 200ms, box-shadow 400ms;
}

/* HTML5 display-role reset for older browsers */
#tarteaucitronRoot article, #tarteaucitronRoot aside, #tarteaucitronRoot details, #tarteaucitronRoot figcaption, #tarteaucitronRoot figure, #tarteaucitronRoot footer, #tarteaucitronRoot header, #tarteaucitronRoot hgroup, #tarteaucitronRoot menu, #tarteaucitronRoot nav, #tarteaucitronRoot section {
  display: block;
}

#tarteaucitronRoot ol, #tarteaucitronRoot ul {
  list-style: none;
}

#tarteaucitronRoot blockquote, #tarteaucitronRoot q {
  quotes: none;
}

#tarteaucitronRoot blockquote:before, #tarteaucitronRoot blockquote:after, #tarteaucitronRoot q:before, #tarteaucitronRoot q:after {
  content: '';
  content: none;
}

#tarteaucitronRoot table {
  border-collapse: collapse;
  border-spacing: 0;
}

#tarteaucitronRoot a:focus-visible, #tarteaucitronRoot button:focus-visible {
  outline: 3px dashed #3d86d8;
}

/***
 * Better scroll management
 */
div#tarteaucitronMainLineOffset {
  margin-top: 0 !important;
}

div#tarteaucitronServices {
  margin-top: 21px !important;
}

#tarteaucitronServices::-webkit-scrollbar {
  width: 5px;
}

#tarteaucitronServices::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
}

#tarteaucitronServices::-webkit-scrollbar-thumb {
  background-color: #ddd;
  outline: 0px solid slategrey;
}

div#tarteaucitronServices {
  box-shadow: 0 40px 60px #545454;
}

/***
 * Responsive layout for the control panel
 */
@media screen and (max-width: 479px) {
  #tarteaucitron .tarteaucitronLine .tarteaucitronName {
    width: 90% !important;
  }
  #tarteaucitron .tarteaucitronLine .tarteaucitronAsk {
    float: left !important;
    margin: 10px 15px 5px;
  }
}

@media screen and (max-width: 767px) {
  #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer, #tarteaucitron {
    background: #fff;
    border: 0 !important;
    bottom: 0 !important;
    height: 100% !important;
    left: 0 !important;
    margin: 0 !important;
    max-height: 100% !important;
    max-width: 100% !important;
    top: 0 !important;
    width: 100% !important;
  }
  #tarteaucitron .tarteaucitronBorder {
    border: 0 !important;
  }
  #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList {
    border: 0 !important;
  }
  #tarteaucitron #tarteaucitronServices .tarteaucitronTitle {
    text-align: left !important;
  }
  .tarteaucitronName .tarteaucitronH2 {
    max-width: 80%;
  }
  #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk {
    text-align: center !important;
  }
  #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk button {
    margin-bottom: 5px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  #tarteaucitron {
    border: 0 !important;
    left: 0 !important;
    margin: 0 5% !important;
    max-height: 80% !important;
    width: 90% !important;
  }
}

/***
 * Common value
 */
#tarteaucitronRoot div#tarteaucitron {
  left: 0;
  right: 0;
  margin: auto;
}

#tarteaucitronRoot button#tarteaucitronBack {
  background: #eee;
}

#tarteaucitron .clear {
  clear: both;
}

#tarteaucitron a {
  color: #424242;
  font-size: 11px;
  font-weight: 700;
  text-decoration: none;
}

#tarteaucitronRoot button {
  background: transparent;
  border: 0;
}

#tarteaucitronAlertBig strong, #tarteaucitronAlertSmall strong,
#tarteaucitronAlertBig a, #tarteaucitronAlertSmall a {
  color: #fff;
}

#tarteaucitron strong {
  font-size: 22px;
  font-weight: 500;
}

#tarteaucitron ul {
  padding: 0;
}

#tarteaucitron .tarteaucitronH1, #tarteaucitron .tarteaucitronH2, #tarteaucitron .tarteaucitronH3, #tarteaucitron .tarteaucitronH4, #tarteaucitron .tarteaucitronH5, #tarteaucitron .tarteaucitronH6 {
  display: block;
}

.cookie-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

/***
 * Root div added just before </body>
 */
#tarteaucitronRoot {
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

#tarteaucitronRoot * {
  box-sizing: initial;
  color: #333;
  font-family: sans-serif !important;
  font-size: 14px;
  line-height: normal;
  vertical-align: initial;
}

#tarteaucitronRoot .tarteaucitronH1 {
  font-size: 1.5em;
  text-align: center;
  color: #fff;
  margin: 15px 0 28px;
}

#tarteaucitronRoot .tarteaucitronH2 {
  display: inline-block;
  margin: 12px 0 0 10px;
  color: #fff;
}

#tarteaucitronCookiesNumberBis.tarteaucitronH2 {
  margin-left: 0;
}

/***
 * Control panel
 */
#tarteaucitronBack {
  background: #fff;
  display: none;
  height: 100%;
  left: 0;
  opacity: 0.7;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2147483646;
}

#tarteaucitron {
  display: none;
  max-height: 80%;
  left: 50%;
  margin: 0 auto 0 -430px;
  padding: 0;
  position: fixed;
  top: 6%;
  width: 860px;
  z-index: 2147483647;
}

#tarteaucitron .tarteaucitronBorder {
  background: #fff;
  border: 2px solid #333;
  border-top: 0;
  height: auto;
  overflow: auto;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronClosePanelCookie,
#tarteaucitron #tarteaucitronClosePanel {
  background: #333333;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  text-decoration: none;
  padding: 4px 0;
  position: absolute;
  right: 0;
  text-align: center;
  width: 70px;
}

#tarteaucitron #tarteaucitronDisclaimer {
  color: #555;
  font-size: 12px;
  margin: 15px auto 0;
  width: 80%;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronHidden,
#tarteaucitron #tarteaucitronServices .tarteaucitronHidden {
  background: rgba(51, 51, 51, 0.07);
}

#tarteaucitron #tarteaucitronServices .tarteaucitronHidden {
  display: none;
  position: relative;
}

#tarteaucitronCookiesList .tarteaucitronH3.tarteaucitronTitle {
  width: 100%;
  box-sizing: border-box;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronTitle,
#tarteaucitron #tarteaucitronServices .tarteaucitronTitle button,
#tarteaucitron #tarteaucitronInfo,
#tarteaucitron #tarteaucitronServices .tarteaucitronDetails {
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  margin: 20px 0px 0px;
  padding: 5px 20px;
  text-align: left;
  width: auto;
  background: #333;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName a,
#tarteaucitron #tarteaucitronServices .tarteaucitronTitle a {
  color: #fff;
  font-weight: 500;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName a:hover,
#tarteaucitron #tarteaucitronServices .tarteaucitronTitle a:hover {
  text-decoration: none !important;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName a {
  font-size: 22px;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronTitle a {
  font-size: 14px;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronTitle {
  padding: 5px 10px;
  margin: 0;
}

#tarteaucitron #tarteaucitronInfo,
#tarteaucitron #tarteaucitronServices .tarteaucitronDetails {
  color: #fff;
  display: none;
  font-size: 12px;
  font-weight: 500;
  margin-top: 0;
  max-width: 270px;
  padding: 20px;
  position: absolute;
  z-index: 2147483647;
}

#tarteaucitron #tarteaucitronInfo a {
  color: #fff;
  text-decoration: underline;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine:hover {
  background: rgba(51, 51, 51, 0.2);
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine {
  background: rgba(51, 51, 51, 0.1);
  border-left: 5px solid transparent;
  margin: 0;
  overflow: hidden;
  padding: 15px 5px;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsAllowed {
  border-color: #1B870B;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsDenied {
  border-color: #9C1A1A;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine {
  background: #333;
  border: 3px solid #333;
  border-left: 9px solid #333;
  border-top: 5px solid #333;
  margin-bottom: 0;
  margin-top: 21px;
  position: relative;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine:hover {
  background: #333;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName {
  margin-left: 15px;
  margin-top: 2px;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName button {
  color: #fff;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronAsk {
  margin-top: 0px !important;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronName {
  display: inline-block;
  float: left;
  margin-left: 10px;
  text-align: left;
  width: 50%;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronName a:hover {
  text-decoration: underline;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk {
  display: inline-block;
  float: right;
  margin: 7px 15px 0;
  text-align: right;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronAllow,
#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronDeny,
.tac_activate .tarteaucitronAllow {
  background: gray;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  padding: 6px 10px;
  text-align: center;
  text-decoration: none;
  width: auto;
  border: 0;
}

#tarteaucitron #tarteaucitronServices #tarteaucitronAllAllowed.tarteaucitronIsSelected {
  background-color: #1B870B;
  opacity: 1;
}

#tarteaucitron #tarteaucitronServices #tarteaucitronAllDenied.tarteaucitronIsSelected,
#tarteaucitron #tarteaucitronServices #tarteaucitronAllDenied2.tarteaucitronIsSelected {
  background-color: #9C1A1A;
  opacity: 1;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsAllowed .tarteaucitronAllow {
  background-color: #1B870B;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsDenied .tarteaucitronDeny {
  background-color: #9C1A1A;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronName .tarteaucitronListCookies {
  color: #333;
  font-size: 12px;
}

#tarteaucitron .tarteaucitronH3 {
  font-size: 18px;
}

#tarteaucitron #tarteaucitronMainLineOffset .tarteaucitronName {
  width: auto !important;
  margin-left: 0 !important;
  font-size: 14px;
}

span#tarteaucitronDisclaimerAlert {
  padding: 0 10px;
  display: inline-block;
}

#tarteaucitron .tarteaucitronBorder, #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain, #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList, #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronHidden, #tarteaucitron #tarteaucitronServices .tarteaucitronMainLine {
  border-color: #333 !important;
}

/***
 * Big alert
 */
.tarteaucitronAlertBigTop {
  top: 0;
}

.tarteaucitronAlertBigBottom {
  bottom: 0;
}

#tarteaucitronRoot #tarteaucitronAlertBig {
  background: #333;
  color: #fff;
  display: none;
  font-size: 15px !important;
  left: 0;
  position: fixed;
  box-sizing: content-box;
  z-index: 2147483645;
  text-align: center;
  padding: 10px 0 10px 0;
  margin: auto;
  width: 100%;
}

#tarteaucitronAlertBig #tarteaucitronPrivacyUrl,
#tarteaucitronAlertBig #tarteaucitronPrivacyUrlDialog,
#tarteaucitronAlertBig #tarteaucitronDisclaimerAlert,
#tarteaucitronAlertBig #tarteaucitronDisclaimerAlert strong {
  font: 15px verdana;
  color: #fff;
}

#tarteaucitronAlertBig #tarteaucitronDisclaimerAlert strong {
  font-weight: 700;
}

#tarteaucitronAlertBig #tarteaucitronPrivacyUrl,
#tarteaucitronAlertBig #tarteaucitronPrivacyUrlDialog {
  cursor: pointer;
}

#tarteaucitronAlertBig #tarteaucitronCloseAlert,
#tarteaucitronAlertBig #tarteaucitronPersonalize,
#tarteaucitronAlertBig #tarteaucitronPersonalize2,
.tarteaucitronCTAButton,
#tarteaucitron #tarteaucitronPrivacyUrl,
#tarteaucitron #tarteaucitronPrivacyUrlDialog,
#tarteaucitronRoot .tarteaucitronDeny,
#tarteaucitronRoot .tarteaucitronAllow {
  background: #008300;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 16px !important;
  line-height: 1.2;
  padding: 5px 10px;
  text-decoration: none;
  margin-left: 7px;
}

#tarteaucitronRoot .tarteaucitronDeny {
  background: #9C1A1A;
}

#tarteaucitronAlertBig #tarteaucitronCloseAlert, #tarteaucitron #tarteaucitronPrivacyUrl, #tarteaucitron #tarteaucitronPrivacyUrlDialog {
  background: #fff;
  color: #333;
  font-size: 13px;
  margin-bottom: 3px;
  margin-left: 7px;
  padding: 5px 10px;
}

#tarteaucitronPercentage {
  background: #0A0 !important;
  box-shadow: 0 0 2px #fff, 0 1px 2px #555;
  height: 5px;
  left: 0;
  position: fixed;
  width: 0;
  z-index: 2147483644;
}

/***
 * Icon
 */
.tarteaucitronIconBottomRight {
  bottom: 0;
  right: 0;
}

.tarteaucitronIconBottomLeft {
  bottom: 0;
  left: 0;
}

.tarteaucitronIconTopRight {
  top: 0;
  right: 0;
}

.tarteaucitronIconTopLeft {
  top: 0;
  left: 0;
}

.tarteaucitronIconTopLeft #tarteaucitronManager {
  border-radius: 2px 7px 7px 2px;
}

.tarteaucitronIconTopRight #tarteaucitronManager {
  border-radius: 7px 2px 2px 7px;
}

.tarteaucitronIconBottomLeft #tarteaucitronManager {
  border-radius: 7px 7px 2px 2px;
}

.tarteaucitronIconBottomRight #tarteaucitronManager {
  border-radius: 7px 7px 2px 2px;
}

#tarteaucitronIcon {
  background: transparent;
  position: fixed;
  display: none;
  width: auto;
  z-index: 2147483646;
}

#tarteaucitronIcon #tarteaucitronManager {
  color: transparent;
  cursor: pointer;
  display: inline-block;
  font-size: 11px !important;
  padding: 8px 10px 8px;
  border: none;
}

#tarteaucitronIcon #tarteaucitronManager img {
  width: 50px;
  height: 50px;
}

#tarteaucitronRoot .tarteaucitronCross::before {
  content: '\2717';
  display: inline-block;
  color: white;
}

#tarteaucitronRoot .tarteaucitronCheck::before {
  content: '\2713';
  display: inline-block;
  color: white;
}

#tarteaucitronRoot .tarteaucitronPlus::before {
  content: '\271b';
  display: inline-block;
  color: white;
}

/***
 * Small alert
 */
.tarteaucitronAlertSmallTop, .tarteaucitronAlertSmallBottom {
  bottom: 0;
}

#tarteaucitronAlertSmall {
  background: #333;
  display: none;
  padding: 0;
  position: fixed;
  right: 0;
  text-align: center;
  width: auto;
  z-index: 2147483646;
}

#tarteaucitronAlertSmall #tarteaucitronManager {
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 11px !important;
  padding: 8px 10px 8px;
}

#tarteaucitronAlertSmall #tarteaucitronManager:hover {
  background: rgba(255, 255, 255, 0.05);
}

#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot {
  background-color: gray;
  border-radius: 5px;
  display: block;
  height: 8px;
  margin-bottom: 1px;
  margin-top: 5px;
  overflow: hidden;
  width: 100%;
}

#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotGreen,
#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotYellow,
#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotRed {
  display: block;
  float: left;
  height: 100%;
  width: 0%;
}

#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotGreen {
  background-color: #1B870B;
}

#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotYellow {
  background-color: #FBDA26;
}

#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotRed {
  background-color: #9C1A1A;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesNumber {
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 30px;
  padding: 0px 10px;
  vertical-align: top;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesNumber:hover {
  background: rgba(255, 255, 255, 0.3);
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer {
  display: none;
  max-height: 70%;
  max-width: 500px;
  position: fixed;
  right: 0;
  width: 100%;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList {
  background: #fff;
  border: 2px solid #333;
  color: #333;
  font-size: 11px;
  height: auto;
  overflow: auto;
  text-align: left;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList strong {
  color: #333;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesTitle {
  background: #333;
  margin-top: 21px;
  padding: 13px 0 9px 13px;
  text-align: left;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesTitle strong {
  color: #fff;
  font-size: 16px;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain {
  background: rgba(51, 51, 51, 0.1);
  padding: 7px 5px 10px;
  word-wrap: break-word;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain:hover {
  background: rgba(51, 51, 51, 0.2);
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain a {
  color: #333;
  text-decoration: none;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain .tarteaucitronCookiesListLeft {
  display: inline-block;
  width: 50%;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain .tarteaucitronCookiesListLeft a strong {
  color: darkred;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain .tarteaucitronCookiesListRight {
  color: #333;
  display: inline-block;
  font-size: 11px;
  margin-left: 10%;
  vertical-align: top;
  width: 30%;
}

/***
 * Fallback activate link
 */
.tac_activate {
  background: #333;
  color: #fff;
  display: table;
  font-size: 12px;
  height: 100%;
  line-height: initial;
  margin: auto;
  text-align: center;
  width: 100%;
}

.tac_float {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.tac_activate .tac_float strong {
  color: #fff;
}

.tac_activate .tac_float .tarteaucitronAllow {
  background-color: #1B870B;
  display: inline-block;
}

/***
 * CSS for services
 */
ins.ferank-publicite, ins.adsbygoogle {
  text-decoration: none;
}

div.amazon_product {
  height: 240px;
  width: 120px;
}

.tarteaucitronIsAllowed .tarteaucitronDeny {
  opacity: 0.4 !important;
}

.tarteaucitronIsDenied .tarteaucitronAllow {
  opacity: 0.4 !important;
}

.tarteaucitronIsAllowed .tarteaucitronAllow {
  opacity: 1 !important;
}

.tarteaucitronIsDenied .tarteaucitronDeny {
  opacity: 1 !important;
}

.tarteaucitronLine .tarteaucitronAllow, .tarteaucitronLine .tarteaucitronDeny {
  opacity: 0.4;
}

#tarteaucitronServices_mandatory button.tarteaucitronAllow {
  opacity: 1;
}

div#tarteaucitronInfo {
  display: block !important;
  position: relative !important;
  text-align: center !important;
  max-width: 80% !important;
  padding: 15px 0 !important;
  margin: -10px auto 40px !important;
  font-size: 1em !important;
  border-bottom: 1px solid;
  border-top: 1px solid;
  border-color: #555;
}

a.tarteaucitronSelfLink {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -30px;
  text-align: center !important;
  display: block;
  height: 30px;
}

.tarteaucitronMainLine .tarteaucitronH2 {
  font-size: 1.2em !important;
  margin-top: 4px !important;
}

span.tarteaucitronTitle.tarteaucitronH3 {
  margin-top: 12px !important;
}

#tarteaucitronCloseCross {
  position: absolute;
  color: #FFFF;
  font-size: 1.8rem;
  cursor: pointer;
  top: 10px;
  right: 26px;
}

.tarteaucitron-spacer-20 {
  height: 20px;
  display: block;
}

.tarteaucitron-display-block {
  display: block;
}

.tarteaucitron-display-none {
  display: none;
}

/* stylelint-disable */
#tarteaucitronRoot .tarteaucitronH1 {
  font-size: 2.5rem;
}

#tarteaucitronRoot div#tarteaucitronInfo {
  font-size: 1.6rem !important;
}

#tarteaucitronRoot div#tarteaucitronInfo a {
  font-size: 1.6rem;
}

#tarteaucitronRoot #tarteaucitronPersonalize {
  background: #fff;
  border: solid 1px rgba(194, 217, 225, 0.5);
  color: #000;
}

#tarteaucitronRoot #tarteaucitronPersonalize:hover, #tarteaucitronRoot #tarteaucitronPersonalize:focus {
  background: transparent;
  color: #fff;
}

#tarteaucitronRoot #tarteaucitronAlertBig #tarteaucitronCloseAlert {
  background: transparent;
  color: #fff;
}

#tarteaucitronRoot #tarteaucitronAlertBig > button {
  margin: 0.5rem;
}

#tarteaucitronRoot #tarteaucitron #tarteaucitronServices .tarteaucitronDetails {
  background: #000;
  display: block !important;
  max-width: inherit;
  padding: 10px 20px;
  position: static;
}

#tarteaucitronRoot #tarteaucitron #tarteaucitronServices .tarteaucitronTitle {
  background: #000;
}

#tarteaucitronRoot #tarteaucitron #tarteaucitronServices .tarteaucitronTitle button {
  margin-top: 0;
}

#tarteaucitronDisclaimerAlert {
  display: block !important;
  margin: 0 0 1rem !important;
  text-align: center !important;
}

#tarteaucitronRoot #tarteaucitronAlertBig {
  box-sizing: border-box !important;
  padding: 2rem 3rem !important;
}

div#tarteaucitronInfo {
  position: inherit !important;
}

#tarteaucitronAlertSmall {
  left: 0;
  right: auto !important;
}

#fancyboxLine {
  display: none;
}

/* stylelint-enable */
form {
  display: block;
  margin: 2em 0 3em;
}

form:first-child {
  margin-top: 0;
}

.form__field-wrapper {
  margin: 1.5em 0 2.5em;
}

.form__field-wrapper.grid {
  margin-bottom: 0;
  margin-top: -1.5em;
}

.confirmation form {
  display: inline-block;
  margin: 0;
}

fieldset {
  margin-top: 3em;
  margin-bottom: 6em;
  border: none;
  position: relative;
  background: transparent;
  min-width: inherit;
}

fieldset:first-child {
  margin-top: 0;
}

.legend,
.rte .legend,
legend {
  display: block;
  padding: 0;
  color: #000;
  text-align: left;
  font-style: normal;
  font-size: 2em;
  font-weight: 700;
  white-space: normal;
  width: 100%;
  margin-bottom: 1em;
}

input, textarea, select {
  font-size: 1.4em;
  font-family: "Raleway", sans-serif;
  background: #fff;
  width: 100%;
  border: 1px solid #808f95;
  padding: 0.67857em 0.71429em;
  color: #808f95;
}

input:disabled, textarea:disabled, select:disabled {
  background: #ebf2f5;
}

input:focus, textarea:focus, select:focus {
  outline: 1px dashed #00acec;
  outline-offset: 0;
}

input::-webkit-input-placeholder {
  color: #808f95;
}

input::-moz-placeholder {
  color: #808f95;
}

input:-ms-input-placeholder {
  color: #808f95;
}

input:-moz-placeholder {
  color: #808f95;
}

input:focus {
  outline: none;
  border: 1px dotted #ee5159;
}

input:read-only, textarea:read-only {
  background: #ebf2f5;
}

textarea {
  height: 7.14286em;
}

select {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  background: #fff url("../Images/form/select_right.png") no-repeat 100% 50%;
  cursor: pointer;
  padding-right: 2.14286em;
}

select:disabled {
  background: #ebf2f5 url("../Images/form/select_right-3.png") no-repeat 100% 50%;
  cursor: inherit;
}

select::-ms-expand {
  display: none;
}

.label,
.rte .label,
label {
  display: block;
  font-size: 1.4em;
  font-weight: 700;
  color: #000;
  margin: 0 0 0.35714em;
  cursor: pointer;
}

.label .text-help,
.rte .label .text-help,
label .text-help {
  font-size: 0.85714em;
}

.label .required,
.rte .label .required,
label .required {
  font-size: 1em;
}

.label input, .label select, .label textarea,
.rte .label input,
.rte .label select,
.rte .label textarea,
label input,
label select,
label textarea {
  margin-top: 0.35714em;
  font-size: 1.4rem;
  padding: 0.60714em 0.71429em;
}

.text-label {
  display: block;
  margin: 0 0 0.35714em;
}

.text-help {
  font-weight: 300;
  font-size: 1.2em;
  display: block;
  color: #4c4c4c;
  margin: .5em 0 .5em;
}

.text-help.text-help--inline {
  display: inline;
}

.required {
  color: #fe0000;
  font-size: 1.4em;
  font-weight: 400;
  font-style: normal;
}

.checkbox.form__field-follow-checkbox {
  display: flex;
  align-items: center;
  margin: .5em 0;
}

.checkbox.form__field-follow-checkbox > * {
  margin: 0;
}

.checkbox.form__field-follow-checkbox input[type="checbox"] {
  margin-right: .5em;
}

.form__field-group {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  border: 1px solid #808f95;
}

.form__field-group > * {
  flex-basis: 100%;
}

.form__field-group input {
  margin: 0;
  border-color: transparent;
}

.form__attached-field {
  background: #ebf2f5;
  flex: 1 0 auto;
  font-size: 0.92857em;
  font-weight: 400;
  padding: 1.1rem 1rem;
}

.geocode__wrapper {
  position: relative;
}

.geocode__wrapper .geocode__wrapper-button.button-1.button-1--no-text, .geocode__wrapper .geocode__wrapper-button .rte .button-1.button-1--no-text {
  position: absolute;
  z-index: 1;
  top: .2rem;
  right: .2rem;
  bottom: .2rem;
}

.geocode__wrapper .geocode__wrapper-button.button-1.button-1--no-text:before, .geocode__wrapper .geocode__wrapper-button .rte .button-1.button-1--no-text:before {
  content: '\e055';
  line-height: normal;
}

form .form__field-datewrapper {
  position: relative;
}

form .form__field-datewrapper .date-picker-control {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 10px;
}

.date-picker table {
  font-size: 1.5em;
}

.ui-timepicker-wrapper {
  font-size: 1.5em;
}

.parsley-errors-list p {
  margin: 5px 0 15px;
  color: #ff000d;
  font-style: italic;
  font-size: 1.2em;
}

.parsley-errors-list p:before {
  font-family: "icons-default";
  content: "";
  margin: 0;
  font-size: 1.6rem;
  color: #ff000d;
  vertical-align: middle;
  display: inline-block;
  line-height: normal;
  text-transform: inherit;
}

input[type=radio], input[type=checkbox] {
  width: auto;
  border: none;
  padding: inherit;
  background: transparent;
}

.radio, .checkbox {
  clear: both;
  margin: 1.5em 0 2.5em;
}

.radio:first-child, .checkbox:first-child {
  margin-top: 1.5em;
}

.radio.radio-checkbox--inline div, .checkbox.radio-checkbox--inline div {
  display: inline-block;
  vertical-align: top;
  margin-top: 0;
  margin-right: 1.2em;
  margin-bottom: 1.3em;
}

.radio.radio-checkbox--inline legend, .checkbox.radio-checkbox--inline legend {
  margin-bottom: 1.28571em;
}

.radio.column-2 div, .checkbox.column-2 div {
  display: inline-block;
  vertical-align: top;
  width: 47.4%;
}

.radio.column-2 div:nth-child(even), .checkbox.column-2 div:nth-child(even) {
  margin-right: 1%;
}

.radio.column-2 div:nth-child(odd), .checkbox.column-2 div:nth-child(odd) {
  margin-left: 1%;
}

.radio.column-2 legend + div, .radio.column-2 legend + div + div, .checkbox.column-2 legend + div, .checkbox.column-2 legend + div + div {
  margin-top: 0;
}

.radio div, .checkbox div {
  margin: 1.1em 0;
}

.radio div:nth-of-type(1), .checkbox div:nth-of-type(1) {
  margin-top: 0;
}

.radio div:last-child, .checkbox div:last-child {
  margin-bottom: 0;
}

.radio legend, .checkbox legend {
  margin: 0.35714em 0 0.35714em;
  font-size: 1.4em;
}

.radio legend .required, .checkbox legend .required {
  font-size: 1em;
}

.radio label, .checkbox label {
  font-weight: 300;
  display: inline-block;
  position: relative;
  line-height: 1em;
  padding: 0 0 0 1.42857em;
  margin: 0;
}

.radio label:before, .checkbox label:before {
  content: "";
  position: absolute;
  display: inline-block;
  background-color: #fff;
  border: 1px solid #c2d9e1;
  font-size: 1em;
  width: 17px;
  height: 17px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  speak: none;
}

.radio label:after, .checkbox label:after {
  position: absolute;
  line-height: normal;
  content: "";
  speak: none;
  background: transparent;
  transition: all ease .5s;
  top: 50%;
  transform: translateY(-50%);
}

.radio input[type=radio], .radio input[type=checkbox], .checkbox input[type=radio], .checkbox input[type=checkbox] {
  position: absolute;
  left: -9999px;
}

.radio input[type=radio]:disabled + label, .radio input[type=checkbox]:disabled + label, .checkbox input[type=radio]:disabled + label, .checkbox input[type=checkbox]:disabled + label {
  cursor: default;
  color: #b3b3b3;
}

.radio input[type=radio]:disabled + label:before, .radio input[type=checkbox]:disabled + label:before, .checkbox input[type=radio]:disabled + label:before, .checkbox input[type=checkbox]:disabled + label:before {
  border-color: #c2d9e1;
  cursor: default;
}

.radio input[type=radio]:focus + label, .radio input[type=checkbox]:focus + label, .checkbox input[type=radio]:focus + label, .checkbox input[type=checkbox]:focus + label {
  outline: 1px dashed #808f95;
  outline-offset: 2px;
}

.radio input[type=checkbox]:checked + label:after, .radio input[type=checkbox]:checked:disabled + label:after, .checkbox input[type=checkbox]:checked + label:after, .checkbox input[type=checkbox]:checked:disabled + label:after {
  top: 50%;
  left: 2px;
  content: "\e059";
  font-family: 'icons-default';
  color: #00acec;
  transform: translateY(-50%);
}

.radio input[type=checkbox]:checked:disabled + label:after, .checkbox input[type=checkbox]:checked:disabled + label:after {
  background: transparent;
  color: #808f95;
}

.radio input[type=radio]:checked + label:after, .radio input[type=radio]:checked:disabled + label:after, .checkbox input[type=radio]:checked + label:after, .checkbox input[type=radio]:checked:disabled + label:after {
  left: 4.45px;
  width: 8px;
  height: 8px;
  background: #00acec;
  border-radius: 50%;
  display: block;
}

.radio label:before {
  border-radius: 50%;
}

.rte .powermail_fieldwrap_file_list > li, .powermail_fieldwrap_file_list > li {
  margin: .5em 0;
  font-size: 1.2em;
  color: #808f95;
  position: relative;
  padding-left: 18px;
}

.rte .powermail_fieldwrap_file_list > li:before, .powermail_fieldwrap_file_list > li:before {
  font-family: "icons-default";
  font-size: 1.4rem;
  color: #68c9f3;
  position: absolute;
  top: 0.3rem;
  right: inherit;
  bottom: inherit;
  left: 0;
  content: "";
  line-height: 1;
}

.rte .powermail_fieldwrap_file_list > li:before, .powermail_fieldwrap_file_list > li:before {
  top: 50%;
  transform: translateY(-50%);
}

.rte .powermail_fieldwrap_file_list > li.powermail_fieldwrap_file_list__delete, .powermail_fieldwrap_file_list > li.powermail_fieldwrap_file_list__delete {
  padding-left: 0;
}

.rte .powermail_fieldwrap_file_list > li.powermail_fieldwrap_file_list__delete:before, .powermail_fieldwrap_file_list > li.powermail_fieldwrap_file_list__delete:before {
  content: inherit;
  display: none;
}

.rte .powermail_fieldwrap_file_list .button-1:before, .powermail_fieldwrap_file_list .button-1:before {
  content: "\e002";
}

.form--dark {
  background: #808f95;
}

.form--dark legend {
  color: #fff;
}

.form--dark label {
  color: #fff;
}

.form--dark .text-help {
  color: #ebf2f5;
}

.form--dark .required {
  color: #ff9898;
}

.form--dark input, .form--dark textarea, .form--dark select {
  background: #67757b;
  border-color: #505b5f;
  color: #fff;
}

.form--dark input:focus, .form--dark textarea:focus, .form--dark select:focus {
  outline-color: #ebf2f5;
  outline-offset: 0;
}

.form--dark select {
  background: #67757b url("../Images/form/select_right-2.png") no-repeat 100% 50%;
}

.form--dark select:disabled {
  background: #67757b url("../Images/form/select_right-3.png") no-repeat 100% 50%;
  color: #9ca8ac;
}

.form--dark .rte .powermail_fieldwrap_file_list > li, .form--dark .powermail_fieldwrap_file_list > li {
  color: #fff;
}

input.button-1, .rte input.button-1 {
  padding-left: 2.66667em;
}

input.button-1.button-1--big, .rte input.button-1.button-1--big {
  padding-left: 1.68421em;
}

input.button-1.button-1--small, .rte input.button-1.button-1--small {
  padding-left: 1.83333em;
}

.button-1, .rte .button-1 {
  font-family: "Raleway", sans-serif;
  display: inline-block;
  vertical-align: middle;
  transition: all ease .3s;
  text-decoration: none;
  font-weight: 400;
  color: #fff;
  text-transform: uppercase;
  background: #1075a1;
  font-size: 1.2em;
  line-height: 1;
  padding: 1.41667em 2.66667em 1.16667em 3.5em;
  position: relative;
}

.button-1:before, .rte .button-1:before {
  font-family: "icons-default";
  font-size: 1.4rem;
  color: #fff;
  position: absolute;
  top: 50%;
  right: inherit;
  bottom: inherit;
  left: 2.2rem;
  content: "";
  line-height: 1;
}

.button-1:before, .rte .button-1:before {
  transform: translateY(-50%);
  transition: all ease .3s;
  font-weight: 400;
}

.button-1[type=reset]:before, .rte .button-1[type=reset]:before {
  content: "\e002";
}

.button-1[type=submit]:before, .rte .button-1[type=submit]:before {
  content: "\e057";
}

.button-1:hover, .button-1:focus, .rte .button-1:hover, .rte .button-1:focus {
  text-decoration: none;
  background: #4c4c4c;
  color: #FFF;
}

.button-1:hover:before, .button-1:focus:before, .rte .button-1:hover:before, .rte .button-1:focus:before {
  color: #FFF;
}

.button-1:hover > svg, .button-1:focus > svg, .rte .button-1:hover > svg, .rte .button-1:focus > svg {
  fill: #FFF;
}

.button-1.button-1--big, .rte .button-1.button-1--big {
  font-size: 1.9em;
  padding: 0.89474em 2.10526em 0.89474em 2.63158em;
}

.button-1.button-1--big:before, .rte .button-1.button-1--big:before {
  left: 1.26316em;
  font-size: 1.9rem;
}

.button-1.button-1--small, .rte .button-1.button-1--small {
  font-size: 1.2em;
  padding: 0.73333em 1.83333em 0.73333em 2.66667em;
}

.button-1.button-1--small:before, .rte .button-1.button-1--small:before {
  left: 1.25em;
  font-size: 1.2rem;
}

.button-1.button-1--no-icon, .rte .button-1.button-1--no-icon {
  padding-left: 1.66667em;
  padding-right: 1.66667em;
}

.button-1.button-1--no-icon:before, .rte .button-1.button-1--no-icon:before {
  content: "";
  margin: 0;
}

.button-1.button-1--no-text, .rte .button-1.button-1--no-text {
  text-align: left;
  text-indent: -9999px;
  white-space: nowrap;
  position: relative;
  padding: 1.08333em 1.58333em;
}

.button-1.button-1--no-text:before, .rte .button-1.button-1--no-text:before {
  text-indent: 0;
  line-height: 0;
  display: block;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  margin: 0;
}

.button-1.button-1--svg, .rte .button-1.button-1--svg {
  padding: 1.16667em 1.08333em;
}

.button-1 > svg, .rte .button-1 > svg {
  fill: #fff;
  width: 1em;
  height: 1em;
  vertical-align: middle;
}

.button-1.button-1--calendar:before, .rte .button-1.button-1--calendar:before {
  left: 0.71429em;
  content: "\e05c";
}

.button-1.button-1--next:before, .rte .button-1.button-1--next:before {
  content: "\e027";
}

.button-1--variation-1, .rte .button-1--variation-1 {
  font-weight: 700;
  color: #000;
  background: #00acec;
}

.button-1--variation-1:before, .rte .button-1--variation-1:before {
  color: #000;
  content: '\e057';
}

.button-1--variation-1 > svg, .rte .button-1--variation-1 > svg {
  fill: #000;
}

.button-1--plus, .rte .button-1--plus {
  width: 5.1rem;
  height: 5.1rem;
  padding: 0;
  background-color: #000;
}

.button-1--plus:before, .rte .button-1--plus:before {
  font-size: 1.6rem;
  content: '\e08a';
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.button-1.deleteAllFiles, .rte .button-1.deleteAllFiles {
  color: #000;
  background: transparent;
  border: 1px solid rgba(0, 172, 236, 0.6);
  font-weight: 700;
}

.button-1.deleteAllFiles:before, .rte .button-1.deleteAllFiles:before {
  color: #000;
}

.button-1.deleteAllFiles:hover, .button-1.deleteAllFiles:focus, .rte .button-1.deleteAllFiles:hover, .rte .button-1.deleteAllFiles:focus {
  background: #4c4c4c;
  color: #fff;
  border-color: #4c4c4c;
}

.button-1.deleteAllFiles:hover:before, .button-1.deleteAllFiles:focus:before, .rte .button-1.deleteAllFiles:hover:before, .rte .button-1.deleteAllFiles:focus:before {
  color: #fff;
}

a.button-1[href^="http://"]:not([href*="ploermel.bzh"]):after,
a.button-1[href^="https://"]:not([href*="ploermel.bzh"]):after,
a.button-1[href^="//"]:not([href*="ploermel.bzh"]):after {
  font-family: "icons-default";
  content: "";
  margin: 0 0 0 0.5rem;
  font-size: 0.8rem;
  color: #fff;
  vertical-align: middle;
  display: inline-block;
  line-height: normal;
  text-decoration: none;
}

a.button-1[href^="http://"]:not([href*="ploermel.bzh"]):after,
a.button-1[href^="https://"]:not([href*="ploermel.bzh"]):after,
a.button-1[href^="//"]:not([href*="ploermel.bzh"]):after {
  transition: all ease .3s;
}

a.button-1[href^="http://"]:not([href*="ploermel.bzh"]):hover:after, a.button-1[href^="http://"]:not([href*="ploermel.bzh"]):focus:after,
a.button-1[href^="https://"]:not([href*="ploermel.bzh"]):hover:after,
a.button-1[href^="https://"]:not([href*="ploermel.bzh"]):focus:after,
a.button-1[href^="//"]:not([href*="ploermel.bzh"]):hover:after,
a.button-1[href^="//"]:not([href*="ploermel.bzh"]):focus:after {
  color: #fff;
}

a.button-1.button-1--variation-1[href^="http://"]:not([href*="ploermel.bzh"]):after,
a.button-1.button-1--variation-1[href^="https://"]:not([href*="ploermel.bzh"]):after,
a.button-1.button-1--variation-1[href^="//"]:not([href*="ploermel.bzh"]):after {
  color: #000;
}

a.button-1.button-1--variation-1[href^="http://"]:not([href*="ploermel.bzh"]):hover:after, a.button-1.button-1--variation-1[href^="http://"]:not([href*="ploermel.bzh"]):focus:after,
a.button-1.button-1--variation-1[href^="https://"]:not([href*="ploermel.bzh"]):hover:after,
a.button-1.button-1--variation-1[href^="https://"]:not([href*="ploermel.bzh"]):focus:after,
a.button-1.button-1--variation-1[href^="//"]:not([href*="ploermel.bzh"]):hover:after,
a.button-1.button-1--variation-1[href^="//"]:not([href*="ploermel.bzh"]):focus:after {
  color: #fff;
}

.tx-powermail-pi1_confirmation_back:before {
  content: "" !important;
}

a:focus, button:focus, input[type="button"]:focus {
  outline: 1px dashed #00acec;
}

.link-block:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  content: '';
}

.link-bloc__context {
  position: relative;
}

.link-bloc__context .link-bloc:after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: "";
  speak: none;
  z-index: 10;
}

.link-view .button-1:before {
  content: "\e027";
}

.column-bloc .link-view {
  margin-top: 2em;
}

.wrapper-main, .sg-menu ul, .top-of-content__wrapper, .section-main__wrapper, .heading .headding__wrapper-title, .heading .heading__wrapper, .menu-stratis .menu-stratis__level-1, .menu-main-1 {
  width: 100%;
  max-width: 118rem;
  margin: 0 auto;
  padding: 0 1em;
}

.wrapper-main--home, .footer .footer__wrapper {
  width: 100%;
  max-width: 124rem;
  margin: 0 auto;
  padding: 0 1em;
}

.background-in-wrapper {
  position: relative;
  background: #ebf2f5;
}

.background-in-wrapper:before, .background-in-wrapper:after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  width: 999em;
  height: 100%;
  background: #ebf2f5;
}

.background-in-wrapper:before {
  left: -9990px;
}

.background-in-wrapper:after {
  right: -9990px;
}

.background-in-wrapper.background-in-wrapper--dark {
  background: #808f95;
}

.background-in-wrapper.background-in-wrapper--dark:before, .background-in-wrapper.background-in-wrapper--dark:after {
  background: #808f95;
}

.column-bloc {
  width: 24em;
  padding-left: 4em;
  margin-bottom: 10em;
}

.column-bloc__wrapper {
  padding: 0;
}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 3rem;
  position: relative;
}

.header--right {
  min-width: 22.1rem;
  display: flex;
  align-items: center;
}

.top-of-content {
  background: #f6f6f6;
}

.top-of-content__wrapper {
  padding: 2em 0;
  border-bottom: 2px solid rgba(197, 197, 197, 0.5);
}

.main {
  width: 100%;
  clear: both;
}

.section-main__wrapper {
  padding-top: 7em;
  padding-bottom: 3em;
}

.section-main__wrapper-2 {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.section-main__wrapper-2 > * {
  display: table-cell;
  vertical-align: top;
}

.section-main__aside {
  width: 35rem;
  padding-right: 7rem;
}

.section-main__aside > .column-bloc *:first-child {
  margin-top: 0;
}

.section-main__content {
  width: 100%;
}

.footer {
  background: #000 url("../Images/temp/footer-bg.png") no-repeat center;
}

.footer .footer__wrapper {
  padding-top: 3em;
  padding-bottom: 1em;
}

.footer .footer__wrapper-2 {
  margin: 0 -1.5em;
  display: flex;
  width: 100%;
}

.footer .footer__wrapper-2 > * {
  margin: 0 1.5em;
}

.footer .site-infos {
  flex-basis: 39%;
  flex-shrink: 0;
}

.footer .menu-cross {
  flex-shrink: 0;
}

.footer .community {
  flex-basis: 17%;
}

.subtitle, .category {
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  font-size: 1.8em;
  text-transform: uppercase;
  color: #4c4c4c;
}

.important, .important a {
  color: #808f95;
  font-weight: 700;
}

.caption {
  font-size: 1.2em;
  font-weight: 300;
  font-style: italic;
  font-family: "Raleway", sans-serif;
  color: #4c4c4c;
}

.date {
  font-size: 1.2em;
  font-weight: 400;
  font-family: "Raleway", sans-serif;
  color: #808f95;
  margin: 0.5em 0;
}

.date-1 {
  font-family: "Raleway", sans-serif;
  color: #66a12c;
  margin-bottom: 2em;
  text-align: left;
}

.date-1 time {
  height: 6rem;
  width: 6.1rem;
  margin: 0 auto;
  padding-left: 1rem;
  padding-top: 1.2rem;
  position: relative;
  display: block;
}

.date-1 time:before {
  width: 0;
  height: 0;
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-top: 43px solid transparent;
  border-left: 61px solid #1075a1;
  border-bottom: 43px solid transparent;
}

.date-1 time + span {
  text-align: center;
  width: 6.1rem;
  display: block;
  font-size: 1.5rem;
  color: #000;
  font-weight: 300;
}

.date-1 time + span + time {
  padding-left: 2.7rem;
}

.date-1 time + span + time:before {
  border-left: 0;
  border-right: 61px solid #000;
}

.date-1 .day, .date-1 .month {
  position: relative;
  z-index: 1;
  color: #fff;
  display: block;
  line-height: 1;
}

.date-1 .day {
  font-size: 2rem;
  font-weight: 900;
}

.date-1 .month {
  font-size: 1.2rem;
  font-weight: 300;
}

.date-2 {
  font-size: 1.4em;
  text-transform: uppercase;
  margin: 0 0 0.57143em;
}

.date-2 time {
  font-weight: 700;
}

.date-2.date-2--big {
  font-size: 2em;
  margin: 0.25em 0 0.4em;
}

.date-3 {
  position: relative;
  font-family: "Raleway", sans-serif;
  font-size: 1.2em;
  font-weight: 700;
  text-transform: uppercase;
  padding-top: 1em;
  padding-left: 1.5em;
  margin-bottom: 1em;
  border-top: 1px solid #a7c6d3;
}

.date-3:before {
  font-family: "icons-default";
  font-size: 1.2rem;
  color: #88c54c;
  position: absolute;
  top: 1.3rem;
  right: inherit;
  bottom: inherit;
  left: 0;
  content: "";
  line-height: 1;
}

.date-3 time {
  font-weight: 300;
}

.date-3.date-3--big {
  font-size: 1.6em;
  padding-top: 0.75em;
  padding-left: 1.25em;
  margin-bottom: 0.75em;
  margin-top: 0.75em;
}

.date-3.date-3--big:before {
  font-size: 1em;
  top: 0.8125em;
}

.date-3.date-3--big br {
  display: none;
}

.date-4 {
  font-size: 1.5em;
  text-transform: uppercase;
  font-weight: 300;
  margin: 0 0 1.33333em;
}

.date-4 time + span + time:before {
  font-family: "icons-default";
  content: "";
  margin: 0 0.5rem 0 0;
  font-size: 1.4rem;
  color: #000;
  vertical-align: -0.1rem;
  display: inline-block;
  line-height: normal;
  text-transform: inherit;
}

.media {
  border-top: 1px solid #a7c6d3;
  color: #808f95;
  text-transform: uppercase;
  font-size: 1.2em;
  padding-top: 1em;
}

.hour-place {
  text-align: center;
}

.hour {
  font-family: "Raleway", sans-serif;
  display: block;
  color: #4c4c4c;
  font-weight: 300;
  font-size: 1.4rem;
  margin-bottom: 0.35714em;
}

.place {
  display: block;
  position: relative;
  color: #4c4c4c;
  font-size: 1.3rem;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.public {
  display: block;
  margin-top: 1rem;
  font-size: 1.3rem;
  text-align: center;
  font-style: italic;
  color: #4c4c4c;
}

.price {
  display: block;
  margin-top: 1rem;
  font-size: 1.3rem;
  text-align: center;
  color: #4c4c4c;
}

.teaser-1 {
  font-size: 1.3em;
  margin: 0.61538em 0;
}

.teaser-2 {
  font-size: 1.8em;
  font-weight: 200;
  line-height: 1.5em;
  color: #242c59;
  margin: 0.66667em 0 0.27778em;
}

.teaser-3 {
  font-size: 1.6em;
  font-weight: 400;
  margin: 0.5em 0;
}

.status {
  font-size: 1.2em;
  background: #ebf2f5;
  padding: 0.41667em;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  margin: 0 0 0.66667em;
}

.status:before {
  font-family: "icons-default";
  content: "";
  margin: 0 5px 0 0;
  font-size: 1rem;
  color: #808f95;
  vertical-align: 0;
  display: inline-block;
  line-height: normal;
  text-transform: inherit;
}

.status.status--new {
  background: #88c54c;
  color: #fff;
}

.status.status--new:before {
  content: "\e093";
  color: #fff;
}

.status.status--in-progress {
  background: #00acec;
  color: #fff;
}

.status.status--in-progress:before {
  content: "\e093";
  color: #fff;
}

.message {
  background: #ebf2f5;
  font-size: 1.4em;
  font-weight: 300;
  padding: 2.5em 3em;
}

.message h2, .message:before {
  content: attr(aria-label);
  display: block;
  color: #000;
  font-size: 1.42857em;
  font-weight: 700;
  margin-bottom: 1em;
}

.message > li {
  padding-left: 1.07143em;
  position: relative;
  margin: 0.35714em 0;
}

.message > li:before {
  font-family: "icons-default";
  font-size: 1.2rem;
  color: #000;
  position: absolute;
  top: 0.4rem;
  right: inherit;
  bottom: inherit;
  left: 0;
  content: "";
  line-height: 1;
}

.message--block:before {
  content: none;
}

.message.message--error {
  background: #ffe5e5;
}

.message.message--error h2, .message.message--error:before {
  color: #fe0000;
}

.message.message--success {
  background: #edfceb;
}

.message.message--success h2, .message.message--success:before {
  color: #278417;
}

.no-result {
  font-size: 1.5em;
  font-weight: 300;
  text-align: center;
}

.heading {
  background: #f6f6f6;
  padding: 6em 0 7em;
}

.heading .headding__wrapper-title {
  margin-bottom: 2em;
}

.heading .heading__wrapper {
  display: flex;
  flex-wrap: nowrap;
  position: relative;
}

.heading .heading__wrapper > *:last-child {
  margin-bottom: 0;
}

.heading .heading__wrapper .heading__wrapper-figure {
  flex-basis: auto;
}

.heading .heading__wrapper .heading__wrap {
  flex-basis: 100%;
}

.heading .heading__figure {
  margin-right: 2em;
  display: table;
}

.heading .heading__picture {
  display: block;
  margin-bottom: .5em;
}

.heading .heading__picture img {
  height: auto;
}

.heading .heading__caption {
  display: table-caption;
  caption-side: bottom;
}

.heading h1 {
  font-family: "Raleway", sans-serif;
  font-weight: 900;
  font-size: 4.5em;
  line-height: 1.3;
  color: #1075a1;
}

.heading .subtitle {
  display: table;
  padding: 0.8rem 1.9rem;
  font-size: 1.8em;
  border: 1px solid #000;
}

.heading .date {
  font-size: 1.4em;
}

.heading .status {
  background: #fff;
  font-size: 1.5em;
  margin-top: 0.33333em;
  display: inline-block;
}

.heading .status.status--new {
  background: #88c54c;
  color: #fff;
}

.heading .status.status--new:before {
  content: "\e093";
  color: #fff;
}

.heading .status.status--in-progress {
  background: #00acec;
  color: #fff;
}

.heading .status.status--in-progress:before {
  content: "\e093";
  color: #fff;
}

.heading__category {
  margin-bottom: 1rem;
}

.heading .heading__media {
  margin-top: 1em;
  padding-top: 1em;
}

.heading .heading__media .date, .heading .heading__media .media {
  font-size: 1.6em;
}

.heading .heading__media .media {
  font-weight: 700;
  border: none;
}

.heading .heading__media .media + .date:before {
  content: " - ";
  display: inline;
  font-size: 1.2em;
  color: #808f95;
}

.heading.heading--picture-right .heading__wrapper .heading__wrapper-figure {
  order: 2;
}

.heading.heading--picture-right .heading__wrapper .heading__wrap {
  order: 1;
}

.heading.heading--picture-right .heading__figure {
  margin-right: 0;
  margin-left: 2em;
}

.heading--single-news .heading__picture img {
  width: 280px;
  max-width: inherit;
  height: auto;
}

.heading--single-event .heading__wrapper {
  display: block;
  flex-wrap: inherit;
}

.heading--single-event .heading__figure {
  margin-right: 0;
}

.heading--single-event .heading__wrapper-figure, .heading--single-event .heading__wrapper-date, .heading--single-event .heading__wrap {
  display: table-cell;
  vertical-align: top;
}

.heading--single-event .heading__picture img {
  width: 280px;
  max-width: inherit;
  height: auto;
}

.heading--single-event .heading__wrapper-date {
  width: 16em;
  border-right: 1px solid #808f95;
}

.heading--single-event .heading__wrapper-calendar-button {
  margin: 1em 1em 0;
  line-height: normal;
}

.heading--single-event .heading__wrap {
  padding-left: 2em;
}

.heading--single-event .button-1 {
  margin-top: 2em;
  cursor: pointer;
}

.heading--single-publications .heading__picture img, .heading--single-public-market .heading__picture img {
  width: 220px;
  max-width: inherit;
  height: auto;
}

.heading--single-directory .list-infos {
  margin-top: 2em;
}

.heading--single-directory .list-infos__wrapper .list-infos__listitems:first-child {
  flex: 1 1 0;
}

.js-cut-to-heading {
  position: absolute;
  top: 0;
  right: 0;
}

.title-1 {
  font-family: "Raleway", sans-serif;
  font-size: 3em;
  line-height: 1em;
  font-weight: 300;
  color: #1075a1;
  text-transform: uppercase;
  margin: 1em 0 0.66667em;
}

.title-1.title-decoration span:first-child {
  padding-left: 0;
  padding-right: 1.3rem;
}

.title-1.title-decoration span:first-child:before {
  content: '';
}

.title-1.title-decoration span:first-child:after {
  font-size: 2rem;
  right: -1rem;
}

.title-2 {
  font-family: "Raleway", sans-serif;
  font-size: 3em;
  font-weight: 300;
  color: #1075a1;
  text-transform: uppercase;
  margin: 0.83333em 0 0.5em;
}

.title-3 {
  font-family: "Raleway", sans-serif;
  font-size: 3.5em;
  line-height: 1em;
  font-weight: 300;
  color: #000;
  margin: 0.71429em 0 0.57143em;
}

.title-decoration {
  position: relative;
}

.title-decoration:before {
  content: '';
  display: block;
  height: 0.1rem;
  width: 100%;
  background-color: #00acec;
  position: absolute;
  top: 50%;
}

.title-decoration span:first-child {
  padding: 0 1.8rem 0 3.1rem;
  line-height: 1.2;
  position: relative;
  background-color: #fff;
}

.title-decoration span:first-child b {
  font-weight: 900;
}

.title-decoration span:first-child:before, .title-decoration span:first-child:after {
  content: '\E008';
  font-family: 'icons-project';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 3rem;
  color: #00acec;
}

.title-decoration span:first-child:before {
  left: 0;
}

.title-decoration span:first-child:after {
  right: -1.3rem;
}

.title-decoration .btn-wrap {
  background-color: #fff;
  padding-left: 1rem;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
}

.title-decoration .btn-wrap > * {
  margin: 0 0.1rem;
}

.title-decoration .btn-wrap .button-1--rss {
  width: 5.1rem;
  height: 5.1rem;
  padding: 0;
  background-color: #00acec;
}

.title-decoration .btn-wrap .button-1--rss svg {
  width: 1.4rem;
  height: 1.4rem;
  fill: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.title-decoration .btn-wrap .button-1--rss:hover, .title-decoration .btn-wrap .button-1--rss:focus {
  background-color: #4c4c4c;
}

.pager {
  width: 100%;
}

.pager ul {
  font-family: "Raleway", sans-serif;
  clear: both;
  display: block;
  margin: 3em 0 5em 0;
  padding: 0;
  text-align: center;
  font-weight: 300;
  font-style: normal;
  text-transform: uppercase;
}

.pager li {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0em;
  padding: 0;
  background: none;
  font-size: 1.2rem;
  font-weight: 700;
}

.pager li a, .pager li span {
  padding: 0.7rem 1.36rem;
  line-height: 2rem;
  display: inline-block;
  vertical-align: middle;
}

.pager li a {
  color: #fff;
  background: #1075a1;
  text-decoration: none;
  transition: all ease .2s;
}

.pager li a:hover, .pager li a:focus {
  background: #4c4c4c;
}

.pager li span {
  cursor: default;
  color: #000;
  background: #00acec;
}

.pager li.pager__prev, .pager li.pager__next {
  margin-top: -0.4rem;
}

.pager li.pager__prev a, .pager li.pager__prev span, .pager li.pager__next a, .pager li.pager__next span {
  min-width: 14rem;
  padding: 1rem 2.68rem;
}

.pager li.pager__prev span, .pager li.pager__next span {
  color: #000;
  background: #ebf2f5;
}

.pager li.pager__prev {
  float: left;
}

.pager li.pager__prev a, .pager li.pager__prev span {
  float: left;
}

.pager li.pager__prev a:before, .pager li.pager__prev span:before {
  font-family: "icons-default";
  content: "";
  margin: 0 0.5rem 0 0;
  font-size: 1.1rem;
  color: #fff;
  vertical-align: -0.1rem;
  display: inline-block;
  line-height: normal;
  text-transform: inherit;
}

.pager li.pager__prev a:hover:before, .pager li.pager__prev a:focus:before, .pager li.pager__prev span:hover:before, .pager li.pager__prev span:focus:before {
  color: #fff;
}

.pager li.pager__prev span {
  color: #c2d9e1;
}

.pager li.pager__prev span:before, .pager li.pager__prev span:hover:before, .pager li.pager__prev span:focus:before {
  color: #c2d9e1;
}

.pager li.pager__next {
  float: right;
}

.pager li.pager__next a, .pager li.pager__next span {
  float: right;
}

.pager li.pager__next a:after, .pager li.pager__next span:after {
  font-family: "icons-default";
  content: "";
  margin: 0 0 0 0.5rem;
  font-size: 1.1rem;
  color: #fff;
  vertical-align: -0.1rem;
  display: inline-block;
  line-height: normal;
  text-decoration: none;
}

.pager li.pager__next a:hover:after, .pager li.pager__next a:focus:after, .pager li.pager__next span:hover:after, .pager li.pager__next span:focus:after {
  color: #fff;
}

.pager li.pager__next span {
  color: #c2d9e1;
}

.pager li.pager__next span:after, .pager li.pager__next span:hover:after, .pager li.pager__next span:focus:after {
  color: #c2d9e1;
}

@keyframes spin {
  0% {
    transform: rotate(0deg) translateY(-50%);
  }
  100% {
    transform: rotate(-360deg) translateY(-50%);
  }
}

.pager-infinite {
  margin: 2em 0;
  text-align: center;
}

.pager-infinite .pager-infinite__button:before {
  content: "\e05f";
}

.pager-infinite .pager-infinite__loading:before {
  animation: spin 1s infinite linear;
  content: "\e05f";
  transform-origin: top;
}

.pager-single .pager-single__back-to-list a {
  background: #00acec;
  color: #000;
  font-weight: 700;
  padding: 0.71429em 1.71429em;
}

.pager-single .pager-single__back-to-list a:before {
  font-family: "icons-default";
  content: "";
  margin: 0 0.5rem 0 0;
  font-size: 1.2rem;
  color: #000;
  vertical-align: -0.1rem;
  display: inline-block;
  line-height: normal;
  text-transform: inherit;
}

.pager-single .pager-single__back-to-list a:before {
  transition: all ease .2s;
}

.pager-single .pager-single__back-to-list a:hover, .pager-single .pager-single__back-to-list a:focus {
  color: #fff;
}

.pager-single .pager-single__back-to-list a:hover:before, .pager-single .pager-single__back-to-list a:focus:before {
  color: #fff;
}

.pager-alphabet {
  margin-bottom: 5em;
}

.pager-alphabet__title {
  font-size: 1.4em;
  font-weight: 700;
  margin-bottom: 0.35714em;
}

.pager-alphabet__listitems {
  text-align: left;
}

.pager-alphabet__listitems > li {
  display: inline-block;
  vertical-align: middle;
  margin: .2em 0.1em;
  text-align: center;
}

.pager-alphabet__listitems > li.pager-alphabet__item--current > a {
  background: #00acec;
  color: #000;
}

.pager-alphabet__listitems > li.pager-alphabet__item--tous a, .pager-alphabet__listitems > li.pager-alphabet__item--number a {
  width: 6.6rem;
}

.pager-alphabet__listitems > li > a, .pager-alphabet__listitems > li > span {
  width: 3rem;
  height: 3rem;
  font-size: 1.2em;
  font-weight: 700;
  background: #1075a1;
  color: #fff;
  display: block;
  padding: 0.58333em 0;
}

.pager-alphabet__listitems > li > a:hover, .pager-alphabet__listitems > li > a:focus {
  background: #4c4c4c;
  color: #fff;
  text-decoration: none;
}

.pager-alphabet__listitems > li > span {
  background: #fff;
  border: 1px solid #e1ebf0;
  color: #b8d1dc;
}

.pager-single-xl {
  width: 100%;
  margin-bottom: 13em;
}

.pager-single-xl ul {
  font-family: "Raleway", sans-serif;
  clear: both;
  display: block;
  margin: 3em 0 5em 0;
  padding: 0;
  text-align: center;
  font-weight: 300;
  font-style: normal;
  text-transform: uppercase;
  position: relative;
}

.pager-single-xl li {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0em;
  padding: 0;
  background: none;
  font-size: 1.2rem;
  font-weight: 700;
}

.pager-single-xl li.pager__prev, .pager-single-xl li.pager__next {
  width: 49%;
}

.pager-single-xl li.pager__prev a.pager__link:hover .pager__button:before, .pager-single-xl li.pager__prev a.pager__link:hover .pager__button:after, .pager-single-xl li.pager__prev a.pager__link:focus .pager__button:before, .pager-single-xl li.pager__prev a.pager__link:focus .pager__button:after, .pager-single-xl li.pager__next a.pager__link:hover .pager__button:before, .pager-single-xl li.pager__next a.pager__link:hover .pager__button:after, .pager-single-xl li.pager__next a.pager__link:focus .pager__button:before, .pager-single-xl li.pager__next a.pager__link:focus .pager__button:after {
  color: #fff;
}

.pager-single-xl li.pager__prev span.pager__link .pager__button, .pager-single-xl li.pager__next span.pager__link .pager__button {
  color: #000;
  background: #ebf2f5;
}

.pager-single-xl li.pager__prev {
  float: left;
}

.pager-single-xl li.pager__prev .pager__button:before {
  font-family: "icons-default";
  content: "";
  margin: 0 0.5rem 0 0;
  font-size: 1.2rem;
  color: #fff;
  vertical-align: -0.1rem;
  display: inline-block;
  line-height: normal;
  text-transform: inherit;
}

.pager-single-xl li.pager__prev .pager__link:hover, .pager-single-xl li.pager__prev .pager__link:focus {
  color: #fff;
}

.pager-single-xl li.pager__prev span.pager__link .pager__button {
  color: #c2d9e1;
}

.pager-single-xl li.pager__prev span.pager__link .pager__button:before, .pager-single-xl li.pager__prev span.pager__link .pager__button:hover:before, .pager-single-xl li.pager__prev span.pager__link .pager__button:focus:before {
  color: #c2d9e1;
}

.pager-single-xl li.pager__prev .pager__article {
  text-align: left;
  padding-left: 1rem;
}

.pager-single-xl li.pager__next {
  float: right;
}

.pager-single-xl li.pager__next .pager__button:after {
  font-family: "icons-default";
  content: "";
  margin: 0 0 0 0.5rem;
  font-size: 1.2rem;
  color: #fff;
  vertical-align: -0.1rem;
  display: inline-block;
  line-height: normal;
  text-decoration: none;
}

.pager-single-xl li.pager__next .pager__link {
  justify-content: flex-end;
}

.pager-single-xl li.pager__next .pager__link:hover, .pager-single-xl li.pager__next .pager__link:focus {
  color: #fff;
}

.pager-single-xl li.pager__next span.pager__link .pager__button {
  color: #c2d9e1;
}

.pager-single-xl li.pager__next span.pager__link .pager__button:after, .pager-single-xl li.pager__next span.pager__link .pager__button:hover:after, .pager-single-xl li.pager__next span.pager__link .pager__button:focus:after {
  color: #c2d9e1;
}

.pager-single-xl li.pager__next .pager__article {
  text-align: right;
  padding-right: 1rem;
}

.pager-single-xl .pager__link {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.pager-single-xl a.pager__link:hover, .pager-single-xl a.pager__link:focus {
  text-decoration: none;
}

.pager-single-xl a.pager__link:hover > .pager__button, .pager-single-xl a.pager__link:focus > .pager__button {
  background: #4c4c4c;
}

.pager-single-xl a.pager__link > .pager__button {
  color: #fff;
  background: #1075a1;
  text-decoration: none;
  transition: all ease .3s;
}

.pager-single-xl a.pager__link span.pager__link {
  color: #000;
  background: #88c54c;
}

.pager-single-xl .pager__button {
  padding: 1.35rem 2.4rem;
  line-height: 1em;
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
}

.pager-single-xl .pager__article {
  text-transform: none;
  font-size: 1.4rem;
  color: #808f95;
  line-height: normal;
  font-weight: 300;
  font-style: italic;
  display: inline-block;
  vertical-align: middle;
}

.pager-single-xl .pager-single-xl__back-to-list {
  position: absolute;
  top: 140%;
  left: 50%;
  transform: translateX(-50%);
}

.pager-single-xl .pager-single-xl__back-to-list a.pager__link {
  display: inline-block;
}

.pager-single-xl .pager-single-xl__back-to-list a.pager__link:hover .pager__button, .pager-single-xl .pager-single-xl__back-to-list a.pager__link:focus .pager__button {
  color: #fff;
  background: #4c4c4c;
}

.pager-single-xl .pager-single-xl__back-to-list a.pager__link:hover .pager__button:before, .pager-single-xl .pager-single-xl__back-to-list a.pager__link:focus .pager__button:before {
  color: #fff;
}

.pager-single-xl .pager-single-xl__back-to-list a.pager__link .pager__button {
  background: #00acec;
  color: #000;
  font-weight: 700;
  padding: 0.71429em 1.71429em;
}

.pager-single-xl .pager-single-xl__back-to-list a.pager__link .pager__button:before {
  font-family: "icons-default";
  content: "";
  margin: 0 0.5rem 0 0;
  font-size: 1.2rem;
  color: #000;
  vertical-align: -0.1rem;
  display: inline-block;
  line-height: normal;
  text-transform: inherit;
}

.pager-single-xl .pager-single-xl__back-to-list a.pager__link .pager__button:before {
  transition: all 0.2s ease;
}

.progress meter {
  float: left;
  margin: 4px 10px 0 0;
  width: 200px;
  height: 10px;
}

meter {
  /* Reset the default appearance */
  /* For Firefox */
  background: #EEE;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2) inset;
  border-radius: 3px;
  border: 1px solid #fff;
  /* For Webkit */
}

meter::-moz-meter-bar {
  border-radius: 3px;
  background: #00acec;
}

meter::-webkit-meter-bar {
  background: #EEE;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2) inset;
  border-radius: 3px;
  border: 0;
  height: 10px;
}

/* For Webkit */
meter::-webkit-meter-optimum-value,
meter::-webkit-meter-suboptimum-value,
meter::-webkit-meter-even-less-good-value {
  border-radius: 3px;
  background: #00acec;
}

.ddm.ddm--on .filters__ddm {
  display: inline-block;
  vertical-align: middle;
}

.ddm.ddm--on .filters__ddm .button-1:before {
  content: "\e031";
}

.filters .ddm .ddm__sub-level {
  position: static;
  top: inherit;
  right: inherit;
  z-index: inherit;
}

.filters {
  margin-top: -6em;
  padding: 0.1em 0 3em 0;
  background: #f6f6f6;
}

.filters form {
  margin: 2em 0 0;
}

.filters .radio fieldset, .filters .checkbox fieldset {
  margin: 0;
}

.filters .radio:first-child, .filters .checkbox:first-child {
  margin-top: 0;
}

.filters .radio.column-2 legend + div, .filters .checkbox.column-2 legend + div {
  margin-top: 1.1em;
}

.filters__header {
  display: inline-block;
  text-align: left;
}

.filters__locate:before {
  content: "\e04d";
}

.filters__event:before {
  content: "\e009";
}

.filters__wrapper {
  display: table;
  width: 100%;
}

.filters__wrapper.filters__wrapper--in-line .filters__field-wrapper {
  width: 100%;
}

.filters__wrapper.filters__wrapper--in-line .filters__field-wrapper .filters__field-1, .filters__wrapper.filters__wrapper--in-line .filters__field-wrapper .filters__field-2 {
  display: table-cell;
}

.filters__wrapper.filters__wrapper--in-line .filters__field-wrapper .filters__field-1 {
  text-align: right;
  padding-right: 1em;
  white-space: nowrap;
}

.filters__wrapper.filters__wrapper--in-line .filters__field-wrapper .filters__field-2 {
  display: table-cell;
  width: 100%;
}

.filters__wrapper.filters__wrapper--in-line .filters__button-wrapper {
  padding-top: .5em;
}

.filters__single-directory {
  text-align: center;
  margin-top: 2rem;
  margin-right: 2rem;
  display: flex;
  flex-wrap: wrap;
}

.filters__single-directory a {
  width: calc(50% - 0.5rem);
  line-height: 1.2;
  padding: 1.7rem 1.6rem 1.4rem;
}

.filters__single-directory a:before {
  content: none;
}

.filters__single-directory a:first-child {
  margin-right: 1rem;
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .filters__wrapper {
    table-layout: fixed;
  }
}

.filters__fields, .filters__button-wrapper {
  display: table-cell;
  vertical-align: top;
}

.filters__fields {
  width: 100%;
}

.filters__fields .filters__fields-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 0;
  margin-left: -1%;
  margin-right: -1%;
}

.filters__fields .filters__fields-wrapper > .filters__field-wrapper {
  margin: .5em 1% 1.5em;
  flex: 1 0 100%;
}

.filters__fields.filters__fields--column-2 .filters__fields-wrapper > .filters__field-wrapper {
  flex: 1 0 48%;
}

.filters__fields.filters__fields--column-3 .filters__fields-wrapper > .filters__field-wrapper {
  flex: 1 0 31.3333%;
}

.filters__fields.filters__fields--column-4 .filters__fields-wrapper > .filters__field-wrapper {
  flex: 1 0 23%;
}

.filters__button-wrapper {
  padding-left: 2em;
  padding-top: 2.7rem;
  white-space: nowrap;
}

.filters__button-wrapper button:first-child {
  margin-right: 0.3rem;
}

.filters__button-wrapper a.filters__directory {
  display: block;
  margin: 3.9rem 0 0;
  text-align: center;
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .filters__button-wrapper {
    width: 25em;
  }
}

.filters__reset:before {
  content: "\e058";
}

.button-1.filters__submit:before {
  content: "\e031";
}

.filters__dark {
  background: #808f95;
}

.sidebar h2, .sidebar-title {
  font-family: "Raleway", sans-serif;
  color: #4c4c4c;
  font-weight: 900;
  font-size: 1.8rem;
  padding-bottom: 2rem;
  text-transform: uppercase;
  margin-top: 0;
}

.sidebar h2 {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 1rem;
}

.sidebar h2:not(:first-child):not(.sidebar-title) {
  margin-top: 3rem;
}

.sidebar h2 > *:first-child {
  margin-top: 0;
}

.toc-title {
  display: none;
}

#barsContainer > button {
  position: relative;
  padding: 2rem 2.5rem 1.8rem 2.5rem;
  display: block;
  width: 100%;
  border-top: 0.5rem solid #ebf2f5;
  text-align: left;
  transition: all 250ms ease;
  white-space: normal;
}

#barsContainer > button.is-current {
  font-weight: 700;
}

#barsContainer > button.is-current > p {
  color: #68c9f3;
  font-weight: 700;
}

#barsContainer > button > p {
  font-size: 1.3rem;
  text-align: left;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  color: #808f95;
}

.toc-bar {
  position: absolute;
  left: 0;
  top: -.5rem;
  height: .5rem;
  background-color: #00acec;
}

.sidebar #anchor-bar + .tools {
  margin-top: 3rem;
}

.section-main__content span.anchor:first-of-type + .colPos0 .ce-bodytext > *:first-child,
.section-main__content span.anchor:first-of-type + .colPos0 > *:first-child,
.encadre > *:first-child,
.rte.ddm__sub-level > *:first-child,
.ddm__sub-level span.anchor:first-of-type + .colPos18181 .ce-bodytext > *:first-child,
.heading--single + .section-main__wrapper .section-main__content > .rte > *:first-child {
  margin-top: 0;
}

.section-main__content span.anchor:first-of-type + .colPos0:first-child > *:not(.story):first-child,
.section-main__content span.anchor:first-of-type + .colPos0 > .story > *:first-child {
  margin-top: -.5rem;
}

.rte h2, .rte .h2, .h2 {
  font-family: "Raleway", sans-serif;
  font-size: 3.4em;
  line-height: 1.2;
  font-weight: 700;
  color: #000;
  margin: 1.76471em 0 0.73529em 0;
}

.rte h2 a, .rte .h2 a, .h2 a {
  color: #88c54c;
}

.rte h2 a:hover, .rte h2 a:focus, .rte .h2 a:hover, .rte .h2 a:focus, .h2 a:hover, .h2 a:focus {
  color: #000;
}

.rte h3, .rte .h3, .h3 {
  position: relative;
  padding-left: 3.5rem;
  font-family: "Raleway", sans-serif;
  font-size: 2.5em;
  line-height: 1.2;
  font-weight: 900;
  color: #000;
  margin: 2em 0 1.4em 0;
  position: relative;
}

.rte h3:before, .rte .h3:before, .h3:before {
  font-family: "icons-project";
  font-size: 3rem;
  color: #00acec;
  position: absolute;
  top: -0.5rem;
  right: inherit;
  bottom: inherit;
  left: 0;
  content: "";
  line-height: 1;
}

.rte h3 a, .rte .h3 a, .h3 a {
  color: #000;
}

.rte h3 a:hover, .rte h3 a:focus, .rte .h3 a:hover, .rte .h3 a:focus, .h3 a:hover, .h3 a:focus {
  color: #808f95;
}

.rte h4, .rte .h4, .h4 {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 2em;
  line-height: 1.2;
  color: #1075a1;
  margin: 2em 0 0.9em 0;
}

.rte h4 a, .rte .h4 a, .h4 a {
  color: #000;
}

.rte h4 a:hover, .rte h4 a:focus, .rte .h4 a:hover, .rte .h4 a:focus, .h4 a:hover, .h4 a:focus {
  color: #808f95;
}

.rte h5, .rte .h5, .h5 {
  font-family: "Raleway", sans-serif;
  font-weight: 900;
  font-size: 1.7em;
  line-height: 1.2;
  color: #4c4c4c;
  margin: 2.35294em 0 1.05882em 0;
}

.rte h5 a, .rte .h5 a, .h5 a {
  color: #4c4c4c;
}

.rte h5 a:hover, .rte h5 a:focus, .rte .h5 a:hover, .rte .h5 a:focus, .h5 a:hover, .h5 a:focus {
  color: #000;
}

.rte h6, .rte .h6, .h6 {
  font-family: "Raleway", sans-serif;
  font-size: 1.5em;
  line-height: 1.2;
  color: #000;
  margin: 1.33333em 0 0.66667em 0;
}

.rte h6 a, .rte .h6 a, .h6 a {
  color: #000;
}

.rte h6 a:hover, .rte h6 a:focus, .rte .h6 a:hover, .rte .h6 a:focus, .h6 a:hover, .h6 a:focus {
  color: #808f95;
}

.rte a {
  color: #1075a1;
  text-decoration: underline;
  font-weight: 900;
}

.rte a:hover, .rte a:focus {
  text-decoration: none;
}

.rte a[href^="http://"]:not([href*="ploermel.bzh"]):after,
.rte a[href^="https://"]:not([href*="ploermel.bzh"]):after,
.rte a[href^="//"]:not([href*="ploermel.bzh"]):after {
  font-family: "icons-default";
  content: "";
  margin: 0 0 0 0.5rem;
  font-size: 1.4rem;
  color: inherit;
  vertical-align: middle;
  display: inline-block;
  line-height: normal;
  text-decoration: none;
}

.rte p {
  font-size: 1.5em;
  font-weight: 300;
  margin: 0 0 0.66667em 0;
  color: #4c4c4c;
}

.rte sup {
  font-size: 65%;
  line-height: 1;
}

.rte .chapeau {
  font-size: 1.5em;
  color: #808f95;
}

.rte .chapeau a {
  color: #808f95;
}

.rte .encadre {
  position: relative;
  padding: 1.875em 1.5625em 1.875em 6.25em;
  background: #1075a1;
  margin: 1.5625em 0 1.5625em;
  position: relative;
}

.rte .encadre:before {
  font-family: "icons-project";
  font-size: 3.9rem;
  color: #fff;
  position: absolute;
  top: 2.5rem;
  right: inherit;
  bottom: inherit;
  left: 3.5rem;
  content: "";
  line-height: 1;
}

.rte .encadre:before {
  transform: rotate(280deg);
}

.rte .encadre:after {
  width: 4.9rem;
  height: 7.4rem;
  content: '';
  position: absolute;
  top: 2.5rem;
  left: 1rem;
  background: url("../Images/temp/encadre-lines.png") no-repeat center;
}

.rte p.encadre,
.rte .encadre p,
.rte .encadre a {
  color: #fff;
  font-size: 1.6rem;
}

.rte blockquote {
  font-size: 1.8em;
  position: relative;
  padding: 0.66667em 0 0.27778em 1.11111em;
  font-weight: 300;
  font-style: italic;
  margin: 1.38889em 0 1.38889em 4.16667em;
  color: #1075a1;
}

.rte blockquote:before {
  font-family: "icons-default";
  font-size: 4em;
  color: #00acec;
  position: absolute;
  top: 0;
  right: inherit;
  bottom: inherit;
  left: -0.83333em;
  content: "";
  line-height: 1;
}

.rte blockquote:before {
  font-style: normal;
}

.rte blockquote p {
  font-size: 1em;
  font-weight: 300;
  color: #1075a1;
}

.rte ul, .rte .show-hide__content ul {
  margin: 2rem 0 1.5rem 2.5rem;
  list-style: none;
  font-size: 1.5rem;
  color: #4c4c4c;
  padding: 0;
}

.rte ul p, .rte .show-hide__content ul p {
  margin: 0;
  font-size: 1.5rem;
}

.rte ul > li, .rte .show-hide__content ul > li {
  position: relative;
  padding-left: 2rem;
  margin-bottom: 18px;
}

.rte ul > li:before, .rte .show-hide__content ul > li:before {
  font-family: "icons-default";
  font-size: 1.4rem;
  color: #00acec;
  position: absolute;
  top: 0.2rem;
  right: inherit;
  bottom: inherit;
  left: 0;
  content: "";
  line-height: 1;
}

.rte ul > li:last-child, .rte .show-hide__content ul > li:last-child {
  margin-bottom: 0;
}

.rte ul > li > ul:first-child, .rte ul > li > a + ul, .rte .show-hide__content ul > li > ul:first-child, .rte .show-hide__content ul > li > a + ul {
  margin: 0 0 1rem .8rem;
  list-style: none;
  padding: .1rem 0 .1rem 0;
}

.rte ul > li > ul:first-child > li, .rte ul > li > a + ul > li, .rte .show-hide__content ul > li > ul:first-child > li, .rte .show-hide__content ul > li > a + ul > li {
  position: relative;
  padding-left: 1.5rem;
  margin-bottom: 0;
}

.rte ul > li > ul:first-child > li:before, .rte ul > li > a + ul > li:before, .rte .show-hide__content ul > li > ul:first-child > li:before, .rte .show-hide__content ul > li > a + ul > li:before {
  font-family: "icons-default";
  font-size: 1rem;
  color: #000;
  position: absolute;
  top: 0.5rem;
  right: inherit;
  bottom: inherit;
  left: 0;
  content: "";
  line-height: 1;
}

.rte ul > li > ol:first-child, .rte ul > li > a + ol, .rte .show-hide__content ul > li > ol:first-child, .rte .show-hide__content ul > li > a + ol {
  margin: 0 0 1rem 2.5rem;
  padding: .1rem 0 .3rem 0;
}

.rte ul > li > ol:first-child > li, .rte ul > li > a + ol > li, .rte .show-hide__content ul > li > ol:first-child > li, .rte .show-hide__content ul > li > a + ol > li {
  padding: 0;
}

.rte ul > li > ol:first-child > li:before, .rte ul > li > a + ol > li:before, .rte .show-hide__content ul > li > ol:first-child > li:before, .rte .show-hide__content ul > li > a + ol > li:before {
  font-family: none;
  content: none;
}

.rte ul > li > ol:first-child > li:first-child, .rte ul > li > a + ol > li:first-child, .rte .show-hide__content ul > li > ol:first-child > li:first-child, .rte .show-hide__content ul > li > a + ol > li:first-child {
  margin-bottom: 0;
}

.rte ol, .rte .show-hide__content ol {
  margin: 2rem 0 1.5rem 2.5rem;
  padding: 0 0 0 1.8rem;
  list-style: decimal;
  font-size: 1.5rem;
  color: #4c4c4c;
}

.rte ol p, .rte .show-hide__content ol p {
  margin-bottom: 0;
  font-size: 1.5rem;
}

.rte ol > li, .rte .show-hide__content ol > li {
  padding: 0;
}

.rte ol > li:first-child, .rte .show-hide__content ol > li:first-child {
  margin-bottom: 18px;
}

.rte ol > li > ol > li, .rte .show-hide__content ol > li > ol > li {
  margin-bottom: 0 !important;
}

.rte ol > li > ol:first-child, .rte ol > li > a + ol, .rte .show-hide__content ol > li > ol:first-child, .rte .show-hide__content ol > li > a + ol {
  margin: 0 0 1rem 2.5rem;
  padding: .1rem 0 .1rem 0;
}

.rte ol > li > ol:first-child > li, .rte ol > li > a + ol > li, .rte .show-hide__content ol > li > ol:first-child > li, .rte .show-hide__content ol > li > a + ol > li {
  padding: 0;
}

.rte ol > li > ul > li, .rte .show-hide__content ol > li > ul > li {
  margin-bottom: 0;
}

.rte ol > li > ul:first-child, .rte ol > li > a + ul, .rte .show-hide__content ol > li > ul:first-child, .rte .show-hide__content ol > li > a + ul {
  margin: 0 0 1rem 1rem;
  list-style: none;
  padding: .1rem 0 .3rem 0;
}

.rte ol > li > ul:first-child > li, .rte ol > li > a + ul > li, .rte .show-hide__content ol > li > ul:first-child > li, .rte .show-hide__content ol > li > a + ul > li {
  padding-left: 1.5rem;
  position: relative;
}

.rte ol > li > ul:first-child > li:before, .rte ol > li > a + ul > li:before, .rte .show-hide__content ol > li > ul:first-child > li:before, .rte .show-hide__content ol > li > a + ul > li:before {
  font-family: "icons-default";
  font-size: 1rem;
  color: #000;
  position: absolute;
  top: 0.25rem;
  right: inherit;
  bottom: inherit;
  left: 0;
  content: "";
  line-height: 1;
}

.rte .reset__list {
  margin: auto;
  padding: 0;
  list-style: none;
  font-size: 1em;
}

.rte .reset__list > li {
  padding: 0;
}

.rte .reset__list > li:before {
  content: "";
  margin: auto;
}

.rte .reset__list > li > ol, .rte .reset__list > li > ul {
  padding: 0;
}

.rte .reset__list > li > ol > li, .rte .reset__list > li > ul > li {
  padding: 0;
}

.rte .reset__list > li > ol > li:before, .rte .reset__list > li > ul > li:before {
  content: "";
  margin: auto;
}

.rte .table-wrapper {
  margin: 4em 0;
}

.rte .table-wrapper-inner {
  max-width: 99.9em;
  overflow-y: auto;
  width: 100%;
}

.rte .table-wrapper-inner::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.rte .table-wrapper-inner::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.rte table {
  width: 100%;
  font-size: 1em;
  border-collapse: separate;
  border-spacing: 0.5rem 0;
}

.rte table caption {
  font-family: "Raleway", sans-serif;
  text-align: left;
  font-weight: 900;
  font-style: italic;
  font-size: 1.7em;
  color: #1075a1;
  margin-bottom: 0.88235em;
}

.rte table th, .rte table td {
  padding: 12px 14px;
}

.rte table th ul, .rte table td ul {
  font-size: 1em;
}

.rte table th p, .rte table td p {
  font-size: 1.15385em;
}

.rte table th {
  font-size: 1.4em;
  text-transform: uppercase;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  color: #242c59;
  text-align: left;
}

.rte table thead th {
  font-size: 1.4em;
  background: #fff;
  font-weight: 400;
  color: #242c59;
  border-bottom: 5px solid #1075a1;
}

.rte table thead th:first-child {
  border-color: #00acec;
}

.rte table tbody th {
  text-align: right;
  border-bottom: 1px solid rgba(128, 143, 149, 0.3);
}

.rte table td {
  font-size: 1.4em;
  color: #727272;
  background: #fff;
  vertical-align: top;
  font-weight: 300;
  border-bottom: 1px solid rgba(128, 143, 149, 0.3);
}

.rte .column--2, .rte .column--3 {
  margin: 4em 0 2em;
  column-gap: 2em;
}

.rte .column--2 {
  columns: 2;
}

.rte .column--3 {
  columns: 3;
}

.ce-align-left {
  text-align: left;
}

.ce-align-center {
  text-align: center;
}

.ce-align-right {
  text-align: right;
}

.ce-table td, .ce-table th {
  vertical-align: top;
}

.ce-textpic, .ce-nowrap .ce-bodytext, .ce-gallery, .ce-row, .ce-uploads li, .ce-uploads div {
  overflow: inherit;
}

.ce-textpic:after, .ce-nowrap .ce-bodytext:after, .ce-gallery:after, .ce-row:after, .ce-uploads li:after, .ce-uploads div:after {
  clear: both;
  display: block;
  content: "";
  visibility: hidden;
  height: 0;
  speak: none;
}

.ce-left .ce-gallery, .ce-column {
  float: left;
}

.ce-center .ce-outer {
  position: relative;
  float: right;
  right: 50%;
}

.ce-center .ce-inner {
  position: relative;
  float: right;
  right: -50%;
}

.ce-right .ce-gallery {
  float: right;
}

.ce-gallery figure {
  display: block !important;
  margin: 0;
}

.ce-gallery figcaption {
  padding-top: 10px;
  display: block !important;
}

.ce-gallery img {
  display: block;
}

.ce-gallery iframe {
  border-width: 0;
}

.ce-border img, .ce-border iframe {
  border: 2px solid black;
  padding: 0px;
}

.ce-intext.ce-right .ce-gallery, .ce-intext.ce-left .ce-gallery, .ce-above .ce-gallery {
  margin-top: 1rem;
  margin-bottom: 20px;
}

.ce-intext.ce-right .ce-gallery {
  margin-left: 30px;
}

.ce-intext.ce-left .ce-gallery {
  margin-right: 30px;
}

.ce-below .ce-gallery {
  margin-top: 10px;
}

.ce-column {
  margin-right: 10px;
}

.ce-column:last-child {
  margin-right: 0;
}

.ce-row {
  margin-bottom: 10px;
}

.ce-row:last-child {
  margin-bottom: 0;
}

.ce-above .ce-bodytext {
  clear: both;
}

.ce-intext.ce-left ol, .ce-intext.ce-left ul {
  padding-left: 40px;
  overflow: auto;
}

.ce-uploads {
  margin: 0;
  padding: 0;
}

.ce-uploads li {
  list-style: none outside none;
  margin: 1em 0;
}

.ce-uploads img {
  float: left;
  padding-right: 1em;
  vertical-align: top;
}

.ce-uploads span {
  display: block;
}

.rte .ce-textpic img {
  max-width: 100%;
  height: auto;
}

.ce-gallery {
  position: relative;
}

.ce-gallery .ce-column {
  width: 100%;
}

.ce-gallery .cookies-denied {
  background-color: #c2d9e1;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  font-family: "Raleway", sans-serif;
  font-size: 1.4rem;
  padding: 0;
  position: relative;
  width: 100%;
}

.ce-gallery .cookies-denied::after {
  content: '';
  display: block;
  padding-top: 84.61538%;
  width: 100%;
}

.ce-gallery .cookies-denied::before {
  content: none;
}

.ce-gallery .cookies-denied::after {
  bottom: auto;
  left: auto;
  position: relative;
  right: auto;
  top: auto;
}

.ce-gallery .cookies-denied span {
  left: 50%;
  position: absolute;
  top: 50%;
  background-color: #278417;
  border-radius: 4px;
  color: #fff;
  display: inline-block;
  padding: 5px;
  transform: translate(-50%, -50%);
  white-space: normal;
  width: 90%;
}

.menu-skip {
  width: 100%;
}

.menu-skip a {
  position: absolute;
  top: -200px;
  left: 0;
  transition: all 0.3s ease;
  color: #000;
  font-size: 1.3em;
}

.menu-skip a:focus {
  top: 0;
  display: block;
  padding: 1.15385em;
  background: #c2d9e1;
  width: 100%;
  z-index: 9999;
}

.menu-skip .desktop {
  display: block;
}

.menu-skip .smartphone {
  display: none;
}

.menu-stratis__opener {
  position: fixed;
  z-index: 9999;
  bottom: 2rem;
  left: 2rem;
  padding: .5rem 1rem;
  background: #fff;
}

.menu-stratis {
  display: none;
  background: #88c54c;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  width: 100%;
  font-size: 1.2em;
}

.menu-stratis.menu-stratis--show {
  display: block;
}

.menu-stratis abbr {
  border: 0;
}

.menu-stratis li {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.menu-stratis li a {
  display: block;
  padding: 0.66667em 0.5em;
  text-decoration: none;
}

.menu-stratis .menu-stratis__level-1 > li {
  width: auto;
  margin-bottom: 0;
}

.menu-stratis .menu-stratis__level-1 > li > a {
  color: #000;
  transition: all ease .3s;
  display: inline-block;
  vertical-align: middle;
}

.menu-stratis .menu-stratis__level-1 > li > a:hover,
.menu-stratis .menu-stratis__level-1 > li > a:focus,
.menu-stratis .menu-stratis__level-1 > li.active > a {
  background: #000;
  color: #FFF;
}

.menu-stratis .menu-stratis__level-1 > li button {
  font-size: 1.3rem;
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  padding: .5rem 1rem;
  margin-right: .8rem;
  margin-left: -.4rem;
  position: relative;
  text-align: left;
  text-indent: -9999px;
  transition: background ease .3s;
}

.menu-stratis .menu-stratis__level-1 > li button:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 5.5px 0 5.5px;
  border-color: #fff transparent transparent transparent;
}

.menu-stratis .menu-stratis__level-1 > li button:hover, .menu-stratis .menu-stratis__level-1 > li button:focus {
  background: #fff;
}

.menu-stratis .menu-stratis__level-1 > li button:hover:before, .menu-stratis .menu-stratis__level-1 > li button:focus:before {
  border-color: #88c54c transparent transparent transparent;
}

.menu-stratis .menu-stratis__level-1 > li button:focus {
  outline: 1px dotted #fff;
}

.menu-stratis .menu-stratis__level-2 {
  box-shadow: 0.2rem 0.2rem 0.4rem #c2d9e1;
  position: absolute;
  z-index: 101;
  top: -9999px;
  left: 0;
  font-size: 1em;
  width: 16.66667em;
  background: #fff;
  border-bottom: 0;
  text-align: left;
  padding: 10px;
}

.menu-stratis .menu-stratis__level-2.menu-stratis__level-2--show {
  top: 2.58333em;
  display: block;
}

.menu-stratis .menu-stratis__level-2 > li {
  display: block;
  float: none;
  border-bottom: 1px solid #c2d9e1;
}

.menu-stratis .menu-stratis__level-2 > li:last-child {
  border-bottom: 0;
}

.menu-stratis .menu-stratis__level-2 > li > a {
  font-weight: 400;
  color: #000;
  transition: all ease .3s;
}

.menu-stratis .menu-stratis__level-2 > li ul,
.menu-stratis .menu-stratis__level-2 > li.act ul {
  margin: 0 0 10px 10px;
  background: #ebf2f5;
}

.menu-stratis .menu-stratis__level-2 > li ul li,
.menu-stratis .menu-stratis__level-2 > li.act ul li {
  border-bottom: 1px solid #fff;
  display: block;
}

.menu-stratis .menu-stratis__level-2 > li ul li a,
.menu-stratis .menu-stratis__level-2 > li.act ul li a {
  color: #000;
}

.menu-stratis .menu-stratis__level-2 > li a:hover,
.menu-stratis .menu-stratis__level-2 > li a:focus,
.menu-stratis .menu-stratis__level-2 > li.act > a,
.menu-stratis .menu-stratis__level-2 > li.active > a {
  background: #88c54c;
  color: #000;
}

.menu-main {
  background: #88c54c;
  color: #fff;
}

.menu-main ul {
  text-align: center;
}

.menu-main a {
  color: #fff;
  font-size: 1.3em;
  text-transform: uppercase;
  display: block;
  padding: 1rem 1rem;
}

.menu-main li {
  display: inline-block;
  vertical-align: middle;
  margin: 0 .5rem;
}

.menu-cross {
  text-transform: uppercase;
  overflow: hidden;
}

.menu-cross li {
  display: block;
  vertical-align: top;
  margin-bottom: 0.7rem;
  padding: .2em 1.5em;
  position: relative;
}

.menu-cross li:before {
  font-family: "icons-default";
  font-size: 1.1rem;
  color: #fff;
  position: absolute;
  top: 0.3rem;
  right: inherit;
  bottom: inherit;
  left: 0;
  content: "";
  line-height: 1;
}

.menu-cross li a {
  color: #fff;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 300;
}

.menu-cross li a:hover span, .menu-cross li a:focus span {
  text-decoration: underline;
}

.menu-cross li.active a {
  text-decoration: none;
  font-weight: 700;
}

.menu-cross li:first-child {
  margin-top: 1rem;
}

.menu-main-1__sublevel {
  display: none;
}

.menu-main-1__sublevel[aria-hidden="false"] {
  display: block;
}

.menu-main-1__item--no-action-in-desktop .menu-main-1__sublevel {
  display: block;
}

/* Styles */
.menu-main-1__wrapper {
  margin: 0 auto;
}

.menu-main-1__item > .menu-main-1__header a {
  display: block;
}

.menu-main-1__closing-button {
  text-align: left;
  text-indent: -9999px;
  white-space: nowrap;
  position: absolute;
  top: 3.6rem;
  right: 3rem;
  width: 30px;
  height: 30px;
  background: transparent;
}

.menu-main-1__closing-button:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  content: "\e002";
  color: #fff;
  font-family: 'icons-default';
  font-size: 1.4rem;
  font-weight: 700;
  text-indent: 0;
  line-height: 0;
  display: block;
  margin: 0;
}

.menu-main-1__listitems-0 > li > .menu-main-1__header {
  display: none;
}

.menu-main-1__listitems-0 > li > .menu-main-1__sublevel {
  display: block;
}

.menu-main-1__listitems-0 > li > .menu-main-1__sublevel > .menu-main-1__closing-button {
  display: none;
}

.menu-main-1__listitems-1 {
  display: flex;
  justify-content: space-around;
}

.menu-main-1__listitems-1 > li {
  display: inline-block;
  vertical-align: middle;
}

.menu-main-1__listitems-1 > li > .menu-main-1__header > a {
  font-size: 1.6rem;
  color: #000;
  text-transform: uppercase;
  padding: 3rem 3rem 3rem 4.5rem;
  text-decoration: none;
  font-weight: 300;
  transition: all 0.3s ease;
}

.menu-main-1__listitems-1 > li > .menu-main-1__header > a b {
  display: block;
  position: relative;
  font-weight: 900;
}

.menu-main-1__listitems-1 > li > .menu-main-1__header > a b:before {
  height: 3rem;
  width: 0.2rem;
  content: '';
  position: absolute;
  left: -1rem;
  top: 0;
  background-color: #00acec;
  transform: rotate(-35deg);
  transition: all 0.3s ease;
}

.menu-main-1__listitems-1 > li > .menu-main-1__header > a:hover, .menu-main-1__listitems-1 > li > .menu-main-1__header > a:focus {
  background-color: #cfe3ec;
}

.menu-main-1__listitems-1 > li > .menu-main-1__sublevel {
  display: block;
  position: absolute;
  z-index: 10;
  top: 100%;
  left: 0;
  right: 0;
  width: 100%;
  max-height: 0;
  visibility: hidden;
  overflow: hidden;
  transition: visibility ease .2s, max-height ease .2s;
}

.menu-main-1__listitems-1 > li > .menu-main-1__sublevel .menu-main-1__listitems-1__wrapper {
  padding: 4.5rem 0 4rem;
  transform: translateY(-110%);
  transition: transform ease-in .2s;
  height: 100%;
  background: #000 url("../Images/temp/menu-bg.png") no-repeat 97% 167%;
}

.menu-main-1__listitems-1 > li > .menu-main-1__sublevel[aria-hidden="false"] {
  visibility: visible;
  max-height: 1000px;
}

.menu-main-1__listitems-1 > li > .menu-main-1__sublevel[aria-hidden="false"] .menu-main-1__listitems-1__wrapper {
  transform: translateY(0);
  transition: transform ease-out .4s .2s;
}

.menu-main-1__listitems-1 > li > .menu-main-1__sublevel a, .menu-main-1__listitems-1 > li > .menu-main-1__sublevel button, .menu-main-1__listitems-1 > li > .menu-main-1__sublevel p {
  color: #fff;
}

.menu-main-1__listitems-1 > li > .menu-main-1__sublevel a:focus, .menu-main-1__listitems-1 > li > .menu-main-1__sublevel button:focus, .menu-main-1__listitems-1 > li > .menu-main-1__sublevel p:focus {
  outline-color: #fff;
}

.menu-main-1__listitems-1 > li.menu-main-1__item--active > .menu-main-1__header > a {
  color: #fff;
  background-color: #000;
}

.menu-main-1__listitems-1 > li.menu-main-1__item--active > .menu-main-1__header > a b:before {
  background-color: #fff;
}

.menu-main-1__listitems-1 .wrapper-main--home, .menu-main-1__listitems-1 .footer .footer__wrapper, .footer .menu-main-1__listitems-1 .footer__wrapper {
  position: relative;
}

.menu-main-1__listitems-2 {
  width: 30.5rem;
  padding: 7rem 0 5.5rem;
  position: relative;
  height: auto;
  transition: height ease .3s;
}

.menu-main-1__listitems-2 > li {
  display: block;
  text-align: right;
}

.menu-main-1__listitems-2 > li .menu-main-1__header {
  display: inline-block;
  position: relative;
  transition: all 0.2s ease;
}

.menu-main-1__listitems-2 > li .menu-main-1__header .menu-main-1__action {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  text-align: left;
  text-indent: -9999px;
  white-space: nowrap;
  width: 3rem;
  background: transparent;
  overflow: hidden;
  flex-basis: 3rem;
}

.menu-main-1__listitems-2 > li .menu-main-1__header .menu-main-1__action:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  content: "\e017";
  font-size: 1.3em;
  font-family: 'icons-default';
  font-weight: 700;
  text-indent: 0;
  line-height: 0;
  display: block;
  margin: 0;
}

.menu-main-1__listitems-2 > li .menu-main-1__header .menu-main-1__action[aria-expanded="true"]:before {
  content: "\e016";
}

.menu-main-1__listitems-2 > li .menu-main-1__header:hover, .menu-main-1__listitems-2 > li .menu-main-1__header:focus {
  background-color: #4c4c4c;
}

.menu-main-1__listitems-2 > li > .menu-main-1__header a {
  display: block;
  padding: 0.8rem 3rem 0.8rem 1.5rem;
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 300;
}

.menu-main-1__listitems-2 > li > .menu-main-1__header a:hover, .menu-main-1__listitems-2 > li > .menu-main-1__header a:focus {
  text-decoration: none;
}

.menu-main-1__listitems-2 > li > .menu-main-1__sublevel {
  height: 100%;
  display: block;
  position: absolute;
  left: 100%;
  top: 0;
  padding: 7rem 0 5.5rem 2.5rem;
  width: 30.5rem;
  visibility: hidden;
  transform: scaleX(0);
  transform-origin: 0 0;
  transition: transform ease .2s, visibility ease 0s .2s;
  background: #4c4c4c;
}

.menu-main-1__listitems-2 > li > .menu-main-1__sublevel[aria-hidden="false"] {
  visibility: visible;
  transform: scaleX(1);
  transition: transform ease .4s, -webkit-transform ease .4s;
}

.menu-main-1__listitems-2 > li:last-child {
  margin-bottom: 0;
}

.menu-main-1__listitems-2 > li.menu-main-1__item--active .menu-main-1__header {
  background-color: #4c4c4c;
}

.menu-main-1__listitems-2 .menu-main-1__closing-button {
  display: none;
}

.menu-main-1__listitems-3 > li {
  text-align: left;
}

.menu-main-1__listitems-3 > li > .menu-main-1__header {
  display: block;
  position: relative;
  transition: all 0.2s ease;
}

.menu-main-1__listitems-3 > li > .menu-main-1__header:before {
  font-family: "icons-default";
  font-size: 1rem;
  color: #fff;
  position: absolute;
  top: 1.2rem;
  right: inherit;
  bottom: inherit;
  left: 1rem;
  content: "";
  line-height: 1;
}

.menu-main-1__listitems-3 > li > .menu-main-1__header a {
  padding: 0.8rem 3rem 0.8rem 2.5rem;
  font-size: 1.6rem;
  font-weight: 700;
}

.menu-main-1__listitems-3 > li > .menu-main-1__header:hover, .menu-main-1__listitems-3 > li > .menu-main-1__header:focus {
  background-color: #000;
}

.menu-main-1__listitems-3 > li > .menu-main-1__header:hover a, .menu-main-1__listitems-3 > li > .menu-main-1__header:focus a {
  text-decoration: none;
}

.menu-main-1__listitems-3 > li > .menu-main-1__sublevel {
  height: 100%;
  padding: 7rem 0 5.5rem 2.5rem;
  margin-left: 0.2rem;
  display: block;
  position: absolute;
  left: 100%;
  top: 0;
  width: 30.5rem;
  visibility: hidden;
  transform: scaleX(0);
  transform-origin: 0 0;
  transition: transform ease .2s, visibility ease 0s .2s;
  background: transparent;
  border: 1px solid #4c4c4c;
}

.menu-main-1__listitems-3 > li > .menu-main-1__sublevel[aria-hidden="false"] {
  transition: transform ease .4s, visibility ease 0s 0s;
  visibility: visible;
  transform: scaleX(1);
}

.menu-main-1__listitems-3 > li.menu-main-1__item--active .menu-main-1__header {
  background-color: #000;
}

.menu-main-1__listitems-4 > li > .menu-main-1__header {
  display: block;
  position: relative;
}

.menu-main-1__listitems-4 > li > .menu-main-1__header:before {
  font-family: "icons-default";
  font-size: 1rem;
  color: #fff;
  position: absolute;
  top: 1.2rem;
  right: inherit;
  bottom: inherit;
  left: 1rem;
  content: "";
  line-height: 1;
}

.menu-main-1__listitems-4 > li > .menu-main-1__header a {
  padding: 0.8rem 3rem 0.8rem 2.5rem;
  font-size: 1.6rem;
  font-weight: 300;
  transition: all 0.2s ease;
}

.menu-main-1__listitems-4 > li > .menu-main-1__header:hover a, .menu-main-1__listitems-4 > li > .menu-main-1__header:focus a {
  font-weight: 700;
}

.menu-info {
  width: 30.5rem;
  padding-left: 2.5rem;
  position: absolute;
  right: 0;
  top: 0;
  color: #fff;
}

.menu-info__header {
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: 300;
}

.menu-info__header b {
  font-weight: 900;
}

.menu-info__img {
  display: block;
  margin-bottom: 1.5rem;
}

.menu-info__img img {
  width: 100%;
  height: auto;
}

.menu-info__category {
  display: inline-block;
  margin-bottom: 1rem;
  padding: 0.85rem 1.2rem;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: 300;
  border: 1px solid #fff;
}

.menu-info__title {
  font-size: 1.8rem;
  font-weight: 900;
}

.menu-info__text {
  margin-bottom: 1.1rem;
  font-size: 1.5rem;
  font-weight: 300;
}

.menu-info__link {
  position: relative;
  padding-left: 1.5rem;
  font-size: 1.6rem;
  font-weight: 300;
  position: relative;
}

.menu-info__link:before {
  font-family: "icons-default";
  font-size: 1rem;
  color: #fff;
  position: absolute;
  top: 0.5rem;
  right: inherit;
  bottom: inherit;
  left: 0;
  content: "";
  line-height: 1;
}

.list {
  position: relative;
}

.list__infos {
  margin: 3em 0;
  font-size: 1.6em;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  border-bottom: 1px solid #808f95;
  padding-bottom: 1.5625em;
  padding-right: 2.5em;
}

.list__infos:first-of-type {
  margin: 0 0 3em 0;
}

.list__rss {
  display: inline-block;
  margin-left: 0.3rem;
}

.list__title-1 {
  background: #88c54c;
  color: #fff;
  font-size: 2em;
  font-weight: 700;
  padding: 0.25em 0.5em;
  margin-bottom: 1.5em;
}

.list-content-1, .rte .list-content-1 {
  font-size: 1em;
}

.list-content-1 > li, .rte .list-content-1 > li {
  text-transform: uppercase;
  font-size: 1.4em;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  margin: 0.35714em 0;
}

.list-content-1 > li:before, .rte .list-content-1 > li:before {
  font-family: "icons-default";
  content: "";
  margin: 0 0.5rem 0 0;
  font-size: 1.2rem;
  color: #88c54c;
  vertical-align: initial;
  display: inline-block;
  line-height: normal;
  text-transform: inherit;
}

.list-content-1 > li > a, .rte .list-content-1 > li > a {
  color: #000;
}

.list-content-1 > li > a:hover, .list-content-1 > li > a:focus, .rte .list-content-1 > li > a:hover, .rte .list-content-1 > li > a:focus {
  color: #88c54c;
}

.list-content-2, .rte .list-content-2 {
  font-size: 1em;
}

.list-content-2 > li, .rte .list-content-2 > li {
  font-size: 1.6em;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  margin: 0.625em 0;
  padding-left: 1.0625em;
  position: relative;
}

.list-content-2 > li:before, .rte .list-content-2 > li:before {
  font-family: "icons-default";
  font-size: 1.2rem;
  color: #00acec;
  position: absolute;
  top: 0.4rem;
  right: inherit;
  bottom: inherit;
  left: 0;
  content: "";
  line-height: 1;
}

.list-content-2 > li > a, .rte .list-content-2 > li > a {
  color: #000;
}

.list-content-2 > li > a:hover, .list-content-2 > li > a:focus, .rte .list-content-2 > li > a:hover, .rte .list-content-2 > li > a:focus {
  color: #88c54c;
}

.list-type-1 {
  margin-bottom: 5em;
}

.list-type-1 .list-type-1__date-2 {
  margin-top: -0.71429em;
}

.list-type-1__item {
  padding: 2em 0;
}

.list-type-1__item:first-child {
  padding-top: 0;
}

.list-type-1__item:last-child {
  padding-bottom: 0;
}

.list-type-1__picture {
  display: block;
  float: left;
}

.list-type-1__picture img {
  margin-right: 2em;
  max-width: 220px;
  height: auto;
}

.list-type-1__wrapper {
  overflow: hidden;
}

.list-type-1__title {
  font-family: "Raleway", sans-serif;
  color: #000;
  font-size: 2em;
  font-weight: 700;
  margin: .2em 0 .8em;
}

.list-type-1__title a {
  color: #000;
}

.list-type-1--event .list-type-1__picture, .list-type-1--event .list-type-1__wrapper-date, .list-type-1--event .list-type-1__wrapper {
  display: table-cell;
  vertical-align: top;
}

.list-type-1--event .list-type-1__picture {
  float: none;
}

.list-type-1--event .list-type-1__wrapper-date {
  padding-right: 2em;
  width: 13em;
  border-right: 1px solid #808f95;
}

.list-type-1--event .list-type-1__wrapper {
  padding-left: 2em;
}

.list-type-1--glossary .list-type-1__item {
  border-bottom: 1px solid #ebf2f5;
}

.list-type-1--glossary .list-type-1__item:last-child {
  padding-bottom: 2em;
}

.list-type-1--poll .status {
  display: inline-block;
  margin: 0;
}

.list-type-2 {
  margin-bottom: 5em;
}

.list-type-2.list-type-2--3col .list-type-2__listitems .list-paginated__wrapper > article {
  flex: 0 1 29.3333%;
}

.list-type-2.list-type-2--2col .list-type-2__listitems .list-paginated__wrapper > article {
  flex: 0 1 46%;
}

.list-type-2__listitems > .list-paginated__wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 -2%;
}

.list-type-2__listitems > .list-paginated__wrapper > article {
  flex: 0 1 29.3333%;
  margin: 0 2% 3em;
}

.list-type-2__picture {
  display: block;
  margin-bottom: 1em;
}

.list-type-2__picture img {
  max-width: 100%;
  height: auto;
}

.list-type-2__picture.list-type-2__picture--video {
  position: relative;
}

.list-type-2__picture.list-type-2__picture--video:before {
  font-family: "icons-default";
  font-size: 5rem;
  color: #00acec;
  position: absolute;
  top: 50%;
  right: inherit;
  bottom: inherit;
  left: 50%;
  content: "";
  line-height: 1;
}

.list-type-2__picture.list-type-2__picture--video:before {
  transform: translateY(-50%) translateX(-50%);
  z-index: 2;
}

.list-type-2__picture.list-type-2__picture--video:after {
  content: "";
  width: 30px;
  height: 30px;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 1;
}

.list-type-2__picture--2 {
  display: table-cell;
  vertical-align: bottom;
  height: 19em;
  margin-bottom: 0;
  padding-bottom: 1em;
}

.list-type-2__title {
  font-family: "Raleway", sans-serif;
  color: #000;
  font-size: 1.6em;
  font-weight: 700;
  margin: .2em 0 .8em;
}

.list-type-2__title a {
  color: #000;
}

.list-type-2__date {
  margin-top: 0;
}

.albums {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 -1%;
}

.albums-item {
  width: 31.3333%;
  margin: 0 1% 3em;
}

.albums-item a {
  position: relative;
  display: block;
}

.albums-item a::before {
  content: '\e075';
  color: #fff;
  font-family: 'icons-default';
  text-align: center;
  font-size: 2em;
  background-color: #00acec;
  position: absolute;
  display: block;
  width: 47px;
  height: 47px;
  line-height: 47px;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: 5;
}

.list-type-3 {
  margin-bottom: 5em;
}

.list-type-3__listitems .list-paginated__wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 -2%;
}

.list-type-3__listitems .list-paginated__wrapper > article {
  flex: 0 1 46%;
  max-width: 46%;
  margin: 0 2% 3em;
}

.list-type-3__item {
  background: #ebf2f5;
  padding: 40px 28px;
  position: relative;
}

.list-type-3__item:before, .list-type-3__item:after {
  content: "";
  speak: none;
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  top: 0;
  right: 0;
}

.list-type-3__item:after {
  border-width: 38px 0 0 38px;
  border-color: transparent transparent transparent #c2d9e1;
}

.list-type-3__item:before {
  border-width: 0 38px 38px 0;
  border-color: transparent #fff transparent transparent;
}

.list-type-3__picture {
  display: block;
  float: left;
}

.list-type-3__picture img {
  margin-right: 1em;
  max-width: 120px;
  height: auto;
}

.list-type-3__wrapper {
  overflow: hidden;
}

.list-type-3__title {
  font-family: "Raleway", sans-serif;
  color: #000;
  font-size: 2em;
  font-weight: 700;
  margin: .2em 0 .8em;
}

.list-type-3__title a {
  color: #000;
}

.list-type-4 .ddm .ddm__sub-level {
  position: static;
  top: inherit;
  right: inherit;
  z-index: inherit;
  display: block;
  height: auto;
  overflow: hidden;
  max-height: 0;
  transition: max-height ease .2s;
}

.list-type-4 .ddm.ddm--active .ddm__title button:before {
  content: "\e001";
  background: #00acec;
  color: #fff;
}

.list-type-4 .ddm.ddm--active .ddm__sub-level {
  max-height: 100em;
  transition: max-height ease 1s;
}

.list-type-4 .ddm__title button {
  text-align: left;
  font-size: 1em;
  display: block;
  width: 100%;
  padding-left: 6em;
  min-height: 4.2em;
  white-space: normal;
  position: relative;
}

.list-type-4 .ddm__title button:before {
  font-family: "icons-default";
  font-size: 1.4rem;
  color: #00acec;
  position: absolute;
  top: 0;
  right: inherit;
  bottom: inherit;
  left: 0;
  content: "";
  line-height: 1;
}

.list-type-4 .ddm__title button:before {
  z-index: 2;
  background: #ebf2f5;
  padding: 1em;
  transition: all ease .5s;
}

.list-type-4 .list-type-4__title {
  font-family: "Raleway", sans-serif;
  font-size: 1.6em;
  font-weight: 700;
  color: #000;
}

.list-type-4 .list-type-4__item {
  padding: 3em 0;
  border-bottom: 1px solid #a7c6d3;
}

.list-type-4 .list-type-4__item:first-child {
  padding-top: 0;
}

.list-type-4 .list-type-4__content {
  padding: 1em 0 0 6em;
}

.list-type-4 .list-type-4__content p {
  font-size: 1.3rem;
}

#pieChartTarget {
  float: left;
  width: 300px;
  height: 300px;
  margin-right: 30px;
}

.rte .list-pie-chart {
  margin-left: 0;
  overflow: hidden;
}

.rte .list-pie-chart > li {
  margin: 0.8em 0;
  padding-left: 1.66667em;
}

#pieChartTarget .pie0 {
  fill: #d94766;
}

.rte .list-pie-chart > .pie0:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  display: block;
  background: #d94766;
}

#pieChartTarget .pie1 {
  fill: #de7f93;
}

.rte .list-pie-chart > .pie1:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  display: block;
  background: #de7f93;
}

#pieChartTarget .pie2 {
  fill: #ef654e;
}

.rte .list-pie-chart > .pie2:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  display: block;
  background: #ef654e;
}

#pieChartTarget .pie3 {
  fill: #ed8c52;
}

.rte .list-pie-chart > .pie3:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  display: block;
  background: #ed8c52;
}

#pieChartTarget .pie4 {
  fill: #febd4c;
}

.rte .list-pie-chart > .pie4:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  display: block;
  background: #febd4c;
}

#pieChartTarget .pie5 {
  fill: #f3e65e;
}

.rte .list-pie-chart > .pie5:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  display: block;
  background: #f3e65e;
}

#pieChartTarget .pie6 {
  fill: #b4d75e;
}

.rte .list-pie-chart > .pie6:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  display: block;
  background: #b4d75e;
}

#pieChartTarget .pie7 {
  fill: #57ba81;
}

.rte .list-pie-chart > .pie7:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  display: block;
  background: #57ba81;
}

#pieChartTarget .pie8 {
  fill: #97dab3;
}

.rte .list-pie-chart > .pie8:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  display: block;
  background: #97dab3;
}

#pieChartTarget .pie9 {
  fill: #50c3bc;
}

.rte .list-pie-chart > .pie9:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  display: block;
  background: #50c3bc;
}

#pieChartTarget .pie10 {
  fill: #a0afb7;
}

.rte .list-pie-chart > .pie10:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  display: block;
  background: #a0afb7;
}

#pieChartTarget .pie11 {
  fill: #306a96;
}

.rte .list-pie-chart > .pie11:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  display: block;
  background: #306a96;
}

#pieChartTarget .pie12 {
  fill: #2d4c59;
}

.rte .list-pie-chart > .pie12:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  display: block;
  background: #2d4c59;
}

#pieChartTarget .pie13 {
  fill: #3da4c6;
}

.rte .list-pie-chart > .pie13:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  display: block;
  background: #3da4c6;
}

#pieChartTarget .pie14 {
  fill: #cfaee3;
}

.rte .list-pie-chart > .pie14:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  display: block;
  background: #cfaee3;
}

#pieChartTarget .pie15 {
  fill: #9873ae;
}

.rte .list-pie-chart > .pie15:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  display: block;
  background: #9873ae;
}

.breadcrumb {
  font-family: "Raleway", sans-serif;
  display: inline-block;
  vertical-align: middle;
  width: 70%;
}

.breadcrumb p {
  font-size: 1.4em;
  font-weight: 700;
  color: #1075a1;
}

.breadcrumb p .breadcrumb__here {
  color: #4c4c4c;
}

.breadcrumb p a {
  font-weight: 300;
  color: #4c4c4c;
}

.breadcrumb p a:after {
  font-family: "icons-default";
  content: "";
  margin: 0 0 0 0.5rem;
  font-size: 1.2rem;
  color: #4c4c4c;
  vertical-align: -0.2rem;
  display: inline-block;
  line-height: normal;
  text-decoration: none;
}

.breadcrumb p a:last-child:after {
  color: #00acec;
}

.tools {
  width: 100%;
  margin-top: 0;
  display: block;
}

.tools h2 {
  font-family: "Raleway", sans-serif;
  color: #4c4c4c;
  font-weight: 900;
  font-size: 1.8rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.tools svg {
  width: 1.6rem;
  height: 1.6rem;
  fill: #fff;
  transition: all 0.25s ease;
}

.tools .button-1:before {
  content: "\e02d";
  color: #fff;
  font-size: 1.4rem;
}

.tools .button-1:hover::before, .tools .button-1:focus::before {
  color: #fff;
}

.tools__listitems {
  margin: 0 -.5em;
  display: flex;
  flex-wrap: wrap;
}

.tools__item {
  padding: 0 .05em;
}

.tools__item > a,
.tools__item button {
  font-size: 0 !important;
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00acec;
  transition: all 0.25s ease;
}

.tools__item > a .icon,
.tools__item button .icon {
  color: #fff;
  display: block;
  font-size: 1.6rem;
}

.tools__item > a .icon::before,
.tools__item button .icon::before {
  display: block;
  content: '';
  font-family: 'icons-default';
  color: inherit;
  font-size: inherit;
  font-weight: normal;
  line-height: 1;
}

.tools__item > a .icon-mail:before,
.tools__item button .icon-mail:before {
  content: '\e028';
}

.tools__item > a:hover, .tools__item > a:focus,
.tools__item button:hover,
.tools__item button:focus {
  background-color: #1075a1;
}

.tools__item > a:hover svg, .tools__item > a:focus svg,
.tools__item button:hover svg,
.tools__item button:focus svg {
  fill: #fff;
}

.tools__item > a:hover .icon, .tools__item > a:focus .icon,
.tools__item button:hover .icon,
.tools__item button:focus .icon {
  color: #fff;
}

.ddm.share-page .ddm__sub-level {
  display: block;
  visibility: hidden;
  top: 100%;
  left: inherit;
  right: 0;
  overflow: hidden;
  z-index: 2;
}

.ddm.share-page .ddm__wrapper {
  transform: translateY(-110%);
  transition: all ease .1s;
}

.ddm.share-page.ddm--active .ddm__sub-level {
  visibility: visible;
}

.ddm.share-page.ddm--active .ddm__wrapper {
  transform: translateY(0);
  transition: all ease .4s;
}

.share-page button {
  color: transparent;
}

.share-page button:hover, .share-page button:focus {
  color: transparent;
}

.share-page .ddm__sub-level {
  text-align: left;
  width: 13em;
}

.share-page .ddm__wrapper {
  background: #1075a1;
  color: #FFF;
  padding: 1em 1.5em;
}

.share-page .ddm__wrapper a {
  color: #fff;
}

.share-page__item {
  font-size: 1.3em;
  margin: .5em 0;
}

.share-page__item:before {
  font-family: "icons-default";
  content: "";
  margin: 0 5px 0 0;
  font-size: 1.4rem;
  color: #fff;
  vertical-align: -0.2rem;
  display: inline-block;
  line-height: normal;
  text-transform: inherit;
}

.share-page__item.email:before {
  content: "\e028";
}

.share-page__item.facebook:before {
  content: "\e098";
}

.share-page__item.twitter:before {
  content: "\e09a";
}

.share-page__item.google:before {
  content: "\e0a4";
}

.share-page__item.instagram:before {
  content: "\e0a1";
}

.share-page__item.linkedin:before {
  content: "\e0a2";
}

.share-page__item.viadeo:before {
  content: "\e0a3";
}

.share-page__item.youtube:before {
  content: "\e09d";
}

.cookies-top-bloc {
  position: fixed;
  z-index: 1000;
  bottom: 20px;
  left: 0;
  right: 0;
  padding: 15px;
  background: #505b5f;
  width: 900px;
  margin: 0 auto;
}

.cookies-top-bloc .teaser-1 {
  margin-top: 0;
  margin-bottom: 2em;
  color: #FFF;
}

.cookies-top-bloc__more:before {
  content: "\e003";
}

.cookies-top-bloc__button-wrapper {
  text-align: right;
}

.family-portal a {
  display: block;
  padding: 1rem 2rem;
  border: 1px solid #cccccc;
  font-size: 1.6rem;
  color: #000;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 300;
  transition: all 0.3s ease;
}

.family-portal a svg {
  display: inline-block;
  width: 3.3rem;
  height: 3.3rem;
  fill: none;
  stroke: #00acec;
  stroke-width: 10;
}

.family-portal a p {
  padding-left: 0.3rem;
  display: inline-block;
}

.family-portal a b {
  display: block;
  font-weight: 900;
}

.family-portal a:hover, .family-portal a:focus {
  background-color: #cfe3ec;
}

.listen__audio {
  margin: 2em 0 2em;
}

.listen__audio audio {
  width: 100%;
  margin-bottom: 1.6em;
}

.listen__audio-title {
  font-size: 1.2em;
  color: #384249;
  font-weight: 300;
  font-style: italic;
}

.listen__download {
  position: relative;
  padding-left: 2.2em;
  font-size: 1.3em;
  font-weight: 300;
  color: #3c3c3b !important;
  position: relative;
}

.listen__download:before {
  font-family: "icons-project";
  font-size: 1.6rem;
  color: #000;
  position: absolute;
  top: 50%;
  right: inherit;
  bottom: inherit;
  left: 0;
  content: "";
  line-height: 1;
}

.listen__download:before {
  padding: 0.3rem 0.3rem;
  border: 1px solid #3c3c3b;
  transform: translateY(-50%);
}

.logo {
  position: relative;
  margin-top: -1.2rem;
  bottom: -2.7rem;
  font-size: 1.3em;
  z-index: 15;
}

.logo a {
  display: block;
}

.stratis {
  background: transparent;
  width: 100%;
  padding-bottom: 3em;
  font-size: 1.2em;
  color: #fff;
  text-align: right;
  text-transform: uppercase;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
}

.stratis a {
  display: inline-block;
  padding: 6px 10px;
  color: #fff;
  background: #c70000;
  text-decoration: none;
  font-weight: 900;
}

.stratis a:hover, .stratis a:focus {
  color: #A40015;
  background: #ffffff;
}

.page-number {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  color: #FFF;
  padding: 5px;
}

.powermail_tab_navigation.tx_powermail_pi1_formconfirmation_back {
  overflow: hidden;
  display: block;
  float: none;
}

.powermail_tab_navigation.tx_powermail_pi1_formconfirmation_back .button-1 {
  float: left;
}

.powermail_tab_navigation.tx_powermail_pi1_formconfirmation_back .button-1.powermail_tab_navigation_next {
  float: right;
}

.powermail_tab_navigation.tx_powermail_pi1_formconfirmation_back .button-1.powermail_tab_navigation_next:before {
  float: right;
  content: "\e027";
  margin: 0 0 0 5px;
}

.powermail_tabmenu {
  display: none;
}

.powermail_tabmenu li span {
  cursor: pointer;
  color: #000;
}

.powermail_tabmenu li span:hover {
  color: #00727e;
}

.powermail_tabmenu li .act {
  color: #00727e;
  text-decoration: underline;
}

.classical-gallery {
  max-width: 70rem;
}

.classical-gallery__title {
  font-family: "Raleway", sans-serif;
  text-align: right;
  font-weight: 400;
  font-size: 1.6em;
  color: #808f95;
  margin: 1.875em 0 0.625em 0;
}

.classical-gallery {
  margin: 4em 0 7em;
}

.classical-gallery__nb-items {
  display: none;
}

.slider-galerie {
  width: 100%;
  height: 100%;
  position: relative;
  margin-bottom: 10px;
}

.slider-galerie .slider-galerie__item {
  width: 100%;
  height: 100%;
  z-index: 1 !important;
  border: none;
  background: #ebf2f5;
}

.slider-galerie .infos-img {
  position: absolute;
  z-index: 1;
  top: 100%;
  right: 0;
  width: calc(100% - 500px);
  display: table;
  padding: 10px;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  font-style: italic;
  color: #808f95;
  font-size: 1.3em;
  text-align: right;
  white-space: normal;
}

.slider-galerie .infos-img__nbItems {
  display: table-cell;
  vertical-align: bottom;
  white-space: nowrap;
  width: 70px;
}

.slider-galerie .infos-img__wrap {
  display: table-cell;
  vertical-align: top;
}

.slider-galerie .infos-img__legend {
  display: block;
}

.slider-galerie a {
  display: block;
  width: 100%;
  height: auto;
  text-align: center;
  position: relative;
  white-space: nowrap;
  padding: 68.08510% 0 0 0;
}

.slider-galerie img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 50%;
  right: 0;
  transform: translateX(-50%) translateY(-50%);
}

.carousel-galerie {
  width: 500px;
  display: inline-block;
  text-align: right;
  position: relative;
  z-index: 1;
}

.carousel-galerie .item {
  border: none;
}

.carousel-galerie__thumb {
  width: 340px;
  height: 74px;
  z-index: 2;
  margin-left: 80px;
  text-align: left;
}

.carousel-galerie__thumb.no-carousel {
  position: relative;
}

.carousel-galerie__thumb.no-carousel .carousel-galerie__item {
  display: inline-block;
  margin: 0 3px;
}

.carousel-galerie__item {
  z-index: 1 !important;
  text-align: center;
}

.carousel-galerie__item.cycle-slide-active button:before, .carousel-galerie__item.cycle-pager-active button:before, .carousel-galerie__item button:hover:before, .carousel-galerie__item button:focus:before {
  background: #00acec;
  /* The Fallback */
  background: rgba(0, 172, 236, 0.6);
}

.carousel-galerie__item button {
  position: relative;
}

.carousel-galerie__item button:before {
  transition: all ease .2s;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0);
}

.carousel-galerie__item button img {
  width: 74px;
  height: 74px;
}

.carousel-galerie__pager {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  height: 74px;
  width: 500px;
  z-index: 1;
}

.carousel-galerie__prev, .carousel-galerie__next {
  position: absolute;
  top: 0;
  padding: 0;
}

.carousel-galerie__prev button, .carousel-galerie__next button {
  width: 74px;
  height: 74px;
  background: #00acec;
  text-indent: -9999px;
  transition: all ease .2s;
  overflow: hidden;
  position: relative;
}

.carousel-galerie__prev button:before, .carousel-galerie__next button:before {
  font-family: "icons-default";
  font-size: 1.8rem;
  color: #fff;
  position: absolute;
  top: 50%;
  right: inherit;
  bottom: inherit;
  left: 50%;
  content: "";
  line-height: 1;
}

.carousel-galerie__prev button:before, .carousel-galerie__next button:before {
  text-indent: 0;
  transform: translateY(-50%) translateX(-50%);
}

.carousel-galerie__prev button:hover, .carousel-galerie__prev button:focus, .carousel-galerie__next button:hover, .carousel-galerie__next button:focus {
  background: #1075a1;
}

.carousel-galerie__prev:before, .carousel-galerie__next:before {
  content: "";
  speak: none;
}

.carousel-galerie__prev {
  left: 0;
}

.carousel-galerie__next {
  right: 0;
}

.carousel-galerie__next button:before {
  content: "\e01b";
}

.parallax {
  height: 470px;
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
}

.videos {
  margin: 4em 0;
}

.video__title {
  font-family: "Raleway", sans-serif;
  font-weight: 900;
  font-style: italic;
  font-size: 1.7em;
  color: #1075a1;
  margin-bottom: 0.88235em;
}

.videos__item {
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.videos__item video {
  width: 100%;
}

.videos__item .videos__ratio + iframe,
.videos__item .videos__ratio + .iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.videos__ratio {
  display: block;
  width: 100%;
  height: auto;
}

.ce-textpic .videos,
.ce-textpic .videos__item {
  margin: 0;
}

.popin #content .setPos:only-of-type .videos {
  margin: 0;
}

.popin #content .setPos:only-of-type .videos .videos__item:only-of-type {
  margin: 0;
}

.ddm.ddm__content + .ddm.ddm__content {
  margin-top: -3.9em;
}

.ddm.ddm__content {
  margin: 4em 0 4.1em 0;
}

.ddm.ddm__content .ddm__title {
  font-size: 1em;
  margin-bottom: 0;
}

.ddm.ddm__content .ddm__sub-level {
  position: static;
  top: inherit;
  right: inherit;
  z-index: inherit;
  display: block;
  height: auto;
  overflow: hidden;
  max-height: 0;
}

.ddm.ddm__content.ddm--active .ddm__sub-level {
  padding: 1em 0;
  max-height: 100%;
}

.ddm__content .ddm__button {
  display: block;
  width: 100%;
  text-align: left;
  font-size: 2rem;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  color: #1075a1;
  background: transparent;
  padding: 0.5em 2em 0.4em 0.5em;
  position: relative;
  transition: all ease .3s;
  white-space: normal;
  border-bottom: 5px solid #1075a1;
  position: relative;
}

.ddm__content .ddm__button:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 40px;
  background: transparent;
  z-index: 1;
}

.ddm__content .ddm__button:before {
  font-family: "icons-default";
  font-size: 1.1rem;
  color: #00acec;
  position: absolute;
  top: 50%;
  right: 1.5rem;
  bottom: inherit;
  left: inherit;
  content: "";
  line-height: 1;
}

.ddm__content .ddm__button:before {
  z-index: 2;
  transform: translateY(-50%);
}

.ddm__content.ddm--active .ddm__button {
  background: #000;
  color: #fff;
  font-weight: 700;
  border-bottom-color: #000;
}

.ddm__content.ddm--active .ddm__button:before {
  content: "\e025";
}

.go-so-far {
  background: #ebf2f5;
  padding: 7em 0;
}

.go-so-far__title {
  margin-top: 0;
}

.go-so-far__wrapper {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  margin: 0 -2%;
}

.go-so-far__wrapper > * {
  flex: 1;
  margin: 0 2%;
}

.go-so-far__wrapper > *:last-child {
  flex: 2;
}

.fancybox-lock .fancybox-overlay {
  overflow: hidden;
}

.popin-home .fancybox-title {
  display: none;
}

.popin-home--fullscreen.fancybox-wrap {
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
}

.popin-home--fullscreen .fancybox-btn-close {
  position: absolute;
  top: 10px;
  right: 40px;
}

.popin-home--fullscreen .fancybox-title {
  display: none;
}

.popin-home--fullscreen .fancybox-inner {
  width: 100% !important;
}

.popin-home--fullscreen .fancybox-inner, .popin-home--fullscreen .fancybox-outer, .popin-home--fullscreen .fancybox-skin {
  height: 100% !important;
}

.print-banner {
  display: none;
}

.newsletter__fields {
  max-width: 30rem;
  position: relative;
}

.newsletter__title {
  margin-bottom: .5em;
  font-size: 1.8rem;
  font-weight: 900;
  color: #fff;
  text-transform: uppercase;
}

.newsletter label {
  font-size: 1.3rem;
  font-weight: 300;
  color: #fff;
}

.newsletter form {
  margin: 0;
}

.newsletter input {
  width: 100%;
  min-height: 4.2rem;
  background: #191919;
  outline: none;
  color: #fff;
  border: none;
  font-size: 1.3em;
}

.newsletter input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-size: 1.3rem;
  font-weight: 300;
  font-style: italic;
}

.newsletter input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-size: 1.3rem;
  font-weight: 300;
  font-style: italic;
}

.newsletter input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-size: 1.3rem;
  font-weight: 300;
  font-style: italic;
}

.newsletter input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-size: 1.3rem;
  font-weight: 300;
  font-style: italic;
}

.newsletter fieldset {
  margin-bottom: .5em;
}

.newsletter__link {
  padding: 2rem 2rem 2rem 3.8rem;
  font-size: 1.2rem;
  font-weight: 900;
  background-color: #fff;
  color: #000;
}

.newsletter__link:before {
  color: #000;
  content: "\e057";
  left: 1.9rem;
}

.newsletter__link:hover, .newsletter__link:focus {
  background-color: #4c4c4c;
  color: #fff;
}

.newsletter__link:hover:before, .newsletter__link:focus:before {
  color: #fff;
}

.newsletter__fields-wrapper, .newsletter__button-wrapper {
  vertical-align: bottom;
}

.newsletter__fields-wrapper button, .newsletter__button-wrapper button {
  width: 4.2rem;
  height: 4.2rem;
  padding: 0;
  background: #191919;
  border: none;
  outline: none;
}

.newsletter__fields-wrapper button svg, .newsletter__button-wrapper button svg {
  fill: #fff;
}

.newsletter__fields-wrapper input {
  padding: 1.25rem 5rem 1.25rem 1rem;
}

.newsletter__button-wrapper {
  position: absolute;
  bottom: 0;
  right: 0;
}

.newsletter__links:after, .newsletter__links:before {
  content: '';
  display: table;
  clear: both;
}

.newsletter__links li {
  float: left;
  margin-top: 1rem;
  margin-right: 2rem;
  position: relative;
}

.newsletter__links li:before {
  content: none;
}

.newsletter__links li a {
  font-size: 1.2rem;
  color: #fff;
  font-weight: 300;
  padding-left: 15px;
}

.newsletter__links li a:before {
  font-family: 'icons-default';
  color: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  font-size: 1.2rem;
}

.newsletter__links li a:hover, .newsletter__links li a:focus {
  color: #fff;
}

.newsletter__links li a:hover:before, .newsletter__links li a:hover:after, .newsletter__links li a:focus:before, .newsletter__links li a:focus:after {
  color: #fff;
}

.newsletter__links li.newsletter__links-archives a:before {
  content: "\e03e";
}

.newsletter__links li.newsletter__links-unsubscribe a:before {
  content: '\e058';
}

.search-btn-outside {
  height: 6.2rem;
  width: 6.2rem;
  margin-left: 1rem;
  background-color: #fff;
  border: 1px solid #cccccc;
  transition: all 0.3s ease;
}

.search-btn-outside > svg {
  width: 2.2rem;
  height: 2.2rem;
  fill: none;
  stroke: #00acec;
  stroke-width: 10;
}

.search-btn-outside::after {
  content: '';
  position: absolute;
  left: 0;
  height: 0;
  width: 1px;
  background-color: #66a12c;
  top: 0;
  transition: all 0.2s ease 400ms;
}

.search-btn-outside:hover, .search-btn-outside:focus {
  background-color: #cfe3ec;
}

.search-btn-outside:hover > svg, .search-btn-outside:focus > svg {
  fill: none;
}

.search-box {
  padding: 0;
  position: absolute;
  top: -100%;
  right: 0;
  width: calc(100% - 26rem);
  height: 100%;
  display: flex;
  align-items: center;
  background-color: #1075a1;
}

.search-box form {
  margin: 0;
  width: 100%;
  display: block;
  position: relative;
  height: 100%;
}

.search-box label {
  margin-right: 0.27778em;
  white-space: nowrap;
  font-size: 1.8em;
  color: #fff;
}

.search-box .search-box__fields-wrapper, .search-box .search-box__button-wrapper {
  display: inline-block;
  vertical-align: bottom;
}

.search-box .search-box__field-wrapper.search-box__field-wrapper--in-line {
  display: table;
  width: 100%;
}

.search-box .search-box__field-wrapper.search-box__field-wrapper--in-line label {
  display: table-cell;
  vertical-align: middle;
  text-align: right;
  padding-right: 1em;
  width: 1%;
}

.search-box .search-box__field-wrapper.search-box__field-wrapper--in-line input, .search-box .search-box__field-wrapper.search-box__field-wrapper--in-line select {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
}

.search-box .ddm {
  position: static;
}

.search-box .ddm > .ddm__sub-level {
  top: 0;
  right: 3em;
  left: 0;
  width: 100%;
  display: table;
  height: 100%;
}

.search-box .ddm > .ddm__sub-level > * {
  display: table-cell;
  vertical-align: middle;
}

.search-box .ddm__title {
  display: none !important;
}

.search-box__wrapper {
  display: block;
  width: 100%;
  position: relative;
  height: 100%;
}

.search-box__button-wrapper {
  height: 100%;
  width: 1.6rem;
}

.search-box__fields-wrapper {
  padding: 0 1.8rem;
}

.search-box__button {
  background-color: #1075a1;
  width: 11.5rem;
  height: 100%;
  border-left: 1px solid #fff;
}

.search-box__button > svg {
  width: 2.7rem;
  height: 2.7rem;
  fill: none;
  stroke: #fff;
  stroke-width: 20;
}

.search-box__button:hover, .search-box__button:focus {
  background-color: #68c9f3;
}

.search-box__button:hover > svg, .search-box__button:focus > svg {
  fill: none;
}

.pertinence {
  float: right;
  font-size: 1.2em;
}

.pertinence span {
  font-size: 0.83333em;
}

.pertinence__metter {
  display: inline-block;
  vertical-align: middle;
  width: 8.33333em;
  height: 0.83333em;
  padding: 0.16667em;
  background: #c2d9e1;
  margin: 0 0.83333em;
}

.pertinence__metter > span {
  display: block;
  height: 0.66667em;
  background: #00acec;
}

.type-content {
  font-size: 1.4rem;
  color: #1075a1;
  font-weight: 700;
}

.go-to-top {
  display: block;
  position: relative;
  right: 1rem;
  bottom: 0;
  z-index: 7000;
}

.go-to-top p {
  margin-top: -5.1em;
  position: absolute;
  right: 0;
  text-align: right;
}

.go-to-top a {
  width: 5.1rem;
  height: 5.1rem;
  background-color: #000;
}

.go-to-top a:before {
  content: "\e025";
}

.subpages-menu {
  margin: 2em 0 3em;
}

.subpages-menu a {
  text-decoration: none;
}

.subpages-menu a:hover, .subpages-menu a:focus {
  text-decoration: underline;
}

.subpages-menu__listitems {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 -2%;
}

.subpages-menu__listitems > * {
  flex: 0 1 29.3333%;
  margin: 0 2% 3em;
}

.subpages-menu__picture {
  display: block;
  margin-bottom: 2em;
}

.subpages-menu__picture img {
  max-width: 100%;
  height: auto;
}

.subpages-menu__title {
  margin: 0 0 0.4em 0;
  font-size: 2em;
  color: #000;
  border: none;
}

.subpages-menu__title a {
  color: #000;
}

.subpages-menu__listitems2 {
  margin: 1.5em 0;
}

.subpages-menu__item2 {
  font-size: 1.4em;
  text-transform: uppercase;
  color: #000;
  margin: 0.35714em 0;
  position: relative;
  padding-left: 1.07143em;
}

.subpages-menu__item2:before {
  font-family: "icons-default";
  font-size: 1.4rem;
  color: #00acec;
  position: absolute;
  top: 0.2rem;
  right: inherit;
  bottom: inherit;
  left: 0;
  content: "";
  line-height: 1;
}

.subpages-menu__item2 a {
  color: #000;
}

.subpages-menu__item2 a:hover, .subpages-menu__item2 a:focus {
  color: #88c54c;
}

.sitemap {
  margin: 2em 0 3em;
}

.sitemap__listitems {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 -1%;
}

.sitemap__listitems > * {
  flex: 0 1 31.3333%;
  margin: 0 1% 4em;
}

.sitemap__header {
  border-bottom: 1px solid #808f95;
  margin-bottom: 1em;
}

.sitemap__title {
  font-size: 2em;
  font-weight: 700;
  margin-bottom: 0.75em;
  color: #000;
}

.sitemap__title a {
  color: #000;
}

.sitemap__teaser {
  font-size: 1.4em;
  font-weight: 300;
  margin: 0.35714em 0 0.71429em;
}

.sitemap__listitems2 {
  text-transform: uppercase;
}

.sitemap__listitems2 a {
  color: #000;
}

.sitemap__item2 {
  position: relative;
  padding-left: 1.5em;
  margin: .5em 0;
}

.sitemap__item2:before {
  font-family: "icons-default";
  font-size: 1.4rem;
  color: #00acec;
  position: absolute;
  top: 0.2rem;
  right: inherit;
  bottom: inherit;
  left: 0;
  content: "";
  line-height: 1;
}

.sitemap__item2 > a {
  font-size: 1.4em;
}

.sitemap__listitems3 {
  margin-left: 1.5em;
  margin-bottom: 1.3em;
}

.sitemap__item3 {
  position: relative;
  padding-left: 1.5em;
  margin: .5em 0;
}

.sitemap__item3:before {
  font-family: "icons-default";
  font-size: 1.4rem;
  color: #000;
  position: absolute;
  top: 0.2rem;
  right: inherit;
  bottom: inherit;
  left: 0;
  content: "";
  line-height: 1;
}

.sitemap__item3 > a {
  font-size: 1.3em;
}

.sitemap__listitems4 {
  margin-left: 1.8em;
  margin-bottom: 1.3em;
}

.sitemap__item4 {
  position: relative;
  padding-left: 1em;
  margin: .5em 0;
}

.sitemap__item4:before {
  font-family: "icons-default";
  font-size: 0.65rem;
  color: #000;
  position: absolute;
  top: 0.4rem;
  right: inherit;
  bottom: inherit;
  left: 0;
  content: "";
  line-height: 1;
}

.sitemap__item4 > a {
  font-size: 1.2em;
}

.list-document__listitems {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 -1%;
}

.list-document__listitems > * {
  flex: 1 1 31.3333%;
  margin: 0 1% 3em;
}

.list-document__listitems .button-1:not(.button-1--big) {
  padding-left: 2.5rem;
  padding-right: 0.7rem;
}

.list-document__listitems .button-1:not(.button-1--big):before {
  left: 0.7rem;
}

.list-document__item.list-document__item--postulate {
  text-align: right;
  align-self: flex-end;
}

.list-document__title-file {
  padding-left: 3.8rem;
  font-size: 1.4em;
  font-weight: 700;
  margin-bottom: 1.07143em;
  overflow: hidden;
  word-break: break-all;
  line-height: 1.4;
  position: relative;
}

.list-document__title-file a {
  position: relative;
  padding-left: 2.85714em;
}

.list-document__title-file .list-document__file-size {
  display: block;
  font-weight: 300;
}

.list-document__title-file:before {
  font-family: "icons-project";
  font-size: 3.5rem;
  color: #00acec;
  position: absolute;
  top: 0.2rem;
  right: inherit;
  bottom: inherit;
  left: 0;
  content: "";
  line-height: 1;
}

.list-document__title-file:before {
  font-weight: 400;
}

.list-document__title-file.list-document__title-file--pdf:before {
  content: "\e003";
}

.list-document__title-file.list-document__title-file--doc:before {
  content: "\e001";
}

.list-document__title-file.list-document__title-file--jpg:before {
  content: "\e002";
}

.list-document__title-file.list-document__title-file--xls:before {
  content: "\e006";
}

.list-document__title-file.list-document__title-file--ppt:before {
  content: "\e004";
}

.list-document__title-file.list-document__title-file--zip:before {
  content: "\e007";
}

.list-document__download:before,
.list-document__read:before {
  font-size: 1.4rem;
  font-family: 'icons-project';
  font-weight: 700;
}

.list-document__download:before {
  content: "\E00B";
}

.list-document__read {
  margin-left: 0.2rem;
}

.list-document__read:before {
  content: "\E00C";
}

.list-document-1__listitems {
  margin: 1.25em 0 0.41667em 0;
}

.list-document-1__listitems + .list-document-1__listitems {
  padding: 0.41667em 0 0;
}

.list-document-1__item {
  display: inline-block;
  font-size: 1.2em;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0.41667em 0;
  color: #000;
  position: relative;
  z-index: 99;
  /*@include default-icons-before('\e063', 0 .5rem 0 0, 1.2rem, $color-2--2, -.2rem);
    a {
        color: $color-black;
        &:hover, &:focus {
            color: $color-1--2;
            text-decoration: underline;
        }
    }
    &.list-document-1__item--read {
        &:before {
            content: "\e06e";
        }
    }*/
}

.list-document-1__item .button-1 {
  padding-left: 4rem;
  padding-right: 2rem;
}

.list-document-1__item.list-document-1__item--download {
  margin-right: 0.3rem;
}

.list-document-1__item.list-document-1__item--download .button-1:before {
  font-family: 'icons-project';
  content: "\E00B";
  font-weight: 700;
}

.list-document-1__item.list-document-1__item--read .button-1:before {
  font-family: 'icons-project';
  content: "\E00C";
  font-weight: 700;
}

.list-document-1__file-size {
  font-size: 1.3rem;
  font-weight: 300;
  white-space: nowrap;
  font-style: italic;
  color: #727272;
}

.open-data__file-size {
  color: #fff;
}

.list-infos {
  font-family: "Raleway", sans-serif;
}

.list-infos__wrapper {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.list-infos__wrapper > * {
  flex: 1 1 0;
}

.list-infos__wrapper .list-infos__listitems:first-child {
  flex: 1.9 1 0;
}

.list-infos__wrapper .list-infos__listitems + .list-infos__listitems {
  border-left: 1px solid #808f95;
  padding-left: 5%;
  margin-left: 5%;
}

.list-infos__item {
  margin: .5em 0;
  font-size: 1.5em;
  position: relative;
  padding-left: 1.6em;
}

.list-infos__item:before {
  font-family: "icons-default";
  font-size: 2rem;
  color: #00acec;
  position: absolute;
  top: -0.2rem;
  right: inherit;
  bottom: inherit;
  left: 0;
  content: "";
  line-height: 1;
}

.list-infos__item a {
  color: #000;
  text-decoration: underline;
}

.list-infos__item a:hover, .list-infos__item a:focus {
  text-decoration: none;
}

.list-infos__item--group p {
  width: 40%;
  display: inline-block;
}

.list-infos__item.list-infos__phone, .list-infos__item.list-infos__fax {
  white-space: nowrap;
}

.list-infos__item.list-infos__address:before {
  content: "\e053";
}

.list-infos__item.list-infos__website:before {
  content: "\e089";
}

.list-infos__item.list-infos__phone:before {
  content: "\e02b";
}

.list-infos__item.list-infos__fax:before {
  content: "\e09f";
}

.list-infos__item.list-infos__email:before {
  content: "\e028";
}

.list-infos__item.list-infos__infos:before {
  content: "\e083";
}

.list-infos__item.list-infos__hours:before {
  content: "\e05b";
}

.list-infos__item.list-infos__fb:before {
  content: "\e097";
}

.list-infos__item.list-infos__tw:before {
  content: "\e09a";
}

.list-infos__item.list-infos__in:before {
  content: "\e0a2";
}

.list-infos__item.list-infos__instagram:before {
  content: "\e0a1";
}

.list-infos__item.list-infos__nom {
  padding-left: 0;
}

.list-infos__item.list-infos__nom:before {
  content: none;
}

.list-infos__item.list-infos__noicon {
  padding-left: 0;
}

.list-infos__item.list-infos__noicon:before {
  content: none;
}

.list-infos__item.list-infos__comment:before {
  content: '\e02e';
}

.comments {
  margin: 2em 0 3em;
}

.comments__title {
  font-size: 2em;
  color: #000;
  font-weight: 700;
  margin: 0 0 1em;
}

.comments__title:before {
  font-family: "icons-default";
  content: "";
  margin: 0 0.5rem 0 0;
  font-size: 1.6rem;
  color: #00acec;
  vertical-align: -0.2rem;
  display: inline-block;
  line-height: normal;
  text-transform: inherit;
}

.comment {
  margin: 2em 0;
}

.comments__listitems {
  margin: 0 0 3em;
}

.comments__listitems--children .comment {
  padding-left: 100px;
}

.comments__listitems--children .comment__wrapper-2 {
  background: #fff;
}

.comments__listitems--children .comment__wrapper-2:after {
  border-color: transparent #fff transparent transparent;
}

.comment__avatar {
  float: left;
  margin-right: 1em;
}

.comment__avatar svg {
  width: 4.3em;
  height: 4.3em;
}

.comment__wrapper {
  overflow: hidden;
  padding-left: 10px;
}

.comment__wrapper-2 {
  background: #ebf2f5;
  border: 1px solid #c2d9e1;
  padding: 2em;
  position: relative;
}

.comment__wrapper-2:before, .comment__wrapper-2:after {
  content: '';
  display: block;
  position: absolute;
  right: 100%;
  width: 0;
  height: 0;
  border-style: solid;
}

.comment__wrapper-2:before {
  top: 19px;
  border-color: transparent #c2d9e1 transparent transparent;
  border-width: 12px;
}

.comment__wrapper-2:after {
  top: 21px;
  border-color: transparent #ebf2f5 transparent transparent;
  border-width: 10px;
}

.comment__header {
  margin-bottom: 1.5em;
}

.comment__author {
  font-size: 1.2em;
  font-weight: 700;
  font-style: normal;
  color: #808f95;
}

.comment__author a {
  color: #808f95;
}

.comment__author + .date:before {
  content: " - ";
  display: inline;
}

.comments .comment__body > * {
  margin-bottom: 0;
}

.comment__reply-link {
  position: absolute;
  top: 20px;
  right: 20px;
}

.comment__reply-link p {
  text-align: right;
  text-transform: uppercase;
  font-family: "Raleway", sans-serif;
}

.comment__reply-link p:before {
  font-family: "icons-default";
  content: "";
  margin: 0 0 0 0;
  font-size: 1rem;
  color: #808f95;
  vertical-align: -0.1rem;
  display: inline-block;
  line-height: normal;
  text-transform: inherit;
}

.comment__reply-link p a {
  color: #808f95;
  font-size: 1.2em;
}

.comment__reply {
  margin: 5em 0 2em;
}

.comment__reply__title {
  font-size: 2em;
  color: #000;
  font-weight: 700;
  margin-bottom: 1em;
}

.comment__reply__avatar {
  margin-bottom: 2.5em;
}

.comment__reply__avatar > div {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
}

.comment__reply__avatar legend {
  margin: .5em 0 .5em;
  font-size: 1.4em;
}

.comment__reply__avatar label {
  display: inline-block;
  position: relative;
  line-height: 1em;
  margin: 0.07143em 0;
  padding: 1.14286em;
  background: #ebf2f5;
}

.comment__reply__avatar label svg {
  width: 3.07143em;
  height: 3.07143em;
}

.comment__reply__avatar input[type=radio] {
  position: absolute;
  left: -9999px;
}

.comment__reply__avatar input[type=radio]:checked + label {
  background: #88c54c;
}

.comment__reply__avatar input[type=radio]:checked + label svg {
  fill: #fff;
}

.comment__reply__avatar input[type=radio]:focus + label {
  outline: 1px dashed #00acec;
  outline-offset: 2px;
}

.footer .site-infos {
  flex-grow: 0.9;
  flex-shrink: 0.9;
}

.site-infos__img-wrap {
  width: 12.5em;
  margin-top: -3rem;
  padding: 2rem .8rem;
  float: left;
  margin-right: 2em;
  background-color: #fff;
}

.site-infos__img-wrap img {
  width: 100%;
  height: auto;
}

.site-infos__wrapper {
  overflow: hidden;
}

.site-infos p, .site-infos ul {
  color: #fff;
  font-size: 1.3em;
  margin: .6rem 0 .9rem;
}

.site-infos p:first-child, .site-infos ul:first-child {
  margin-top: 0;
}

.site-infos p {
  font-size: 1.3rem;
  font-weight: 300;
  text-transform: uppercase;
  font-style: normal;
}

.site-infos p.site-infos__schedule, .site-infos p.site-infos__phone {
  text-transform: none;
}

.site-infos p.site-infos__contact .button-1 {
  padding: 2rem 2rem 2rem 3.8rem;
  font-size: 1.2rem;
  font-weight: 900;
  background-color: #fff;
  color: #000;
}

.site-infos p.site-infos__contact .button-1:before {
  content: '\e02a';
  color: #000;
  left: 1.9rem;
}

.site-infos p.site-infos__contact .button-1:hover, .site-infos p.site-infos__contact .button-1:focus {
  background-color: #4c4c4c;
  color: #fff;
}

.site-infos p.site-infos__contact .button-1:hover:before, .site-infos p.site-infos__contact .button-1:focus:before {
  color: #fff;
}

.site-infos ul > li {
  margin: 0.2em 0;
}

.site-infos a {
  color: #fff;
}

.column-bloc.bloc-news img {
  max-width: 100%;
  height: auto;
  margin-bottom: 2em;
}

.column-bloc.bloc-news .bloc-news__title {
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 1.6em;
  margin: 0 0 0.9375em;
  color: #000;
}

.column-bloc.bloc-news .bloc-news__title a {
  color: #000;
}

.column-bloc.bloc-news .bloc-news__title a:hover, .column-bloc.bloc-news .bloc-news__title a:focus {
  color: #88c54c;
}

.column-bloc.bloc-event img {
  max-width: 100%;
  height: auto;
  margin-bottom: 2em;
}

.column-bloc.bloc-event .bloc-event__wrapper-date {
  margin-bottom: 2em;
}

.column-bloc.bloc-event .bloc-event__title {
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 1.6em;
  margin: 0 0 0.9375em;
  color: #000;
}

.column-bloc.bloc-event .bloc-event__title a {
  color: #000;
}

.column-bloc.bloc-event .bloc-event__title a:hover, .column-bloc.bloc-event .bloc-event__title a:focus {
  color: #88c54c;
}

.column-bloc.bloc-event .date-1 {
  text-align: left;
}

.column-bloc.bloc-event .date-1 span:first-child + time {
  margin-left: 0;
}

.column-bloc.bloc-event .hour-place {
  text-align: left;
}

.bloc-publications {
  margin: 3.5rem 0 4rem;
}

.bloc-publications__wrapper {
  margin-left: 2rem;
  padding-top: 0.7rem;
  overflow: hidden;
}

.bloc-publications__title {
  font-size: 3rem;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  margin: 0.3rem 0 0;
  color: #000;
}

.bloc-publications__item {
  display: flex;
}

.bloc-publications__item > a {
  min-width: 16.4rem;
  display: block;
}

.bloc-publications__item > a img {
  display: block;
  width: 100%;
  height: auto;
  border: 1px solid #e7e7e7;
}

.bloc-publications h3 {
  line-height: 1;
}

.bloc-publications p {
  font-size: 1.4rem;
}

.bloc-publications .category {
  color: #1075a1;
  font-size: 1.6rem;
}

.bloc-publications .list-document-1__file-size + p {
  margin-top: 2rem;
}

.bloc-publications .button-1 {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
  font-size: 1.2rem;
}

.bloc-publications .btn-wrap .button-1--plus {
  width: 3.5rem;
  height: 3.5rem;
}

.column-bloc .bloc-publications__title {
  font-size: 1.6em;
  margin: 0 0 0.5em;
}

.column-bloc.bloc-directory img {
  max-width: 100%;
  height: auto;
  margin-bottom: 2em;
}

.column-bloc.bloc-directory .bloc-directory__title {
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 1.6em;
  margin: 0 0 0.9375em;
  color: #000;
}

.column-bloc.bloc-directory .bloc-directory__title a {
  color: #000;
}

.column-bloc.bloc-directory .bloc-directory__title a:hover, .column-bloc.bloc-directory .bloc-directory__title a:focus {
  color: #88c54c;
}

.column-bloc.bloc-directory .list-infos__wrapper {
  display: block;
  flex-wrap: inherit;
  justify-content: inherit;
}

.column-bloc.bloc-directory .list-infos__wrapper > * {
  flex: inherit;
}

.column-bloc.bloc-directory .list-infos__wrapper .list-infos__listitems + .list-infos__listitems {
  border-left: 0;
  padding-left: 0;
  margin-left: 0;
}

.column-bloc.bloc-directory .button-1:before {
  content: "\e041";
}

.column-bloc.bloc-contact img {
  max-width: 100%;
  height: auto;
  margin-bottom: 2em;
}

.column-bloc.bloc-contact .column-bloc__item + .column-bloc__item {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid black;
}

.column-bloc.bloc-contact .bloc-contact__title {
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 1.6em;
  margin: 0 0 0.9375em;
  color: #000;
}

.column-bloc.bloc-contact .bloc-contact__title a {
  color: #000;
}

.column-bloc.bloc-contact .bloc-contact__title a:hover, .column-bloc.bloc-contact .bloc-contact__title a:focus {
  color: #88c54c;
}

.column-bloc.bloc-contact .list-infos__wrapper {
  display: block;
  flex-wrap: inherit;
  justify-content: inherit;
}

.column-bloc.bloc-contact .list-infos__wrapper > * {
  flex: inherit;
}

.column-bloc.bloc-contact .list-infos__wrapper .list-infos__listitems + .list-infos__listitems {
  border-left: 0;
  padding-left: 0;
  margin-left: 0;
}

.column-bloc.bloc-contact .button-1 {
  margin-top: 0.83333em;
}

.column-bloc.bloc-contact .button-1:before {
  content: "\e02a";
}

.block-organigrame__title {
  margin-top: 0;
}

.block-organigrame__wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 3em -0.5em 1em;
}

.block-organigrame__wrapper .organigrame-item {
  width: calc((100% / 2) - 1em);
  margin: 0 0.5em 3em;
}

.organigrame-item {
  transition: all 0.5s ease;
  display: flex;
}

.organigrame-item__img {
  position: relative;
  display: block;
  min-width: 150px;
  max-width: 150px;
  margin-right: 2em;
}

.organigrame-item__img img {
  display: block;
  width: 100%;
}

.organigrame-item__wrap {
  color: #000;
  position: relative;
  flex-grow: 1;
  font-size: 1.3em;
}

.organigrame-item__wrap p:not(:last-child) {
  margin-bottom: 1em;
}

.organigrame-item__wrap a {
  color: #000;
}

.organigrame-item__wrap .button-1 {
  color: #fff;
  display: block;
  width: 100%;
  max-width: 250px;
}

.organigrame-item__wrap .button-1:before {
  content: '\e028';
}

.organigrame-item__title {
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 1.5em;
  text-transform: none;
  margin-bottom: 0.5em;
}

.organigrame-item__post {
  font-weight: 700;
}

.block-contact {
  margin: 6rem 0;
}

.block-contact .list-type-3__item {
  display: flex;
  padding: 0;
}

.block-contact .list-type-3__item:before, .block-contact .list-type-3__item:after {
  content: none;
}

.block-contact .list-type-3__item img {
  max-width: 100%;
  margin-right: 0;
}

.block-contact .list-type-3__item .list-infos {
  flex: 1;
  padding: 3.6rem 4rem;
}

.block-contact .list-type-3__item .list-infos__item {
  padding-left: 0;
}

.block-contact .list-type-3__item .list-infos__item:before {
  content: '';
}

.block-contact .list-type-3__item .list-infos__item.list-infos__hours {
  padding-left: 1.6em;
}

.block-contact .list-type-3__item .list-infos__item.list-infos__hours:before {
  content: "\e05b";
}

.block-contact .list-type-3__item .list-infos__item.list-infos__website, .block-contact .list-type-3__item .list-infos__item.list-infos__email {
  width: 4rem;
  height: 4rem;
  display: inline-block;
  text-indent: -9999px;
  overflow: hidden;
}

.block-contact .list-type-3__item .list-infos__item.list-infos__website a, .block-contact .list-type-3__item .list-infos__item.list-infos__email a {
  width: 100%;
  height: 100%;
  display: block;
  padding: 0;
  margin: 0;
}

.block-contact .list-type-3__item .list-infos__item.list-infos__website a:before, .block-contact .list-type-3__item .list-infos__item.list-infos__email a:before {
  font-size: 1.6rem;
  text-indent: 0;
  color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.block-contact .list-type-3__item .list-infos__item.list-infos__website a:before {
  content: "\e089";
}

.block-contact .list-type-3__item .list-infos__item.list-infos__email a:before {
  content: "\e028";
}

.block-contact .list-type-3__item .list-infos__item a {
  text-decoration: none;
}

.block-contact .list-type-3__item .list-infos__item a:hover, .block-contact .list-type-3__item .list-infos__item a:focus {
  text-decoration: underline;
}

.block-contact .list-type-3__picture {
  min-width: 28rem;
}

.block-contact .list-type-3__category {
  font-size: 1.2rem;
  font-weight: 400;
  text-transform: uppercase;
}

.block-contact .list-type-3__title {
  margin-bottom: 0;
  font-size: 2rem;
  font-weight: 900;
}

.block-contact .list-type-3__wrapper + .list-infos__item {
  margin-top: 0;
}

.block-contact .list-infos__listitems .list-infos__item:first-child {
  margin-top: 0;
}

.block-contact .list-infos__listitems:first-child {
  padding-right: 1.5rem;
}

.block-contact .list-infos__listitems + .list-infos__listitems {
  border-left: 0;
  padding-left: 0;
}

.block-contact h2 {
  margin-bottom: 1.5rem;
  font-size: 1.8rem;
  font-weight: 900;
  color: #4c4c4c;
  text-transform: uppercase;
}

.telecharger {
  margin: 6rem 0;
}

.telecharger__wrap {
  background-color: #1075a1;
}

.telecharger .list-document__listitems {
  padding: 4rem 3rem 4rem 2rem;
  margin: 0;
}

.telecharger .list-document__listitems > * {
  min-height: 5rem;
  padding: 1.5rem 1rem 1.5rem 0;
  flex: 1 1 46%;
  margin: 0 0 0 2rem;
}

.telecharger .list-document__listitems > *:nth-child(odd) {
  border-right: 1px solid #fff;
}

.telecharger .list-document__title-file {
  margin-bottom: 0;
  overflow: visible;
  padding-left: 0;
}

.telecharger .list-document__title-file:before {
  color: #fff;
  top: 50%;
  transform: translateY(-50%);
}

.telecharger .list-document__title-file a {
  display: block;
  color: #fff;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 2;
}

.telecharger .list-document__title-file .mo {
  font-size: 1.2rem;
}

.news {
  padding: 5.5rem 0 0;
  background-color: #f5f8fa;
}

.news .title-decoration span,
.news .title-decoration .btn-wrap {
  background-color: #f5f8fa;
}

.news__wrap {
  margin: 3rem -0.1rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.news__wrap > * {
  width: 25%;
  padding: 0 0.1rem 4rem;
}

.news__wrap > *:first-child {
  padding-left: 0;
}

.news__wrap > *:last-child {
  padding-right: 0;
}

.news__wrap img {
  display: block;
  margin: 0 auto;
}

.news__content {
  padding: 1rem 2rem 0;
  text-align: center;
}

.news__content .category {
  display: inline-block;
  padding: 0.8rem 1rem;
  margin-bottom: 0.7rem;
  color: #1075a1;
  font-size: 1.2rem;
  border: 1px solid #1075a1;
}

.news__content a {
  color: #000;
  font-size: 1.8rem;
  font-weight: 900;
}

.map-btn-mobile {
  width: 100%;
  padding: 1.3rem 0;
  display: none;
  color: #fff;
  background-color: #000;
  font-size: 2rem;
  font-weight: 900;
  text-align: center;
  transition: background 0.3s ease;
}

.map-btn-mobile:before {
  margin-right: 1rem;
  content: '\e04d';
  vertical-align: middle;
  color: #00acec;
  font-size: 3.4rem;
  font-family: 'icons-default';
}

.map-btn-mobile:hover, .map-btn-mobile:focus {
  background-color: #4c4c4c;
  text-decoration: none;
}

.agenda {
  padding: 6.5rem 0 5.5rem;
  background-color: #ebf2f5;
}

.agenda .title-decoration span,
.agenda .title-decoration .btn-wrap {
  background-color: #ebf2f5;
}

.agenda__wrap {
  margin: 2.5rem -0.1rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.agenda__wrap > * {
  width: 25%;
  padding: 0 0.1rem 2.5rem;
}

.agenda__wrap > *:first-child {
  padding-left: 0;
}

.agenda__wrap > *:last-child {
  padding-right: 0;
}

.agenda__wrap img {
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.agenda__content {
  padding: 1.7rem 2.5rem 0 7rem;
}

.agenda__content .category {
  margin-bottom: 0.4rem;
  color: #1075a1;
  font-size: 1.2rem;
}

.agenda__content a {
  display: block;
  position: relative;
  padding-bottom: 1rem;
  margin-bottom: 0.7rem;
  color: #000;
  font-size: 1.8rem;
  font-weight: 900;
}

.agenda__content a:before {
  width: 3.2rem;
  height: 0.1rem;
  background-color: #00acec;
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
}

.agenda__content--noimg {
  margin-top: 4.3rem;
}

.agenda .date-1 {
  position: absolute;
  top: -3rem;
  left: 0;
}

.agenda .hour-place {
  text-align: left;
}

.agenda .place {
  padding-left: 1.5rem;
  position: relative;
}

.agenda .place:before {
  font-family: "icons-default";
  font-size: 1.3rem;
  color: #00acec;
  position: absolute;
  top: 0;
  right: inherit;
  bottom: inherit;
  left: 0;
  content: "";
  line-height: 1;
}

.agenda .place:before {
  font-style: normal;
}

.agenda-proposer {
  width: 22.5rem;
  height: 4rem;
  display: block;
  margin: 3rem auto 0;
  padding: 1.5rem 1rem 1.5rem 3.8rem;
  color: #000;
  font-size: 1.3rem;
  font-weight: 700;
  background-color: transparent;
  border: 1px solid #00acec;
}

.agenda-proposer:before {
  left: 1.5rem;
  font-size: 1.4rem;
  content: '\e05c';
  color: #000;
}

.agenda-proposer:hover, .agenda-proposer:focus {
  border-color: #4c4c4c;
}

.agenda-callendar {
  background-color: #1075a1;
}

.agenda-callendar form {
  margin: 0;
  padding: 3rem 0 0.5rem;
}

.agenda-form-theme .form__field-wrapper {
  margin: 0 auto 1.75em;
  max-width: 242px;
}

.agenda-form-theme .field-wrapper {
  display: flex;
  flex-wrap: wrap;
  color: #fff;
}

.agenda-form-theme .field-wrapper * {
  color: inherit;
}

.agenda-form-theme .field-wrapper label {
  display: block;
  font-size: 1.6em;
  width: 100%;
  text-transform: uppercase;
  color: #fff;
}

.agenda-form-theme .field-wrapper select {
  border: none;
  width: auto;
  flex-grow: 1;
  padding: 0.85714em 0.71429em;
}

.agenda-form-theme .field-wrapper button {
  margin: 1.5px 0 1.5px 10px;
  width: 37px;
  height: 37px;
  background-color: #68c9f3;
  border: none;
  border-radius: 50%;
}

.agenda-form-theme .field-wrapper button:before {
  color: inherit;
  transition: none;
}

.agenda-form-theme .field-wrapper button:hover {
  background-color: #fff;
  color: #68c9f3;
}

.agenda__figure {
  display: table;
  margin: 0 auto;
}

.community a {
  position: relative;
  display: block;
  padding: .2em 1.5em;
  color: #fff;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 300;
  text-transform: uppercase;
  position: relative;
}

.community a:before {
  font-family: "icons-default";
  font-size: 1.1rem;
  color: #fff;
  position: absolute;
  top: 0.3rem;
  right: inherit;
  bottom: inherit;
  left: 0;
  content: "";
  line-height: 1;
}

.community img {
  display: block;
  width: 100%;
  max-width: 12.2rem;
  height: auto;
  margin-left: 3rem;
}

.banner {
  position: relative;
}

.banner__item {
  height: 63.3rem;
  width: 100%;
  position: relative;
  z-index: 5 !important;
}

.banner__item > img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.banner__item--video {
  position: relative;
  overflow: hidden;
}

.banner__item--video video {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.banner .video__wrap {
  overflow: hidden;
}

.banner__info {
  position: absolute;
  right: 25rem;
  top: 12rem;
}

.banner__info-wrap {
  width: 45rem;
}

.banner__info-wrap:before {
  width: 0.5rem;
  height: 9.3rem;
  content: '';
  position: absolute;
  bottom: -5rem;
  left: -3rem;
  background-color: #fff;
  transform: rotate(-35deg);
}

.banner__info-wrap p,
.banner__info-wrap a {
  color: #fff;
  text-shadow: 0.5px 0.9px 3px rgba(0, 0, 0, 0.35);
  text-transform: uppercase;
}

.banner__info-wrap p {
  display: inline-block;
  padding: 1rem 1.2rem;
  font-size: 1.6rem;
  font-weight: 700;
  border: 1px solid #fff;
}

.banner__info-wrap a {
  display: block;
  font-size: 4.5rem;
  font-weight: 900;
}

.banner .pagerCarrousel1 {
  margin-top: 1rem;
}

.banner .pagerCarrousel1 li {
  margin-right: 0.5rem;
  display: inline-block;
}

.banner .modeplay {
  width: 5rem;
  height: 5rem;
  position: absolute;
  right: 5.2rem;
  bottom: 0.1rem;
  z-index: 12;
  text-indent: -9999px;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.9);
  transition: all 0.3s ease;
}

.banner .modeplay span {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
}

.banner .modeplay span:before {
  content: '\e076';
  font-size: 1.6rem;
  text-indent: 0;
  font-family: 'icons-default';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #00acec;
  transition: all 0.3s ease;
}

.banner .modeplay--pause span:before {
  content: '\e075';
}

.banner .modeplay:hover, .banner .modeplay:focus {
  background: #00acec;
  /* The Fallback */
  background: rgba(0, 172, 236, 0.9);
}

.banner .modeplay:hover span:before, .banner .modeplay:focus span:before {
  color: #fff;
}

.banner .prevNextCarrousel1 {
  position: absolute;
  bottom: 0.1rem;
  right: 0.1rem;
  z-index: 10;
}

.banner .prevNextCarrousel1 > * {
  display: inline-block;
}

.banner .prevCarrousel1 button,
.banner .nextCarrousel1 button {
  width: 5rem;
  height: 5rem;
  position: relative;
  background-color: rgba(255, 255, 255, 0.9);
  text-indent: -9999px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.banner .prevCarrousel1 button:before,
.banner .nextCarrousel1 button:before {
  font-size: 1.6rem;
  text-indent: 0;
  font-family: 'icons-default';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #00acec;
  transition: all 0.3s ease;
}

.banner .prevCarrousel1:hover button, .banner .prevCarrousel1:focus button,
.banner .nextCarrousel1:hover button,
.banner .nextCarrousel1:focus button {
  background: #00acec;
  /* The Fallback */
  background: rgba(0, 172, 236, 0.9);
}

.banner .prevCarrousel1:hover button:before, .banner .prevCarrousel1:focus button:before,
.banner .nextCarrousel1:hover button:before,
.banner .nextCarrousel1:focus button:before {
  color: #fff;
}

.banner .prevCarrousel1 button:before {
  content: '\e026';
}

.banner .nextCarrousel1 {
  margin-left: 5.2rem;
}

.banner .nextCarrousel1 button:before {
  content: '\e027';
}

.services {
  margin: 4rem 0 4.5rem;
  position: relative;
}

.services__wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.services__item {
  width: calc(20% - 0.2rem);
  min-height: 16.2rem;
  margin: 0.1rem;
  padding: 3rem 1.7rem 1rem;
  display: block;
  text-align: center;
  border: 1px solid #d5d5d5;
  transition: all 0.3s ease;
}

.services__item svg {
  max-width: 5.3rem;
  max-height: 5.4rem;
  fill: #00acec;
  transition: all 0.3s ease;
}

.services__item p {
  margin-top: 1.5rem;
  font-size: 1.6rem;
  color: #000;
  word-break: break-word;
  white-space: normal;
  transition: all 0.3s ease;
}

.services__item:hover, .services__item:focus {
  background-color: #1075a1;
  border-color: #1075a1;
  text-decoration: none;
}

.services__item:hover svg, .services__item:focus svg {
  fill: #fff;
}

.services__item:hover p, .services__item:focus p {
  color: #fff;
}

.services .prevNextCaroussel2 {
  display: none;
}

.services .prevCaroussel2,
.services .nextCaroussel2 {
  position: absolute;
  top: 50%;
}

.services .prevCaroussel2 button,
.services .nextCaroussel2 button {
  width: 3.5rem;
  height: 3.5rem;
  background-color: #00acec;
  transition: all 0.3s ease;
}

.services .prevCaroussel2 button span,
.services .nextCaroussel2 button span {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  text-indent: -9999px;
  overflow: hidden;
}

.services .prevCaroussel2 button span:before,
.services .nextCaroussel2 button span:before {
  text-indent: 0;
  font-family: 'icons-default';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 1.6rem;
}

.services .prevCaroussel2 button.disabled,
.services .nextCaroussel2 button.disabled {
  background-color: #4c4c4c;
}

.services .prevCaroussel2:hover button, .services .prevCaroussel2:focus button,
.services .nextCaroussel2:hover button,
.services .nextCaroussel2:focus button {
  background-color: #4c4c4c;
}

.services .prevCaroussel2 {
  left: 1rem;
}

.services .prevCaroussel2 button span:before {
  content: '\e016';
}

.services .nextCaroussel2 {
  right: 1rem;
}

.services .nextCaroussel2 button span:before {
  content: '\e017';
}

.actus {
  /* Local variable */
  background-color: #fff;
}

.actus .title-decoration span,
.actus .title-decoration .btn-wrap {
  background-color: #fff;
}

.actus .news__wrap > * {
  width: 33.33%;
}

.actus .news__wrap img {
  width: 100%;
  height: auto;
}

.actus--lg {
  display: flex;
  position: relative;
}

.actus--lg .actus__info {
  position: absolute;
  top: 50%;
  left: 45%;
  transform: translateY(-50%);
  background-color: #1075a1;
  color: #fff;
}

.actus--lg .actus__info a {
  color: #fff;
  text-transform: uppercase;
}

.actus--lg .actus__info a h3 {
  position: relative;
  font-weight: 100;
  font-size: 4rem;
}

.actus--lg .actus__info a h3:before {
  width: 0.1rem;
  height: 9.3rem;
  content: '';
  position: absolute;
  bottom: -3rem;
  left: -3rem;
  background-color: #fff;
  transform: rotate(-35deg);
}

.actus--lg .actus__info a b {
  font-weight: 900;
}

.actus--lg .actus__info p {
  margin-top: 3rem;
  margin-left: 2.5rem;
  font-size: 1.5rem;
  font-weight: 300;
}

.actus--lg .actus__info-wrap {
  padding: 3.5rem 4rem 8.5rem;
  position: relative;
}

.actus--lg .actus__info-wrap:before {
  width: 0.1rem;
  height: 7rem;
  content: '';
  position: absolute;
  bottom: -0.7rem;
  left: 40%;
  background-color: #fff;
  transform: rotate(-35deg);
}

.travaux {
  margin-bottom: 4rem;
}

.travaux__wrap {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}

.travaux__icon {
  padding: 3.5rem 10rem 3rem 2rem;
  display: flex;
  align-items: center;
  color: #fff;
  background-color: #000;
}

.travaux__icon img {
  width: 2.4rem;
  height: auto;
  margin-right: 0.8rem;
}

.travaux__icon p {
  font-weight: 300;
  font-size: 1.6rem;
  text-transform: uppercase;
}

.travaux__icon p b {
  display: block;
  font-weight: 900;
}

.travaux__info {
  width: calc(100% - 23rem) !important;
  padding: 3.5rem 18rem 3rem 0rem;
  position: relative;
  background-color: #1075a1;
  z-index: 1;
}

.travaux__info:before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: -5.5rem;
  background-color: #1075a1;
  transform: skewX(35deg);
  z-index: -1;
  border-left: 1px solid #fff;
}

.travaux__info-item {
  display: inline-flex !important;
  padding-left: 0.5rem;
  color: #fff;
}

.travaux__info-item a {
  white-space: normal;
  color: #fff;
}

.travaux__info-item a h3 {
  font-size: 1.8rem;
  font-weight: 900;
}

.travaux__info-item p {
  white-space: normal;
  font-size: 1.6rem;
  font-weight: 300;
}

.travaux__info .prevNextCaroussel3 {
  position: absolute;
  top: 50%;
  right: calc(4.5rem + 1.51rem);
  transform: translateY(-50%);
}

.travaux__info .prevNextCaroussel3 > * {
  display: inline-block;
  margin: 0 0.1rem;
}

.travaux__info .prevCaroussel3 button,
.travaux__info .nextCaroussel3 button {
  width: 4.5rem;
  height: 4.5rem;
  background-color: #fff;
  transition: all 0.3s ease;
}

.travaux__info .prevCaroussel3 button span,
.travaux__info .nextCaroussel3 button span {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  text-indent: -9999px;
  overflow: hidden;
}

.travaux__info .prevCaroussel3 button span:before,
.travaux__info .nextCaroussel3 button span:before {
  text-indent: 0;
  font-family: 'icons-default';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #1075a1;
  font-size: 1.6rem;
}

.travaux__info .prevCaroussel3 button.disabled,
.travaux__info .nextCaroussel3 button.disabled {
  background-color: #4c4c4c;
}

.travaux__info .prevCaroussel3:hover button, .travaux__info .prevCaroussel3:focus button,
.travaux__info .nextCaroussel3:hover button,
.travaux__info .nextCaroussel3:focus button {
  background-color: #4c4c4c;
}

.travaux__info .prevCaroussel3:hover button span:before, .travaux__info .prevCaroussel3:focus button span:before,
.travaux__info .nextCaroussel3:hover button span:before,
.travaux__info .nextCaroussel3:focus button span:before {
  color: #fff;
}

.travaux__info .prevCaroussel3 button span:before {
  content: '\e016';
}

.travaux__info .nextCaroussel3 button span:before {
  content: '\e017';
}

.travaux__plus {
  display: block;
  position: absolute;
  width: 4.5rem;
  height: 4.5rem;
  top: 50%;
  right: 1.5rem;
  background-color: #fff;
  transform: translateY(-50%);
  transition: all 0.3s ease;
}

.travaux__plus span {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}

.travaux__plus span:before {
  content: '\e08a';
  text-indent: 0;
  font-family: 'icons-default';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #1075a1;
  font-size: 1.6rem;
}

.travaux__plus:hover, .travaux__plus:focus {
  background-color: #4c4c4c;
}

.travaux__plus:hover span:before, .travaux__plus:focus span:before {
  color: #fff;
}

.projets {
  /* Local variable */
  padding: 4.5rem 0 5rem;
  background-color: #ebf2f5;
}

.projets .title-decoration span,
.projets .title-decoration .btn-wrap {
  background-color: #ebf2f5;
}

.projets__wrap {
  display: flex;
  flex-direction: row-reverse;
  position: relative;
}

.projets__wrap .projets__info {
  position: absolute;
  top: 50%;
  right: 45%;
  transform: translateY(-50%);
  background-color: #000;
  color: #fff;
}

.projets__wrap .projets__info a {
  color: #fff;
  text-transform: uppercase;
}

.projets__wrap .projets__info a h3 {
  position: relative;
  font-weight: 100;
  font-size: 4rem;
}

.projets__wrap .projets__info a h3:before {
  width: 0.1rem;
  height: 9.3rem;
  content: '';
  position: absolute;
  bottom: -3rem;
  left: -3rem;
  background-color: #fff;
  transform: rotate(-35deg);
}

.projets__wrap .projets__info a b {
  font-weight: 900;
}

.projets__wrap .projets__info p {
  margin-top: 3rem;
  margin-left: 2.5rem;
  font-size: 1.5rem;
  font-weight: 300;
}

.projets__wrap .projets__info-wrap {
  padding: 3.5rem 4rem 8.5rem;
  position: relative;
}

.projets__wrap .projets__info-wrap:before {
  width: 0.1rem;
  height: 7rem;
  content: '';
  position: absolute;
  bottom: -0.7rem;
  left: 40%;
  background-color: #fff;
  transform: rotate(-35deg);
}

.kiosque {
  padding: 3rem 0;
}

.kiosque__wrap {
  align-items: flex-start;
  display: flex;
}

.kiosque .list-document-1__file-size {
  position: relative;
}

.kiosque .list-document-1__file-size:before {
  content: '';
  width: 3.2rem;
  height: 0.1rem;
  background-color: #00acec;
  position: absolute;
  bottom: -1.2rem;
  left: 0;
}

.kiosque .bloc-publications {
  margin: 0 auto 4rem;
  flex: 1;
}

.kiosque .bloc-publications__wrapper {
  display: flex;
}

.kiosque .bloc-publications__item > a {
  min-width: 25rem;
}

.kiosque .bloc-publications__item > a img {
  width: 100%;
  max-width: 100%;
}

.kiosque .list-document-1__listitems {
  margin-top: 4rem;
  padding-left: 3rem;
}

.kiosque .list-document-1__listitems .button-1 {
  width: 11.2rem;
  height: 10rem;
  padding: 0;
  display: block;
  overflow: hidden;
  font-weight: 700;
}

.kiosque .list-document-1__listitems .button-1:before {
  left: 50%;
  top: 2.5rem;
  transform: translateX(-50%);
  font-size: 2.5rem;
}

.kiosque .list-document-1__listitems .button-1 span {
  width: 100%;
  height: 100%;
  padding-top: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
}

.kiosque .list-document-1__listitems .button-1 span:before, .kiosque .list-document-1__listitems .button-1 span:after {
  height: 2rem;
  width: 0.1rem;
  content: '';
  position: absolute;
  background-color: #fff;
  transform: rotate(-35deg);
}

.kiosque .list-document-1__listitems .button-1 span:before {
  top: -0.3rem;
  left: 2rem;
}

.kiosque .list-document-1__listitems .button-1 span:after {
  bottom: -0.3rem;
  right: 4rem;
}

.kiosque__newsletter {
  max-width: 40rem;
  margin-left: 11rem;
  padding: 4rem;
  background-color: #ebf2f5;
}

.kiosque__newsletter form {
  margin-bottom: 0;
}

.kiosque__newsletter h3 {
  font-size: 2.3rem;
  font-weight: 300;
  text-transform: uppercase;
  position: relative;
  z-index: 1;
}

.kiosque__newsletter h3 b {
  display: block;
  font-weight: 900;
}

.kiosque__newsletter p {
  font-size: 1.4rem;
  font-weight: 300;
  position: relative;
  z-index: 1;
}

.kiosque__newsletter-content {
  padding-right: 8rem;
  position: relative;
}

.kiosque__newsletter-content:before {
  width: 0;
  height: 0;
  content: '';
  position: absolute;
  right: -4rem;
  top: -4rem;
  border-style: solid;
  border-width: 0 16rem 16rem 0;
  border-color: transparent #00acec transparent transparent;
}

.kiosque__newsletter-content:after {
  width: 0;
  height: 0;
  content: '';
  position: absolute;
  right: -4rem;
  top: -4rem;
  border-style: solid;
  border-width: 16rem 0 0 16rem;
  border-color: transparent transparent transparent #ebf2f5;
}

.kiosque__newsletter-icon {
  position: absolute;
  right: -2.5rem;
  top: -3.5rem;
}

.kiosque__newsletter-icon svg {
  width: 8.9rem;
  height: 8.8rem;
  transform: rotate(-10deg);
  fill: #fff;
  stroke: #fff;
  stroke-width: 5px;
}

.kiosque__newsletter .kiosque__bottom {
  margin-top: 2.5rem;
}

.kiosque__newsletter .newsletter__button-wrapper {
  position: static;
}

.kiosque__newsletter .newsletter__button-wrapper .button-1 {
  width: 100%;
  height: auto;
  padding: 1.8rem;
  background-color: #1075a1;
  font-weight: 700;
  font-size: 1.6rem;
}

.kiosque__newsletter .newsletter__button-wrapper .button-1:before {
  transform: translate(0);
  position: static;
  margin-right: 1rem;
}

.kiosque__newsletter .newsletter__button-wrapper .button-1:hover, .kiosque__newsletter .newsletter__button-wrapper .button-1:focus {
  background-color: #4c4c4c;
}

.kiosque__newsletter .newsletter__links li a {
  color: #000;
}

.kiosque__newsletter .newsletter__links li a:before {
  color: #00acec;
}

.enimages {
  margin: 6rem 0;
}

.enimages__wrap {
  display: flex;
  flex-wrap: wrap;
}

.enimages--lg, .enimages--sm {
  width: 50%;
}

.enimages--sm {
  display: flex;
  flex-wrap: wrap;
}

.enimages--sm .item {
  width: 50%;
}

.enimages .item {
  position: relative;
  display: block;
  color: #fff;
  backface-visibility: hidden;
}

.enimages .item__img {
  width: 100%;
  height: 100%;
  padding-bottom: 73%;
  position: relative;
  overflow: hidden;
}

.enimages .item__img img {
  width: auto;
  height: auto;
  min-height: 100%;
  max-width: 100%;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.enimages .item__content {
  width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, -45%);
  opacity: 1;
  visibility: hidden;
  text-align: center;
  white-space: normal;
}

.enimages .item__content-category {
  font-size: 1.3rem;
  font-weight: 300;
  text-transform: uppercase;
}

.enimages .item__content-title {
  font-size: 1.8rem;
  font-weight: 900;
}

.enimages .item__content-amount {
  margin-top: 0.5rem;
  font-size: 1.4rem;
  font-style: italic;
  font-weight: 300;
}

.enimages .item__content .button-1 {
  min-width: 17.3rem;
  height: 4rem;
  margin-top: 3rem;
  font-size: 1.2rem;
  font-weight: 700;
  transition: background 0.3s ease;
}

.enimages .item--video .item__img {
  position: relative;
}

.enimages .item--video .item__img:before {
  font-family: "icons-default";
  font-size: 1.6rem;
  color: #fff;
  position: absolute;
  top: 50%;
  right: inherit;
  bottom: inherit;
  left: 50%;
  content: "";
  line-height: 1;
}

.enimages .item--video .item__img:before {
  width: 4.7rem;
  height: 4.7rem;
  display: block;
  z-index: 5;
  transform: translate(-50%, -50%);
  text-align: center;
  line-height: 4.7rem;
  background: #000;
  /* The Fallback */
  background: rgba(0, 0, 0, 0.9);
}

.enimages .item:after {
  width: 100%;
  height: 100%;
  content: '';
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  background: #000;
  /* The Fallback */
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: all 0.25s ease;
}

.enimages .item:hover, .enimages .item:focus {
  text-decoration: none;
}

.enimages .item:hover .item__img:before, .enimages .item:focus .item__img:before {
  content: none;
}

.enimages .item:hover:after, .enimages .item:focus:after {
  opacity: 1;
}

.enimages .item:hover .item__content, .enimages .item:focus .item__content {
  opacity: 1;
  visibility: visible;
  transform: translate(-50%, -50%);
}

.social__wrap {
  margin: 0 -1.5rem 2rem;
}

.social .filter-social {
  display: flex;
  list-style: none;
}

.social .filter-social a {
  display: block;
  color: #fff;
  height: 5.1rem;
  width: 5.1rem;
  margin: 0 0.1rem;
  text-align: center;
  line-height: 5.1rem;
  font-size: 1.6rem;
  transition: all 0.3s ease;
  background-color: #000;
}

.social .filter-social a:before {
  content: '\e099';
  font-weight: 400;
  font-family: 'icons-default';
}

.social .filter-social a:hover, .social .filter-social a:focus {
  text-decoration: none;
  background-color: #4c4c4c;
}

.social .filter-social .filter-social-facebook:before {
  content: "\e099";
}

.social .filter-social .filter-social-twitter:before {
  content: "\e09b";
}

.social .filter-social .filter-social-instagram:before {
  content: '\E00E';
  font-family: 'icons-project';
}

.social .social-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 0 1rem;
  padding: 0;
  list-style: none;
  overflow: hidden;
}

.social .social-list a {
  color: #1075a1;
  word-break: break-all;
}

.social .social-list-item {
  width: 25%;
  padding: 0 1.5em 4em;
}

.social .social-list-thumbnail {
  display: flex;
  justify-content: flex-start;
  position: relative;
  opacity: 1;
  transition: all 0.25s ease;
}

.social .social-list-thumbnail:before {
  position: absolute;
  left: 1rem;
  top: 1rem;
  z-index: 1;
  content: '';
  font-size: 1.6em;
  color: #fff;
  font-family: 'icons-default';
}

.social .social-list-thumbnail:after {
  width: 0;
  height: 0;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  border-style: solid;
  border-width: 6rem 6rem 0 0;
  border-color: #00acec transparent transparent transparent;
}

.social .social-list-thumbnail:hover, .social .social-list-thumbnail:focus {
  opacity: 0.85;
}

.social .social-list-thumbnail img {
  display: block;
  width: 100%;
  height: 100%;
}

.social .social-list-facebook .social-list-thumbnail:before {
  content: '\e099';
}

.social .social-list-twitter .social-list-thumbnail:before {
  content: '\e09b';
}

.social .social-list-instagram .social-list-thumbnail:before {
  content: '\E00E';
  font-family: 'icons-project';
}

.social .social-list-content {
  color: #000;
}

.social .social-list-content--noimg {
  margin-top: 5.5rem;
}

.social .social-list-head {
  padding: 2em 0 1em;
  display: block;
}

.social .social-list-head:hover .social-list-head-title {
  text-decoration: underline !important;
}

.social .social-list-head-ico {
  float: left;
  width: 42px;
  margin-right: 10px;
  border: 1px solid rgba(119, 119, 119, 0.5);
}

.social .social-list-head-title {
  font-size: 1.4rem;
  display: block;
  margin: 0.15em 0;
  font-weight: 900;
  color: #000;
}

.social .social-list-head-subtitle {
  font-size: 1.1rem;
  color: #717171;
}

.social .social-list-desc {
  font-size: 1.3em;
  font-weight: 300;
  margin: 0 0 1em;
}

.social .social-list .social-list-date {
  font-size: 1.1em;
  text-transform: uppercase;
  color: #1075a1;
  margin: 11px 0 7px;
}

@media (max-width: 1279px) {
  img {
    max-width: 100%;
    height: auto;
  }
  .sg-code {
    white-space: normal;
  }
  .fancybox-galerie {
    padding: 0;
  }
  .fancybox-opened .fancybox-title {
    padding: 10px;
  }
  .fancybox-wrap .fancybox-close {
    right: 0;
    bottom: 0;
    padding: 0;
    background: none;
    border: 1px solid transparent;
  }
  .fancybox-wrap .fancybox-close:hover, .fancybox-wrap .fancybox-close:focus {
    border: 1px dotted #fff;
  }
  .fancybox-wrap .fancybox-close span {
    display: none;
  }
  .fancybox-wrap .fancybox-close img {
    display: inline;
  }
  .fancybox-nav {
    bottom: 50%;
    width: 35px;
    height: 35px;
    display: block;
  }
  .fancybox-nav:focus {
    border: 1px dotted #fff;
  }
  .fancybox-nav span {
    display: none;
  }
  .fancybox-nav img {
    display: inline;
  }
  .fancybox-prev {
    left: 0;
  }
  .fancybox-next {
    right: 0;
  }
  .fancybox-more-infos {
    display: block;
  }
  #fancybox-buttons {
    width: 34px;
    height: 32px;
    overflow: hidden;
    position: fixed;
    top: inherit;
    bottom: 0;
    z-index: 9996;
  }
  #fancybox-buttons .btnPlay {
    color: #000;
    padding-left: 0;
    text-align: center;
    border: 1px solid transparent;
    height: 30px;
    width: 32px;
  }
  #fancybox-buttons .btnPlay img {
    display: block;
  }
  #fancybox-buttons .btnPlay:hover, #fancybox-buttons .btnPlay:focus {
    border: 1px dotted #fff;
  }
  #fancybox-buttons .btnPlay:before {
    background: none;
  }
  #fancybox-buttons .btnPlay.btnPlayOn:before {
    background: none;
  }
  .fancybox-close-wrap {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 32px;
    text-align: right;
    z-index: 9995;
  }
  .fancybox-close-wrap .fancybox-close {
    position: static;
    bottom: inherit;
    right: inherit;
  }
  .fancybox-galerie .fancybox-wrap-title {
    position: fixed;
    bottom: 32px;
    left: 0;
    width: 100%;
    z-index: 9996;
  }
  .fancybox-galerie .fancybox-title {
    position: absolute;
    bottom: -9999px;
    left: 0;
  }
  .display .fancybox-title {
    display: block;
    bottom: 0;
    z-index: 9800;
    background: rgba(0, 0, 0, 0.8);
  }
  .fancybox-galerie .fancybox-more-infos {
    position: fixed;
    bottom: 0;
    left: 32px;
    z-index: 9996;
    display: block;
    border: 1px solid transparent;
  }
  .fancybox-galerie .fancybox-more-infos:hover, .fancybox-galerie .fancybox-more-infos:focus {
    border: 1px dotted #fff;
  }
  label {
    margin-top: 0;
  }
  .radio, .checkbox {
    margin: 2em 0 1.5em;
  }
  .radio.column-2 div, .checkbox.column-2 div {
    display: block;
    vertical-align: inherit;
    width: 100%;
  }
  .radio.column-2 div:nth-child(even), .checkbox.column-2 div:nth-child(even) {
    margin-right: 0;
  }
  .radio.column-2 div:nth-child(odd), .checkbox.column-2 div:nth-child(odd) {
    margin-left: 0;
  }
  .radio legend, .checkbox legend {
    margin-top: 0;
  }
  .button-1.button-1--no-text-in-mobile, .rte .button-1.button-1--no-text-in-mobile {
    text-align: left;
    text-indent: -9999px;
    white-space: nowrap;
    position: relative;
    padding: 1.08333em 1.75em;
  }
  .button-1.button-1--no-text-in-mobile:before, .rte .button-1.button-1--no-text-in-mobile:before {
    text-indent: 0;
    line-height: 0;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    margin: 0;
    font-size: 1.16667em;
  }
  .wrapper-main, .sg-menu ul, .top-of-content__wrapper, .section-main__wrapper, .heading .headding__wrapper-title, .heading .heading__wrapper, .menu-stratis .menu-stratis__level-1, .menu-main-1 {
    width: 100%;
    max-width: 96rem;
  }
  .heading {
    padding: 4em 0 6em;
  }
  .heading h1 {
    font-size: 3.5em;
  }
  .heading--single-news .heading__picture img {
    width: auto;
    max-width: 100%;
  }
  .filters__single-directory {
    margin-bottom: 1rem;
  }
  .filters__single-directory .filters__locate:before {
    content: "\e04d";
  }
  .filters__single-directory .filters__event:before {
    content: "\e009";
  }
  .pager li.pager__prev span, .pager li.pager__prev a, .pager li.pager__next span, .pager li.pager__next a {
    min-width: 4rem;
    text-indent: -9999px;
    white-space: nowrap;
    position: relative;
    padding: 1rem 2rem;
  }
  .pager li.pager__prev span:before, .pager li.pager__prev span:after, .pager li.pager__prev a:before, .pager li.pager__prev a:after, .pager li.pager__next span:before, .pager li.pager__next span:after, .pager li.pager__next a:before, .pager li.pager__next a:after {
    text-indent: 0;
    line-height: 0;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
  }
  .pager-single-xl li.pager__prev .pager__button, .pager-single-xl li.pager__next .pager__button {
    text-indent: -9999px;
    white-space: nowrap;
    position: relative;
    padding: 0.71429em 1.28571em;
  }
  .pager-single-xl li.pager__prev .pager__button:before, .pager-single-xl li.pager__prev .pager__button:after, .pager-single-xl li.pager__next .pager__button:before, .pager-single-xl li.pager__next .pager__button:after {
    text-indent: 0;
    line-height: 0;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
  }
  .pager-single-xl .pager__link {
    align-items: flex-start;
  }
  .sidebar {
    width: 100% !important;
    position: static !important;
  }
  .sidebar h2 {
    margin-bottom: 0;
  }
  .sidebar-tablet,
  .sidebar .toc-bar {
    display: none;
  }
  .sticky-wrapper {
    width: 100%;
  }
  #barsContainer > button {
    padding: 1rem 2.5rem 1.5rem 2.5rem;
  }
  #barsContainer > button:first-child {
    padding: 1rem 2.5rem 2.2rem 2.5rem;
    border-top-color: transparent;
  }
  .rte p {
    font-size: 1.4em;
    margin: 0.35714em 0 0.71429em 0;
  }
  .rte .table-wrapper {
    position: relative;
  }
  .rte .table-wrapper-fade {
    position: absolute;
    right: 0;
    width: 30px;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 100%);
  }
  .rte .table-wrapper-inner {
    max-width: 48.7em;
  }
  .rte table caption {
    text-align: left;
  }
  .teaser-2 {
    font-size: 1.6em;
  }
  .header--right,
  .header > .search-box {
    display: none;
  }
  .section-main__wrapper {
    padding-top: 3rem;
  }
  .section-main__wrapper-2 {
    display: flex;
    flex-wrap: wrap;
  }
  .section-main__wrapper-2 > * {
    display: block;
  }
  .section-main__content {
    padding-top: 3.5rem;
  }
  .section-main__content + .section-main__aside {
    padding-right: 0;
    padding-left: 0;
  }
  .section-main__content + .section-main__aside .column-bloc {
    padding-right: 0;
    padding-left: 0;
  }
  .section-main__aside {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
  .footer .footer__wrapper-2 {
    flex-wrap: wrap;
  }
  .footer .site-infos {
    flex-basis: 60%;
    flex-shrink: 0;
  }
  .footer .menu-cross {
    flex-basis: 30%;
    flex-shrink: 0;
  }
  .footer .newsletter {
    margin-top: 7.5rem;
    margin-left: 15rem;
    flex-basis: 43.5%;
  }
  .footer .community {
    margin-top: 7.5rem;
    flex-basis: 29%;
  }
  .menu-skip .desktop {
    display: none;
  }
  .menu-skip .smartphone {
    display: block;
  }
  .menu-stratis {
    display: none;
  }
  .menu-stratis .menu-stratis__level-2 {
    display: none;
  }
  body.menu-main-1--active {
    overflow-y: hidden;
  }
  body.menu-main-1--active .menu-main-1__listitems-0 > .menu-main-1__item {
    overflow-y: auto;
    height: 100%;
  }
  .menu-main-1__item--no-action-in-desktop .menu-main-1__sublevel {
    display: none;
  }
  .menu-main-1__item--no-action-in-desktop .menu-main-1__sublevel[aria-hidden="false"] {
    display: block;
  }
  /* Styles */
  .menu-main-1__wrapper {
    z-index: 100;
    position: static;
  }
  .menu-main-1 .wrapper-main, .menu-main-1 .sg-menu ul, .sg-menu .menu-main-1 ul, .menu-main-1 .top-of-content__wrapper, .menu-main-1 .section-main__wrapper, .menu-main-1 .heading .headding__wrapper-title, .heading .menu-main-1 .headding__wrapper-title, .menu-main-1 .heading .heading__wrapper, .heading .menu-main-1 .heading__wrapper, .menu-main-1 .menu-stratis .menu-stratis__level-1, .menu-stratis .menu-main-1 .menu-stratis__level-1, .menu-main-1 .menu-main-1 {
    width: 100%;
    padding: 0;
    margin: 0;
  }
  .menu-main-1__listitems-0 > li {
    position: fixed;
    z-index: 101;
    top: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    transition: transform ease .3s;
    transform: translate(100%, 0);
    width: 100%;
    background: #fff;
  }
  .menu-main-1__listitems-0 > li > .menu-main-1__header {
    height: 10.1rem;
    display: flex;
    align-items: center;
    background: #fff;
    border-bottom: 1px solid #000;
  }
  .menu-main-1__listitems-0 > li > .menu-main-1__header > span {
    display: block;
    text-transform: uppercase;
    font-size: 1.8rem;
    font-weight: 700;
    color: #000;
    margin-left: 8rem;
  }
  .menu-main-1__listitems-0 > li > .menu-main-1__header .menu-main-1__action {
    text-align: left;
    text-indent: -9999px;
    white-space: nowrap;
    position: absolute;
    top: 1.9rem;
    left: -7.3rem;
    width: 6.3rem;
    height: 6.3rem;
    background: #fff;
    border: 1px solid #cccccc;
    transition: left ease .3s, top ease .3s, background 0.3s ease;
  }
  .menu-main-1__listitems-0 > li > .menu-main-1__header .menu-main-1__action:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
    content: '\e02f';
    color: #00acec;
    font-family: 'icons-default';
    font-size: 1.6rem;
    text-indent: 0;
    line-height: 0;
    display: block;
  }
  .menu-main-1__listitems-0 > li > .menu-main-1__header .menu-main-1__action:hover, .menu-main-1__listitems-0 > li > .menu-main-1__header .menu-main-1__action:focus {
    background-color: #4c4c4c;
  }
  .menu-main-1__listitems-0 > li > .menu-main-1__header .menu-main-1__action:hover:before, .menu-main-1__listitems-0 > li > .menu-main-1__header .menu-main-1__action:focus:before {
    color: #fff;
  }
  .menu-main-1__listitems-0 > li > .menu-main-1__sublevel {
    display: none;
  }
  .menu-main-1__listitems-0 > li > .menu-main-1__sublevel[aria-hidden="false"] {
    display: block;
  }
  .menu-main-1__listitems-0 > li > .menu-main-1__sublevel > .menu-main-1__closing-button {
    display: block;
  }
  .menu-main-1__listitems-0 > li > .menu-main-1__sublevel > .menu-main-1__closing-button:before {
    color: #049ee0;
  }
  .menu-main-1__listitems-0 > li.menu-main-1__item--active {
    transform: translate(0, 0);
  }
  .menu-main-1__listitems-0 > li.menu-main-1__item--active > .menu-main-1__header .menu-main-1__action {
    left: 0;
  }
  .menu-main-1__listitems-1 {
    display: block;
    background: #fff;
  }
  .menu-main-1__listitems-1 > li {
    display: block;
    vertical-align: inherit;
    border-bottom: 1px solid #1075a1;
  }
  .menu-main-1__listitems-1 > li > .menu-main-1__header > a {
    color: #000;
    padding: 2.25rem 4.5rem 2.25rem 3rem;
    position: relative;
  }
  .menu-main-1__listitems-1 > li > .menu-main-1__header > a:after {
    position: absolute;
    top: 50%;
    right: 3.6rem;
    transform: translateY(-50%);
    content: "\e08a";
    font-family: 'icons-default';
    color: #049ee0;
    font-size: 1.6rem;
    font-weight: 300;
    display: block;
  }
  .menu-main-1__listitems-1 > li > .menu-main-1__header > a[aria-expanded="true"]:after {
    color: #fff;
    content: "\e023";
  }
  .menu-main-1__listitems-1 > li > .menu-main-1__sublevel {
    position: static;
    max-height: inherit;
    display: none;
  }
  .menu-main-1__listitems-1 > li > .menu-main-1__sublevel .menu-main-1__listitems-1__wrapper {
    background: #000;
    transform: inherit;
    height: auto;
    padding: 0;
  }
  .menu-main-1__listitems-1 > li > .menu-main-1__sublevel[aria-hidden="false"] {
    display: block;
    max-height: inherit;
  }
  .menu-main-1__listitems-1 > li > .menu-main-1__sublevel[aria-hidden="false"] .menu-main-1__listitems-1__wrapper {
    transform: inherit;
    transition: inherit;
  }
  .menu-main-1__listitems-1 .wrapper-main--home, .menu-main-1__listitems-1 .footer .footer__wrapper, .footer .menu-main-1__listitems-1 .footer__wrapper {
    padding: 0;
  }
  .menu-main-1__listitems-2 {
    width: 100%;
    padding: 1.7rem 0 3rem;
  }
  .menu-main-1__listitems-2 > li {
    text-align: left;
  }
  .menu-main-1__listitems-2 > li .menu-main-1__header {
    display: block;
  }
  .menu-main-1__listitems-2 > li .menu-main-1__header .menu-main-1__action {
    right: 3rem;
  }
  .menu-main-1__listitems-2 > li .menu-main-1__header .menu-main-1__action:before {
    content: "\e08a";
  }
  .menu-main-1__listitems-2 > li .menu-main-1__header .menu-main-1__action[aria-expanded="true"]:before {
    content: "\e023";
  }
  .menu-main-1__listitems-2 > li > .menu-main-1__header a {
    padding: 1.2rem 4rem 1.2rem 2.5rem;
  }
  .menu-main-1__listitems-2 > li > .menu-main-1__sublevel {
    display: none;
    position: static;
    width: 100%;
    padding: 1rem 2.5rem 2rem 2.5rem;
    transform: inherit;
    height: 0;
    transition: inherit;
    background: #4c4c4c;
  }
  .menu-main-1__listitems-2 > li > .menu-main-1__sublevel[aria-hidden="false"] {
    display: block;
    transform: inherit;
    height: auto;
    transition: inherit;
  }
  .menu-main-1__listitems-3 {
    padding: .5em 0;
  }
  .menu-main-1__listitems-3 > li > .menu-main-1__header:before {
    top: 1.5rem;
  }
  .menu-main-1__listitems-3 > li > .menu-main-1__header a {
    padding: 1.1rem 4rem 1.1rem 2.5rem;
  }
  .menu-main-1__listitems-3 > li > .menu-main-1__header .menu-main-1__action {
    right: 0.5rem;
  }
  .menu-main-1__listitems-3 > li > .menu-main-1__sublevel {
    display: none;
    position: static;
    width: 100%;
    padding: 0.5rem 0 0.5rem 2.5rem;
    margin-left: 0;
    transform: inherit;
    height: 0;
    transition: inherit;
    background: #000;
  }
  .menu-main-1__listitems-3 > li > .menu-main-1__sublevel[aria-hidden="false"] {
    display: block;
    transform: inherit;
    height: auto;
    transition: inherit;
  }
  .menu-main-1__listitems-4 {
    padding: .5em 0;
  }
  .menu-main-1__listitems-4 > li > .menu-main-1__header:before {
    top: 1.6rem;
  }
  .menu-main-1__listitems-4 > li > .menu-main-1__header a {
    padding: 1.1rem 4rem 1.1rem 2.5rem;
  }
  .menu-info {
    position: static;
    padding-bottom: 4rem;
  }
  .list-type-1__picture img {
    max-width: 120px;
  }
  .list-type-1__title {
    font-size: 1.6em;
  }
  /*
.list-type-1--event {
  .list-type-1__picture {
    img {
      margin-right: 2em;
    }
  }
  .list-type-1__wrapper-date {

    display: table-cell;
    vertical-align: top;
    width: 13em;
    border-bottom: 0;
    margin-bottom: 0;
    .date-1, .hour-place {
      width: auto;
      display: block;
      vertical-align: inherit;
      padding-bottom: 0;
    }
    .date-1 {
      text-align: center;
      time:nth-of-type(1) {
        margin-left: 1em;
      }
    }
  }
  .list-type-1__wrapper {
    padding-left: 2em;
  }
}
*/
  .list-type-2.list-type-2--3col .list-type-2__listitems .list-paginated__wrapper > article {
    flex-basis: 46%;
  }
  .list-type-2__listitems .list-paginated__wrapper > article {
    flex-basis: 46%;
  }
  .list-type-3__picture {
    float: none;
  }
  .list-type-3__picture img {
    margin-right: 0;
    margin-bottom: 1em;
    max-width: 100%;
    height: auto;
  }
  .menu-main-1 .search-box {
    width: 90%;
    height: auto;
    padding-bottom: 3rem;
    margin: 12rem auto 0;
    position: static;
    background-color: transparent;
  }
  .menu-main-1 .search-box .search-box__fields-wrapper {
    border: 1px solid #cccccc;
  }
  .menu-main-1 .search-box__button-wrapper > button {
    width: 6.3rem !important;
    height: 6.3rem !important;
    margin-left: 0.5rem;
    background: transparent;
    border: 1px solid #cccccc;
  }
  .menu-main-1 .search-box__button-wrapper > button svg {
    stroke: #00acec;
  }
  .menu-main-1 .search-box__button-wrapper > button:hover svg, .menu-main-1 .search-box__button-wrapper > button:focus svg {
    stroke: #4c4c4c;
  }
  .menu-main-1 label {
    display: none !important;
  }
  .menu-main-1 input {
    padding: 1.45rem 1rem;
    border: none;
  }
  .menu-main-1 input::-webkit-input-placeholder {
    font-size: 1.6rem;
    font-weight: 300;
    color: rgba(0, 0, 0, 0.7);
    text-overflow: ellipsis !important;
  }
  .menu-main-1 input::-moz-placeholder {
    font-size: 1.6rem;
    font-weight: 300;
    color: rgba(0, 0, 0, 0.7);
    text-overflow: ellipsis !important;
  }
  .menu-main-1 input:-ms-input-placeholder {
    font-size: 1.6rem;
    font-weight: 300;
    color: rgba(0, 0, 0, 0.7);
    text-overflow: ellipsis !important;
  }
  .menu-main-1 input:-moz-placeholder {
    font-size: 1.6rem;
    font-weight: 300;
    color: rgba(0, 0, 0, 0.7);
    text-overflow: ellipsis !important;
  }
  .menu-main-1 .ddm .ddm__sub-level {
    position: static;
    height: auto;
    right: 0;
    max-width: 100%;
    padding: 2.25rem 1rem;
  }
  .cookies-top-bloc {
    width: 100%;
    bottom: 0;
  }
  .cookies-top-bloc__button-wrapper {
    text-align: center;
  }
  .list-document__listitems {
    flex-direction: column;
  }
  .list-document__listitems > * {
    flex: 0 0 1;
  }
  .list-infos__wrapper {
    display: block;
  }
  .list-infos__wrapper .list-infos__listitems + .list-infos__listitems {
    border-left: 0;
    border-top: 1px solid #808f95;
    padding-left: 0;
    margin-left: 0;
    padding-top: 5%;
    margin-top: 5%;
  }
  .list-infos__item--group p {
    width: 100%;
  }
  .comments__listitems--children .comment {
    padding-left: 30px;
  }
  .comments__listitems--children .comment__wrapper-2:after {
    border-color: transparent transparent #fff transparent;
  }
  .comment__avatar {
    float: none;
    margin-right: 0;
    margin-bottom: 1em;
  }
  .comment__wrapper {
    padding-left: 0;
    padding-top: 12px;
  }
  .comment__wrapper-2:before, .comment__wrapper-2:after {
    right: inherit;
  }
  .comment__wrapper-2:before {
    top: -24px;
    left: 28px;
    border-color: transparent transparent #c2d9e1 transparent;
  }
  .comment__wrapper-2:after {
    top: -20px;
    left: 30px;
    border-color: transparent transparent #ebf2f5 transparent;
  }
  .comment__reply-link {
    position: static;
    top: inherit;
    right: inherit;
  }
  .block-contact .list-infos__wrapper {
    display: flex;
  }
  .block-contact .list-infos__wrapper .list-infos__listitems + .list-infos__listitems {
    padding-top: 0;
    margin-top: 0;
    border-top: 0;
  }
  .telecharger .list-document__listitems {
    flex-direction: row;
  }
  .telecharger .list-document__title-file a {
    line-height: 1.1;
  }
  .agenda {
    padding: 4.5rem 0 8rem;
  }
  .agenda__wrap {
    max-width: 70rem;
    margin: 3.5rem auto 0;
  }
  .agenda__wrap > * {
    width: 50%;
    max-width: 28.9rem;
  }
  .agenda-home .agenda__wrap {
    max-width: 85%;
    margin: 0 auto;
  }
  .agenda-home .agenda__item {
    width: 50%;
    max-width: 50%;
  }
  .news__wrap {
    max-width: 70rem;
    margin: 3.5rem auto 0;
  }
  .news__wrap > * {
    width: 50%;
    max-width: 28.9rem;
  }
  .parallax {
    height: 22.5rem;
  }
  .tools {
    display: none;
  }
  .stratis {
    padding-bottom: 0;
  }
  .tools {
    display: none;
  }
  .banner__item {
    height: 30.5rem;
  }
  .banner__info {
    top: 3rem;
    right: 8rem;
  }
  .banner__info-wrap a {
    font-size: 3.5rem;
  }
  .banner .pagerCarrousel1 {
    margin-top: 6rem;
  }
  .services__wrap {
    width: calc(100% - 12rem);
    margin: 4rem auto 4.5rem;
  }
  .services__item {
    width: 100%;
  }
  .services .item {
    display: inline-flex !important;
    flex-wrap: wrap;
  }
  .services .prevNextCaroussel2 {
    display: block;
  }
  .actus {
    /* Local variable */
  }
  .actus .news__wrap {
    margin-top: 0;
    max-width: 100%;
  }
  .actus .news__wrap > * {
    max-width: 33.33%;
  }
  .actus--lg {
    flex-direction: column;
  }
  .actus--lg .actus__info {
    width: 94%;
    margin: 0 auto;
    position: static;
    transform: translateY(-4rem);
  }
  .actus--lg .actus__info a h3 {
    font-size: 3.4rem;
  }
  .actus--lg .actus__info-wrap {
    padding: 3rem 3rem 6.5rem;
  }
  .actus--lg .actus__info-wrap:before {
    height: 4.5rem;
  }
  .travaux__info-item a h3 {
    font-size: 1.3rem;
  }
  .travaux__info-item p {
    font-size: 1.1rem;
  }
  .projets {
    /* Local variable */
    padding-bottom: 2rem;
  }
  .projets__wrap {
    flex-direction: column-reverse;
  }
  .projets__wrap .projets__info {
    width: 94%;
    margin: 0 auto;
    position: static;
    transform: translateY(-4rem);
  }
  .projets__wrap .projets__info a h3 {
    font-size: 3.4rem;
  }
  .projets__wrap .projets__info-wrap {
    padding: 3rem 3rem 6.5rem;
  }
  .projets__wrap .projets__info-wrap:before {
    height: 4.5rem;
  }
  .kiosque__wrap {
    align-items: center;
    flex-direction: column;
  }
  .kiosque__bottom {
    display: flex;
    align-items: center;
  }
  .kiosque .list-document-1__listitems {
    margin-top: 2rem;
    padding-left: 0;
  }
  .kiosque .bloc-publications {
    width: 85%;
  }
  .kiosque .bloc-publications__wrapper {
    flex-direction: column;
  }
  .kiosque__newsletter {
    max-width: 100%;
    margin-left: 0;
  }
  .kiosque__newsletter h3 b {
    display: inline-block;
  }
  .kiosque__newsletter form {
    display: inline-block;
  }
  .kiosque__newsletter .newsletter__links {
    margin-left: 2rem;
    margin-top: 1rem;
  }
  .kiosque__newsletter .newsletter__links li {
    display: block;
    margin-top: 0.5rem;
    float: none;
  }
  .kiosque .newsletter__fields {
    max-width: 100%;
    display: flex;
  }
  .kiosque .newsletter__fields-wrapper {
    width: 32rem;
    max-width: 32rem;
    vertical-align: middle;
  }
  .enimages__wrap {
    width: 85%;
    margin: 0 auto;
    flex-direction: column;
  }
  .enimages--lg, .enimages--sm {
    width: 100%;
  }
  .enimages .item {
    margin-bottom: 3.5rem;
    color: #000;
  }
  .enimages .item__img {
    padding: 0;
    height: auto;
  }
  .enimages .item__img img {
    width: 100%;
    position: static;
    transform: translate(0, 0);
  }
  .enimages .item__content {
    width: 100%;
    padding-top: 1.5rem;
    position: static;
    opacity: 1;
    visibility: visible;
    transform: translate(0, 0);
  }
  .enimages .item__content-title {
    margin-top: 0.7rem;
  }
  .enimages .item__content .button-1 {
    display: none;
  }
  .enimages .item:after {
    content: none;
  }
  .enimages .item:hover .item__content, .enimages .item:focus .item__content {
    transform: translate(0, 0);
  }
  .enimages .item:hover .item__content-title, .enimages .item:focus .item__content-title {
    text-decoration: underline;
  }
  .enimages .item--video:hover .item__img:before, .enimages .item--video:focus .item__img:before {
    content: '\e027';
  }
  .social__wrap {
    width: 85%;
    margin: 0 auto;
  }
  .social .social-list-item {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .form__field-group {
    flex-wrap: wrap;
  }
  .form__attached-field {
    flex: 1 0 100%;
  }
  .button-1.button-1--no-text-in-smartphone, .rte .button-1.button-1--no-text-in-smartphone {
    text-align: left;
    text-indent: -9999px;
    white-space: nowrap;
    position: relative;
    padding: 1.29167em 1.75em;
  }
  .button-1.button-1--no-text-in-smartphone:before, .rte .button-1.button-1--no-text-in-smartphone:before {
    text-indent: 0;
    line-height: 0;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    margin: 0;
    font-size: 1.16667em;
  }
  .button-1--plus, .rte .button-1--plus {
    width: 3.5rem;
    height: 3.5rem;
  }
  .background-in-wrapper {
    margin: 0 -1em;
    padding: 0 1em;
  }
  .background-in-wrapper:before, .background-in-wrapper:after {
    width: 0;
  }
  .column-bloc {
    width: 100%;
    padding-left: 0;
  }
  .header {
    padding: 0 1rem;
  }
  .top-of-content {
    display: none;
  }
  .footer .footer__wrapper-2 {
    margin: 0;
    display: block;
  }
  .footer .footer__wrapper-2 > * {
    flex: inherit;
    margin: 0 0 2em 0;
  }
  .footer .menu-cross {
    display: flex;
    justify-content: center;
  }
  .filters {
    margin-top: 0;
  }
  .filters form {
    margin: 2em 0 0;
  }
  .ddm .filters__ddm__sub-level {
    display: block;
    height: auto;
    overflow: hidden;
    max-height: 0;
    transition: max-height ease .2s;
  }
  .ddm.ddm--active .filters__ddm__sub-level {
    max-height: 100em;
    transition: max-height ease 1s;
  }
  .filters__wrapper {
    display: block;
    table-layout: inherit;
  }
  .filters__fields, .filters__button-wrapper {
    display: block;
    vertical-align: inherit;
  }
  .filters__fields .filters__fields-wrapper {
    display: block;
    flex-wrap: inherit;
    justify-content: inherit;
    margin: 0;
  }
  .filters__fields .filters__fields-wrapper > .filters__field-wrapper {
    align-self: inherit;
    flex: inherit;
    margin-right: 0;
    margin-left: 0;
  }
  .filters__fields.filters__fields--column-2 .filters__fields-wrapper > .filters__field-wrapper, .filters__fields.filters__fields--column-3 .filters__fields-wrapper > .filters__field-wrapper, .filters__fields.filters__fields--column-4 .filters__fields-wrapper > .filters__field-wrapper {
    width: 100%;
  }
  .filters__button-wrapper {
    padding-left: 0;
    padding-top: 0;
    text-align: right;
    width: 100%;
  }
  .teaser-2 {
    font-size: 1.6em;
    line-height: 1.2em;
  }
  .date-1 time {
    margin: 0;
  }
  .sidebar h2 {
    margin-bottom: 1.5rem;
  }
  #barsContainer > button {
    padding: 1.5rem 2.5rem 2rem 2.5rem;
  }
  .heading {
    padding: 3rem 0 3.5rem;
  }
  .heading .heading__wrapper {
    padding: 0 10px;
    display: block;
  }
  .heading .heading__figure {
    width: 100%;
    float: none;
    margin-right: 0;
    margin-bottom: 2em;
  }
  .heading .subtitle {
    margin-bottom: 0.7rem;
    padding: 1rem 1.9rem;
    font-size: 1.2rem;
  }
  .heading h1 {
    font-size: 2.4em;
  }
  .heading.heading--picture-right .heading__figure {
    margin-left: 0;
  }
  .heading--single-event .heading__wrapper-figure, .heading--single-event .heading__wrapper-date, .heading--single-event .heading__wrap {
    display: block;
    vertical-align: inherit;
  }
  .heading--single-event .heading__picture img {
    width: auto;
    max-width: 100%;
  }
  .heading--single-event .heading__wrapper-date {
    width: 100%;
    margin-top: 3rem;
    border-right: 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #808f95;
    margin-bottom: 2em;
  }
  .heading--single-event .heading__wrapper-date .date-1, .heading--single-event .heading__wrapper-date .hour-place {
    vertical-align: middle;
    padding-bottom: 2em;
  }
  .heading--single-event .heading__wrapper-date .date-1 {
    text-align: left;
  }
  .heading--single-event .heading__wrapper-date .date-1 time:nth-of-type(1) {
    margin-left: 0;
  }
  .heading--single-event .heading__wrap {
    padding-left: 0;
  }
  .title-1 {
    font-size: 3em;
  }
  .title-1.title-decoration span:first-child:after {
    content: none;
  }
  .title-3 {
    margin-bottom: 2.5rem;
    font-size: 2em;
  }
  .title-decoration:before {
    content: none;
  }
  .title-decoration span:first-child {
    display: block;
    width: 80%;
  }
  .title-decoration span:first-child:after {
    content: none;
  }
  .title-decoration .btn-wrap {
    background-color: #fff;
    padding-left: 1rem;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
  }
  .title-decoration .btn-wrap > * {
    margin: 0 0.1rem;
  }
  .title-decoration .btn-wrap .button-1--rss {
    width: 3.5rem;
    height: 3.5rem;
  }
  .ce-left .ce-gallery, .ce-column {
    float: none;
  }
  .ce-right .ce-gallery {
    float: none;
  }
  .ce-intext.ce-right .ce-gallery {
    margin-left: 0;
  }
  .ce-intext.ce-left .ce-gallery {
    margin-right: 0;
  }
  .rte h2, .rte .h2, .h2 {
    font-size: 2.8em;
  }
  .rte h3, .rte .h3, .h3 {
    font-size: 2.2em;
  }
  .rte p {
    font-size: 1.5em;
  }
  .menu-main-1__listitems-0 > li > .menu-main-1__header .menu-main-1__action {
    width: 5rem;
    height: 5rem;
    top: 1.1rem;
    left: -6.3rem;
  }
  .menu-main-1__listitems-0 > li.menu-main-1__item--active > .menu-main-1__header .menu-main-1__action {
    top: 2.4rem;
    left: 1rem;
  }
  .list-type-1__item {
    padding: 4em 0;
  }
  .list-type-1__picture {
    float: none;
    margin-right: 0;
    margin-bottom: 2em;
  }
  .list-type-1__picture img {
    max-width: 100%;
    margin-right: 0;
  }
  .list-type-1--event .list-type-1__picture, .list-type-1--event .list-type-1__wrapper-date, .list-type-1--event .list-type-1__wrapper {
    display: block;
    vertical-align: inherit;
  }
  .list-type-1--event .list-type-1__picture img {
    margin-right: 0;
  }
  .list-type-1--event .list-type-1__wrapper-date {
    padding-right: 0;
    border-right: none;
    display: table;
    width: 100%;
    border-bottom: 1px solid #808f95;
    margin-bottom: 2em;
  }
  .list-type-1--event .list-type-1__wrapper-date .date-1, .list-type-1--event .list-type-1__wrapper-date .hour-place {
    width: 50%;
    display: table-cell;
    vertical-align: middle;
    padding-bottom: 2em;
  }
  .list-type-1--event .list-type-1__wrapper-date .date-1 {
    text-align: left;
  }
  .list-type-1--event .list-type-1__wrapper-date .date-1 time:nth-of-type(1) {
    margin-left: 0;
  }
  .list-type-1--event .list-type-1__wrapper {
    padding-left: 0;
  }
  .list-type-2.list-type-2--3col .list-type-2__listitems .list-paginated__wrapper > article {
    flex-basis: 100%;
  }
  .list-type-2.list-type-2--2col .list-type-2__listitems .list-paginated__wrapper > article {
    flex-basis: 100%;
  }
  .list-type-2__listitems .list-paginated__wrapper > article {
    flex-basis: 100%;
  }
  .list-type-3__listitems .list-paginated__wrapper {
    flex-direction: column;
    margin: 0;
  }
  .list-type-3__listitems .list-paginated__wrapper > article {
    flex: 0 1 100%;
    max-width: inherit;
    margin: 0 0 3em;
  }
  #pieChartTarget {
    float: none;
    margin: 0 auto;
  }
  .rte .list-pie-chart {
    width: 100%;
    float: none;
  }
  .logo {
    width: 17.6rem;
    bottom: -2rem;
  }
  .classical-gallery {
    position: relative;
    margin-left: -10px;
    margin-right: -10px;
  }
  .classical-gallery__title {
    padding: 0 10px;
  }
  .classical-gallery__nb-items {
    width: 5rem;
    height: 5rem;
    display: block;
    padding: 1.4rem 0;
    background: #00acec;
    color: #fff;
    position: absolute;
    z-index: 0;
    bottom: 0;
    right: 0;
    font-size: 1.6rem;
    font-weight: 700;
    margin: 0 !important;
    text-align: center;
  }
  .slider-galerie {
    margin-bottom: 0;
  }
  .slider-galerie__item {
    height: auto;
    display: none;
  }
  .slider-galerie__item:first-child {
    display: block;
  }
  .slider-galerie .infos-img {
    display: none;
    position: static;
    right: inherit;
    bottom: inherit;
    background: #000;
  }
  .slider-galerie a {
    display: inline-block;
    text-decoration: none;
    padding: 0;
  }
  .slider-galerie img {
    max-height: inherit;
    position: static;
    top: inherit;
    bottom: inherit;
    left: inherit;
    right: inherit;
    transform: inherit;
    display: inline;
  }
  .carousel-galerie__thumb, .carousel-galerie__pager {
    display: none;
  }
  .parallax {
    width: 100%;
    height: 50vw;
    background-attachment: inherit;
    background-size: 100% auto;
    background-repeat: no-repeat;
  }
  .go-so-far__wrapper {
    flex-wrap: wrap;
  }
  .go-so-far__wrapper > * {
    flex-basis: 100%;
  }
  .sitemap__listitems {
    flex-direction: column;
  }
  .sitemap__listitems > * {
    flex: 0 1 100%;
  }
  .subpages-menu__listitems {
    flex-direction: column;
  }
  .subpages-menu__listitems > * {
    flex-basis: 100%;
  }
  .telecharger {
    margin: 2rem 0;
  }
  .telecharger .list-document__listitems {
    padding: 3rem 1rem;
  }
  .telecharger .list-document__listitems > * {
    margin-left: 0;
    padding: 2.3rem 1rem 2.3rem 0;
    flex-basis: 100%;
  }
  .telecharger .list-document__listitems > *:nth-child(odd) {
    border-right: none;
  }
  .telecharger .list-document__listitems > *:nth-child(3) {
    border-bottom: 1px solid #fff;
  }
  .block-organigrame__wrapper {
    margin-left: 0;
    margin-right: 0;
  }
  .block-organigrame__wrapper .organigrame-item {
    width: 100%;
    margin: 1em 0;
  }
  .block-organigrame__wrapper .organigrame-item__img {
    min-width: 100px;
    margin-right: 1rem;
  }
  .agenda__wrap {
    flex-direction: column;
  }
  .agenda__wrap > * {
    width: 100%;
    margin: 0 auto;
  }
  .agenda-home .agenda__wrap {
    max-width: 100%;
  }
  .agenda-home .agenda__item {
    width: 100%;
    max-width: 100%;
  }
  .map-title--localiser,
  #map {
    display: none;
  }
  .map-btn-mobile {
    display: block;
  }
  .block-contact .list-type-3__item,
  .block-contact .list-infos__wrapper {
    flex-direction: column;
  }
  .block-contact .list-type-3__item .list-infos {
    padding: 1.5rem 2rem;
  }
  .site-infos {
    width: 50%;
    margin: 0 auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .site-infos__img-wrap {
    margin-right: 0;
    margin-bottom: 2.5rem;
  }
  .site-infos p.site-infos__contact .button-1 {
    margin-top: 2rem;
  }
  .newsletter {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .newsletter__fields {
    max-width: 100%;
  }
  .community {
    width: 80%;
    margin: 0 auto !important;
  }
  .bloc-publications {
    width: 100%;
  }
  .bloc-publications__item {
    flex-direction: column;
  }
  .bloc-publications__item > a {
    margin-bottom: 1rem;
  }
  .bloc-publications__item > a img {
    width: inherit;
    max-width: 100%;
    margin: 0 auto 0.5rem;
  }
  .bloc-publications__wrapper {
    margin-left: 0;
  }
  .bloc-publications .category {
    margin-bottom: 1rem;
  }
  .bloc-publications .list-document-1__file-size {
    display: block;
    margin-top: 0.5rem;
  }
  .news__wrap {
    flex-direction: column;
  }
  .news__wrap > * {
    width: 100%;
    margin: 0 auto;
  }
  .banner__item {
    height: auto;
  }
  .banner__item--video {
    display: none;
  }
  .banner__info {
    margin-top: -3.5rem;
    position: static;
  }
  .banner__info-wrap {
    width: 100%;
    padding: 1.1rem;
  }
  .banner__info-wrap p,
  .banner__info-wrap a {
    text-shadow: none;
  }
  .banner__info-wrap p {
    margin-bottom: 0.7rem;
    font-size: 1.3rem;
    background-color: #000;
    border-color: transparent;
  }
  .banner__info-wrap a {
    font-size: 1.8rem;
    color: #000;
  }
  .banner .pagerCarrousel1,
  .banner .modeplay,
  .banner .prevNextCarrousel1 {
    display: none;
  }
  .actus {
    /* Local variable */
  }
  .actus .news__wrap > * {
    width: 100%;
    max-width: 100%;
  }
  .actus--lg .actus__info {
    width: 100%;
  }
  .actus--lg .actus__info a h3 {
    font-size: 2rem;
  }
  .actus--lg .actus__info a h3:before {
    bottom: -6rem;
    left: -4rem;
  }
  .actus--lg .actus__info p {
    margin-top: 1.5rem;
    margin-left: 0;
  }
  .actus--lg .actus__info-wrap {
    padding: 2rem 2rem 3rem;
  }
  .actus--lg .actus__info-wrap:before {
    height: 2.5rem;
  }
  .travaux__wrap {
    flex-direction: column;
  }
  .travaux__icon {
    padding: 1.9rem;
    justify-content: center;
  }
  .travaux__info {
    width: 100% !important;
    padding: 2rem 2rem 8rem;
  }
  .travaux__info:before {
    content: none;
  }
  .travaux__info-item {
    justify-content: center;
    text-align: center;
  }
  .travaux__info-item a h3 {
    font-size: 1.5rem;
  }
  .travaux__info-item p {
    font-size: 1.3rem;
  }
  .travaux .prevNextCaroussel3 {
    top: inherit;
    bottom: 2rem;
    right: calc(50% + 2.5rem);
    transform: translate(50%, 0);
  }
  .travaux__plus {
    top: inherit;
    bottom: 2rem;
    right: calc(50% - 4.5rem);
    transform: translate(50%, 0);
  }
  .projets {
    /* Local variable */
  }
  .projets__wrap {
    flex-direction: column-reverse;
  }
  .projets__wrap .projets__info {
    width: 100%;
  }
  .projets__wrap .projets__info a h3 {
    font-size: 2rem;
  }
  .projets__wrap .projets__info a h3:before {
    bottom: -6rem;
    left: -4rem;
  }
  .projets__wrap .projets__info p {
    margin-top: 1.5rem;
    margin-left: 0;
  }
  .projets__wrap .projets__info-wrap {
    padding: 2rem 2rem 3rem;
  }
  .projets__wrap .projets__info-wrap:before {
    height: 2.5rem;
  }
  .kiosque__newsletter {
    max-width: 100%;
    margin-left: 0;
    padding: 3rem;
  }
  .kiosque__newsletter form {
    width: 100%;
  }
  .kiosque__newsletter h3 {
    font-size: 2rem;
  }
  .kiosque__newsletter h3 b {
    display: block;
  }
  .kiosque__newsletter .newsletter__links {
    margin-left: 0;
  }
  .kiosque__newsletter .newsletter__links li {
    display: inline-block;
    margin-top: 0.5rem;
    float: none;
  }
  .kiosque__newsletter-content:before, .kiosque__newsletter-content:after {
    right: -3rem;
    top: -3rem;
  }
  .kiosque__newsletter-icon {
    right: -2rem;
    top: -2rem;
  }
  .kiosque__newsletter-icon svg {
    width: 7.9rem;
    height: 7.8rem;
  }
  .kiosque .newsletter__fields {
    flex-direction: column;
  }
  .kiosque .newsletter__fields-wrapper {
    width: 100%;
    max-width: 100%;
    vertical-align: middle;
  }
  .kiosque__bottom {
    flex-direction: column;
    align-items: flex-start;
  }
  .enimages__wrap {
    width: 100%;
  }
  .enimages--sm .item {
    width: 100%;
  }
  .social__wrap {
    width: 100%;
  }
  .social .social-list-item {
    width: 100%;
  }
}
