.sidebar h2, .sidebar-title {
    font-family: $typo-1;
    color: $color-3--4;
    font-weight: $black;
    font-size: 1.8rem;
    padding-bottom: 2rem;
    text-transform: uppercase;
    margin-top: 0;
}
.sidebar h2 {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 1rem;
    &:not(:first-child):not(.sidebar-title) {
        margin-top: 3rem;
    }
    > *:first-child {
        margin-top: 0;
    }
}
.toc-title {
    display: none;
}
#barsContainer {
    > button {
        position: relative;
        padding: 2rem 2.5rem 1.8rem 2.5rem;
        display: block;
        width: 100%;
        border-top: 0.5rem solid $color-3--1;
        text-align: left;
        transition: all 250ms ease;
        white-space: normal;
        &.is-current {
            font-weight: $bold;
            > p {
                color: $color-2--1;
                font-weight: $bold;
            }
        }
        > p {
            font-size: 1.3rem;
            text-align: left;
            font-family: $typo-1;
            font-weight: $light;
            color: $color-3--3;
        }
    }
}
.toc-bar {
    position: absolute;
    left: 0;
    top: -.5rem;
    height: .5rem;
    background-color: $color-2--2;
}
.sidebar {
    #anchor-bar + .tools {
        margin-top: 3rem;
    }
}
