// @name Signature stratis
.stratis {
    background: transparent;
    width: 100%;
    padding-bottom: 3em;
    font-size: 1.2em;
    color: $color-white;
    text-align: right;
    text-transform: uppercase;
    font-family: $typo-2;
    font-weight: $light;
    a {
        display: inline-block;
        padding: 6px 10px;
        color: $color-white;
        background: #c70000;
        text-decoration: none;
        font-weight: $black;
        &:hover, &:focus {
            color: #A40015;
            background: #ffffff;
        }
    }
}
