// @name Document list
// @description List of document in publications list

.list-document__listitems {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 -1%;
    > * {
        flex: 1 1 31.3333%;
        margin: 0 1% 3em;
    }
    .button-1:not(.button-1--big) {
        padding-left: 2.5rem;
        padding-right: 0.7rem;
        &:before {
            left: 0.7rem;
        }
    }
}

.list-document__item {
    &.list-document__item--postulate {
        text-align: right;
        align-self: flex-end;
    }
}

.list-document__title-file {
    padding-left: 3.8rem;
    font-size: 1.4em;
    font-weight: $bold;
    margin-bottom: em(1.5, 1.4);
    overflow: hidden;
    word-break: break-all;
    line-height: 1.4;
    a {
        position: relative;
        padding-left: em(4, 1.4);
    }
    .list-document__file-size {
        display: block;
        font-weight: $light;
    }
    @include default-icons-absolute-before('\e005', 3.5rem, $color-2--2, 0.2rem, inherit, inherit, 0, 'icons-project');
    &:before {
        font-weight: $normal;
    }
    &.list-document__title-file--pdf {
        &:before {
            content: "\e003";
        }
    }
    &.list-document__title-file--doc {
        &:before {
            content: "\e001";
        }
    }
    &.list-document__title-file--jpg {
        &:before {
            content: "\e002";
        }
    }
    &.list-document__title-file--xls {
        &:before {
            content: "\e006";
        }
    }
    &.list-document__title-file--ppt {
        &:before {
            content: "\e004";
        }
    }
    &.list-document__title-file--zip {
        &:before {
            content: "\e007";
        }
    }
}

.list-document__download,
.list-document__read {
    &:before {
        font-size: 1.4rem;
        font-family: 'icons-project';
        font-weight: $bold;
    }
}

.list-document__download {
    &:before {
        content: "\E00B";
    }
}

.list-document__read {
    margin-left: 0.2rem;
    &:before {
        content: "\E00C";
    }
}

// @name list document type 1
// @description Liste in bloc
.list-document-1__listitems {
    $list-document-1__listitems__font-size: 1.2;
    margin: em(1.5, $list-document-1__listitems__font-size) 0 em(0.5, $list-document-1__listitems__font-size) 0;
    & + .list-document-1__listitems {
        padding: em(0.5, $list-document-1__listitems__font-size) 0 0;
    }
}

.list-document-1__item {
    display: inline-block;
    $list-document-1__item__font-size: 1.2;
    font-size: #{$list-document-1__item__font-size}em;
    text-transform: uppercase;
    font-weight: $bold;
    margin: em(0.5, $list-document-1__item__font-size) 0;
    color: $color-black;
    position: relative;
    z-index: 99;
    /*@include default-icons-before('\e063', 0 .5rem 0 0, 1.2rem, $color-2--2, -.2rem);
    a {
        color: $color-black;
        &:hover, &:focus {
            color: $color-1--2;
            text-decoration: underline;
        }
    }
    &.list-document-1__item--read {
        &:before {
            content: "\e06e";
        }
    }*/
    .button-1 {
        padding-left: 4rem;
        padding-right: 2rem;
    }
    &.list-document-1__item--download {
        margin-right: 0.3rem;
        .button-1 {
            &:before {
                font-family: 'icons-project';
                content: "\E00B";
                font-weight: $bold;
            }
        }
    }
    &.list-document-1__item--read {
        .button-1 {
            &:before {
                font-family: 'icons-project';
                content: "\E00C";
                font-weight: $bold;
            }
        }
    }
}

.list-document-1__file-size {
    font-size: 1.3rem;
    font-weight: $light;
    white-space: nowrap;
    font-style: italic;
    color: $color-3--6;
}

.open-data__file-size {
    color: $color-white;
}
