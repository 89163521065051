.logo {
    position: relative;
    margin-top: -1.2rem;
    bottom: -2.7rem;
    font-size: 1.3em;
    z-index: 15;
    a {
        display: block;
    }
}
