.go-to-top {
    display: block;
    position: relative;
    right: 1rem;
    bottom: 0;
    z-index: 7000;
    p {
        margin-top: -5.1em;
        position: absolute;
        right: 0;
        text-align: right;
    }
    a {
        width: 5.1rem;
        height: 5.1rem;
        background-color: $color-black;
        &:before {
            content: "\e025";
        }
    }
}
