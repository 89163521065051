.social {
    $color-social-fb: #3b5998;
    $color-social-tw: #00acee;
    &__wrap {
        margin: 0 -1.5rem 2rem;
    }
    .filter-social {
        display: flex;
        list-style: none;
        a {
            display: block;
            color: $color-white;
            height: 5.1rem;
            width: 5.1rem;
            margin: 0 0.1rem;
            text-align: center;
            line-height: 5.1rem;
            font-size: 1.6rem;
            transition: all 0.3s ease;
            background-color: $color-black;
            &:before {
                content: '\e099';
                font-weight: $normal;
                font-family: 'icons-default';
            }
            &:hover,
            &:focus {
                text-decoration: none;
                background-color: $color-3--4;
            }
        }
        .filter-social-facebook:before {
            content: "\e099";
        }
        .filter-social-twitter:before {
            content: "\e09b";
        }
        .filter-social-instagram:before {
            content: '\E00E';
            font-family: 'icons-project';
        }
    }
    .social-list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin: 0 0 1rem;
        padding: 0;
        list-style: none;
        overflow: hidden;
        a {
            color: $color-2--3;
            word-break: break-all;
        }
        &-item {
            width: 25%;
            padding: 0 1.5em 4em;
        }
        &-thumbnail {
            display: flex;
            justify-content: flex-start;
            position: relative;
            opacity: 1;
            transition: all 0.25s ease;
            &:before {
                position: absolute;
                left: 1rem;
                top: 1rem;
                z-index: 1;
                content: '';
                font-size: 1.6em;
                color: $color-white;
                font-family: 'icons-default';
            }
            &:after {
                width: 0;
                height: 0;
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                border-style: solid;
                border-width: 6rem 6rem 0 0;
                border-color: $color-2--2 transparent transparent transparent;
            }
            &:hover,
            &:focus {
                opacity: 0.85;
            }
            img {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
        &-facebook .social-list-thumbnail:before {
            content: '\e099';
        }
        &-twitter .social-list-thumbnail:before {
            content: '\e09b';
        }
        &-instagram .social-list-thumbnail:before {
            content: '\E00E';
            font-family: 'icons-project';
        }
        &-content {
            color: $color-black;
            &--noimg {
                margin-top: 5.5rem;
            }
        }
        &-head {
            padding: 2em 0 1em;
            display: block;
            @extend .clear-fix;
            &:hover {
                .social-list-head-title {
                    text-decoration: underline !important;
                }
            }
            &-ico {
                float: left;
                width: 42px;
                margin-right: 10px;
                border: 1px solid rgba(119, 119, 119, 0.5);
            }
            &-title {
                font-size: 1.4rem;
                display: block;
                margin: 0.15em 0;
                font-weight: $black;
                color: $color-black;
            }
            &-subtitle {
                font-size: 1.1rem;
                color: #717171;
            }
        }
        &-desc {
            font-size: 1.3em;
            font-weight: $light;
            margin: 0 0 1em;
        }
        .social-list-date {
            font-size: 1.1em;
            text-transform: uppercase;
            color: $color-2--3;
            margin: 11px 0 7px;
        }
    }
}
