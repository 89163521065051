.enimages {
    &__wrap {
        width: 85%;
        margin: 0 auto;
        flex-direction: column;
    }
    &--lg,
    &--sm {
        width: 100%;
    }
    .item {
        margin-bottom: 3.5rem;
        color: $color-black;
        &__img {
            padding: 0;
            height: auto;
            img {
                width: 100%;
                position: static;
                transform: translate(0, 0);
            }
        }
        &__content {
            width: 100%;
            padding-top: 1.5rem;
            position: static;
            opacity: 1;
            visibility: visible;
            transform: translate(0, 0);
            &-title {
                margin-top: 0.7rem;
            }
            .button-1 {
                display: none;
            }
        }
        &:after {
            content: none;
        }
        &:hover,
        &:focus {
            .item__content {
                transform: translate(0, 0);
                &-title {
                    text-decoration: underline;
                }
            }
        }
        &--video {
            &:hover,
            &:focus {
                .item__img {
                    &:before {
                        content: '\e027';
                    }
                }
            }
        }
    }
}
