.services {
    &__wrap {
        width: calc(100% - 12rem);
        margin: 4rem auto 4.5rem;
    }
    &__item {
        width: 100%;
    }
    .item {
        display: inline-flex!important;
        flex-wrap: wrap;
    }
    .prevNextCaroussel2 {
        display: block;
    }
}
