// @name Liste type 1
// @description Basic list
.list-type-1__item {
    padding: 4em 0;
}

.list-type-1__picture {
    float: none;
    margin-right: 0;
    margin-bottom: 2em;
    img {
        max-width: 100%;
        margin-right: 0;
    }
}
