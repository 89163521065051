.agenda {
    padding: 4.5rem 0 8rem;
    &__wrap {
        max-width: 70rem;
        margin: 3.5rem auto 0;
        > * {
            width: 50%;
            max-width: 28.9rem;
        }
    }
    &-home {
        .agenda {
            &__wrap {
                max-width: 85%;
                margin: 0 auto;
            }
            &__item {
                width: 50%;
                max-width: 50%;
            }
        }
    }
}
