// @name Main section heading
// @description Default css
$heading__h1__font-size: 3.5;
.heading {
    padding: 4em 0 6em;
    h1 {
        font-size: #{$heading__h1__font-size}em;
    }
}


// @name Heading single news
// @dependency .heading
.heading--single-news {
    .heading__picture {
        img {
            width: auto;
            max-width: 100%;
        }
    }
}
