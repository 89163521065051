.community {
    a {
        position: relative;
        display: block;
        padding: .2em 1.5em;
        color: $color-white;
        text-decoration: none;
        font-size: 1.2rem;
        font-weight: $light;
        text-transform: uppercase;
        @include default-icons-absolute-before('\e027', 1.1rem, $color-white, 0.3rem, inherit, inherit, 0);
    }
    img {
        display: block;
        width: 100%;
        max-width: 12.2rem;
        height: auto;
        margin-left: 3rem;
    }
}
