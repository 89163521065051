.enimages {
    margin: 6rem 0;
    &__wrap {
        display: flex;
        flex-wrap: wrap;
    }
    &--lg,
    &--sm {
        width: 50%;
    }
    &--sm {
        display: flex;
        flex-wrap: wrap;
        .item {
            width: 50%;
        }
    }
    .item {
        position: relative;
        display: block;
        color: $color-white;
        backface-visibility: hidden;
        &__img {
            width: 100%;
            height: 100%;
            padding-bottom: 73%;
            position: relative;
            overflow: hidden;
            img {
                width: auto;
                height: auto;
                min-height: 100%;
                max-width: 100%;
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
        &__content {
            width: 90%;
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 10;
            transform: translate(-50%, -45%);
            opacity: 1;
            visibility: hidden;
            text-align: center;
            white-space: normal;
            &-category {
                font-size: 1.3rem;
                font-weight: $light;
                text-transform: uppercase;
            }
            &-title {
                font-size: 1.8rem;
                font-weight: $black;
            }
            &-amount {
                margin-top: 0.5rem;
                font-size: 1.4rem;
                font-style: italic;
                font-weight: $light;
            }
            .button-1 {
                min-width: 17.3rem;
                height: 4rem;
                margin-top: 3rem;
                font-size: 1.2rem;
                font-weight: $bold;
                transition: background 0.3s ease;
            }
        }
        &--video {
            .item__img {
                @include default-icons-absolute-before('\e027', 1.6rem, $color-white, 50%, inherit, inherit, 50%, 'icons-default');
                &:before {
                    width: 4.7rem;
                    height: 4.7rem;
                    display: block;
                    z-index: 5;
                    transform: translate(-50%, -50%);
                    text-align: center;
                    line-height: 4.7rem;
                    @include background-opacity($color-black, 0.9);
                }
            }
        }
        &:after {
            width: 100%;
            height: 100%;
            content: '';
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            @include background-opacity($color-black, 0.5);
            opacity: 0;
            transition: all 0.25s ease;
        }
        &:hover,
        &:focus {
            text-decoration: none;
            .item__img {
                &:before {
                    content: none;
                }
            }
            &:after {
                opacity: 1;
            }
            .item__content {
                opacity: 1;
                visibility: visible;
                transform: translate(-50%, -50%);
            }
        }
    }
}
