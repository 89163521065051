// Newsletter inscription
.newsletter {
    &__fields {
        max-width: 30rem;
        position: relative;
    }
    &__title {
        margin-bottom: .5em;
        font-size: 1.8rem;
        font-weight: $black;
        color: $color-white;
        text-transform: uppercase;
    }
    label {
        font-size: 1.3rem;
        font-weight: $light;
        color: $color-white;
    }
    form {
        margin: 0;
    }
    input {
        width: 100%;
        min-height: 4.2rem;
        background: #191919;
        outline: none;
        color: $color-white;
        border: none;
        font-size: 1.3em;
        @include placeholder(){
            color: rgba(255, 255, 255, 0.5);
            font-size: 1.3rem;
            font-weight: $light;
            font-style: italic;
        }
    }
    fieldset {
        margin-bottom: .5em;
    }
    &__link {
        padding: 2rem 2rem 2rem 3.8rem;
        font-size: 1.2rem;
        font-weight: $black;
        background-color: $color-white;
        color: $color-black;
        &:before {
            color: $color-black;
            content: "\e057";
            left: 1.9rem;
        }
        &:hover,
        &:focus {
            background-color: $color-3--4;
            color: $color-white;
            &:before {
                color: $color-white;
            }
        }
    }
}
.newsletter__fields-wrapper, .newsletter__button-wrapper {
    vertical-align: bottom;
    button {
        width: 4.2rem;
        height: 4.2rem;
        padding: 0;
        background: #191919;
        border: none;
        outline: none;
        svg {
            fill: $color-white;
        }
    }
}
.newsletter__fields-wrapper {
    input {
        padding: 1.25rem 5rem 1.25rem 1rem;
    }
}
.newsletter__button-wrapper {
    position: absolute;
    bottom: 0;
    right: 0;
}
.newsletter__links {
    @include clearfix;
    li {
        float: left;
        margin-top: 1rem;
        margin-right: 2rem;
        position: relative;
        &:before {
            content: none;
        }
        a {
            font-size: 1.2rem;
            color: $color-white;
            font-weight: $light;
            padding-left: 15px;
            &:before {
                font-family: 'icons-default';
                color: $color-white;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                font-size: 1.2rem
            }
            &:hover,
            &:focus {
                color: $color-white;
                &:before,
                &:after {
                    color: $color-white;
                }
            }
        }
        &.newsletter__links-archives {
            a {
                &:before {
                    content: "\e03e";
                }
            }
        }
        &.newsletter__links-unsubscribe {
            a {
                &:before {
                    content: '\e058';
                }
            }
        }
    }
}
