// @name Title 1
// @description Styling for title 1
.title-1 {
    $title--1__font-size: 3;
    font-size: #{$title--1__font-size}em;
    &.title-decoration {
        span:first-child {
            &:after {
                content: none;
            }
        }
    }
}

// @name Title 3
// @description Styling for title 3
$title--3__font-size: 2;
.title-3 {
    margin-bottom: 2.5rem;
    font-size: #{$title--3__font-size}em;
}

// @name Title decortaion
// @description Styling for title decortaion
.title-decoration {
    &:before {
        content: none;
    }
    span:first-child {
        display: block;
        width: 80%;
        &:after {
            content: none;
        }
    }
    .btn-wrap {
        background-color: $color-white;
        padding-left: 1rem;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        > * {
            margin: 0 0.1rem;
        }
        .button-1--rss {
            width: 3.5rem;
            height: 3.5rem;
        }
    }
}
