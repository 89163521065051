.kiosque {
    &__newsletter {
        max-width: 100%;
        margin-left: 0;
        padding: 3rem;
        form {
            width: 100%;
        }
        h3 {
            font-size: 2rem;
            b {
                display: block;
            }
        }
        .newsletter__links {
            margin-left: 0;
            li {
                display: inline-block;
                margin-top: 0.5rem;
                float: none;
            }
        }
        &-content {
            &:before,
            &:after {
                right: -3rem;
                top: -3rem;
            }
        }
        &-icon {
            right: -2rem;
            top: -2rem;
            svg {
                width: 7.9rem;
                height: 7.8rem;
            }
        }
    }
    .newsletter__fields {
        flex-direction: column;
        &-wrapper {
            width: 100%;
            max-width: 100%;
            vertical-align: middle;
        }
    }
    &__bottom {
        flex-direction: column;
        align-items: flex-start;
    }
}
