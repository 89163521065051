.filters__single-directory {
    margin-bottom: 1rem;
    .filters__locate {
        &:before {
            content: "\e04d";
        }
    }

    .filters__event {
        &:before {
            content: "\e009";
        }
    }
}
