.header {
    padding: 0 1rem;
}

// @name Top of content
.top-of-content {
    display: none;
}

// @name Footer
.footer {
    .footer__wrapper-2 {
        margin: 0;
        display: block;
        > * {
            flex: inherit;
            margin: 0 0 2em 0;
        }
    }
    .menu-cross {
        display: flex;
        justify-content: center;
    }
}
