.projets {

    /* Local variable */
    $this: &;

    padding-bottom: 2rem;
    &__wrap {
        flex-direction: column-reverse;
        #{$this}__info {
            width: 94%;
            margin: 0 auto;
            position: static;
            transform: translateY(-4rem);
            a {
                h3 {
                    font-size: 3.4rem;
                }
            }
            &-wrap {
                padding: 3rem 3rem 6.5rem;
                &:before {
                    height: 4.5rem;
                }
            }
        }
    }
}
