// @name TYPO3 default content style
.ce-align-left {
    text-align: left;
}

.ce-align-center {
    text-align: center;
}

.ce-align-right {
    text-align: right;
}

.ce-table td, .ce-table th {
    vertical-align: top;
}

.ce-textpic, .ce-nowrap .ce-bodytext, .ce-gallery, .ce-row, .ce-uploads li, .ce-uploads div {
    overflow: inherit;
    &:after {
        clear: both;
        display: block;
        content: "";
        visibility: hidden;
        height: 0;
        speak: none;
    }
}

.ce-left .ce-gallery, .ce-column {
    float: left;
}

.ce-center .ce-outer {
    position: relative;
    float: right;
    right: 50%;
}

.ce-center .ce-inner {
    position: relative;
    float: right;
    right: -50%;
}

.ce-right .ce-gallery {
    float: right;
}

.ce-gallery figure {
    display: block !important;
    margin: 0;
}

.ce-gallery figcaption {
    padding-top: 10px;
    display: block !important;
}

.ce-gallery img {
    display: block;
}

.ce-gallery iframe {
    border-width: 0;
}

.ce-border img, .ce-border iframe {
    border: 2px solid black;
    padding: 0px;
}

.ce-intext.ce-right .ce-gallery, .ce-intext.ce-left .ce-gallery, .ce-above .ce-gallery {
    margin-top: 1rem;
    margin-bottom: 20px;
}

.ce-intext.ce-right .ce-gallery {
    margin-left: 30px;
}

.ce-intext.ce-left .ce-gallery {
    margin-right: 30px;
}

.ce-below .ce-gallery {
    margin-top: 10px;
}

.ce-column {
    margin-right: 10px;
}

.ce-column:last-child {
    margin-right: 0;
}

.ce-row {
    margin-bottom: 10px;
}

.ce-row:last-child {
    margin-bottom: 0;
}

.ce-above .ce-bodytext {
    clear: both;
}

.ce-intext.ce-left ol, .ce-intext.ce-left ul {
    padding-left: 40px;
    overflow: auto;
}

.ce-uploads {
    margin: 0;
    padding: 0;
}

.ce-uploads li {
    list-style: none outside none;
    margin: 1em 0;
}

.ce-uploads img {
    float: left;
    padding-right: 1em;
    vertical-align: top;
}

.ce-uploads span {
    display: block;
}

// @name TYPO3 text&media content blocks margin
.rte {
    .ce-textpic {
        img {
            max-width: 100%;
            height: auto;
        }
    }
}

.ce-gallery {
    position: relative;

    .ce-column {
        width: 100%;
    }

    .cookies-denied {
        @include responsive-ratio(260, 220, 'after');
        background-color: $color-3--2;
        border: 0;
        border-radius: 0;
        cursor: pointer;
        font-family: $typo-1;
        font-size: 1.4rem;
        padding: 0;
        position: relative;
        width: 100%;

        &::before {
            content: none;
        }

        &::after {
            @include relative();
        }

        span {
            @include absolute(50%, null, null, 50%);
            background-color: $color-green;
            border-radius: 4px;
            color: $color-white;
            display: inline-block;
            padding: 5px;
            transform: translate(-50%, -50%);
            white-space: normal;
            width: 90%;
        }
    }
}
