.projets {

    /* Local variable */
    $this: &;

    &__wrap {
        flex-direction: column-reverse;
        #{$this}__info {
            width: 100%;
            a {
                h3 {
                    font-size: 2rem;
                    &:before {
                        bottom: -6rem;
                        left: -4rem;
                    }
                }
            }
            p {
                margin-top: 1.5rem;
                margin-left: 0;
            }
            &-wrap {
                padding: 2rem 2rem 3rem;
                &:before {
                    height: 2.5rem;
                }
            }
        }
    }
}
