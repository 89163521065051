// @name Search box
// @description Search module
.menu-main-1 {
    .search-box {
        width: 90%;
        height: auto;
        padding-bottom: 3rem;
        margin: 12rem auto 0;
        position: static;
        background-color: transparent;
    }
    .search-box .search-box__fields-wrapper {
        border: 1px solid #cccccc;
    }
    .search-box__button-wrapper > button {
        width: 6.3rem!important;
        height: 6.3rem!important;
        margin-left: 0.5rem;
        background: transparent;
        border: 1px solid #cccccc;
        svg {
            stroke: $color-2--2;
        }
        &:hover,
        &:focus {
            svg {
                stroke: $color-3--4;
            }
        }
    }
    label {
        display: none!important;
    }
    input {
        padding: 1.45rem 1rem;
        border: none;

        @include placeholder() {
            font-size: 1.6rem;
            font-weight: $light;
            color: rgba(0, 0, 0, 0.7);
            text-overflow: ellipsis!important;
        }
    }
    .ddm {
        .ddm__sub-level {
            position: static;
            height: auto;
            right: 0;
            max-width: 100%;
            padding: 2.25rem 1rem;
        }
    }
}
