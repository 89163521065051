// @name Tools
// @description Tools on page and sharing on social media network
.tools {
    width: 100%;
    margin-top: 0;
    display: block;
    h2 {
        font-family: $typo-1;
        color: $color-3--4;
        font-weight: $black;
        font-size: 1.8rem;
        text-transform: uppercase;
        margin-bottom: 1rem;
    }
    svg {
        width: 1.6rem;
        height: 1.6rem;
        fill: $color-white;
        transition: all 0.25s ease;
    }
    .button-1 {
        &:before {
            content: "\e02d";
            color: $color-white;
            font-size: 1.4rem;
        }
        &:hover,
        &:focus {
            &::before {
                color: $color-white;
            }
        }
    }
}

.tools__listitems {
    margin: 0 -.5em;
    display: flex;
    flex-wrap: wrap;
}

.tools__item {
    padding: 0 .05em;
    > a,
    button {
        font-size: 0!important;
        width: 4rem;
        height: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color-2--2;
        transition: all 0.25s ease;
        .icon {
            color: $color-white;
            display: block;
            font-size: 1.6rem;
            &::before {
                display: block;
                content: '';
                font-family: 'icons-default';
                color: inherit;
                font-size: inherit;
                font-weight: normal;
                line-height: 1;
            }
            &-mail {
                &:before {
                    content: '\e028';
                }
            }
        }
        &:hover,
        &:focus {
            background-color: $color-2--3;
            svg {
                fill: $color-white;
            }
            .icon {
                color: $color-white;
            }
        }
    }
}

// @name Share page
.ddm.share-page {
    .ddm__sub-level {
        display: block;
        visibility: hidden;
        top: 100%;
        left: inherit;
        right: 0;
        overflow: hidden;
        z-index: 2;

    }
    .ddm__wrapper {
        transform: translateY(-110%);
        transition: all ease .1s;
    }
    &.ddm--active {
        .ddm__sub-level {
            visibility: visible;
        }
        .ddm__wrapper {
            transform: translateY(0);
            transition: all ease .4s;
        }
    }
}

.share-page {
    button {
        color: transparent;
        &:hover,
        &:focus {
            color: transparent;
        }
    }
    .ddm__sub-level {
        text-align: left;
        width: 13em;
    }
    .ddm__wrapper {
        background: $color-2--3;
        color: color-contrast($color-2--3);
        padding: 1em 1.5em;
        a {
            color: $color-white;
        }
    }
}



.share-page__item {
    font-size: 1.3em;
    margin: .5em 0;
    @include default-icons-before('\e098', 0 5px 0 0, 1.4rem, $color-white, -.2rem);
    &.email {
        &:before {
            content: "\e028";
        }
    }
    &.facebook {
        &:before {
            content: "\e098";
        }
    }
    &.twitter {
        &:before {
            content: "\e09a";
        }
    }
    &.google {
        &:before {
            content: "\e0a4";
        }
    }
    &.instagram {
        &:before {
            content: "\e0a1";
        }
    }
    &.linkedin {
        &:before {
            content: "\e0a2";
        }
    }
    &.viadeo {
        &:before {
            content: "\e0a3";
        }
    }
    &.youtube {
        &:before {
            content: "\e09d";
        }
    }
}
