.news {
    &__wrap {
        max-width: 70rem;
        margin: 3.5rem auto 0;
        > * {
            width: 50%;
            max-width: 28.9rem;
        }
    }
}
