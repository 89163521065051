// @name Progress bar
.progress {
    meter {
        float: left;
        margin: 4px 10px 0 0;
        width: 200px;
        height: 10px;
    }
}

meter {
    /* Reset the default appearance */
    //-moz-appearance: none;
    //appearance: none;
    /* For Firefox */
    background: #EEE;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2) inset;
    border-radius: 3px;
    border: 1px solid #fff;
    /* For Webkit */
}

meter::-moz-meter-bar {
    border-radius: 3px;
    background: #00acec;
}

meter::-webkit-meter-bar {
    background: #EEE;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2) inset;
    border-radius: 3px;
    border: 0;
    height: 10px;
}

/* For Webkit */
meter::-webkit-meter-optimum-value,
meter::-webkit-meter-suboptimum-value,
meter::-webkit-meter-even-less-good-value {
    border-radius: 3px;
    background: #00acec;
}
