// @name bloc publications

.bloc-publications {
    margin: 3.5rem 0 4rem;
    &__wrapper {
        margin-left: 2rem;
        padding-top: 0.7rem;
        overflow: hidden;
    }
    &__title {
        $bloc-publications__title__font-size: 3;
        font-size: #{$bloc-publications__title__font-size}rem;
        font-family: $typo-1;
        font-weight: $light;
        margin: 0.3rem 0 0;
        color: $color-black;
    }
    &__item {
        display: flex;
        > a {
            min-width: 16.4rem;
            display: block;
            img {
                display: block;
                width: 100%;
                height: auto;
                border: 1px solid #e7e7e7;
            }
        }
    }
    h3 {
        line-height: 1;
    }
    p {
        font-size: 1.4rem;
    }
    .category {
        color: $color-2--3;
        font-size: 1.6rem;
    }
    .list-document-1__file-size {
        & + p {
            margin-top: 2rem;
        }
    }
    .button-1 {
        padding-top: 1.6rem;
        padding-bottom: 1.6rem;
        font-size: 1.2rem;
    }
    .btn-wrap {
        .button-1--plus {
            width: 3.5rem;
            height: 3.5rem;
        }
    }
}

.column-bloc {
    .bloc-publications__title {
        $bloc-publications__title__font-size: 1.6;
        font-size: #{$bloc-publications__title__font-size}em;
        margin: 0 0 em(.8, $bloc-publications__title__font-size);
    }
}
