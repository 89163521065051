// @name Teaser 2
// @description Teaser in heading
.teaser-2 {
    font-size: 1.6em;
    line-height: 1.2em;
}

// @name Date 1
// @description Date in event
.date-1 {
    time {
        margin: 0;
    }
}
