.map-btn-mobile {
    width: 100%;
    padding: 1.3rem 0;
    display: none;
    color: $color-white;
    background-color: $color-black;
    font-size: 2rem;
    font-weight: $black;
    text-align: center;
    transition: background 0.3s ease;
    &:before {
        margin-right: 1rem;
        content: '\e04d';
        vertical-align: middle;
        color: $color-2--2;
        font-size: 3.4rem;
        font-family: 'icons-default';
    }
    &:hover,
    &:focus {
        background-color: $color-3--4;
        text-decoration: none;
    }
}
