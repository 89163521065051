.travaux {
    &__info {
        &-item {
            a {
                h3 {
                    font-size: 1.3rem;
                }
            }
            p {
                font-size: 1.1rem;
            }
        }
    }
}
