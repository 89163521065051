.services {
    margin: 4rem 0 4.5rem;
    position: relative;
    &__wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    &__item {
        width: calc(20% - 0.2rem);
        min-height: 16.2rem;
        margin: 0.1rem;
        padding: 3rem 1.7rem 1rem;
        display: block;
        text-align: center;
        border: 1px solid #d5d5d5;
        transition: all 0.3s ease;
        svg {
            max-width: 5.3rem;
            max-height: 5.4rem;
            fill: $color-2--2;
            transition: all 0.3s ease;
        }
        p {
            margin-top: 1.5rem;
            font-size: 1.6rem;
            color: $color-black;
            word-break: break-word;
            white-space: normal;
            transition: all 0.3s ease;
        }
        &:hover,
        &:focus {
            background-color: $color-2--3;
            border-color: $color-2--3;
            text-decoration: none;
            svg {
                fill: $color-white;
            }
            p {
                color: $color-white;
            }
        }
    }
    .prevNextCaroussel2 {
        display: none;
    }
    .prevCaroussel2,
    .nextCaroussel2 {
        position: absolute;
        top: 50%;
        button {
            width: 3.5rem;
            height: 3.5rem;
            background-color: $color-2--2;
            transition: all 0.3s ease;
            span {
                width: 100%;
                height: 100%;
                display: block;
                position: relative;
                text-indent: -9999px;
                overflow: hidden;
                &:before {
                    text-indent: 0;
                    font-family: 'icons-default';
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    color: $color-white;
                    font-size: 1.6rem;
                }
            }
            &.disabled {
                background-color: $color-3--4;
            }
        }
        &:hover,
        &:focus {
            button {
                background-color: $color-3--4;
            }
        }
    }
    .prevCaroussel2 {
        left: 1rem;
        button {
            span {
                &:before {
                    content: '\e016';
                }
            }
        }
    }
    .nextCaroussel2 {
        right: 1rem;
        button {
            span {
                &:before {
                    content: '\e017';
                }
            }
        }
    }
}
