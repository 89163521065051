// @name site informations
.footer {
    .site-infos {
        flex-grow: 0.9;
        flex-shrink: 0.9;
    }
}

.site-infos {
    &__img-wrap {
        width: 12.5em;
        margin-top: -3rem;
        padding: 2rem .8rem;
        float: left;
        margin-right: 2em;
        background-color: $color-white;
        img {
            width: 100%;
            height: auto;
        }
    }
    &__wrapper {
        overflow: hidden;
    }
    p, ul {
        color: $color-white;
        font-size: 1.3em;
        margin: .6rem 0 .9rem;
        &:first-child {
            margin-top: 0;
        }
    }
    p {
        font-size: 1.3rem;
        font-weight: $light;
        text-transform: uppercase;
        font-style: normal;
        &.site-infos__schedule,
        &.site-infos__phone {
            text-transform: none;
        }
        &.site-infos__contact {
            .button-1 {
                padding: 2rem 2rem 2rem 3.8rem;
                font-size: 1.2rem;
                font-weight: $black;
                background-color: $color-white;
                color: $color-black;
                &:before {
                    content: '\e02a';
                    color: $color-black;
                    left: 1.9rem;
                }
                &:hover,
                &:focus {
                    background-color: $color-3--4;
                    color: $color-white;
                    &:before {
                        color: $color-white;
                    }
                }
            }
        }
    }
    ul {
        > li {
            margin: 0.2em 0;
        }
    }
    a {
        color: $color-white;
    }
}
