// @name Pager list
.pager {
    width: 100%;
    ul {
        font-family: $typo-2;
        clear: both;
        display: block;
        margin: 3em 0 5em 0;
        padding: 0;
        text-align: center;
        font-weight: $light;
        font-style: normal;
        text-transform: uppercase;
    }
    li {
        display: inline-block;
        vertical-align: middle;
        margin: 0 em(0, 1.4);
        padding: 0;
        background: none;
        font-size: 1.2rem;
        font-weight: $bold;
        a, span {
            padding: 0.7rem 1.36rem;
            line-height: 2rem;
            display: inline-block;
            vertical-align: middle;
        }
        a {
            color: $color-white;
            background: $color-2--3;
            text-decoration: none;
            transition: all ease .2s;
            &:hover, &:focus {
                background: $color-3--4;
            }
        }
        span {
            cursor: default;
            color: $color-black;
            background: $color-2--2;
        }
        &.pager__prev, &.pager__next {
            margin-top: -0.4rem;
            a, span {
                min-width: 14rem;
                padding: 1rem 2.68rem;
            }
            // Inactive
            span {
                color: color-contrast($color-3--1);
                background: $color-3--1;
            }
        }
        &.pager__prev {
            float: left;
            a, span {
                float: left;
                @include default-icons-before('\e026', 0 .5rem 0 0, 1.1rem, $color-white, -.1rem);
                &:hover:before, &:focus:before {
                    color: #fff;
                }
            }
            span {
                color: $color-3--2;
                // Inactive
                &:before, &:hover:before, &:focus:before {
                    color: $color-3--2;
                }
            }
        }
        &.pager__next {
            float: right;
            a, span {
                float: right;
                @include default-icons-after('\e027', 0 0 0 .5rem, 1.1rem, $color-white, -.1rem);
                &:hover:after, &:focus:after {
                    color: #fff;
                }
            }
            span {
                color: $color-3--2;
                // Inactive
                &:after, &:hover:after, &:focus:after {
                    color: $color-3--2;
                }
            }
        }
    }
}

// @name Pager infinite scroll

@keyframes spin {
    0% {
        transform: rotate(0deg) translateY(-50%);
    }
    100% {
        transform: rotate(-360deg) translateY(-50%);
    }
}

.pager-infinite {
    margin: 2em 0;
    text-align: center;
    // @dependence .button-1
    .pager-infinite__button {
        &:before {
            content: "\e05f";
        }
    }
    .pager-infinite__loading {
        &:before {
            animation: spin 1s infinite linear;
            content: "\e05f";
            transform-origin: top;
        }
    }
}

// @name Pager single
// @dependence .pager
.pager-single {
    .pager-single__back-to-list {
        a {
            background: $color-2--2;
            color: $color-black;
            font-weight: $bold;
            padding: em(1, 1.4) em(2.4, 1.4);
            @include default-icons-before('\e02f', 0 .5rem 0 0, 1.2rem, $color-black, -.1rem);
            &:before {
                transition: all ease .2s;
            }
            &:hover,
            &:focus {
                color: $color-white;
                &:before {
                    color: $color-white;
                }
            }
        }
    }
}

// @name Pager single title
// TODO

// @name Pager alphabet
.pager-alphabet {
    margin-bottom: 5em;
}

.pager-alphabet__title {
    font-size: 1.4em;
    font-weight: $bold;
    margin-bottom: em(0.5, 1.4);
}

.pager-alphabet__listitems {
    text-align: left;
    > li {
        display: inline-block;
        vertical-align: middle;
        margin: .2em 0.1em;
        text-align: center;
        &.pager-alphabet__item--current {
            > a {
                background: $color-2--2;
                color: $color-black;
            }
        }
        &.pager-alphabet__item--tous,
        &.pager-alphabet__item--number {
            a {
                width: 6.6rem;
            }
        }
        > a, > span {
            width: 3rem;
            height: 3rem;
            $pager-alphabet__listitems__a__font-size: 1.2;
            font-size: #{$pager-alphabet__listitems__a__font-size}em;
            font-weight: $bold;
            background: $color-2--3;
            color: $color-white;
            display: block;
            padding: em(.7, $pager-alphabet__listitems__a__font-size) 0;
        }
        > a {
            &:hover, &:focus {
                background: $color-3--4;
                color: $color-white;
                text-decoration: none;
            }
        }
        > span {
            background: $color-white;
            border: 1px solid darken($color-3--1, 3%);
            color: darken($color-3--1, 15%);
        }
    }
}

// @name Pager single xl
.pager-single-xl {
    width: 100%;
    margin-bottom: 13em;
    ul {
        font-family: $typo-2;
        clear: both;
        display: block;
        margin: 3em 0 5em 0;
        padding: 0;
        text-align: center;
        font-weight: $light;
        font-style: normal;
        text-transform: uppercase;
        position: relative;
        @extend .clear-fix;
    }
    li {
        display: inline-block;
        vertical-align: middle;
        margin: 0 em(0, 1.4);
        padding: 0;
        background: none;
        font-size: 1.2rem;
        font-weight: $bold;
        &.pager__prev, &.pager__next {
            width: 49%;
            .pager__button {
                //padding: 1rem 2.4rem;
            }
            a.pager__link {
                &:hover, &:focus {
                    .pager__button {
                        &:before, &:after {
                            color: $color-white;
                        }
                    }
                }
            }
            span.pager__link {
                .pager__button {
                    color: color-contrast($color-3--1);
                    background: $color-3--1;
                }
            }
        }
        &.pager__prev {
            float: left;
            .pager__button {
                @include default-icons-before('\e026', 0 .5rem 0 0, 1.2rem, $color-white, -.1rem);
            }
            .pager__link {
                &:hover, &:focus {
                    color: $color-white;
                }
            }
            span.pager__link {
                .pager__button {
                    color: $color-3--2;
                }
                .pager__button:before, .pager__button:hover:before, .pager__button:focus:before {
                    color: $color-3--2;
                }
            }
            .pager__article {
                text-align: left;
                padding-left: 1rem;
            }
        }
        &.pager__next {
            float: right;
            .pager__button {
                @include default-icons-after('\e027', 0 0 0 .5rem, 1.2rem, $color-white, -.1rem);
            }
            .pager__link {
                justify-content: flex-end;
                &:hover, &:focus {
                    color: $color-white;
                }
            }
            span.pager__link {
                .pager__button {
                    color: $color-3--2;
                }
                .pager__button:after, .pager__button:hover:after, .pager__button:focus:after {
                    color: $color-3--2;
                }
            }
            .pager__article {
                text-align: right;
                padding-right: 1rem;
            }
        }
    }
    .pager__link {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: flex-start;
    }
    a.pager__link {
        &:hover, &:focus {
            text-decoration: none;
            > .pager__button {
                background: $color-3--4;
            }
        }
        > .pager__button {
            color: $color-white;
            background: $color-2--3;
            text-decoration: none;
            transition: all ease .3s;
        }
        span.pager__link {
            color: color-contrast($color-1--2);
            background: $color-1--2;
        }
    }
    .pager__button {
        padding: 1.35rem 2.4rem;
        line-height: 1em;
        display: inline-block;
        vertical-align: middle;
        white-space: nowrap;
    }
    .pager__article {
        text-transform: none;
        font-size: 1.4rem;
        color: $color-3--3;
        line-height: normal;
        font-weight: $light;
        font-style: italic;
        display: inline-block;
        vertical-align: middle;
    }
    .pager-single-xl__back-to-list {
        position: absolute;
        top: 140%;
        left: 50%;
        transform: translateX(-50%);
        a.pager__link {
            display: inline-block;
            &:hover, &:focus {
                .pager__button {
                    color: $color-white;
                    background: $color-3--4;
                    &:before {
                        color: $color-white;
                    }
                }
            }
            .pager__button {
                background: $color-2--2;
                color: $color-black;
                font-weight: $bold;
                padding: em(1, 1.4) em(2.4, 1.4);
                @include default-icons-before('\e02f', 0 .5rem 0 0, 1.2rem, $color-black, -.1rem);
                &:before {
                    transition: all 0.2s ease;
                }
            }
        }
    }
}

