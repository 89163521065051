.albums {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 -1%;
    &-item {
        width: 31.3333%;
        margin: 0 1% 3em;
        a {
            position: relative;
            display: block;
            &::before {
                content: '\e075';
                color: #fff;
                font-family: 'icons-default';
                text-align: center;
                font-size: 2em;
                background-color: $color-2--2;
                position: absolute;
                display: block;
                width: 47px;
                height: 47px;
                line-height: 47px;
                left: 50%;
                top: 50%;
                -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                border-radius: 50%;
                z-index: 5;
            }
        }
    }
}
