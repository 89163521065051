.menu-main-1__sublevel {
    display: none;
    &[aria-hidden="false"] {
        display: block;
    }
}

//
.menu-main-1__item--no-action-in-desktop {
    .menu-main-1__sublevel {
        display: block;
    }
}

/* Styles */

//All level

.menu-main-1 {
    @extend .wrapper-main;
    &__wrapper {
        margin: 0 auto;
    }
    &__item {
        > .menu-main-1__header {
            a {
                display: block;
            }
        }
        > .menu-main-1__sublevel {

        }
        //&.menu-main-1__item--current {
        //    text-decoration: underline;
        //}
    }
    &__closing-button {
        text-align: left;
        text-indent: -9999px;
        white-space: nowrap;
        position: absolute;
        top: 3.6rem;
        right: 3rem;
        width: 30px;
        height: 30px;
        background: transparent;
        &:before {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%);
            content: "\e002";
            color: $color-white;
            font-family: 'icons-default';
            font-size: 1.4rem;
            font-weight: $bold;
            text-indent: 0;
            line-height: 0;
            display: block;
            margin: 0;
        }
    }
}

// Level 0 (displayed in tablet and smartphone)
.menu-main-1__listitems-0 {
    > li {
        > .menu-main-1__header {
            display: none;
        }
        > .menu-main-1__sublevel {
            display: block;
            > .menu-main-1__closing-button {
                display: none;
            }
        }
    }
}

// Level 1
.menu-main-1__listitems-1 {
    display: flex;
    justify-content: space-around;
    > li {
        display: inline-block;
        vertical-align: middle;
        > .menu-main-1__header {
            > a {
                font-size: 1.6rem;
                color: $color-black;
                text-transform: uppercase;
                padding: 3rem 3rem 3rem 4.5rem;
                text-decoration: none;
                font-weight: $light;
                transition: all 0.3s ease;
                b {
                    display: block;
                    position: relative;
                    font-weight: $black;
                    &:before {
                        height: 3rem;
                        width: 0.2rem;
                        content: '';
                        position: absolute;
                        left: -1rem;
                        top: 0;
                        background-color: $color-2--2;
                        transform: rotate(-35deg);
                        transition: all 0.3s ease;
                    }
                }
                &:hover,
                &:focus {
                    background-color: #cfe3ec;
                }
            }
        }
        > .menu-main-1__sublevel {
            display: block;
            position: absolute;
            z-index: 10;
            top: 100%;
            left: 0;
            right: 0;
            width: 100%;
            max-height: 0;
            visibility: hidden;
            overflow: hidden;
            transition: visibility ease .2s, max-height ease .2s;
            .menu-main-1__listitems-1__wrapper {
                padding: 4.5rem 0 4rem;
                transform: translateY(-110%);
                transition: transform ease-in .2s;
                height: 100%;
                background: $color-black url("../Images/temp/menu-bg.png") no-repeat 97% 167%;
            }
            // level 1 Displayed
            &[aria-hidden="false"] {
                visibility: visible;
                max-height: 1000px;
                .menu-main-1__listitems-1__wrapper {
                    transform: translateY(0);
                    transition: transform ease-out .4s .2s;
                }
            }
            a, button, p {
                color: $color-white;
                &:focus {
                    outline-color: $color-white;
                }
            }
        }
        &.menu-main-1__item--active {
            > .menu-main-1__header {
                > a {
                    color: $color-white;
                    background-color: $color-black;
                    b {
                        &:before {
                            background-color: $color-white;
                        }
                    }
                }
            }
        }
    }
    .wrapper-main--home {
        position: relative;
    }
}

// Level 2
.menu-main-1__listitems-2 {
    width: 30.5rem;
    padding: 7rem 0 5.5rem;
    position: relative;
    height: auto;
    transition: height ease .3s;
    > li {
        display: block;
        text-align: right;
        // Level 2 and 3
        .menu-main-1__header {
            display: inline-block;
            position: relative;
            transition: all 0.2s ease;
            .menu-main-1__action {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                text-align: left;
                text-indent: -9999px;
                white-space: nowrap;
                width: 3rem;
                background: transparent;
                overflow: hidden;
                flex-basis: 3rem;
                &:before {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%);
                    content: "\e017";
                    font-size: 1.3em;
                    font-family: 'icons-default';
                    font-weight: $bold;
                    text-indent: 0;
                    line-height: 0;
                    display: block;
                    margin: 0;
                }
                &[aria-expanded="true"] {
                    &:before {
                        content: "\e016"
                    }
                }
            }
            &:hover,
            &:focus {
                background-color: $color-3--4;
            }
        }
        > .menu-main-1__header {
            a {
                display: block;
                padding: 0.8rem 3rem 0.8rem 1.5rem;
                text-transform: uppercase;
                font-size: 1.6rem;
                font-weight: $light;
                &:hover,
                &:focus {
                    text-decoration: none;
                }
            }
        }
        > .menu-main-1__sublevel {
            height: 100%;
            display: block;
            position: absolute;
            left: 100%;
            top: 0;
            padding: 7rem 0 5.5rem 2.5rem;
            width: 30.5rem;
            visibility: hidden;
            transform: scaleX(0);
            transform-origin: 0 0;
            transition: transform ease .2s, visibility ease 0s .2s;
            background: $color-3--4;
            &[aria-hidden="false"] {
                visibility: visible;
                transform: scaleX(1);
                transition: transform ease .4s, -webkit-transform ease .4s;
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
        &.menu-main-1__item--active {
            .menu-main-1__header {
                background-color: $color-3--4;
            }
        }
    }
    .menu-main-1__closing-button {
        display: none;
    }
}

// Level 3
.menu-main-1__listitems-3 {
    > li {
        text-align: left;
        > .menu-main-1__header {
            display: block;
            @include default-icons-absolute-before('\e027', 1rem, $color-white, 1.2rem, inherit, inherit, 1rem, 'icons-default');
            transition: all 0.2s ease;
            a {
                padding: 0.8rem 3rem 0.8rem 2.5rem;
                font-size: 1.6rem;
                font-weight: $bold;
            }
            &:hover,
            &:focus {
                background-color: $color-black;
                a {
                    text-decoration: none;
                }
            }
        }
        > .menu-main-1__sublevel {
            height: 100%;
            padding: 7rem 0 5.5rem 2.5rem;
            margin-left: 0.2rem;
            display: block;
            position: absolute;
            left: 100%;
            top: 0;
            width: 30.5rem;
            visibility: hidden;
            transform: scaleX(0);
            transform-origin: 0 0;
            transition: transform ease .2s, visibility ease 0s .2s;
            background: transparent;
            border: 1px solid $color-3--4;
            &[aria-hidden="false"] {
                transition: transform ease .4s, visibility ease 0s 0s;
                visibility: visible;
                transform: scaleX(1);
            }
        }
        &.menu-main-1__item--active {
            .menu-main-1__header {
                background-color: $color-black;
            }
        }
    }
}

// Level 4
.menu-main-1__listitems-4 {
    > li {
        > .menu-main-1__header {
            display: block;
            @include default-icons-absolute-before('\e027', 1rem, $color-white, 1.2rem, inherit, inherit, 1rem, 'icons-default');
            a {
                padding: 0.8rem 3rem 0.8rem 2.5rem;
                font-size: 1.6rem;
                font-weight: $light;
                transition: all 0.2s ease;
            }
            &:hover,
            &:focus {
                a {
                    font-weight: $bold;
                }
            }
        }
    }
}

.menu-info {
    width: 30.5rem;
    padding-left: 2.5rem;
    position: absolute;
    right: 0;
    top: 0;
    color: $color-white;
    &__header {
        margin-bottom: 1rem;
        font-size: 2rem;
        font-weight: $light;
        b {
            font-weight: $black;
        }
    }
    &__img {
        display: block;
        margin-bottom: 1.5rem;
        img {
            width: 100%;
            height: auto;
        }
    }
    &__category {
        display: inline-block;
        margin-bottom: 1rem;
        padding: 0.85rem 1.2rem;
        font-size: 1.2rem;
        text-transform: uppercase;
        font-weight: $light;
        border: 1px solid $color-white;
    }
    &__title {
        font-size: 1.8rem;
        font-weight: $black;
    }
    &__text {
        margin-bottom: 1.1rem;
        font-size: 1.5rem;
        font-weight: $light;
    }
    &__link {
        position: relative;
        padding-left: 1.5rem;
        font-size: 1.6rem;
        font-weight: $light;
        @include default-icons-absolute-before('\e027', 1rem, $color-white, 0.5rem, inherit, inherit, 0, 'icons-default');
    }
}
