.kiosque {
    padding: 3rem 0;
    &__wrap {
        align-items: flex-start;
        display: flex;
    }
    .list-document-1__file-size {
        position: relative;
        &:before {
            content: '';
            width: 3.2rem;
            height: 0.1rem;
            background-color: $color-2--2;
            position: absolute;
            bottom: -1.2rem;
            left: 0;
        }
    }
    .bloc-publications {
        margin: 0 auto 4rem;
        flex: 1;
        &__wrapper {
            display: flex;
        }
        &__item {
            > a {
                min-width: 25rem;
                img {
                    width: 100%;
                    max-width: 100%;
                }
            }
        }
    }
    .list-document-1__listitems {
        margin-top: 4rem;
        padding-left: 3rem;
        .button-1 {
            width: 11.2rem;
            height: 10rem;
            padding: 0;
            display: block;
            overflow: hidden;
            font-weight: $bold;
            &:before {
                left: 50%;
                top: 2.5rem;
                transform: translateX(-50%);
                font-size: 2.5rem;
            }
            span {
                width: 100%;
                height: 100%;
                padding-top: 3.5rem;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                text-align: center;
                &:before,
                &:after {
                    height: 2rem;
                    width: 0.1rem;
                    content: '';
                    position: absolute;
                    background-color: $color-white;
                    transform: rotate(-35deg);
                }
                &:before {
                    top: -0.3rem;
                    left: 2rem;
                }
                &:after {
                    bottom: -0.3rem;
                    right: 4rem;

                }
            }
        }
    }
    &__newsletter {
        max-width: 40rem;
        margin-left: 11rem;
        padding: 4rem;
        background-color: $color-3--1;
        form {
            margin-bottom: 0;
        }
        h3 {
            font-size: 2.3rem;
            font-weight: $light;
            text-transform: uppercase;
            position: relative;
            z-index: 1;
            b {
                display: block;
                font-weight: $black;
            }
        }
        p {
            font-size: 1.4rem;
            font-weight: $light;
            position: relative;
            z-index: 1;
        }
        &-content {
            padding-right: 8rem;
            position: relative;
            &:before {
                width: 0;
                height: 0;
                content: '';
                position: absolute;
                right: -4rem;
                top: -4rem;
                border-style: solid;
                border-width: 0 16rem 16rem 0;
                border-color: transparent $color-2--2 transparent transparent;
            }
            &:after {
                width: 0;
                height: 0;
                content: '';
                position: absolute;
                right: -4rem;
                top: -4rem;
                border-style: solid;
                border-width: 16rem 0 0 16rem;
                border-color: transparent transparent transparent $color-3--1;
            }
        }
        &-icon {
            position: absolute;
            right: -2.5rem;
            top: -3.5rem;
            svg {
                width: 8.9rem;
                height: 8.8rem;
                transform: rotate(-10deg);
                fill: $color-white;
                stroke: $color-white;
                stroke-width: 5px;
            }
        }
        .kiosque__bottom {
            margin-top: 2.5rem;
        }
        .newsletter__button-wrapper {
            position: static;
            .button-1 {
                width: 100%;
                height: auto;
                padding: 1.8rem;
                background-color: $color-2--3;
                font-weight: $bold;
                font-size: 1.6rem;
                &:before {
                    transform: translate(0);
                    position: static;
                    margin-right: 1rem;
                }
                &:hover,
                &:focus {
                    background-color: $color-3--4;
                }
            }
        }
        .newsletter__links {
            li {
                a {
                    color: $color-black;
                    &:before {
                        color: $color-2--2;
                    }
                }
            }
        }
    }
}
