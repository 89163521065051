.ddm.ddm__content + .ddm.ddm__content {
    margin-top: -3.9em;
}

.ddm.ddm__content {
    margin: 4em 0 4.1em 0;
    .ddm__title {
        font-size: 1em;
        margin-bottom: 0;
    }
    .ddm__sub-level {
        position: static;
        top: inherit;
        right: inherit;
        z-index: inherit;
        display: block;
        height: auto;
        overflow: hidden;
        max-height: 0;
        //transition: all ease .2s;
    }
    &.ddm--active {
        .ddm__sub-level {
            padding: 1em 0;
            max-height: 100%;
            //transition: all ease-in-out 1s;
        }
    }
}

.ddm__content {
    .ddm__button {
        display: block;
        width: 100%;
        text-align: left;
        font-size: 2rem;
        font-family: $typo-3;
        font-weight: $light;
        color: $color-2--3;
        background: transparent;
        padding: em(1, 2) em(4, 2) em(0.8, 2) em(1, 2);
        position: relative;
        transition: all ease .3s;
        white-space: normal;
        border-bottom: 5px solid $color-2--3;
        &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 40px;
            background: transparent;
            z-index: 1;
        }
        @include default-icons-absolute-before('\e024', 1.1rem, $color-2--2, 50%, 1.5rem, inherit, inherit);
        &:before {
            z-index: 2;
            transform: translateY(-50%);
        }
    }
    &.ddm--active {
        .ddm__button {
            background: $color-black;
            color: $color-white;
            font-weight: $bold;
            border-bottom-color: $color-black;
            &:before {
                content: "\e025";
            }
        }
    }
}
