// @name Go so far
// @description Sections content below the content

.go-so-far {
    background: $color-3--1;
    padding: 7em 0;
}

.go-so-far__title {
    margin-top: 0;
}

.go-so-far__wrapper {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    margin: 0 -2%;
    > * {
        flex: 1;
        margin: 0 2%;
        &:last-child {
            flex: 2;
        }
    }
}

.go-so-far__item {

}
