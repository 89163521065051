.travaux {

    $btn__width: 4.5;

    margin-bottom: 4rem;
    &__wrap {
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
    }
    &__icon {
        padding: 3.5rem 10rem 3rem 2rem;
        display: flex;
        align-items: center;
        color: $color-white;
        background-color: $color-black;
        img {
            width: 2.4rem;
            height: auto;
            margin-right: 0.8rem;
        }
        p {
            font-weight: $light;
            font-size: 1.6rem;
            text-transform: uppercase;
            b {
                display: block;
                font-weight: $black;
            }
        }
    }
    &__info {
        width: calc(100% - 23rem)!important;
        padding: 3.5rem 18rem 3rem 0rem;
        position: relative;
        background-color: $color-2--3;
        z-index: 1;
        &:before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: -5.5rem;
            background-color: $color-2--3;
            transform: skewX(35deg);
            z-index: -1;
            border-left: 1px solid $color-white;
        }
        &-item {
            display: inline-flex!important;
            padding-left: 0.5rem;
            color: $color-white;
            a {
                white-space: normal;
                color: $color-white;
                h3 {
                    font-size: 1.8rem;
                    font-weight: $black;
                }
            }
            p {
                white-space: normal;
                font-size: 1.6rem;
                font-weight: $light;
            }
        }
        .prevNextCaroussel3 {
            position: absolute;
            top: 50%;
            right: calc(#{$btn__width}rem + 1.51rem);
            transform: translateY(-50%);
            > * {
                display: inline-block;
                margin: 0 0.1rem;
            }
        }
        .prevCaroussel3,
        .nextCaroussel3 {
            button {
                width: #{$btn__width}rem;
                height: #{$btn__width}rem;
                background-color: $color-white;
                transition: all 0.3s ease;
                span {
                    width: 100%;
                    height: 100%;
                    display: block;
                    position: relative;
                    text-indent: -9999px;
                    overflow: hidden;
                    &:before {
                        text-indent: 0;
                        font-family: 'icons-default';
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        color: $color-2--3;
                        font-size: 1.6rem;
                    }
                }
                &.disabled {
                    background-color: $color-3--4;
                }
            }
            &:hover,
            &:focus {
                button {
                    background-color: $color-3--4;
                    span {
                        &:before {
                            color: $color-white;
                        }
                    }
                }
            }
        }
        .prevCaroussel3 {
            button {
                span {
                    &:before {
                        content: '\e016';
                    }
                }
            }
        }
        .nextCaroussel3 {
            button {
                span {
                    &:before {
                        content: '\e017';
                    }
                }
            }
        }
    }
    &__plus {
        display: block;
        position: absolute;
        width: #{$btn__width}rem;
        height: #{$btn__width}rem;
        top: 50%;
        right: 1.5rem;
        background-color: $color-white;
        transform: translateY(-50%);
        transition: all 0.3s ease;
        span {
            width: 100%;
            height: 100%;
            display: block;
            position: relative;
            &:before {
                content: '\e08a';
                text-indent: 0;
                font-family: 'icons-default';
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                color: $color-2--3;
                font-size: 1.6rem;
            }
        }
        &:hover,
        &:focus {
            background-color: $color-3--4;
            span {
                &:before {
                    color: $color-white;
                }
            }
        }
    }
}
