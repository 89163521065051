// @name Subtitle or category
$category__font-size: 1.8;
.subtitle, .category {
    font-family: $typo-2;
    font-weight: $light;
    font-size: #{$category__font-size}em;
    text-transform: uppercase;
    color: $color-3--4;
}

// @name Important
// @description Simple text with important information
.important, .important a {
    color: $color-3--3;
    font-weight: $bold;
}

// @name caption
// @description Default caption
.caption {
    font-size: 1.2em;
    font-weight: $light;
    font-style: italic;
    font-family: $typo-2;
    color: $color-3--4;
}

// @name Date
// @description Default date
.date {
    $date__font-size: 1.2;
    font-size: #{$date__font-size}em;
    font-weight: $normal;
    font-family: $typo-2;
    color: $color-3--3;
    margin: em(0.6, $date__font-size) 0;
    &.date--big {

    }
}

// @name Date 1
// @description Date in event
.date-1 {
    $date__width: 6.1;
    font-family: $typo-2;
    color: $color-1--3;
    margin-bottom: 2em;
    text-align: left;
    time {
        height: 6rem;
        width: #{$date__width}rem;
        margin: 0 auto;
        padding-left: 1rem;
        padding-top: 1.2rem;
        position: relative;
        display: block;
        &:before {
            width: 0;
            height: 0;
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            border-top: 43px solid transparent;
            border-left: 61px solid $color-2--3;
            border-bottom: 43px solid transparent;
        }
        & + span {
            text-align: center;
            width: #{$date__width}rem;
            display: block;
            font-size: 1.5rem;
            color: $color-black;
            font-weight: $light;
        }
        & + span + time {
            padding-left: 2.7rem;
            &:before {
                border-left: 0;
                border-right: 61px solid $color-black;
            }
        }
    }
    .day, .month {
        position: relative;
        z-index: 1;
        color: $color-white;
        display: block;
        line-height: 1;
    }
    .day {
        font-size: 2rem;
        font-weight: $black;
    }
    .month {
        font-size: 1.2rem;
        font-weight: $light;
    }
}

// @name Date 2
// @description Date in job
$date-2__font-size: 1.4;
$date-2--big__font-size: 2;
.date-2 {
    font-size: #{$date-2__font-size}em;
    text-transform: uppercase;
    //color: $color-1--2;
    margin: 0 0 em(.8, $date-2__font-size);
    time {
        font-weight: $bold;
    }
    &.date-2--big {
        font-size: #{$date-2--big__font-size}em;
        margin: em(.5, $date-2--big__font-size) 0 em(.8, $date-2--big__font-size);
    }
}

// @name Date 3
// @description Date in public market
.date-3 {
    $date-3__font-size: 1.2;
    @include default-icons-absolute-before('\e05b', 1.2rem, $color-1--2, 1.3rem, inherit, inherit, 0);
    font-family: $typo-2;
    font-size: #{$date-3__font-size}em;
    font-weight: $bold;
    text-transform: uppercase;
    padding-top: em(1.2, $date-3__font-size);
    padding-left: em(1.8, $date-3__font-size);
    margin-bottom: em(1.2, $date-3__font-size);
    border-top: 1px solid darken($color-3--1, 20%);
    time {
        font-weight: $light;
    }
    &.date-3--big {
        $date-3--big__font-size: 1.6;
        font-size: #{$date-3--big__font-size}em;
        padding-top: em(1.2, $date-3--big__font-size);
        padding-left: em(2, $date-3--big__font-size);
        margin-bottom: em(1.2, $date-3--big__font-size);
        margin-top: em(1.2, $date-3--big__font-size);
        &:before {
            font-size: em(1.6, $date-3--big__font-size);
            top: em(1.3, $date-3--big__font-size);
        }
        br {
            display: none;
        }
    }
}

// @name Date 4
// @description Date in publications bloc
.date-4 {
    $date-4__font-size: 1.5;
    font-size: #{$date-4__font-size}em;
    text-transform: uppercase;
    font-weight: $light;
    margin: 0 0 em(2, $date-4__font-size);
    time {
        & + span + time {
            @include default-icons-before('\e017', 0 .5rem 0 0, 1.4rem, $color-black, -.1rem);
        }
    }
}

// @name Media
// Media in publications, public market, albums
.media {
    border-top: 1px solid darken($color-3--1, 20%);
    color: $color-3--3;
    text-transform: uppercase;
    font-size: 1.2em;
    padding-top: em(1.2, 1.2);
}

// @name Hour and place wrapper
.hour-place {
    text-align: center;
}

// @name Hour
// @description Default hour
.hour {
    $hour__font-size: 1.4;
    font-family: $typo-2;
    display: block;
    color: $color-3--4;
    font-weight: $light;
    font-size: #{$hour__font-size}rem;
    margin-bottom: em(0.5, $hour__font-size);
}

// @name Place
// @description Default place
.place {
    display: block;
    position: relative;
    color: $color-3--4;
    font-size: 1.3rem;
    font-family: $typo-2;
    font-weight: $light;
    font-style: italic;
}

// @name public
// @description Default public
.public {
    display: block;
    margin-top: 1rem;
    font-size: 1.3rem;
    text-align: center;
    font-style: italic;
    color: $color-3--4;
}

// @name price
// @description Default price
.price {
    display: block;
    margin-top: 1rem;
    font-size: 1.3rem;
    text-align: center;
    color: $color-3--4;
}

// @name Teaser 1
// @description Teaser in list view
.teaser-1 {
    $teaser-1__font-size: 1.3;
    font-size: #{$teaser-1__font-size}em;
    margin: em(.8, $teaser-1__font-size) 0;
}

// @name Teaser 2
// @description Teaser in heading
$teaser-2__font-size: 1.8;
.teaser-2 {
    font-size: #{$teaser-2__font-size}em;
    font-weight: $extralight;
    line-height: em(2.7, $teaser-2__font-size);
    color: #242c59;
    margin: em(1.2, $teaser-2__font-size) 0 em(.5, $teaser-2__font-size);
}

// @name Teaser 3
// @description Teaser in bloc of right column
.teaser-3 {
    $teaser-3__font-size: 1.6;
    font-size: #{$teaser-3__font-size}em;
    font-weight: $normal;
    margin: em(.8, $teaser-3__font-size) 0;
}

// @name status
// @description Status for public market
.status {
    $status__font-size: 1.2;
    font-size: #{$status__font-size}em;
    background: $color-3--1;
    padding: em(.5, $status__font-size);
    font-family: $typo-2;
    font-weight: $light;
    text-transform: uppercase;
    margin: 0 0 em(.8, $status__font-size);
    @include default-icons-before('\e057', 0 5px 0 0, 1rem, $color-3--3, 0);
    &.status--new {
        background: $color-1--2;
        color: $color-white;
        &:before {
            content: "\e093";
            color: $color-white;
        }
    }
    &.status--in-progress {
        background: $color-2--2;
        color: $color-white;
        &:before {
            content: "\e093";
            color: $color-white;
        }
    }
}

// @name Message
// @state error - Display error message
$error__font-size: 1.4;
.message {
    background: $color-3--1;
    font-size: #{$error__font-size}em;
    font-weight: $light;
    padding: em(3.5, $error__font-size) em(4.2, $error__font-size);
    h2,
    &:before {
        content: attr(aria-label);
        display: block;
        color: $color-black;
        font-size: em(2, $error__font-size);
        font-weight: $bold;
        margin-bottom: em(2, 2);
    }
    > li {
        padding-left: em(1.5, $error__font-size);
        @include default-icons-absolute-before('\e027', 1.2rem, $color-black, .4rem, inherit, inherit, 0);
        margin: em(.5, $error__font-size) 0;
    }
    &--block {
        &:before {
            content: none;
        }
    }
    &.message--error {
        background: lighten($color-red, 45%);
        h2,
        &:before {
            color: $color-red;
        }
    }
    &.message--success {
        background: lighten($color-green, 65%);
        h2,
        &:before {
            color: $color-green;
        }
    }
}

// @name No result
.no-result {
    font-size: 1.5em;
    font-weight: $light;
    text-align: center;
}
