// @name Search box
// @description Search module
.search-btn-outside {
    height: 6.2rem;
    width: 6.2rem;
    margin-left: 1rem;
    background-color: $color-white;
    border: 1px solid #cccccc;
    transition: all 0.3s ease;
    > svg {
        width: 2.2rem;
        height: 2.2rem;
        fill: none;
        stroke: $color-2--2;
        stroke-width: 10;
    }
    &::after {
        content: '';
        position: absolute;
        left: 0;
        height: 0;
        width: 1px;
        background-color: $color-1--3;
        top: 0;
        transition: all 0.2s ease 400ms;
    }
    &:hover,
    &:focus {
        background-color: #cfe3ec;
        > svg {
            fill: none;
        }
    }
}
.search-box {
    padding: 0;
    position: absolute;
    top: -100%;
    right: 0;
    width: calc(100% - 26rem);
    height: 100%;
    display: flex;
    align-items: center;
    background-color: $color-2--3;
    //display: none;
    form {
        margin: 0;
        width: 100%;
        display: block;
        position: relative;
        height: 100%;
    }
    label {
        margin-right: em(0.5, 1.8);
        white-space: nowrap;
        font-size: 1.8em;
        color: $color-white;
    }
    .search-box__fields-wrapper, .search-box__button-wrapper {
        display: inline-block;
        vertical-align: bottom;
    }
    // @name Search Field wrapper
    // @state .search__field-wrapper--in-line - Indicates label and field are side by side.
    .search-box__field-wrapper {
        &.search-box__field-wrapper--in-line {
            display: table;
            width: 100%;
            label {
                display: table-cell;
                vertical-align: middle;
                text-align: right;
                padding-right: 1em;
                width: 1%;
            }
            input, select {
                display: table-cell;
                vertical-align: middle;
                width: 100%;
            }
        }
    }
    // Drop down menu
    .ddm {
        position: static;
        > .ddm__sub-level {
            top: 0;
            right: 3em;
            left: 0;
            width: 100%;
            display: table;
            height: 100%;

            > * {
                display: table-cell;
                vertical-align: middle;
            }
        }
        &__title {
            display: none!important;
        }
    }
    &__wrapper {
        display: block;
        width: 100%;
        position: relative;
        height: 100%;
    }
    &__button-wrapper {
        height: 100%;
        width: 1.6rem;
    }
    &__fields-wrapper {
        padding: 0 1.8rem;
    }
    &__button {
        background-color: $color-2--3;
        width: 11.5rem;
        height: 100%;
        border-left: 1px solid $color-white;
        > svg {
            width: 2.7rem;
            height: 2.7rem;
            fill: none;
            stroke: $color-white;
            stroke-width: 20;
        }
        &:hover,
        &:focus {
            background-color: $color-2--1;
            > svg {
                fill: none;
            }
        }
    }
}



// @name Search list
$pertinence__font-size: 1.2;
.pertinence {
    float: right;
    font-size: #{$pertinence__font-size}em;
    span {
        font-size: em(1, 1.2);
    }
}
.pertinence__metter {
    display: inline-block;
    vertical-align: middle;
    width: em(10, $pertinence__font-size);
    height: em(1, $pertinence__font-size);
    padding: em(.2, $pertinence__font-size);
    background: $color-3--2;
    margin: 0 em(1, $pertinence__font-size);
    > span {
        display: block;
        height: em(.8, $pertinence__font-size);;
        background: $color-2--2;
    }
}

.type-content {
    font-size: 1.4rem;
    color: $color-2--3;
    font-weight: $bold;
}

//.secondaryContentSection {
//    .facets-remove-all {
//        margin-bottom: 5rem;
//    }
//    padding-right: 2.5rem;
//    a {
//        font-size: 1.4rem;
//        font-weight: $light;
//        color: $color-black;
//    }
//    .facet-option {
//        margin-bottom: 0.7rem;
//    }
//    h3 {
//        font-family: $typo-2;
//        text-transform: uppercase;
//        color: $color-black;
//        font-size: 1.8rem;
//        font-weight: $bold;
//        margin-top: 0;
//        margin-bottom: 1rem;
//    }
//    .facet-label,
//    .subtitle {
//        margin-top: 2.5rem;
//        position: relative;
//        padding: 1rem 0.7rem 1.5rem;
//        color: $color-black!important;
//        font-size: 16px;
//        font-weight: $bold;
//        text-transform: inherit;
//        background-color: transparent!important;
//        &:before {
//            width: 100%;
//            height: 0.5rem;
//            content: "";
//            position: absolute;
//            left: 0;
//            bottom: 0;
//            background: $color-2--7 url('../Images/bg/pattern-practical-information.png') repeat-x 0 100%!important;
//        }
//    }
//    .facet-option-list {
//        margin-left: 0;
//        li {
//            &:before {
//                content: none;
//            }
//        }
//    }
//    .column-bloc {
//        margin-right: 0;
//        margin-bottom: 1rem;
//    }
//    .filter-solr-date {
//        min-width: 31rem;
//    }
//    .solr-field {
//        display: inline-block;
//        max-width: 14.5rem;
//        width: 49%;
//        vertical-align: top;
//        label {
//            font-size: 1.4rem;
//        }
//        input {
//            font-size: 1.4rem;
//        }
//        .form__field-datewrapper {
//            input[type=date] {
//                background-color: #f2f7fa;
//                color: #c5c5c5;
//            }
//        }
//        .button-1 {
//            margin-top: 0.3rem;
//        }
//    }
//    .button-1 {
//        display: table;
//        margin-top: 1.5rem;
//        padding: 1.4rem 3.8rem;
//        font-size: 1.2rem;
//        margin-right: 0;
//        margin-left: auto;
//    }
//}
//#tx-solr-facets-in-use {
//    ul {
//        li {
//            padding-right: 1.5rem;
//            a {
//                &:after {
//                    content: '\e058';
//                    font-size: 1rem;
//                    margin-left: 0.1rem;
//                    font-family: 'icons-default';
//                    vertical-align: middle;
//                }
//            }
//        }
//    }
//}
//.facets-remove-all {
//    .button-1 {
//        margin-left: 2.5rem;
//        &:before {
//            font-size: 1rem;
//        }
//    }
//}
//@media (max-width: 767px) {
//    .secondaryContentSection {
//        .solr-field {
//            display: block;
//            width: 70%;
//        }
//    }
//}
