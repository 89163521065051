.banner {
    position: relative;
    &__item {
        height: 63.3rem;
        width: 100%;
        position: relative;
        z-index: 5 !important;
        > img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            font-family: 'object-fit: cover;'
        }
        &--video {
            position: relative;
            overflow: hidden;
            video {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                object-fit: cover;
                font-family: 'object-fit: cover;'
            }
        }
    }
    .video {
        &__wrap {
            overflow: hidden;
        }
    }
    &__info {
        position: absolute;
        right: 25rem;
        top: 12rem;
        &-wrap {
            width: 45rem;
            &:before {
                width: 0.5rem;
                height: 9.3rem;
                content: '';
                position: absolute;
                bottom: -5rem;
                left: -3rem;
                background-color: $color-white;
                transform: rotate(-35deg);
            }
            p,
            a {
                color: $color-white;
                text-shadow: 0.5px 0.9px 3px rgba(0, 0, 0, 0.35);
                text-transform: uppercase;
            }
            p {
                display: inline-block;
                padding: 1rem 1.2rem;
                font-size: 1.6rem;
                font-weight: $bold;
                border: 1px solid $color-white;
            }
            a {
                display: block;
                font-size: 4.5rem;
                font-weight: $black;
            }
        }
    }
    .pagerCarrousel1 {
        margin-top: 1rem;
        li {
            margin-right: 0.5rem;
            display: inline-block;
        }
    }
    .modeplay {
        width: 5rem;
        height: 5rem;
        position: absolute;
        right: 5.2rem;
        bottom: 0.1rem;
        z-index: 12;
        text-indent: -9999px;
        overflow: hidden;
        background-color: rgba(255, 255, 255, 0.9);
        transition: all 0.3s ease;
        span {
            display: block;
            width: 100%;
            height: 100%;
            position: relative;
            &:before {
                content: '\e076';
                font-size: 1.6rem;
                text-indent: 0;
                font-family: 'icons-default';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: $color-2--2;
                transition: all 0.3s ease;
            }
        }
        &--pause {
            span {
                &:before {
                    content: '\e075';
                }
            }
        }
        &:hover,
        &:focus {
            @include background-opacity($color-2--2, 0.9);
            span {
                &:before {
                    color: $color-white;
                }
            }
        }
    }
    .prevNextCarrousel1 {
        position: absolute;
        bottom: 0.1rem;
        right: 0.1rem;
        z-index: 10;
        > * {
            display: inline-block;
        }
    }
    .prevCarrousel1,
    .nextCarrousel1 {
        button {
            width: 5rem;
            height: 5rem;
            position: relative;
            background-color: rgba(255, 255, 255, 0.9);
            text-indent: -9999px;
            overflow: hidden;
            transition: all 0.3s ease;
            &:before {
                font-size: 1.6rem;
                text-indent: 0;
                font-family: 'icons-default';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: $color-2--2;
                transition: all 0.3s ease;
            }
        }
        &:hover,
        &:focus {
            button {
                @include background-opacity($color-2--2, 0.9);
                &:before {
                    color: $color-white;
                }
            }
        }
    }
    .prevCarrousel1 {
        button {
            &:before {
                content: '\e026';
            }
        }
    }
    .nextCarrousel1 {
        margin-left: 5.2rem;
        button {
            &:before {
                content: '\e027';
            }
        }
    }
}
