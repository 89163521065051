// @name Title 1
// @description Styling for title 1
$title--1__font-size: 3;
.title-1 {
    font-family: $typo-1;
    font-size: #{$title--1__font-size}em;
    line-height: 1em;
    font-weight: $light;
    color: $color-2--3;
    text-transform: uppercase;
    margin: em(3, $title--1__font-size) 0 em(2, $title--1__font-size);
    &.title-decoration {
        span:first-child {
            padding-left: 0;
            padding-right: 1.3rem;
            &:before {
                content: '';
            }
            &:after {
                font-size: 2rem;
                right: -1rem;
            }
        }
    }
}

// @name Title 2
// @description Styling for title 2
$title--2__font-size: 3;
.title-2 {
    font-family: $typo-3;
    font-size: #{$title--2__font-size}em;
    font-weight: $light;
    color: $color-2--3;
    text-transform: uppercase;
    margin: em(2.5, $title--2__font-size) 0 em(1.5, $title--2__font-size);
}

// @name Title 3
// @description Styling for title 3
$title--3__font-size: 3.5;
.title-3 {
    font-family: $typo-1;
    font-size: #{$title--3__font-size}em;
    line-height: 1em;
    font-weight: $light;
    color: $color-black;
    margin: em(2.5, $title--3__font-size) 0 em(2, $title--3__font-size);
}

// @name Title decortaion
// @description Styling for title decortaion
.title-decoration {
    position: relative;
    &:before {
        content: '';
        display: block;
        height: 0.1rem;
        width: 100%;
        background-color: $color-2--2;
        position: absolute;
        top: 50%;
    }
    span:first-child {
        padding: 0 1.8rem 0 3.1rem;
        line-height: 1.2;
        position: relative;
        background-color: $color-white;
        b {
            font-weight: $black;
        }
        &:before,
        &:after {
            content: '\E008';
            font-family: 'icons-project';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            font-size: 3rem;
            color: $color-2--2;
        }
        &:before {
            left: 0;
        }
        &:after {
            right: -1.3rem;
        }
    }
    .btn-wrap {
        background-color: $color-white;
        padding-left: 1rem;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        > * {
            margin: 0 0.1rem;
        }
        .button-1--rss {
            width: 5.1rem;
            height: 5.1rem;
            padding: 0;
            background-color: $color-2--2;
            svg {
                width: 1.4rem;
                height: 1.4rem;
                fill: $color-white;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
            &:hover,
            &:focus {
                background-color: $color-3--4;
            }
        }
    }
}
