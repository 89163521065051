.block-contact {
    .list-type-3__item,
    .list-infos__wrapper {
        flex-direction: column;
    }

    .list-type-3__item {
        .list-infos {
            padding: 1.5rem 2rem;
        }
    }
}
