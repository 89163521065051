// @name Pager list
.pager {
    li {
        &.pager__prev, &.pager__next {
            span, a {
                min-width: 4rem;
                text-indent: -9999px;
                white-space: nowrap;
                position: relative;
                padding: 1rem 2rem;
                &:before, &:after {
                    text-indent: 0;
                    line-height: 0;
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    margin: 0;
                }
            }
        }
    }
}

.pager-single-xl {
    li {
        &.pager__prev, &.pager__next {
            .pager__button {
                text-indent: -9999px;
                white-space: nowrap;
                position: relative;
                padding: em(1, 1.4) em(1.8, 1.4);
                &:before, &:after {
                    text-indent: 0;
                    line-height: 0;
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    margin: 0;
                }
            }
        }
    }
    .pager__link {
        //flex-wrap: wrap;
        align-items: flex-start;
    }
    .pager__button {
        //order: 1;
    }
    .pager__article {
        //order: 2;
    }
}
