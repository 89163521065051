/*
    Unminified development version of the datepicker stylesheet. Please use the
    minified version in production - remember to run a find/replace on the
    minified file to update the image locations to suit your installation - more
    info can be found in the header of the minified file.
    F.Y.I: Here's the HTML produced for each instance of the datePicker, in this
    case associated with a form element having an id of "demo-1"
<div id="fd-demo-1" class="date-picker drag-enabled" aria-describedby="fd-dp-aria-describedby" aria-labelledby="demo-1_label" aria-hidden="false" role="application" dir="ltr">
    <div>
        <table class="date-picker-table" role="grid">
            <thead class="date-picker-thead">
                <tr role="presentation">
                    <th class="date-picker-title drag-enabled" colspan="7"><span class="month-display drag-enabled">January&nbsp;</span><span class="year-display drag-enabled">2013</span></th>
                </tr>
                <tr role="presentation">
                    <th><span class="prev-but prev-year" id="demo-1-prev-year-but" title="Previous year">«</span></th>
                    <th><span class="prev-but prev-month" id="demo-1-prev-month-but" title="Previous month">‹</span></th>
                    <th colspan="3"><span class="today-but fd-disabled" id="demo-1-today-but" title="">Today</span></th>
                    <th><span class="next-but next-month" id="demo-1-next-month-but" title="Next month">›</span></th>
                    <th><span class="next-but next-year" id="demo-1-next-year-but" title="Next year">»</span></th>
                </tr>
                <tr>
                    <th class="date-picker-day-header" scope="col" role="columnheader" id="demo-1-col-0" title="Monday">Mon</th>
                    <th class="date-picker-day-header" scope="col" role="columnheader" id="demo-1-col-1" title="Tuesday"><span class="fd-day-header" title="Tuesday">Tue</span></th>
                    <th class="date-picker-day-header" scope="col" role="columnheader" id="demo-1-col-2" title="Wednesday"><span class="fd-day-header" title="Wednesday">Wed</span></th>
                    <th class="date-picker-day-header" scope="col" role="columnheader" id="demo-1-col-3" title="Thursday"><span class="fd-day-header" title="Thursday">Thu</span></th>
                    <th class="date-picker-day-header" scope="col" role="columnheader" id="demo-1-col-4" title="Friday"><span class="fd-day-header" title="Friday">Fri</span></th>
                    <th class="date-picker-day-header date-picker-highlight" scope="col" role="columnheader" id="demo-1-col-5" title="Saturday"><span class="fd-day-header" title="Saturday">Sat</span></th>
                    <th class="date-picker-day-header date-picker-highlight" scope="col" role="columnheader" id="demo-1-col-6" title="Sunday"><span class="fd-day-header" title="Sunday">Sun</span></th>
                </tr>
            </thead>
            <tbody>
                <tr role="row">
                    <td aria-describedby="demo-1-col-0" aria-selected="false" role="gridcell" class="date-picker-unused" title="">&nbsp;</td>
                    <td aria-describedby="demo-1-col-1" aria-selected="false" role="gridcell" title="January 01, 2013" class="day-1 cell-1 cd-20130101 yyyymm-201301 mmdd-0101">1</td>
                    <td aria-describedby="demo-1-col-2" aria-selected="false" role="gridcell" title="January 02, 2013" class="day-2 cell-2 cd-20130102 yyyymm-201301 mmdd-0102">2</td>
                    <td aria-describedby="demo-1-col-3" aria-selected="false" role="gridcell" title="January 03, 2013" class="day-3 cell-3 cd-20130103 yyyymm-201301 mmdd-0103">3</td>
                    <td aria-describedby="demo-1-col-4" aria-selected="false" role="gridcell" title="January 04, 2013" class="day-4 cell-4 cd-20130104 yyyymm-201301 mmdd-0104">4</td>
                    <td aria-describedby="demo-1-col-5" aria-selected="false" role="gridcell" title="January 05, 2013" class="day-5 cell-5 cd-20130105 yyyymm-201301 mmdd-0105 date-picker-highlight">5</td>
                    <td aria-describedby="demo-1-col-6" aria-selected="false" role="gridcell" title="January 06, 2013" class="day-6 cell-6 cd-20130106 yyyymm-201301 mmdd-0106 date-picker-highlight">6</td>
                </tr>
                <tr role="row">
                    <td aria-describedby="demo-1-col-0" aria-selected="false" role="gridcell" title="January 07, 2013" class="day-0 cell-7 cd-20130107 yyyymm-201301 mmdd-0107">7</td>
                    <td aria-describedby="demo-1-col-1" aria-selected="false" role="gridcell" title="January 08, 2013" class="day-1 cell-8 cd-20130108 yyyymm-201301 mmdd-0108">8</td>
                    <td aria-describedby="demo-1-col-2" aria-selected="false" role="gridcell" title="January 09, 2013" class="day-2 cell-9 cd-20130109 yyyymm-201301 mmdd-0109">9</td>
                    <td aria-describedby="demo-1-col-3" aria-selected="false" role="gridcell" title="January 10, 2013" class="day-3 cell-10 cd-20130110 yyyymm-201301 mmdd-0110">10</td>
                    <td aria-describedby="demo-1-col-4" aria-selected="false" role="gridcell" title="January 11, 2013" class="day-4 cell-11 cd-20130111 yyyymm-201301 mmdd-0111">11</td>
                    <td aria-describedby="demo-1-col-5" aria-selected="false" role="gridcell" title="January 12, 2013" id="demo-1-date-picker-hover" class="day-5 cell-12 cd-20130112 yyyymm-201301 mmdd-0112 date-picker-today date-picker-highlight date-picker-hover" tabindex="0">12</td>
                    <td aria-describedby="demo-1-col-6" aria-selected="false" role="gridcell" title="January 13, 2013" class="day-6 cell-13 cd-20130113 yyyymm-201301 mmdd-0113 date-picker-highlight">13</td>
                </tr>
                ... snip ...
            </tbody>
        </table>
    </div>
</div>
*/
$color-1--3-dark: rgba(#025152, 0.4);
/*
    Hide the datepickers associated input by using a className
*/
.fd-hidden-input {
    display: none;
}
/*
    Screen reader class - used to hide spans inside each cell from the visual
    display. See: http://webaim.org/techniques/css/invisiblecontent/
*/
.fd-screen-reader {
    position: absolute;
    left: -999em;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
    -moz-outline: 0 none;
    outline: 0 none;
}
/*
    The wrapper div
*/
.date-picker {
    position: absolute;
    z-index: 9999;
    text-align: center;
    /*
    Change the font-size to suit your needs. The following line is for the demo
    that has a 14px font-size defined on the body tag.
    All other rules are "em" based and inherit from the font-size set here.
    */
    //font: 900 0.8em/1em "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Verdana, Arial, "Lucida Grande", sans-serif;
    //background: transparent;
    user-select: none;
    transition: opacity .75s ease-in-out;
}
/*
    The following rule is used whenever CSS Animations are supported by the
    browser
*/
.date-picker.fd-dp-fade {
    opacity: 0;
}
/*
    Styles for the static datePickers
*/
.static-datepicker {
    position: relative;
    top: 0;
    left: 0;
    z-index: 0;
    max-width: 242px;
    margin: 0 auto 1.5em;
}
/*
    The activation "button" created beside each input for popup datePickers
*/
.date-picker-control span {
    display: block;
    width: 18px;
    height: 18px;
    overflow: hidden;
    margin: auto 0;
    resize: none;
    outline: none;
}
/*
    The hover effect on the activation button
*/
//.date-picker-button-active span,
//.date-picker-control:focus span,
//.date-picker-control:hover span,
//.date-picker-control:active span {
//    outline: none;
//    box-shadow: 0 0 5px rgba(40, 130, 170, 0.7);
//    border-radius: 2px;
//}
/*
    A base64 encoded image-sprite is used for the activation button. IE6 and 7
    get fed "normal" png images within the rule that follows.
*/
//.date-picker-control:link,
//.date-picker-control:visited {
//    position: relative;
//    display: -moz-inline-box;
//    border: 0 none;
//    padding: 0;
//    margin: 0 4px;
//    background-color: transparent;
//    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAB+CAYAAAAp+EF2AAAD8ElEQVRo3u2Zu0scXxTHz8zcXR0H8UnEIpXyQ8TGaJNCFAlqEUyTMlUaWTC/IoR0vxQ2vz8hfZJGIki2URARkkbxhSJq4avI7uqK7x2V9XF/93v83WGzmIezKVLcL1zmvubcc+49w352xurr63tZW1s7EI1GPSkloVxfX5Ou55f8sYuLCz+TybwRMNLf3++dn5+jk8vl5eU39Ww2G/TlX6+urrzZ2dkBAU9gZGVlBZ08If+qC9r5Y6WlpeQ4jmf/714oI9orhCuOj49peXmZDg4OCDo6OqKSkhKswm0stL+/T5WVlWTbNvchVLQxhjpsiEgkQm1tbWwANy8uLlJ9fT2Vl5dzGzePjY1RR0cH1zFvfX2dOjs7ybIs2tjYoOHhYbLhHizrmyBcc0tuH25GKLhCqMOGQEgTExMcKzQ1NUX/vntHjoiS1l8lxbwYdHJywl4cHh5y3/b2NteF53kcGjYQK66urtL1wzYSKjQtMfyRuru7eRz7hT1FG8L8oaEhsmEAljFJb/Bt0uMoOiwdGmxwaOPj48FebG1tUTqRIqfYDSaXp1I0MjLCdZXFtLa2xp7BSCKRuDnxWCwmVZxSnYZU8Ut1QjKVSkl1rFJtolSTZTweD9o7OztydHRUai0sLMiWlhYpckPTp5J7YmgLIQhpokPMVRAakml6eppc1+WbsJG+73Pq64TE4wNDqOs80qeIMHGSAqvV1dUFmQsjjY2NQeZCu7u71NzcHGQ5rq2trUH+wYbARKxeVVXFhlCHERQtpAjGtYeYU11dze2ysrKbFFEe+Ol02kNCYlIymeRnDZN1/DjJmZkZbus8gnGMbW5u0unpqW8pF1/W1NQMKPc8TOQHULCjwROPvqKiIq5jQYSv2zCiUuANGRkZGRl9R5ZiyGcK/2KK3NyQDHmmfn3fgiFjiiHdAhjSVQwZA0O6v4EhXcOQIRiSQSudZjrRAt7dmSHn5uaooaGBKioqAg9Qurq6fsiQ4jaG1GHk8uKdGRJuK/Tjk9NaWloKQvseQ3Jo7e3tvCcwNjk5SU1NTQEvoYA8enp6uL63t0fz8/NBaDjlwcFBMgz5awx5phLQLZAhz8CQzxRDxpR7bkiGPFMp8Nb8RhgZGRn9iCHNe0jDkIYhzXtI8x7SvIc0MjIyuhNDPn/x9NP9qsbeomgxhWHI7MU5HWa/xgWMvOj/mwpgSJqZlb0CnhTKkMKJkmHIkN+y3394T8Xql1QrEomG+5b9+MkjevCgmRxbqJVtev3qn59+y76dIS2HjdgolhOOIUEeXwY+M23chGPxzeZb9p/EkBk/w39iCmFI/9QnMOSnezX3eiMiQmEYEkaSiWT8P0zZxbn5chQoAAAAAElFTkSuQmCC);
//    background-repeat: no-repeat;
//    background-position: 0 -72px;
//    min-width: 18px;
//    line-height: 1;
//    cursor: pointer;
//    visibility: visible;
//    text-decoration: none;
//    vertical-align: text-bottom;
//    resize: none;
//}
/*
    Hover states etc for the activation button
*/
//.date-picker-button-active:link,
//.date-picker-button-active:visited,
//.date-picker-control:focus,
//.date-picker-control:hover,
//.date-picker-control:active {
//    background-position: 0 0;
//}
///*
//    Disabled activation button
//*/
//.date-picker-control-disabled:link,
//.date-picker-control-disabled:visited,
//.date-picker-control-disabled:hover,
//.date-picker-control-disabled:active {
//    background-position: 0 -36px;
//    cursor: default;
//}
//.date-picker-control-disabled:hover span,
//.date-picker-control-disabled:active span {
//    box-shadow: none !important;
//}
/*
    Default datepicker "button" styles
*/
.date-picker th span {
    line-height: 1em;
}
/*
    The "month, year" display
*/
.date-picker .month-display,
.date-picker .year-display {
    display: inline;
    font-size: 1.6rem;
    font-weight: $black;
    text-transform: uppercase;
}
/*
    Generic button class for Next & Previous (both month & year) buttons
*/
.date-picker .date-picker-thead {
    position: relative;
    .prev-but,
    .next-but {
        font-size: 2em;
        display: inline-block;
        width: 30px;
        text-align: center;
        font-family: $typo-1;
        font-weight: 200;
        cursor: pointer;
        color: $color-2--2;
        &:hover,
        &:active {
            color: $color-3--4
        }
    }
    // navigation buttons
    tr:nth-child(2) {
        position: absolute;
        display: flex;
        justify-content: space-between;
        top: 2px;
        left: 0;
        width: 100%;
        th {
            height: 30px;
        }
        th:first-child,
        th:last-child,
        th[colspan] {
            display: none;
        }
    }
    .prev-but,
    .next-but,
    .today-but {
        transition: all .2s ease-in-out;
    }
}
/*
    Hover effect for Next & Previous (month, year) buttons
*/
.date-picker .prev-but:hover,
.date-picker .next-but:hover,
.date-picker .today-but:hover {
    //color: #a84444;
}
.date-picker .prev-but:hover,
.date-picker .next-but:hover {
}
/*
    Today button
*/
.date-picker .today-but {
    text-align: center;
    margin: 0 auto;
    font-weight: normal;
    font-size: 1em;
    width: 100%;
    text-decoration: none;
    padding-top: 0.3em;
    text-transform: uppercase;
    vertical-align: middle;
    cursor: pointer
}
/*
    Disabled buttons
*/
.date-picker-disabled .prev-but,
.date-picker-disabled .next-but,
.date-picker-disabled .today-but,
.date-picker-disabled .prev-but:hover,
.date-picker-disabled .next-but:hover,
.date-picker-disabled .today-but:hover,
.date-picker .prev-but.fd-disabled:hover,
.date-picker .next-but.fd-disabled:hover,
.date-picker .fd-disabled,
.date-picker .fd-disabled:hover {
    color: #aaa;
    cursor: default !important;
    opacity: 1;
}
.date-picker table {
    table-layout: auto;
    empty-cells: show;
    border-spacing: 4px;
    border-collapse: separate;
    position: relative;
    margin: 0;
    padding: 0 0 1em;
    //border-bottom: 1px solid $color-1--3-dark;
    text-align: center;
    width: auto;
    height: auto;
    color: $color-white;
    font-size: 1em;
    //border-radius: 0.8em;
    //box-shadow: 0px 0px 5px #aaa;
}
/*
    Common TD & TH styling
*/
.date-picker {
    td,
    th {
        padding: 0;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;
        font-weight: $extralight;
        font-family: $typo-1;
        width: 30px;
        overflow: hidden;
    }
    th {
        font-size: 1.6rem;
        padding: 0;
        text-align: center;
        vertical-align: middle;
    }
    td {
        height: 4rem;
        font-size: 1.8rem;
        background-color: transparent;
        transition: background-color 0.25s ease;
    }
}
//.date-picker .date-picker-week-header {
//    border: 1px solid #dcdcdc;
//    font-style: oblique;
//    background: transparent;
//    cursor: default;
//}
//
.date-picker .date-picker-thead {
    tr:last-child {
    }
    position: relative;
    &:after {
        content: '';
        display: block;
        position: absolute;
        margin-top: -1em;
        left: 0;
        width: 100%;
        height: 7px;
        border-bottom: 1px solid rgba(0, 172, 236, 0.6);
        z-index: -1;
    }
}
///*
//    The "mon tue wed etc" day header styles
//*/
.date-picker .date-picker-day-header {
    cursor: help;
    padding: 0.2em 0 1em;
    text-transform: uppercase;
    height: auto;
}
///*
//    tfoot status bar
//*/
//.date-picker .date-picker-statusbar {
//    cursor: default;
//    font-weight: normal;
//    text-transform: uppercase;
//    letter-spacing: 0.1em;
//    border: 0 none;
//    background: #fff;
//    height: 2.8em;
//}
///*
//    TD cell that is _not_ used to display a day of the month
//*/
//.date-picker .date-picker-unused {
//    border-color: #dcdcdc;
//    cursor: default !important;
//    background-position: 0 -25px;
//}
/*
	The TH cell used to display the "month, year" title
*/
.date-picker .date-picker-title {
    width: auto;
    height: auto;
    padding: 0.4em 0;
}
/*
	The "highlight days" style
*/
.date-picker {
    td[data-highlight],
    td.date-picker-highlight {
        border-bottom: 1px solid $color-white;
        &:hover,
        &:focus {
            border-bottom-color: transparent;
            color: $color-black;
            background-color: $color-2--2;
        }
    }

    .date-picker-today {
        cursor: pointer;
        background-color: #0e6991;
    }

    //td.date-picker-hover,
    //td.month-out.date-picker-hover {
    //    color: $color-black;
    //    background-color: $color-2--2;
    //}
    td.date-picker-selected-date {
        color: $color-black;
        background-color: $color-2--2;
    }
}
/*
	The "disabled days" style
*/
.date-picker .day-disabled {
    color: $color-2--1 !important;
    cursor: default;
    background-color: transparent !important;
}
//.date-picker .month-out {
//    border-color: #ddd;
//    border-color: rgba(220, 220, 220, 0.8);
//    color: #aaa;
//    background-position: 0 -25px;
//}
/*
	The date "out of range" style
*/
.date-picker .out-of-range,
.date-picker .not-selectable {
    color: #ccc;
    font-style: oblique;
    background: #fcfcfc;
    cursor: default;
}
/*
	Week number "out of range" && "month-out" styles
*/
.date-picker th.month-out,
.date-picker th.out-of-range {
    color: #aaa;
    font-style: oblique;
    background: #fcfcfc;
}
/*
	Week numbers "out of range"
*/
.date-picker .out-of-range {
    opacity: 0.6;
}
/*
	Used when the entire grid is full but the next/prev months dates cannot be
	selected
*/
.date-picker .not-selectable {
    opacity: 0.8;
}
.oldie .date-picker .out-of-range {
    filter: alpha(opacity=60);
}
.oldie .date-picker .not-selectable {
    filter: alpha(opacity=80);
}
.date-picker tr {
    display: table-row;
}
.date-picker sup {
    font-size: 0.86em;
    letter-spacing: normal;
    text-transform: none;
    height: 0;
    line-height: 1;
    position: relative;
    top: -0.2em;
    vertical-align: baseline !important;
}
.date-picker .month-display,
.date-picker .year-display {
    cursor: default;
}
.date-picker td:focus,
.date-picker .date-picker-hover {
    overflow: hidden;
    outline: 0 none;
}
/*
	Add a box-shadow and enhance border for datepickers that have keyboard focus
*/
.date-picker-focus table {
    //border-color: #999;
    outline: 1px dashed $color-2--2;
    //border-color: rgba(153, 153, 153, .8);
    //box-shadow: 0px 0px 5px #aaa;
}
/*
	Draggable datepickers
*/
.date-picker .drag-enabled,
.date-picker .drag-enabled span {
    cursor: move;
}
/*
	Disabled datePicker
*/
.date-picker-disabled table {
    opacity: .8 !important;
}
.date-picker-disabled table:before,
.date-picker-disabled table:after {
    display: none;
}
.oldie .date-picker-disabled table {
    filter: alpha(opacity=80);
}
.date-picker-disabled,
.date-picker-disabled td,
.date-picker-disabled th,
.date-picker-disabled th span {
    cursor: default !important;
}
