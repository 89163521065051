.menu-main-1__listitems-0 {
    > li {
        > .menu-main-1__header {
            .menu-main-1__action {
                width: 5rem;
                height: 5rem;
                top: 1.1rem;
                left: -6.3rem;
            }
        }
        &.menu-main-1__item--active {
            > .menu-main-1__header {
                .menu-main-1__action {
                    top: 2.4rem;
                    left: 1rem;
                }
            }
        }
    }
}
