.banner {
    &__item {
        height: auto;
        &--video {
            display: none;
        }
    }
    &__info {
        margin-top: -3.5rem;
        position: static;
        &-wrap {
            width: 100%;
            padding: 1.1rem;
            p,
            a {
               text-shadow: none;
            }
            p {
                margin-bottom: 0.7rem;
                font-size: 1.3rem;
                background-color: $color-black;
                border-color: transparent;
            }
            a {
                font-size: 1.8rem;
                color: $color-black;
            }
        }
    }
    .pagerCarrousel1,
    .modeplay,
    .prevNextCarrousel1 {
        display: none;
    }
}
