.sidebar {
    width: 100%!important;
    position: static!important;
    h2 {
        margin-bottom: 0;
    }
    &-tablet,
    .toc-bar {
        display: none;
    }
}
.sticky-wrapper {
    width: 100%;
}

#barsContainer {
    > button {
        padding: 1rem 2.5rem 1.5rem 2.5rem;
        &:first-child {
            padding: 1rem 2.5rem 2.2rem 2.5rem;
            border-top-color: transparent;
        }
    }
}
