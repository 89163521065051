// @name List infos
.list-infos {
    font-family: $typo-2;
}

.list-infos__wrapper {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    > * {
        flex: 1 1 0;
    }
    .list-infos__listitems:first-child {
        flex: 1.9 1 0;
    }
    .list-infos__listitems + .list-infos__listitems {
        border-left: 1px solid $color-3--3;
        padding-left: 5%;
        margin-left: 5%;
    }
}

.list-infos__item {
    $list-infos__item__font-size: 1.5;
    margin: .5em 0;
    font-size: #{$list-infos__item__font-size}em;
    @include default-icons-absolute-before('\e053', 2rem, $color-2--2, -.2rem, inherit, inherit, 0);
    padding-left: em(2.4, $list-infos__item__font-size);
    a {
        color: $color-black;
        text-decoration: underline;
        &:hover, &:focus {
            text-decoration: none;
        }
    }
    &--group {
        p {
            width: 40%;
            display: inline-block;
        }
    }
    &.list-infos__phone, &.list-infos__fax {
        white-space: nowrap;
    }
    &.list-infos__address {
        &:before {
            content: "\e053";
        }
    }
    &.list-infos__website {
        &:before {
            content: "\e089";
        }
    }
    &.list-infos__phone {
        &:before {
            content: "\e02b";
        }
    }
    &.list-infos__fax {
        &:before {
            content: "\e09f";
        }
    }
    &.list-infos__email {
        &:before {
            content: "\e028";
        }
    }
    &.list-infos__infos {
        &:before {
            content: "\e083";
        }
    }
    &.list-infos__hours {
        &:before {
            content: "\e05b";
        }
    }
    &.list-infos__fb {
        &:before {
            content: "\e097";
        }
    }
    &.list-infos__tw {
        &:before {
            content: "\e09a";
        }
    }
    &.list-infos__in {
        &:before {
            content: "\e0a2";
        }
    }
    &.list-infos__instagram {
        &:before {
            content: "\e0a1";
        }
    }
    &.list-infos__nom {
        padding-left: 0;
        &:before {
            content: none;
        }
    }
    &.list-infos__noicon {
        padding-left: 0;
        &:before {
            content: none;
        }
    }
    &.list-infos__comment {
        &:before {
            content: '\e02e';
        }
    }
}
