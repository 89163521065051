.travaux {
    &__wrap {
        flex-direction: column;
    }
    &__icon {
        padding: 1.9rem;
        justify-content: center;
    }
    &__info {
        width: 100% !important;
        padding: 2rem 2rem 8rem;
        &:before {
            content: none;
        }
        &-item {
            justify-content: center;
            text-align: center;
            a {
                h3 {
                    font-size: 1.5rem;
                }
            }
            p {
                font-size: 1.3rem;
            }
        }
    }
    .prevNextCaroussel3 {
        top: inherit;
        bottom: 2rem;
        right: calc(50% + 2.5rem);
        transform: translate(50%, 0);
    }
    &__plus {
        top: inherit;
        bottom: 2rem;
        right: calc(50% - 4.5rem);
        transform: translate(50%, 0);
    }
}
