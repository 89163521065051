.bloc-publications {
    width: 100%;
    &__item {
        flex-direction: column;
        > a {
            margin-bottom: 1rem;
            img {
                width: inherit;
                max-width: 100%;
                margin: 0 auto 0.5rem;
            }
        }
    }
    &__wrapper {
        margin-left: 0;
    }
    .category {
        margin-bottom: 1rem;
    }
    .list-document-1__file-size {
        display: block;
        margin-top: 0.5rem;
    }
}
