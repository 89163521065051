.enimages {
    &__wrap {
        width: 100%;
    }
    &--sm {
        .item {
            width: 100%;
        }
    }
}
