.actus {

    /* Local variable */
    $this: &;

    .news__wrap {
        margin-top: 0;
        max-width: 100%;
        > * {
            max-width: 33.33%;
        }
    }
    &--lg {
        flex-direction: column;
        #{$this}__info {
            width: 94%;
            margin: 0 auto;
            position: static;
            transform: translateY(-4rem);
            a {
                h3 {
                    font-size: 3.4rem;
                }
            }
            &-wrap {
                padding: 3rem 3rem 6.5rem;
                &:before {
                    height: 4.5rem;
                }
            }
        }
    }
}
