//@name Page content

// @name Title h2
$content-h2__font-size: 2.8;
.rte h2, .rte .h2, .h2 {
    font-size: #{$content-h2__font-size}em;
}

// @name Title h3
$content-h3__font-size: 2.2;
.rte h3, .rte .h3, .h3 {
    font-size: #{$content-h3__font-size}em;
}

.rte {
    // @name Paragraph
    $content-paragraph__font-size: 1.5;
    p {
        font-size: #{$content-paragraph__font-size}em;
    }
}
