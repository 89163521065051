// @name Agenda
// @description Sections content below the content
.block-organigrame {
    &__title {
        margin-top: 0;
    }
    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        margin: 3em -0.5em 1em;
        .organigrame-item {
            width: calc((100% / 2) - 1em);
            margin: 0 0.5em 3em;
        }
    }
}
.organigrame-item {
    transition: all 0.5s ease;
    display: flex;
    &__img {
        position: relative;
        display: block;
        min-width: 150px;
        max-width: 150px;
        margin-right: 2em;
        img {
            display: block;
            width: 100%;
        }
    }
    &__wrap {
        color: $color-black;
        position: relative;
        flex-grow: 1;
        font-size: 1.3em;
        p {
            &:not(:last-child) {
                margin-bottom: 1em;
            }
        }
        a {
            color: $color-black;
        }
        .button-1 {
            color: $color-white;
            display: block;
            width: 100%;
            max-width: 250px;
            &:before {
                content: '\e028';
            }
        }
    }
    &__title {
        font-family: $typo-1;
        font-weight: $bold;
        font-size: 1.5em;
        text-transform: none;
        margin-bottom: 0.5em;
    }
    &__post {
        font-weight: $bold;
    }
}
