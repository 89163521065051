.telecharger {
    margin: 6rem 0;
    &__wrap {
        background-color: $color-2--3;
    }
    .list-document {
        &__listitems {
            padding: 4rem 3rem 4rem 2rem;
            margin: 0;
            > * {
                min-height: 5rem;
                padding: 1.5rem 1rem 1.5rem 0;
                flex: 1 1 46%;
                margin: 0 0 0 2rem;
                &:nth-child(odd) {
                    border-right: 1px solid $color-white;
                }
            }
        }
        &__title-file {
            margin-bottom: 0;
            overflow: visible;
            padding-left: 0;
            &:before {
                color: $color-white;
                top: 50%;
                transform: translateY(-50%);
            }
            a {
                display: block;
                color: $color-white;
                font-size: 1.6rem;
                font-weight: $light;
                line-height: 2;
            }
            .mo {
                font-size: 1.2rem;
            }
        }
    }
}
