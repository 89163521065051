.news {
    padding: 5.5rem 0 0;
    background-color: #f5f8fa;
    .title-decoration {
        span,
        .btn-wrap {
            background-color: #f5f8fa;
        }
    }
    &__wrap {
        margin: 3rem -0.1rem 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        > * {
            width: 25%;
            padding: 0 0.1rem 4rem;
            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0;
            }
        }
        img {
            display: block;
            margin: 0 auto;
        }
    }
    &__content {
        padding: 1rem 2rem 0;
        text-align: center;
        .category {
            display: inline-block;
            padding: 0.8rem 1rem;
            margin-bottom: 0.7rem;
            color: $color-2--3;
            font-size: 1.2rem;
            border: 1px solid $color-2--3;
        }
        a {
            color: $color-black;
            font-size: 1.8rem;
            font-weight: $black;
        }
    }
}
